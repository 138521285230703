import Vue from 'vue';
import Router, { Route, RouterOptions } from 'vue-router';
import AnalyticsService from './core/services/analytics/AnalyticsService';
import routes, { RouteNames } from './routes';
import FeaturesService from './core/services/FeaturesService';
import {
  GET_CURRENT_USER,
  GET_USER_PERMISSIONS
} from './core/services/store/user.module';
import { UserRole } from './core/common/UserRole';
import { Features } from './core/common/Features';
import { IPermission } from './api/ApplicationPermissions';

Vue.use(Router);

export enum CheckForAccessType {
  Features = 0,
  Roles = 1,
  Permissions = 2
}

class JigsawVueRouter extends Router {
  constructor(options: RouterOptions) {
    super(options);
  }

  get routeNames(): Record<string, string> {
    return RouteNames;
  }

  resolve(to, current, append) {
    let resolved = super.resolve(to, current, append);
    return resolved;
  }
}

const router = new JigsawVueRouter({
  routes: routes
});

if (import.meta.env.DEV) {
  const playgroundRoutesLoader = import('./playgrounds/routes');

  playgroundRoutesLoader.then(({ playgroundRoutes }) => {
    router.addRoute(playgroundRoutes);
  });
}

router.beforeEach((to: Route, from: Route, next: Function) => {
  const featureCheck = to.matched.some(
    (record) => record.meta.features && record.meta.features.value?.length
  );
  const roleCheck = to.matched.some(
    (record) => record.meta.roles && record.meta.roles.value?.length
  );
  const permissionCheck = to.matched.some(
    (record) => record.meta.permissions && record.meta.permissions.value?.length
  );
  if (featureCheck || roleCheck || permissionCheck) {
    const featuresAllowed = featureCheck
      ? checkForAccess(
          CheckForAccessType.Features,
          to.meta.features.value,
          to.meta.features.allRequired
        )
      : true;

    const rolesAllowed = roleCheck
      ? checkForAccess(
          CheckForAccessType.Roles,
          to.meta.roles.value,
          to.meta.roles.allRequired
        )
      : true;

    const permissionsAllowed = permissionCheck
      ? checkForAccess(
          CheckForAccessType.Permissions,
          to.meta.permissions.value,
          to.meta.permissions.allRequired
        )
      : true;
    if (featuresAllowed && rolesAllowed && permissionsAllowed) {
      next();
    } else {
      console.warn('No permission to the route: ', to.path);
      next({ name: RouteNames.home });
    }
  } else {
    next();
  }
});

router.afterEach((to: Route, from: Route) => {
  AnalyticsService.trackPageView(to.name);
});

const checkForAccess = (
  type: CheckForAccessType,
  values: Array<Features | UserRole | IPermission>,
  allRequired: boolean
): boolean => {
  switch (type) {
    case CheckForAccessType.Features: {
      return allRequired
        ? values.every((feature: Features) =>
            FeaturesService.hasFeature(feature)
          )
        : values.some((feature: Features) =>
            FeaturesService.hasFeature(feature)
          );
    }
    case CheckForAccessType.Roles: {
      const user = Vue.$globalStore.getters[GET_CURRENT_USER];
      if (!user?.roles?.length) {
        return false;
      }
      const hasRole = (role: UserRole): boolean => user.roles.includes(role);
      return allRequired ? values.every(hasRole) : values.some(hasRole);
    }
    case CheckForAccessType.Permissions: {
      const permissions = Vue.$globalStore.getters[GET_USER_PERMISSIONS];
      if (!permissions?.length) {
        return false;
      }
      const hasPermission = (permission: IPermission): boolean =>
        permissions.includes(permission.key);
      return allRequired
        ? values.every(hasPermission)
        : values.some(hasPermission);
    }
  }
};

export default router;
