/* global pendo */

import { CurrentAccount, CurrentUserProfileEditDto } from '@/api/models';
import appConfig from '@/core/config/appConfig';
import i18nService from '../i18n.service';
import appInfo from '@/app-info.json';

export enum PendoEventName {
  EDGE_SELECTED = 'Line Selected',
  EDGE_LABEL_EDITING_STARTED = 'Line Label Editing Started',
  EDGE_LABEL_ADDED = 'Line Label Added',
  EDGE_LABEL_EDITED = 'Line Label Edited'
}

export type PendoEdgeEventData = {
  edgeId: string;
  lineType: string;
};

export default class PendoService {
  public static setAuthenticatedUser(
    user: CurrentUserProfileEditDto,
    account: CurrentAccount
  ) {
    if (appConfig?.pendo?.enabled) {
      if (pendo) {
        const additionalDetail = appConfig.pendo?.additionalDetail ?? false;
        const language = user?.language ?? i18nService.defaultLanguage;
        const pendoData = {
          visitor: {
            id: additionalDetail
              ? user.emailAddress
              : window.location.hostname + ':' + user.userId,
            email: additionalDetail ? user.emailAddress : '',
            applanguage: additionalDetail ? language : '',
            authenticationSource: additionalDetail
              ? user.authenticationSource
              : '',
            hostname: window.location.hostname,
            licenced: user.isLicensed,
            roles: user.roles,
            totalCount: user.documentCounts.totalCount,
            stepsCount: user.documentCounts.stepsCount,
            structureDiagramCount: user.documentCounts.structureDiagramCount
          },
          account: {
            id: !user.customerId ? window.location.hostname : user.customerId,
            hostname: window.location.hostname,
            version: appInfo.version,
            buildDate: appInfo.buildDate,
            features: user.features,
            tourGuide: appConfig.pendo.tourGuide,
            totalCount: account.documentCounts.totalCount,
            stepsCount: account.documentCounts.stepsCount,
            structureDiagramCount: account.documentCounts?.structureDiagramCount
          }
        };

        pendo.initialize(pendoData);
      } else {
        console.error('Pendo not injected');
      }
    }
  }

  public static injectPendo(apiKey) {
    try {
      if (appConfig?.pendo?.enabled) {
        (function (apiKey) {
          (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = [
              'initialize',
              'identify',
              'updateOptions',
              'pageLoad',
              'track'
            ];
            for (w = 0, x = v.length; w < x; ++w)
              (function (m) {
                o[m] =
                  o[m] ||
                  function () {
                    o._q[m === v[0] ? 'unshift' : 'push'](
                      [m].concat([].slice.call(arguments, 0))
                    );
                  };
              })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
          })(window, document, 'script', 'pendo');
        })(apiKey);
      }
    } catch (e) {
      console.debug('Pendo failure', e);
    }
  }

  public static triggerPendoEvent(
    eventName: PendoEventName,
    eventData?: PendoEdgeEventData
  ) {
    if (!appConfig?.pendo?.enabled || !pendo) return;
    // console.log(`[PENDO] FIRING EVENT: ${eventName}`, eventData);
    pendo.track(eventName, eventData);
  }
}
