<template>
  <div>
    <slot :on-click="pickFile"></slot>
    <ImportFileModal
      v-if="importDefinitions.length > 1"
      :show-modal="showImportModal"
      :loading="loading"
      :import-definitions="importDefinitions"
      :selected-import-definition="selectedImportDefinition"
      :dropzone-options="dropzoneOptions"
      :import-type="importType"
      @success="onUploadSuccess"
      @error="handleGenericError"
      @closed="showImportModal = false"
      @select:import-definition="selectedImportDefinition = $event"
    />
    <ImportDropzone
      v-if="importDefinitions.length === 1 || isPowerPointImport"
      ref="importDropzone"
      class="pointer-events-none hidden"
      :dropzone-options="dropzoneOptions"
      @success="onUploadSuccess"
      @error="handleGenericError"
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { DropzoneOptions } from 'dropzone';
import ImportDropzone from '@/view/pages/hub/components/imports/ImportDropzone.vue';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import {
  ImportDefinitionDto,
  ImportedFileResultDto,
  ImportType,
  ProcessImportedFileDto
} from '@/api/models';
import ImportsApiService from '@/api/ImportsApiService';
import ImportFileModal from '@/view/pages/hub/components/imports/ImportFileModal.vue';
type ProcessImportedFileArgs = {
  file: File;
  binaryObjectId: string;
  importDefinitionId: number;
};
@Component({
  name: 'ImportFile',
  components: {
    ImportFileModal,
    ImportDropzone
  }
})
class ImportFile extends CreateDocumentMixin {
  @Prop({ type: Object, required: false })
  dropzoneOptions: DropzoneOptions;
  @Prop({ type: Number, required: true })
  importType: ImportType;
  selectedImportDefinition: ImportDefinitionDto = null;
  importDefinitions: ImportDefinitionDto[] = [];
  showImportModal = false;
  loading = false;
  $refs!: {
    importDropzone: ImportDropzone;
  };

  get isPowerPointImport(): boolean {
    return this.importType === ImportType.PowerPoint;
  }
  async onUploadSuccess(data): Promise<void> {
    this.loading = true;
    const result = await this.processUploaded(data);
    if (result) {
      await this.createNewDocumentFromImport(
        false,
        result,
        this.isPowerPointImport
      );
    }
    this.loading = false;
    this.showImportModal = false;
  }
  async processUploaded({
    file,
    binaryObjectId,
    importDefinitionId
  }: ProcessImportedFileArgs): Promise<ImportedFileResultDto | null> {
    try {
      const response = await ImportsApiService.importFile({
        binaryObjectId,
        name: file.name.replace('.pptx', '').replace('.ppt', ''),
        importDefinitionId:
          importDefinitionId ?? this.selectedImportDefinition?.id,
        importType: this.importType
      } as ProcessImportedFileDto);
      if (!response?.data?.result.importId) {
        this.handleGenericError();
        return null;
      }
      return response?.data.result;
    } catch (err) {
      this.handleProcessImportError(err);
    }
    return null;
  }
  handleProcessImportError(err: AxiosError): void {
    if (err.response?.status == 403) {
      this.$notify({
        title: '',
        text: this.$t('PLEASE_REMOVE_PASSWORD_FROM_FILE_AND_TRY_AGAIN'),
        type: 'warning'
      });
      return;
    }
    if (err?.response?.data?.error?.message) {
      this.$notify({
        title: this.$t('IMPORT_FAILED'),
        text: err.response.data.error.message,
        type: 'error'
      });
      return;
    }
    this.handleGenericError();
  }
  handleGenericError(): void {
    this.$notify({
      title: this.$t('IMPORT_FAILED'),
      text: this.$t('THIS_IMPORT_COULD_NOT_BE_ADDED'),
      type: 'error'
    });
    this.showImportModal = false;
  }
  async pickFile(): Promise<void> {
    if (this.importType === ImportType.PowerPoint) {
      (this.$refs.importDropzone.$el as HTMLElement).click();
    } else {
      try {
        await this.getImportDefinitions();
        this.$nextTick(() => {
          if (this.importDefinitions.length === 1) {
            (this.$refs.importDropzone.$el as HTMLElement).click();
          } else {
            this.showImportModal = true;
          }
        });
      } catch {
        this.$notify({
          title: this.$t('GET_IMPORT_DEFINITIONS_ERROR'),
          type: 'error'
        });
      }
    }
  }
  async getImportDefinitions(): Promise<void> {
    try {
      const result = await ImportsApiService.getAll();
      if (result?.data?.result?.length) {
        this.importDefinitions = result.data.result;
        this.selectedImportDefinition = this.importDefinitions[0];
        return;
      }
    } catch {
      this.$notify({
        title: this.$t('GET_IMPORT_DEFINITIONS_ERROR'),
        type: 'error'
      });
    }
    throw new Error();
  }
}
export default ImportFile;
</script>
