import { Module } from 'vuex';

import groupBy from 'lodash/groupBy';
import i18n from '@/core/plugins/vue-i18n';

import DataPropertyDefinitionsApiService from '@/api/DataPropertyDefinitionsApiService';
import {
  DataPropertyDefinitionDto,
  DataPropertyValueScope
} from '@/api/models';

// namespace
export const DATAPROPERTYDEFINITIONS_NAMESPACE = 'dataPropertyDefinitions';

// actions
export const LOAD_ALL_DATAPROPERTYDEFINITIONS =
  'loadAllDataPropertyDefinitions';
export const LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID =
  'loadDataPropertyDefinitionsByDocumentId';
export const LOAD_DATAPROPERTYDEFINITIONS_BY_VALUE_SCOPE =
  'loadDataPropertyDefinitionsByValueScope';
export const CREATE_DATAPROPERTYDEFINITION = 'createDataPropertyDefinition';
export const DELETE_DATAPROPERTYDEFINITION = 'deleteDataPropertyDefinition';
export const UPDATE_DATAPROPERTYDEFINITION = 'updateDataPropertyDefinition';

// mutations
export const SET_DATAPROPERTYDEFINITIONS = 'setDataPropertyDefinitions';
export const SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE =
  'setDataPropertyDefinitionsByValueScope';
export const SET_CREATE_DATAPROPERTYDEFINITION =
  'setCreateDataPropertyDefinition';
export const SET_DELETE_DATAPROPERTYDEFINITION =
  'setDeleteDataPropertyDefinition';
export const SET_UPDATE_DATAPROPERTYDEFINITION =
  'setUpdateDataPropertyDefinition';

// getters
export const GET_ALL_DATAPROPERTYDEFINITIONS = 'getDataPropertyDefinitions';
export const GET_DOCUMENT_DATAPROPERTYDEFINITIONS =
  'getDocumentDataPropertyDefinitions';
export const GET_DECORATOR_DATAPROPERTYDEFINITIONS =
  'getDecoratorDataPropertyDefinitions';
export const GET_ENTITY_TYPE_DATAPROPERTYDEFINITIONS =
  'getEntityTypePropertyDefinitions';
export const GET_SYSTEM_DATAPROPERTYDEFINITIONS =
  'getSystemDataPropertyDefinitions';
export const GET_PEOPLE_DATAPROPERTYDEFINITIONS =
  'getPeopleDataPropertyDefinitions';
interface State {
  systemDefinitions: DataPropertyDefinitionDto[];
  decoratorDefinitions: DataPropertyDefinitionDto[];
  documentDefinitions: DataPropertyDefinitionDto[];
  entityTypeDefinitions: DataPropertyDefinitionDto[];
  peopleDefinitions: DataPropertyDefinitionDto[];
}

const datapropertydefinitionsModule: Module<State, any> = {
  namespaced: true,

  state: {
    systemDefinitions: [],
    decoratorDefinitions: [],
    documentDefinitions: [],
    entityTypeDefinitions: [],
    peopleDefinitions: []
  },

  getters: {
    [GET_ALL_DATAPROPERTYDEFINITIONS](state) {
      return [
        ...state.systemDefinitions,
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.entityTypeDefinitions,
        ...state.peopleDefinitions
      ];
    },

    [GET_DOCUMENT_DATAPROPERTYDEFINITIONS](state) {
      return state.documentDefinitions.map((item) => ({
        ...item,
        label: i18n.t(item.label)
      }));
    },

    [GET_SYSTEM_DATAPROPERTYDEFINITIONS](state) {
      return state.systemDefinitions.map((item) => ({
        ...item,
        label: i18n.t(item.label)
      }));
    },

    [GET_DECORATOR_DATAPROPERTYDEFINITIONS](state) {
      return state.decoratorDefinitions.map((item) => ({
        ...item,
        label: i18n.t(item.label)
      }));
    },

    [GET_ENTITY_TYPE_DATAPROPERTYDEFINITIONS](state) {
      return state.entityTypeDefinitions.map((item) => ({
        ...item,
        label: i18n.t(item.label)
      }));
    },

    [GET_PEOPLE_DATAPROPERTYDEFINITIONS](state) {
      return state.peopleDefinitions.map((item) => ({
        ...item,
        label: i18n.t(item.label)
      }));
    }
  },

  mutations: {
    [SET_DATAPROPERTYDEFINITIONS](state, payload: DataPropertyDefinitionDto[]) {
      const definitionsGroupedByScope = groupBy(payload, (d) => d.valueScope);

      let decoratorDefinitions = [];
      let documentDefinitions = [];
      let systemDefinitions = [];
      let entityTypeDefinition = [];
      let peopleDefinitions = [];
      for (const definitionGroup in definitionsGroupedByScope) {
        switch (parseInt(definitionGroup)) {
          case DataPropertyValueScope.Decorator:
            decoratorDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.Diagram:
            documentDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.System:
            systemDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.EntityType:
            entityTypeDefinition = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.People:
            peopleDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          default:
            throw 'Unknown data property definition value scope';
        }
      }
      state.decoratorDefinitions = decoratorDefinitions;
      state.documentDefinitions = documentDefinitions;
      state.systemDefinitions = systemDefinitions;
      state.entityTypeDefinitions = entityTypeDefinition;
      state.peopleDefinitions = peopleDefinitions;
    },

    [SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE](
      state,
      payload: DataPropertyDefinitionDto[]
    ) {
      const valueScope = payload?.[0]?.valueScope;

      if (typeof valueScope !== 'number') {
        return;
      }

      if (payload.some((definition) => definition.valueScope !== valueScope)) {
        throw 'Definitions do not share a common value scope';
      }

      switch (valueScope) {
        case DataPropertyValueScope.Decorator:
          state.decoratorDefinitions = payload;
          break;
        case DataPropertyValueScope.Diagram:
          state.documentDefinitions = payload;
          break;
        case DataPropertyValueScope.System:
          state.systemDefinitions = payload;
          break;
        case DataPropertyValueScope.EntityType:
          state.entityTypeDefinitions = payload;
          break;
        case DataPropertyValueScope.People:
          state.peopleDefinitions = payload;
          break;
        default:
          throw 'Unknown data property definition value scope';
      }
    },

    [SET_CREATE_DATAPROPERTYDEFINITION](
      state,
      payload: DataPropertyDefinitionDto
    ) {
      switch (payload.valueScope) {
        case DataPropertyValueScope.Decorator:
          state.decoratorDefinitions.push(payload);
          break;
        case DataPropertyValueScope.Diagram:
          state.documentDefinitions.push(payload);
          break;
        case DataPropertyValueScope.System:
          state.systemDefinitions.push(payload);
          break;
        case DataPropertyValueScope.EntityType:
          state.entityTypeDefinitions.push(payload);
          break;
        case DataPropertyValueScope.People:
          state.peopleDefinitions.push(payload);
          break;

        default:
          throw 'Unknown data property definition value scope';
      }
    },

    [SET_DELETE_DATAPROPERTYDEFINITION](state, payload: number) {
      const definitions = [
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.systemDefinitions,
        ...state.entityTypeDefinitions,
        ...state.peopleDefinitions
      ];

      const filteredDefinitions = definitions.filter(
        (definition) => definition.id !== payload
      );
      this.commit(
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${SET_DATAPROPERTYDEFINITIONS}`,
        filteredDefinitions
      );
    },

    [SET_UPDATE_DATAPROPERTYDEFINITION](
      state,
      payload: DataPropertyDefinitionDto
    ) {
      const definitions = [
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.systemDefinitions,
        ...state.entityTypeDefinitions,
        ...state.peopleDefinitions
      ];

      // update definition
      const dataPropertyIndex = definitions.findIndex(
        (dp) => dp.id === payload.id
      );
      if (dataPropertyIndex > -1) {
        definitions[dataPropertyIndex] = payload;
      }

      // add child definitions
      if (payload.childDefinitions.length) {
        const newChildDefinitionsItems = payload.childDefinitions.filter(
          (childDefinition) =>
            definitions.some(
              (definition) => definition.id !== childDefinition.id
            )
        );

        if (newChildDefinitionsItems.length) {
          definitions.push(...newChildDefinitionsItems);
        }
      }

      this.commit(
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${SET_DATAPROPERTYDEFINITIONS}`,
        definitions
      );
    }
  },

  actions: {
    //You probably don't want to use this! Use LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID instead
    async [LOAD_ALL_DATAPROPERTYDEFINITIONS](context) {
      const response = await DataPropertyDefinitionsApiService.getAll();
      context.commit(SET_DATAPROPERTYDEFINITIONS, response.data.result);
      return response.data.result;
    },

    async [LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID](
      context,
      payload: number
    ) {
      const response =
        await DataPropertyDefinitionsApiService.getDataPropertyDefinitionsByDocumentId(
          { id: payload }
        );

      if (response.status === 200) {
        context.commit(
          SET_DATAPROPERTYDEFINITIONS,
          response.data.result.definitions
        );
        return response.data.result.definitions;
      }
    },

    async [LOAD_DATAPROPERTYDEFINITIONS_BY_VALUE_SCOPE](
      context,
      scope: DataPropertyValueScope
    ) {
      const response =
        await DataPropertyDefinitionsApiService.getDataPropertyDefinitionsByScope(
          {
            scope: scope
          }
        );

      if (response.status == 200) {
        context.commit(
          SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE,
          response.data.result
        );
      }
    },

    async [CREATE_DATAPROPERTYDEFINITION](
      context,
      payload: DataPropertyDefinitionDto
    ) {
      const response = await DataPropertyDefinitionsApiService.create(payload);

      if (response.status === 200) {
        const definitionId = response.data.result;
        context.commit(SET_CREATE_DATAPROPERTYDEFINITION, {
          ...payload,
          id: definitionId
        });
        return definitionId;
      }
    },

    async [DELETE_DATAPROPERTYDEFINITION](context, payload) {
      const result = await DataPropertyDefinitionsApiService.delete({
        id: payload.id
      });
      if (result.status === 200) {
        context.commit(SET_DELETE_DATAPROPERTYDEFINITION, payload.id);
      }
    },

    async [UPDATE_DATAPROPERTYDEFINITION](context, payload) {
      const result = await DataPropertyDefinitionsApiService.update(payload);
      if (result.status === 200) {
        const updatedDataPropertyDefinitionResponse =
          await DataPropertyDefinitionsApiService.getDataPropertyDefinitionForView(
            { id: payload.id }
          );
        const updatedDataPropertyDefinition =
          updatedDataPropertyDefinitionResponse.data.result
            .dataPropertyDefinition;

        context.commit(
          SET_UPDATE_DATAPROPERTYDEFINITION,
          updatedDataPropertyDefinition
        );

        return updatedDataPropertyDefinition;
      }
    }
  }
};
export default datapropertydefinitionsModule;
