import { CompositeNodeStyleDto, CompositeShape } from '@/api/models';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import {
  Class,
  GeneralPath,
  ICanvasContext,
  IInputModeContext,
  ILassoTestable,
  INode,
  INodeStyle,
  Insets,
  InsetsConvertible,
  IRectangle,
  IRenderContext,
  MutableRectangle,
  NodeStyleBase,
  Point,
  Rect,
  ShapeNodeShape,
  SimpleNode,
  SvgVisual,
  SvgVisualGroup,
  Visual
} from 'yfiles';

export type StyleDefinition = {
  nodeStyle: INodeStyle;
  insets?: InsetsConvertible;
};

const dummyNode = new SimpleNode();
const dummyLayout = new MutableRectangle();
dummyNode.layout = dummyLayout;

function configureStyle(node: INode, styleDefinition: StyleDefinition): INode {
  dummyLayout.reshape(node.layout);
  dummyNode.style = styleDefinition.nodeStyle;
  dummyNode.labels = node.labels;
  dummyNode.tag = node.tag;
  dummyNode.ports = node.ports;
  return dummyNode;
}

export default class CompositeNodeStyle extends NodeStyleBase {
  private readonly noMainInsets: boolean;
  private mainStyle: INodeStyle;

  private _shape: CompositeShape;
  private _styleDefinitions: StyleDefinition[];

  public get styleDefinitions(): StyleDefinition[] {
    return this._styleDefinitions;
  }

  public get shape(): CompositeShape {
    return this._shape;
  }

  constructor(shape: CompositeShape, styleDefinitions: StyleDefinition[]) {
    super();
    this._styleDefinitions = styleDefinitions;
    this._shape = shape;
    styleDefinitions.forEach((s) => {
      if (s.insets) {
        s.insets = Insets.from(s.insets);
      } else {
        s.insets = Insets.EMPTY;
      }
    });
    if (styleDefinitions.length < 1) {
      throw new Error('Specify at least one style definition!');
    }
    this.mainStyle = styleDefinitions[0].nodeStyle;
    this.noMainInsets = (styleDefinitions[0].insets as Insets).isEmpty;
  }

  public static calculateInsets(
    insets: Insets,
    node: INode
  ): InsetsConvertible {
    const left = (node.layout.width * insets.left) / 100;
    const top = (node.layout.height * insets.top) / 100;
    const right = (node.layout.width * insets.right) / 100;
    const bottom = (node.layout.height * insets.bottom) / 100;
    return { left: left, top: top, right: right, bottom: bottom };
  }

  createVisual(context: IRenderContext, node: INode): Visual | null {
    const styleDefinitions = this.styleDefinitions;
    const group = new SvgVisualGroup();
    dummyNode.labels = node.labels;
    dummyNode.tag = node.tag;
    dummyNode.ports = node.ports;
    for (let i = 0; i < styleDefinitions.length; i++) {
      const styleDefinition = styleDefinitions[i];
      dummyLayout.reshape(
        node.layout
          .toRect()
          .getReduced(
            CompositeNodeStyle.calculateInsets(
              styleDefinition.insets as Insets,
              node
            )
          )
      );
      dummyNode.style = styleDefinition.nodeStyle;
      const styleVisual = styleDefinition.nodeStyle.renderer
        .getVisualCreator(dummyNode, dummyNode.style)
        .createVisual(context) as SvgVisual;
      group.add(styleVisual);
    }
    return group;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    node: INode
  ): Visual | null {
    const styleDefinitions = this.styleDefinitions;
    const group = oldVisual as SvgVisualGroup;
    dummyNode.labels = node.labels;
    dummyNode.tag = node.tag;
    dummyNode.ports = node.ports;
    for (let i = 0; i < styleDefinitions.length; i++) {
      const styleDefinition = styleDefinitions[i];
      // slight performance improvement and less garbage creation for quick update calls.
      if (i === 0 && this.noMainInsets) {
        dummyLayout.reshape(node.layout);
      } else {
        const insets = styleDefinition.insets as Insets;

        dummyLayout.reshape(
          node.layout
            .toRect()
            .getReduced(CompositeNodeStyle.calculateInsets(insets, node))
        );
      }

      dummyNode.style = styleDefinition.nodeStyle;
      const oldInnerVisual = group.children.get(i);
      const styleVisual =
        oldInnerVisual === null
          ? (styleDefinition.nodeStyle.renderer
              .getVisualCreator(dummyNode, dummyNode.style)
              .createVisual(context) as SvgVisual)
          : (styleDefinition.nodeStyle.renderer
              .getVisualCreator(dummyNode, dummyNode.style)
              .updateVisual(context, oldInnerVisual) as SvgVisual);
      if (styleVisual !== oldInnerVisual) {
        group.children.set(i, styleVisual);
      }
    }

    return group;
  }

  configureMainStyle(node: INode): INode {
    // in case we don't have insets for the main node style, we can use the original node
    // because the layout is just the same
    if (this.noMainInsets) {
      return node;
    }
    return configureStyle(node, this.styleDefinitions[0]);
  }

  getBounds(context: ICanvasContext, node: INode): Rect {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getBoundsProvider(dummyNode, this.mainStyle)
      .getBounds(context);
  }

  getIntersection(node: INode, inner: Point, outer: Point): Point | null {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getShapeGeometry(dummyNode, this.mainStyle)
      .getIntersection(inner, outer);
  }

  getOutline(node: INode): GeneralPath | null {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getShapeGeometry(dummyNode, this.mainStyle)
      .getOutline();
  }

  isHit(context: IInputModeContext, location: Point, node: INode): boolean {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getHitTestable(dummyNode, this.mainStyle)
      .isHit(context, location);
  }

  isInBox(context: IInputModeContext, rectangle: Rect, node: INode): boolean {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getMarqueeTestable(dummyNode, this.mainStyle)
      .isInBox(context, rectangle);
  }

  isInPath(
    context: IInputModeContext,
    path: GeneralPath,
    node: INode
  ): boolean {
    const dummyNode = this.configureMainStyle(node);
    const testable = this.mainStyle.renderer
      .getContext(dummyNode, this.mainStyle)
      .lookup(ILassoTestable.$class) as ILassoTestable;
    if (testable) {
      return testable.isInPath(context, path);
    } else {
      return super.isInPath(context, path, node);
    }
  }

  isInside(node: INode, location: Point): boolean {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getShapeGeometry(dummyNode, this.mainStyle)
      .isInside(location);
  }

  isVisible(context: ICanvasContext, rectangle: Rect, node: INode): boolean {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getVisibilityTestable(dummyNode, this.mainStyle)
      .isVisible(context, rectangle);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  lookup(node: INode, type: Class): Object | null {
    const dummyNode = this.configureMainStyle(node);
    return this.mainStyle.renderer
      .getContext(dummyNode, this.mainStyle)
      .lookup(type);
  }
}
