export enum CommandType {
  'bold',
  'underline',
  'italic',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  'customIndentBlock',
  'customOutdentBlock',
  'bulletedList',
  'numberedList',
  'listStyle',
  'listMarkerColor',
  'imageinsert',
  'heading',
  'backgroundColor',
  'borderColor',
  'borderWidth',
  'insertPageBreak',
  'insertTable',
  'undo',
  'redo',
  'cut',
  'copy',
  'paste',
  'strikethrough',
  'subscript',
  'superscript',
  'lineHeight',
  'alignment'
}
