import { Module } from 'vuex';
import Filter from '../search/filters/Filter';

export const SEARCH_NAMESPACE = 'search';
export const GET_SEARCH_FILTERS = 'getSearchFilters';
export const SET_SEARCH_FILTERS = 'setSearchFilters';

const getInitialState = () => {
  return {
    document: null,
    searchFilters: []
  };
};

interface State {
  searchFilters: Filter[];
}

const searchModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_SEARCH_FILTERS](state) {
      return state.searchFilters;
    }
  },
  actions: {
    [SET_SEARCH_FILTERS](ctx, payload) {
      ctx.commit(SET_SEARCH_FILTERS, payload);
    }
  },
  mutations: {
    [SET_SEARCH_FILTERS](state, payload) {
      state.searchFilters = payload;
    }
  }
};

export default searchModule;
