import { convertSvgElementToImage } from '@/core/utils/common.utils';
import { GraphComponent, ImageNodeStyle } from 'yfiles';
import ExportOptions from '../ExportOptions';
import ExportUtils from '../ExportUtils';
import IExportResult from './IExportResult';
import IExportProvider from './IExportProvider';
import { ExportPageElementType } from '../ExportPageElementType';
import JPoint from '@/core/common/JPoint';
import { PageElementPosition } from '@/api/models';
import JRect from '@/core/common/JRect';
import { SvgProvider, VsdxExportConfiguration } from 'vsdx-export-for-yfiles';

const vsdxImport = () => import('vsdx-export-for-yfiles');

export default class VisioExportProvider implements IExportProvider {
  private _fileExtension = 'vsdx';
  private _mimeType = 'application/vnd.ms-visio.drawing';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent
  ): Promise<IExportResult> {
    await this.appendAdditionalElements(graphComponent, options);
    const vsdx = await vsdxImport();
    const vsdxExport = new vsdx.VsdxExport();

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: await vsdxExport.writeBlob(
        graphComponent,
        await this.getExportConfig(vsdx)
      )
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  private async getExportConfig(vsdx: {
    VsdxExportConfiguration: { createDefault: () => VsdxExportConfiguration };
    SvgProvider: typeof SvgProvider;
  }): Promise<any> {
    const config = vsdx.VsdxExportConfiguration.createDefault();

    config.cssStyleSheet = '';
    config.evaluateFormulas = false;
    config.masterProviders.add(new vsdx.SvgProvider());
    return config;
  }

  private async appendAdditionalElements(
    graphComponent: GraphComponent,
    options: ExportOptions
  ): Promise<void> {
    const exportPage = options.metadata.currentPage;
    const additionalElementTypes: ExportPageElementType[] = [];
    if (ExportUtils.shouldIncludeLegend(options.document, exportPage.page)) {
      additionalElementTypes.push(ExportPageElementType.Legend);
    }
    if (ExportUtils.shouldIncludeLogo(options.document, exportPage.page)) {
      additionalElementTypes.push(ExportPageElementType.Logo);
    }
    await exportPage.generateAdditionalElements(
      options,
      additionalElementTypes
    );

    for (const el of exportPage.additionalElements) {
      const svg = await el.toSvgAsync();
      const image = await convertSvgElementToImage(svg, 'png');

      let position: JPoint;
      if (<PageElementPosition>el.options.position in PageElementPosition) {
        position = ExportUtils.getAdditionalElementPosition(
          JRect.fromYFiles(graphComponent.contentRect),
          el.options.position,
          image.height,
          image.width
        );
      } else {
        position = new JPoint(
          (el.options.position as JPoint).x * graphComponent.contentRect.width +
            graphComponent.contentMargins.left +
            graphComponent.contentMargins.right,
          (el.options.position as JPoint).y *
            graphComponent.contentRect.height +
            graphComponent.contentMargins.top +
            graphComponent.contentMargins.bottom
        );

        position.x = graphComponent.contentRect.x + position.x;
        position.y = graphComponent.contentRect.y + position.y;
      }

      const style = new ImageNodeStyle({ image: image.src });
      const node = graphComponent.graph.createNode({
        layout: {
          x: position.x,
          y: position.y,
          width: image.width,
          height: image.height
        }
      });
      graphComponent.graph.setStyle(node, style);
    }
  }
}
