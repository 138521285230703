//@ts-nocheck
import clamp from 'lodash/clamp';
import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  INode,
  Point,
  OrientedRectangle,
  ILookup,
  IEnumerable,
  List,
  BaseClass,
  Size
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import JigsawInteriorNodeLabelModelParameter from './JigsawInteriorNodeLabelModelParameter';
export default class JigsawInteriorNodeLabelModel extends BaseClass(
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
) {
  /**
   * SY
   * The center point now only refer to the y axis.
   * All labels are full width of the node and the anchor point is to the left of the node (-1), 0 being the center on the y axis.
   * Normally we'd remove all of the other positions within here but we are not sure if this change will be permanent at this time
   */

  /**
   * When true, will ensure the label is not allowed outside the bounds of the node
   */
  private constrainToNode: boolean = true;
  /**
   * These are all position vectors, they are used to anchor the labels
   */
  static get NORTH(): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, -1)
    );
  }
  // static get NORTH_EAST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(1, -1)
  //   );
  // }
  // static get EAST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(1, 0)
  //   );
  // }
  // static get SOUTH_EAST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(1, 1)
  //   );
  // }
  static get SOUTH(): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, 1)
    );
  }

  // static get SOUTH_WEST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(-1, 1)
  //   );
  // }
  // static get WEST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(-1, 0)
  //   );
  // }
  // static get NORTH_WEST(): JigsawInteriorNodeLabelModelParameter {
  //   return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
  //     new Point(-1, -1)
  //   );
  // }
  static get CENTER(): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModel().createParameterFromPositionVector(
      new Point(-1, 0)
    );
  }

  /**
   * Returns instances of the support interfaces (which are actually the model instance itself)
   */
  lookup<T>(type: Class<T>): T {
    if (type === ILabelModelParameterProvider.$class) {
      // If we request a ILabelModelParameterProvider AND we use discrete label candidates, we return the label model
      // itself, otherwise, null is returned, which means that continuous label positions are supported.
      return this;
    } else if (type === ILabelModelParameterFinder.$class) {
      // If we request a ILabelModelParameterProvider, we return the label model itself, so we can always retrieve a
      // matching parameter for a given actual position.
      return this;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const ownerNode = label.owner;
    if (
      ownerNode instanceof INode &&
      layoutParameter instanceof JigsawInteriorNodeLabelModelParameter
    ) {
      const nodeLayout = ownerNode.layout;
      const labelSize = label.preferredSize;

      // try calculate the base offset from the parameters position vector
      // if we have no vector, default to the center
      let offset: Point = this.getAnchorPosition(
        layoutParameter.positionVector,
        ownerNode,
        labelSize
      );
      if (layoutParameter.offset) {
        offset = offset.add(layoutParameter.offset);
      }

      const anchor = new Point(
        nodeLayout.x + offset.x,
        nodeLayout.y + offset.y
      );

      const labelHeight = labelSize.height;
      const labelWidth = nodeLayout.width - diagramConfig.nodeLabelPadding * 2;
      return new OrientedRectangle(anchor.x, anchor.y, labelWidth, labelHeight);
    }
    return IOrientedRectangle.EMPTY;
  }

  measureText(label: ILabel): Size {
    return label.style.renderer.getPreferredSize(label, label.style);
  }

  createDefaultParameter(): ILabelModelParameter {
    return JigsawInteriorNodeLabelModel.CENTER as unknown as ILabelModelParameter;
  }

  createParameterFromOffset(
    point: Point
  ): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModelParameter(this, {
      offset: point,
      positionVector: JigsawInteriorNodeLabelModel.CENTER.positionVector
    });
  }
  createParameterFromPositionVector(
    positionVector: Point
  ): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModelParameter(this, {
      positionVector: positionVector
    });
  }

  createParameterFromVectorAndOffset(
    vector: Point,
    offset: Point
  ): JigsawInteriorNodeLabelModelParameter {
    return new JigsawInteriorNodeLabelModelParameter(this, {
      offset: offset,
      positionVector: vector
    });
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }
  /**
   * Clamps the x axis
   * @param x
   * @param node the node to have the anchor clamped too
   * @param labelSize the visual size of the label
   * @returns the clamped value
   */
  private clampX(x: number, node: INode, labelSize: Size): number {
    return clamp(
      x,
      node.layout.x + diagramConfig.nodeLabelPadding,
      node.layout.maxX - labelSize.width - diagramConfig.nodeLabelPadding
    );
  }

  /**
   * Clamps the y axis
   * @param y
   * @param node the node to have the anchor clamped too
   * @param labelSize the visual size of the label
   * @returns the clamped value
   */
  private clampY(y: number, node: INode, labelSize: Size): number {
    return clamp(y, node.layout.y + labelSize.height, node.layout.maxY);
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    const node = label.owner as INode;
    const labelModel = model as unknown as JigsawInteriorNodeLabelModel;
    const parameter =
      label.layoutParameter as unknown as JigsawInteriorNodeLabelModelParameter;
    let x = layout.anchorLocation.x;
    let y = layout.anchorLocation.y;
    if (this.constrainToNode) {
      const labelSize = label.layout.toSize();
      x = this.clampX(x, node, labelSize);

      // Only clamp the y axis when the label is small than the node and can actually be dragged around within the node bounds
      // if the label is larger than it's container (node) we should not allow any movement, where are you going to move it too?
      if (labelSize.height < node.layout.height) {
        y = this.clampY(y, node, labelSize);
      } else {
        y = label.layout.toOrientedRectangle().anchorY;
      }
    }

    const labelLayout = new Point(x, y)
      .subtract(label.layout.anchorLocation)
      .add(parameter.offset ?? Point.ORIGIN);
    const newParameter = labelModel.createParameterFromVectorAndOffset(
      parameter.positionVector,
      labelLayout
    );
    return newParameter as unknown as ILabelModelParameter;
  }

  /**
   * Takes a direction vector relative to the node's center and returns a position
   * @param vector
   * @param node
   * @returns
   */
  public getAnchorPosition(vector: Point, node: INode, labelSize: Size): Point {
    if (!vector) {
      throw 'vector cannot be null';
    }
    if (!node) {
      throw 'node cannot be null';
    }
    if (!labelSize) {
      throw 'labelSize cannot be null';
    }
    const nodeLayout = node.layout;
    // translates the vector into a position relative to the nodes center
    let anchor: Point = null;
    // North
    if (vector.x == 0 && vector.y == -1) {
      anchor = new Point(diagramConfig.nodeLabelPadding, 0);
    }

    // North East
    else if (vector.x == 1 && vector.y == -1) {
      anchor = new Point(
        nodeLayout.width - labelSize.width - diagramConfig.nodeLabelPadding,
        0
      );
    }
    // East
    else if (vector.x == 1 && vector.y == 0) {
      anchor = new Point(
        nodeLayout.width - labelSize.width - diagramConfig.nodeLabelPadding,
        nodeLayout.height / 2 - labelSize.height / 2
      );
    }

    //South East
    else if (vector.x == 1 && vector.y == 1) {
      anchor = new Point(
        nodeLayout.width - labelSize.width - diagramConfig.nodeLabelPadding,
        nodeLayout.height - labelSize.height
      );
    }

    // South
    else if (vector.x == 0 && vector.y == 1) {
      anchor = new Point(
        diagramConfig.nodeLabelPadding,
        nodeLayout.height - labelSize.height
      );
    }

    // South West
    else if (vector.x == -1 && vector.y == 1) {
      anchor = new Point(
        diagramConfig.nodeLabelPadding,
        nodeLayout.height - labelSize.height
      );
    }

    // West
    else if (vector.x == -1 && vector.y == 0) {
      anchor = new Point(
        diagramConfig.nodeLabelPadding,
        nodeLayout.height / 2 - labelSize.height / 2
      );
    }

    // North West
    else if (vector.x == -1 && vector.y == -1) {
      anchor = new Point(diagramConfig.nodeLabelPadding, 0);
    } else {
      // Center
      anchor = new Point(
        diagramConfig.nodeLabelPadding,
        nodeLayout.height / 2 - labelSize.height / 2
      );
    }
    // labels should be anchored bottom left of the node, not top left as normal.
    // see https://docs.yworks.com/yfileshtml/#/dguide/view_item-layout#view_item-layout_labels
    return anchor.add(new Point(0, labelSize.height));
  }

  getMaxWidth(label: ILabel): number {
    const ownerNode = label.owner;

    if (!(ownerNode instanceof INode)) {
      throw 'owner is not an node';
    }

    return ownerNode.layout.width - diagramConfig.nodeLabelPadding * 2;
  }
}
