import axios, {
    AxiosResponse
} from 'axios';
import {
    PersonDto,
    ZadarResponse,
    CreateOrEditPersonDto,
    EntityDto,
    FileDto
} from './models'
export default class PeopleApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PersonDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/People/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditPersonDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/People/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/People/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static initializePeopleWithNewDefinition(body: EntityDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/People/InitializePeopleWithNewDefinition`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static importFromExcel(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/People/ImportFromExcel`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static exportToExcel(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/People/ExportToExcel`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
}