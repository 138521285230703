enum CacheType {
  Unset = 'Unset',
  LogoExport = 'LogoExport',
  DiagramLegendExport = 'LegendExport',
  MasterLegendExport = 'MasterLegendExport',
  RichTextLabelSvgElement = 'RichTextLabelSvgElement',
  RichTextLabelTextSize = 'RichTextLabelTextSize',
  ConvertedSvgToImage = 'ConvertedSvgToImage',
  ConvertedUrlToDataUrl = 'ConvertedUrlToDataUrl',
  ImageSize = 'ImageSize',
  LayoutHtml = 'LayoutHtml',
  ScaledImage = 'ScaledImage',
  TemplateThumbElement = 'TemplateThumbElement',
  PageThumbElement = 'PageThumbElement',
  CommonDiagramNodes = 'CommonDiagramNodes',
  SharesCommonDiagramNodes = 'SharesCommonDiagramNodes',
  DiagramsWithCommonNodes = 'DiagramsWithCommonNodes',
  LegendDefinition = 'LegendDefinition',
  DiagramLayout = 'DiagramLayout',
  ContentAreaByItemType = 'ContentAreaByItemType',
  CroppedImage = 'CroppedImage'
}
export default CacheType;
