import {
  DataPropertyDefinitionDto,
  DataPropertyDefinitionItemDto,
  DataPropertyDto,
  DataPropertyValueScope,
  ThemeDto
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import appConsts from '@/core/config/appConsts';
import DataPropertyStyleService from '@/core/services/graph/DataPropertyStyleService';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import GraphElementsComparer from '@/core/utils/GraphElementsComparer';
import GraphElementsHashGenerator from '@/core/utils/GraphElementsHashGenerator';
import { IEdge, IGraph, INode } from 'yfiles';
import LegendItem from './LegendItem';
import { LegendItemType } from './LegendItemType';
import LegendUtils from './LegendUtils';
import i18n from '@/core/plugins/vue-i18n';
import Vue from 'vue';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  GET_ALL_DATAPROPERTYDEFINITIONS
} from '@/core/services/store/datapropertydefinitions.module';
import {
  DOCUMENT_NAMESPACE,
  GET_CURRENT_THEME
} from '@/core/services/store/document.module';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames
} from '@/core/common/DataPropertyDisplayType';
import ILegendDefinition from './ILegendDefinition';
import { LegendType } from './LegendType';
import { b64EncodeUnicode } from '@/core/utils/common.utils';

export default class LegendGenerator {
  private get currentTheme(): ThemeDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_CURRENT_THEME}`
    ];
  }

  private get dataPropertyDefinitions(): DataPropertyDefinitionDto[] {
    return Vue.$globalStore.getters[
      `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${GET_ALL_DATAPROPERTYDEFINITIONS}`
    ];
  }

  private graph: IGraph;
  private oldDefinition: ILegendDefinition = null;

  private isVisibleOnLegend = (id: number) =>
    id == appConsts.JURISDICTION_DEFINITION_ID ||
    id == appConsts.STATE_DEFINITION_ID ||
    DataPropertyUtils.isSymbolDataPropertyDefinition(id);

  constructor(graph: IGraph, oldDefinition?: ILegendDefinition) {
    this.graph = graph;
    this.oldDefinition = oldDefinition;
  }

  public generate(ignoreSaved: boolean = false): LegendItem[] {
    const newItems: LegendItem[] = [];
    const dataProps: DataPropertyDto[] = [];
    const edges = this.graph.edges;
    let nodeData = [
      ...this.graph.nodes.map((node) => {
        const key = GraphElementsHashGenerator.getNodeHashKey(node);
        const legacyKey = GraphElementsHashGenerator.getNodeHashKey(node, true);

        return {
          node,
          key,
          legacyKey,
          isIncluded: this.isNodeIncluded(key, legacyKey)
        };
      })
    ];
    // If at least one node of each type is included, we want to add it to the legend
    // Only applicable for diagram exports
    nodeData = nodeData.sort((a) => (a.isIncluded ? -1 : 1));

    for (const data of nodeData) {
      const node = data.node;
      const isSameStyleForGroup = node.tag.isGroupNode
        ? !!newItems.filter((n) => {
            return (
              n.data.tag.isGroupNode &&
              // don't compare stroke width as it's hardcoded for legend items
              n.data.tag.grouping.fillColor === node.tag.grouping.fillColor &&
              n.data.tag.grouping.strokeDash === node.tag.grouping.strokeDash &&
              n.data.tag.grouping.strokeColor ===
                node.tag.grouping.strokeColor &&
              n.data.tag.groupUuid !== node.tag.groupUuid
            );
          }).length
        : false;

      const itemsAreEqual = newItems.some(
        (item) =>
          item.type == LegendItemType.Node &&
          GraphElementsComparer.nodesEqual(node, <INode>item.data)
      );

      if (
        node.tag.annotationType != AnnotationType.Text &&
        node.tag.annotationType != AnnotationType.ArrowHead &&
        node.tag.annotationType != AnnotationType.EdgeToNowhereNode &&
        !itemsAreEqual &&
        !isSameStyleForGroup
      ) {
        const item = this.createNodeLegendItem(
          data.node,
          data.key,
          data.legacyKey,
          data.isIncluded,
          ignoreSaved
        );
        newItems.push(item);
      }

      if (node.tag.dataProperties) {
        dataProps.push(...(<any>node.tag.dataProperties));
      }
    }

    for (const edge of edges) {
      if (
        !newItems.some(
          (item) =>
            item.type == LegendItemType.Edge &&
            GraphElementsComparer.edgesEqual(edge, <IEdge>item.data)
        )
      ) {
        const item = this.createEdgeLegendItem(edge, ignoreSaved);
        newItems.push(item);
      }

      if (edge.tag.dataProperties) {
        dataProps.push(...(<any>edge.tag.dataProperties));
      }
    }

    const filteredDataProps = dataProps.filter(
      (dp) =>
        DataPropertyUtils.isSymbolDataPropertyDefinition(
          dp.dataPropertyDefinitionId
        ) ||
        ((dp.dataPropertyDefinitionId == appConsts.JURISDICTION_DEFINITION_ID ||
          dp.dataPropertyDefinitionId == appConsts.STATE_DEFINITION_ID) &&
          nodeData.some((data) =>
            data.node.tag.dataProperties.some(
              (nodeDp) =>
                nodeDp.dataPropertyDefinitionId ==
                  dp.dataPropertyDefinitionId &&
                nodeDp.value === dp.value &&
                ((dp.dataPropertyDefinitionId ===
                  appConsts.JURISDICTION_DEFINITION_ID &&
                  this.isJurisdictionFlagShown(data.node)) ||
                  (dp.dataPropertyDefinitionId ===
                    appConsts.STATE_DEFINITION_ID &&
                    this.isJurisdictionStateShown(data.node)))
            )
          ))
    );
    const distinctDataProps = filteredDataProps.filter(
      (item, i, arr) =>
        arr.findIndex(
          (p) =>
            p.dataPropertyDefinitionId === item.dataPropertyDefinitionId &&
            p.value === item.value
        ) === i
    );

    const diagramDataProperties = this.graph.nodes
      .filter(
        (node) =>
          node.tag.isIncluded &&
          node.tag.dataProperties &&
          node.tag.dataProperties.length > 0
      )
      /* Select data properties only */
      .flatMap(
        (n) => n.tag.dataProperties as any
      ) as unknown as DataPropertyDto[];

    for (const prop of distinctDataProps) {
      const item = this.createDataPropertyLegendItem(
        prop,
        ignoreSaved,
        diagramDataProperties
      );
      if (item) {
        newItems.push(item);
      }
    }

    if (
      this.oldDefinition?.displayItems &&
      this.oldDefinition?.options.legendType !== LegendType.Page
    ) {
      for (const item of this.oldDefinition.displayItems) {
        const existingItem = newItems.some(() =>
          LegendUtils.getSavedItemDefinition({
            itemsDefinition: newItems,
            type: item.type,
            name: item.name,
            key: item.key
          })
        );

        if (!existingItem) {
          newItems.push(LegendItem.deserialize(item));
        }
      }
    }

    const items = this.oldDefinition?.displayItems || this.oldDefinition?.items;
    // Rearrange items based on the saved order
    if (!ignoreSaved && items && items.length) {
      for (let i = items.length - 1; i >= 0; i--) {
        const def = items[i];
        const item = newItems.find(
          (x) =>
            x.type == def.type &&
            x.symbol == def.symbol &&
            (!x.name || !def.name || x.name == def.name)
        );
        if (item) {
          const idx = newItems.indexOf(item);
          if (idx != i) {
            newItems.splice(i, 0, newItems.splice(idx, 1)[0]);
          }
        }
      }
    }

    return newItems;
  }

  private createNodeLegendItem(
    node: INode,
    key: string,
    legacyKey: string,
    isIncluded: boolean,
    ignoreSaved: boolean
  ): LegendItem {
    const name = node.tag.placeholderText || node.tag.name;
    const symbol = ElementSvgRenderUtils.createIconFromNode(node);
    const isFiltered = !isIncluded;
    const def =
      ignoreSaved || !this.oldDefinition
        ? null
        : LegendUtils.getSavedItemDefinition({
            itemsDefinition: this.oldDefinition.items,
            type: LegendItemType.Node,
            name,
            key,
            legacyKey
          });

    const dpStyleIsActive =
      DataPropertyStyleService.isDataPropertyStyleActive(node);

    let label = def?.label ?? i18n.t(name).toString();
    if (dpStyleIsActive) {
      let definitionItem = DataPropertyUtils.getDefinitionItemByDefinitionId(
        node,
        appConsts.JURISDICTION_DEFINITION_ID
      );

      if (definitionItem && (!def || def.isPristine)) {
        label =
          i18n.t(name).toString() +
          ', ' +
          i18n.t(definitionItem.itemValue).toString();
      }
    }

    return new LegendItem({
      type: LegendItemType.Node,
      show: def?.show ?? true,
      isPristine: def?.isPristine ?? true,
      data: node,
      label: label,
      name: name,
      symbol: symbol,
      key: key,
      isFiltered: isFiltered
    });
  }

  private createEdgeLegendItem(edge: IEdge, ignoreSaved: boolean): LegendItem {
    const name = edge.tag.placeholderText || edge.tag.name;
    const symbol = ElementSvgRenderUtils.createIconFromEdge(edge);
    const key = GraphElementsHashGenerator.getEdgeHashKey(edge);
    const isFiltered = !this.isEdgeIncluded(key);
    const def =
      ignoreSaved || !this.oldDefinition
        ? null
        : LegendUtils.getSavedItemDefinition({
            itemsDefinition: this.oldDefinition.items,
            type: LegendItemType.Edge,
            name,
            key
          });
    return new LegendItem({
      type: LegendItemType.Edge,
      data: edge,
      show: def?.show ?? true,
      label: def?.label ?? i18n.t(name).toString(),
      name: name,
      symbol: symbol,
      key: key,
      isFiltered: isFiltered
    });
  }

  private createDataPropertyLegendItem(
    dataProperty: DataPropertyDto,
    ignoreSaved: boolean,
    diagramDataProperties: DataPropertyDto[]
  ): LegendItem {
    if (!this.dataPropertyDefinitions) return;

    const definition = this.dataPropertyDefinitions.find(
      (d) => d.id == dataProperty.dataPropertyDefinitionId
    );

    if (definition) {
      let definitionItem: DataPropertyDefinitionItemDto = null;
      if (definition.valueScope === DataPropertyValueScope.Decorator) {
        if (dataProperty.value === true) {
          definitionItem = definition.dataPropertyDefinitionItems[0];
          if (this.currentTheme) {
            definitionItem = DataPropertyUtils.applyThemeDataPropertyItemStyle(
              definitionItem,
              this.currentTheme.themeDataPropertyItems
            );
          }
        }
      } else {
        definitionItem = definition.dataPropertyDefinitionItems.find(
          (i) => i.id == dataProperty.value
        );
      }

      if (definitionItem && definitionItem.imageData) {
        let symbol = definitionItem.imageData;
        //State Initials
        if (definitionItem.itemInitials && !definitionItem.customized) {
          let circleSize = 9;
          let fontSize = 10;
          let circleX = 15;
          let circleY = 9.5;
          let textOffsetX = 2.25;
          let textOffsetY = 4.5;

          const svgImage = DataPropertyUtils.createStateInitialsCircleSvgVisual(
            definitionItem.itemInitials,
            circleSize,
            fontSize,
            circleX,
            circleY,
            textOffsetX,
            textOffsetY
          );

          const svgElement = `<svg viewbox="0 0 30 20"  width="30" height="20" xmlns="http://www.w3.org/2000/svg">${svgImage.svgElement.outerHTML}</svg>`;
          symbol = `data:image/svg+xml;base64,${b64EncodeUnicode(svgElement)}`;
        }
        const name = definitionItem.itemValue;
        const dataPropertyDefinitionItemKey =
          GraphElementsHashGenerator.getDataPropertyDefinitionItem(
            definitionItem
          );
        const isFiltered = !this.isDataPropertyIncluded(
          definitionItem.id,
          diagramDataProperties
        );
        const def =
          ignoreSaved || !this.oldDefinition
            ? null
            : LegendUtils.getSavedItemDefinition({
                itemsDefinition: this.oldDefinition.items,
                type: LegendItemType.DataProperty,
                name,
                key: dataPropertyDefinitionItemKey
              });
        return new LegendItem({
          type: LegendItemType.DataProperty,
          show: def?.show ?? true,
          data: dataProperty,
          label: def?.label ?? i18n.t(name),
          name: name,
          symbol: symbol,
          key: dataPropertyDefinitionItemKey,
          isFiltered: isFiltered
        });
      }
    }
  }

  private isEdgeIncluded(key: string): boolean {
    return this.graph.edges.some(
      (e) =>
        e.tag.isIncluded && GraphElementsHashGenerator.getEdgeHashKey(e) == key
    );
  }

  private isNodeIncluded(key: string, legacyKey: string): boolean {
    return this.graph.nodes.some((e) => {
      const nodeKey = GraphElementsHashGenerator.getNodeHashKey(e);
      return e.tag.isIncluded && (nodeKey === key || nodeKey === legacyKey);
    });
  }

  private isDataPropertyIncluded(
    dataPropertyDefinitionItemId: number,
    dataProperties: DataPropertyDto[]
  ): boolean {
    const filteredDataPropertyDefinitions = this.dataPropertyDefinitions.filter(
      (dpd) => this.isVisibleOnLegend(dpd.id)
    );

    const filteredDataProperties = dataProperties.filter((dp) =>
      this.isVisibleOnLegend(dp.dataPropertyDefinitionId)
    );

    return DataPropertyUtils.isDataPropertyDefinitionItemIncluded(
      filteredDataProperties,
      dataPropertyDefinitionItemId,
      filteredDataPropertyDefinitions
    );
  }

  private isJurisdictionFlagShown(node): boolean {
    return (
      node.tag.dataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ] &&
      node.tag.dataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ].includes(DataPropertyDisplayType.Decorator)
    );
  }

  private isJurisdictionStateShown(node): boolean {
    return (
      node.tag.dataPropertyDisplayTypes[DataPropertyDisplayTypeNames.State] &&
      node.tag.dataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.State
      ].includes(DataPropertyDisplayType.Decorator)
    );
  }
}
