export enum Features {
  StructureDiagram = 'StructureDiagram',
  PersonnelChart = 'PersonnelChart',
  Import = 'Import',
  Steps = 'Steps',
  StepsLite = 'StepsLite',
  AutoLayout = 'AutoLayout',
  iManage = 'IManage',
  DataEnhancement = 'DataEnhancement',
  StepsHide = 'StepsHide',
  Mirroring = 'Mirroring',
  Flipbook = 'Flipbook',
  StepsDesign = 'StepsDesign',
  ImagePages = 'ImagePages',
  UnlicenceUsers = 'UnlicenceUsers',
  TextPages = 'TextPages',
  ContinuationSheets = 'ContinuationSheets',
  Demo = 'Demo',
  Timelines = 'Timelines',
  ImportExcel = 'ImportExcel',
  ImportPowerPoint = 'ImportPowerPoint',
  ImportExcelBuilder = 'ImportExcelBuilder',
  DataLite = 'DataLite',
  DataFull = 'DataFull'
}
