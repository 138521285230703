import {
  generateUuid,
  RgbaToHex,
  RgbToHex,
  validateUuid
} from '@/core/utils/common.utils';
import {
  ArcEdgeStyle,
  Arrow,
  BezierEdgeStyle,
  Color,
  DashStyle,
  Fill,
  FontStyle,
  FontStyleStringValues,
  FontWeight,
  FontWeightStringValues,
  GraphComponent,
  IBend,
  IEdge,
  IGraph,
  ILabel,
  IListEnumerable,
  ImageNodeStyle,
  LinearGradient,
  INode,
  Insets,
  IPort,
  PolylineEdgeStyle,
  ShapeNodeStyle,
  SolidColorFill,
  Stroke,
  TextDecoration,
  TextDecorationStringValues,
  VoidNodeStyle
} from 'yfiles';
import {
  CompositeNodeStyleDto,
  CompositeNodeStyleShapeDto,
  CompositeStyleDefinitionDto,
  DataPropertyDto,
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  EdgePortDto,
  EdgeStyleDto,
  EdgeVisualType,
  FileAttachmentDto,
  INodeStyleDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  StrokeDto,
  ImageNodeStyleDto,
  GroupNodeStyleDto,
  DataPropertyTagDto,
  TextBoxNodeStyleDto,
  DividingLineNodeStyleDto,
  JigsawPathShapeNodeStyleDto,
  FillType,
  SolidColorFillDto,
  LinearGradientDto,
  GradientSpreadMethod,
  GradientStopDto,
  PointDto
} from '@/api/models';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { RotatableNodeStyleDecorator } from '../RotatableNodes';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import CompositeNodeStyle from '@/core/styles/composite/CompositeNodeStyle';
import GroupNodeStyle from '@/core/styles/GroupNodeStyle';
import defaultArrowStyle from '@/core/config/defaultArrowStyle';
import { JigsawShapeNodeStyleRenderer } from '@/core/styles/JigsawShapeNodeStyleRenderer';
import INodeLabelData from './INodeLabelData';
import IEdgeLabelData from './IEdgeLabelData';
import JigsawEdgeLabelModelParameter from '../label-models/JigsawEdgeLabelModelParameter';
import { LabelModelType } from '../label-models/LabelModelType';
import TextBoxNodeStyle from '@/core/styles/TextBoxNodeStyle';
import DividingLineNodeStyle from '@/core/styles/DividingLineNodeStyle';
import INodeTag from '@/core/common/INodeTag';
import isNil from 'lodash/isNil';
import JigsawPathShapeNodeStyle from '@/core/styles/JigsawPathShapeNodeStyle';
/**
 * This takes a graph, and converts it to a DiagramDto
 */
export default class DiagramWriter {
  /**
   * @param IGraph graph IGraph from yFiles
   */

  public write(graph: IGraph): {
    nodes: DiagramNodeDto[];
    edges: DiagramEdgeDto[];
  } {
    const graphNodes = graph.nodes.toArray();
    const graphEdges = graph.edges.toArray();
    for (let index = 0; index < graphNodes.length; index++) {
      const element = graphNodes[index];
      this.ensureUniqueUuid(element, graphNodes);
    }

    for (let index = 0; index < graphEdges.length; index++) {
      const element = graphEdges[index];
      this.ensureUniqueUuid(element, graphEdges);
    }
    const nodes = this.convertNodes(graphNodes);
    const edges = this.convertEdges(graphEdges);
    return {
      nodes: nodes,
      edges: edges
    };
  }

  private ensureUniqueUuid(
    item: INode | IEdge,
    items: INode[] | IEdge[]
  ): void {
    // Legacy check for 2.14 divider lines
    if (
      item instanceof INode &&
      !validateUuid(item.tag.uuid) &&
      (item.tag.name === 'HORIZONTAL_DIVIDER' ||
        item.tag.name === 'VERTICAL_DIVIDER')
    ) {
      item.tag = DiagramUtils.regenerateItemUuid(item.tag) as INodeTag;
    }

    if (item.tag.id) {
      return; // if a node/edge has an id assigned we cannot risk changing the UUID.
    }

    const duplicate = items.some(
      (x) => x != item && x.tag.uuid == item.tag.uuid
    );
    if (!duplicate) {
      return;
    }
    console.warn(`Found duplicate id ${item.tag.uuid}`);
    item.tag = DiagramUtils.regenerateItemUuid(item.tag);
  }

  public convertNodes(graphNodes: INode[]): DiagramNodeDto[] {
    return graphNodes.map((node) => this.convertNode(node));
  }
  public convertNode(graphNode: INode): DiagramNodeDto {
    this.initializeTag(graphNode);
    return {
      id: graphNode.tag.id || null, // might be undefined if new node
      uuid: this.getElementUuid(graphNode),
      originalUuid: this.getElementOriginalUuid(graphNode),
      layout: {
        x: graphNode.layout.x,
        y: graphNode.layout.y,
        width: graphNode.layout.width,
        height: graphNode.layout.height
      },
      data: this.convertNodeDataObject(graphNode),
      label: this.convertLabels(graphNode.labels),
      dataProperties: JSON.parse(JSON.stringify(graphNode.tag.dataProperties)),
      dataPropertyTags: JSON.parse(
        JSON.stringify(graphNode.tag.dataPropertyTags)
      ),
      style: DiagramWriter.convertNodeStyle(graphNode),
      isGroupNode: graphNode.tag.isGroupNode,
      groupUuid: graphNode.tag.groupUuid,
      attachments: graphNode.tag.attachments as FileAttachmentDto[],
      isIncluded: graphNode.tag.isIncluded,
      highlight: graphNode.tag.highlight
    };
  }

  private convertNodeDataObject(graphNode: INode): any {
    return {
      isFixedInLayout: graphNode.tag.isFixedInLayout,
      isLocked: graphNode.tag.isLocked,
      definitionCustomised: graphNode.tag.definitionCustomised,
      isAnnotation: graphNode.tag.isAnnotation,
      name: graphNode.tag.name,
      originalName: graphNode.tag.originalName,
      sequenceNumber: graphNode.tag.sequenceNumber,
      originalSequenceNumber: graphNode.tag.originalSequenceNumber,
      annotationType: graphNode.tag.annotationType,
      displayOrder: graphNode.tag.displayOrder,
      grouping: {
        fillColor: graphNode.tag.grouping?.fillColor,
        strokeColor: graphNode.tag.grouping?.strokeColor,
        strokeDash: graphNode.tag.grouping?.strokeDash,
        strokeWidth: graphNode.tag.grouping?.strokeWidth
      },
      dataPropertyDisplayTypes: JSON.parse(
        JSON.stringify(graphNode.tag.dataPropertyDisplayTypes)
      ),
      labelData: this.createNodeLabelData(graphNode.labels.find()),
      labelIsPlaceholder: graphNode.tag.labelIsPlaceholder,
      dataPropertyStyle: {
        isActive: graphNode.tag.dataPropertyStyle?.isActive
      },
      decorationStates: JSON.parse(
        JSON.stringify(graphNode.tag.decorationStates)
      ),
      isResized: graphNode.tag.isResized,
      indicatorsPosition: graphNode.tag.indicatorsPosition,
      quickStartData: graphNode.tag.quickStartData,
      entityTypeId: graphNode.tag.entityTypeId
    } as any;
  }

  private createNodeLabelData(label: ILabel): INodeLabelData {
    if (label == null) {
      return null;
    }
    if (!(label.owner instanceof INode)) {
      throw 'owner is not node';
    }
    const labelParam = label.layoutParameter as any;
    const labelModelType = DiagramUtils.getLabelModelType(labelParam);
    const labelData = {
      textFit: label.owner.tag.labelData.textFit,
      wrapTextInShape: label.owner.tag.labelData.wrapTextInShape ?? false,
      modelType: labelModelType,
      offset: null,
      positionVector: null
    };

    switch (labelModelType) {
      case LabelModelType.Exterior:
      case LabelModelType.Interior:
        labelData.positionVector = {
          x: labelParam.positionVector.x,
          y: labelParam.positionVector.y
        };

        if (!isNil(labelParam.offset)) {
          labelData.offset = {
            x: labelParam.offset.x,
            y: labelParam.offset.y
          };
        }
        break;
    }

    return labelData;
  }

  public static convertNodeStyle(node: INode): INodeStyleDto {
    let graphNodeStyle = node.style;
    let rotation: number = null;

    // Let's try and unwrap the style

    // in some cases the JigsawNodeStyle can be wrapped by RotatableNodeStyleDecorator
    // Rotation logic should be moved into JigsawNodeStyle(Base)
    if (graphNodeStyle instanceof RotatableNodeStyleDecorator) {
      rotation = graphNodeStyle.angle;
      graphNodeStyle = graphNodeStyle.wrapped;
    }

    if (graphNodeStyle instanceof JigsawNodeStyle) {
      graphNodeStyle = graphNodeStyle.baseStyle;
    }

    if (graphNodeStyle instanceof GroupNodeStyle) {
      return DiagramWriter.convertGroupNodeStyle(node, graphNodeStyle);
    }

    if (graphNodeStyle instanceof ShapeNodeStyle) {
      return DiagramWriter.convertShapeNodeStyle(node, graphNodeStyle);
    }

    if (graphNodeStyle instanceof ImageNodeStyle) {
      return DiagramWriter.convertImageNodeStyle(
        node,
        graphNodeStyle,
        rotation
      );
    }

    if (graphNodeStyle instanceof DividingLineNodeStyle) {
      return DiagramWriter.convertDividingLineNodeStyle(node, graphNodeStyle);
    }

    if (graphNodeStyle instanceof CompositeNodeStyle) {
      return DiagramWriter.convertCompositeNodeStyle(node, graphNodeStyle);
    }

    if (graphNodeStyle instanceof TextBoxNodeStyle) {
      return DiagramWriter.convertTextBoxNodeStyle(node, graphNodeStyle);
    }

    if (graphNodeStyle instanceof VoidNodeStyle) {
      return DiagramUtils.getSystemDefaultVoidNodeStyle();
    }

    if (graphNodeStyle instanceof JigsawPathShapeNodeStyle) {
      return DiagramWriter.convertJigsawPathShapeNodeStyle(
        node,
        graphNodeStyle
      );
    }

    throw new Error(`unsupported nodestyle ${graphNodeStyle}`);
  }

  /**
   * Converts to its dto
   * @param node
   * @param style
   * @returns JigsawPathShapeNodeStyleDto
   */
  public static convertJigsawPathShapeNodeStyle(
    node: INode,
    style: JigsawPathShapeNodeStyle
  ): JigsawPathShapeNodeStyleDto {
    return new JigsawPathShapeNodeStyleDto(
      DiagramWriter.convertFill(style.fill),
      DiagramWriter.convertStroke(style.stroke),
      style.shape,
      undefined,
      NodeVisualType.JigsawPathShape,
      (node.tag?.style as JigsawPathShapeNodeStyleDto)?.labelStyle
    );
  }

  /**
   * Converts a yFiles ShapeNodeStyle into a Jigsaw CompositeNodeStyleShapeDto
   * @param node The node
   * @param style The nodes shape style
   * @returns composite node style shape
   */
  public static convertCompositeNodeStyleShape(
    style: ShapeNodeStyle
  ): CompositeNodeStyleShapeDto {
    return {
      fill: this.convertFill(style.fill),
      shape: this.convertNodeShape(style),
      stroke: this.convertStroke(style.stroke)
    };
  }

  /**
   * Converts a CompositeNodeStyle into a CompositeNodeStyleDto
   * @param node
   * @param style
   * @returns composite node style
   */
  public static convertCompositeNodeStyle(
    node: INode,
    style: CompositeNodeStyle
  ): CompositeNodeStyleDto {
    let styleDefinitions: CompositeStyleDefinitionDto[] = [];

    for (const styleDefinition of style.styleDefinitions) {
      let style = DiagramWriter.convertCompositeNodeStyleShape(
        styleDefinition.nodeStyle as ShapeNodeStyle
      );

      let insets = styleDefinition.insets as Insets;
      styleDefinitions.push({
        nodeStyle: style,
        insets: {
          bottom: insets.bottom,
          left: insets.left,
          right: insets.right,
          top: insets.top
        }
      });
    }
    return new CompositeNodeStyleDto(
      style.shape,
      styleDefinitions,
      NodeVisualType.Composite,
      null,
      (node.tag?.style as CompositeNodeStyleDto)?.labelStyle
    );
  }

  public static convertDividingLineNodeStyle(
    node: INode,
    style: DividingLineNodeStyle
  ): DividingLineNodeStyleDto {
    return new DividingLineNodeStyleDto(
      NodeVisualType.DividingLine,

      style.type,
      DiagramWriter.convertStroke(style.stroke)
    );
  }

  /**
   * Converts a yFiles ImageNodeStyle into Jigsaw ImageNodeStyleDto
   * @param node
   * @param style
   * @param rotation
   * @returns image node style
   */
  public static convertImageNodeStyle(
    node: INode,
    style: ImageNodeStyle,
    rotation: number
  ): ImageNodeStyleDto {
    return new ImageNodeStyleDto(
      NodeVisualType.Image,
      style.image,
      null,
      null,
      null,
      rotation,
      (node.tag?.style as ImageNodeStyleDto)?.labelStyle
    );
  }

  /**
   * Converts group node style
   * @param node
   * @param style
   * @returns group node style
   */
  public static convertGroupNodeStyle(
    node: INode,
    style: GroupNodeStyle
  ): GroupNodeStyleDto {
    return new GroupNodeStyleDto(NodeVisualType.Group);
  }

  public static convertTextBoxNodeStyle(
    node: INode,
    style: TextBoxNodeStyle
  ): TextBoxNodeStyleDto {
    return new TextBoxNodeStyleDto(
      NodeVisualType.TextBox,
      DiagramWriter.convertFill(style.fill),
      DiagramWriter.convertStroke(style.stroke)
    );
  }

  /**
   * Converts a yFiles ShapeNodeStyle to a Jigsaw ShapeNodeStyleDto
   * @param node
   * @param style
   * @returns shape node style
   */
  public static convertShapeNodeStyle(
    node: INode,
    style: ShapeNodeStyle
  ): ShapeNodeStyleDto {
    return new ShapeNodeStyleDto(
      DiagramWriter.convertFill(style.fill),
      DiagramWriter.convertStroke(style.stroke),
      DiagramWriter.convertNodeShape(style),
      undefined,
      NodeVisualType.Shape,
      (node.tag?.style as ShapeNodeStyleDto)?.labelStyle
    );
  }

  public static convertNodeShape(style: ShapeNodeStyle): NodeShape {
    if (style.renderer instanceof JigsawShapeNodeStyleRenderer) {
      return style.renderer.shape;
    }

    throw 'Style does not use JigsawShapeNodeStyleRenderer';
  }

  private convertLabels(graphLabels: IListEnumerable<ILabel>): string {
    if (graphLabels && graphLabels.size <= 0) {
      return null;
    }
    let label = graphLabels.first();
    return label.text;
  }

  public convertEdges(graphEdges: IEdge[]): DiagramEdgeDto[] {
    return graphEdges.map((edge) => this.convertEdge(edge));
  }
  public convertEdge(graphEdge: IEdge): DiagramEdgeDto {
    this.initializeTag(graphEdge);

    return {
      id: graphEdge.tag.id || null, // might be undefined if new node,
      uuid: this.getElementUuid(graphEdge),
      originalUuid: this.getElementOriginalUuid(graphEdge),
      sourceNodeUuid: graphEdge.sourceNode.tag.uuid,
      targetNodeUuid: graphEdge.targetNode.tag.uuid,
      bends: this.convertBends(graphEdge.bends),
      style: DiagramWriter.convertEdgeStyle(graphEdge),
      sourcePort: this.convertPort(graphEdge.sourcePort),
      targetPort: this.convertPort(graphEdge.targetPort),
      label: graphEdge.tag.labelIsPlaceholder
        ? null
        : this.convertLabels(graphEdge.labels),
      data: this.convertEdgeDataObject(graphEdge),
      dataProperties: graphEdge.tag.dataProperties as DataPropertyDto[],
      dataPropertyTags: graphEdge.tag.dataPropertyTags as DataPropertyTagDto[],
      attachments: graphEdge.tag.attachments as FileAttachmentDto[],
      isIncluded: graphEdge.tag.isIncluded,
      highlight: graphEdge.tag.highlight
    };
  }

  private createEdgeLabelData(label: ILabel): IEdgeLabelData {
    if (label == null) {
      return null;
    }
    if (!(label.owner instanceof IEdge)) {
      throw 'owner is not edge';
    }

    const layoutParam = label.layoutParameter as JigsawEdgeLabelModelParameter;

    return {
      layout: {
        anchorX: label.layout.anchorX,
        anchorY: label.layout.anchorY,
        width: label.layout.width,
        height: label.layout.height,
        upX: label.layout.upX,
        upY: label.layout.upY
      },
      labelPosition: {
        distance: layoutParam.distance,
        left: layoutParam.left,
        ratio: layoutParam.ratio,
        segmentIndex: layoutParam.segmentIndex
      },
      textFit: label.owner.tag.labelData.textFit
    };
  }

  private convertEdgeDataObject(graphEdge: IEdge): any {
    const bounds = graphEdge.style.renderer
      .getBoundsProvider(graphEdge, graphEdge.style)
      .getBounds(DiagramUtils.defaultCanvasContext);
    return {
      edited: graphEdge.tag.edited,
      placeholder: graphEdge.tag.placeholder,
      autoCreated: graphEdge.tag.autoCreated,
      sourcePortFixed: graphEdge.tag.sourcePortFixed,
      sourcePortDirection: graphEdge.tag.sourcePortDirection,
      targetPortFixed: graphEdge.tag.targetPortFixed,
      targetPortDirection: graphEdge.tag.targetPortDirection,
      definitionCustomised: graphEdge.tag.definitionCustomised,
      isFixedInLayout: graphEdge.tag.isFixedInLayout,
      name: graphEdge.tag.name,
      isAnnotation: graphEdge.tag.isAnnotation,
      busid: graphEdge.tag.busid,
      isOrphan: graphEdge.tag.isOrphan,
      labelData: graphEdge.tag.labelIsPlaceholder
        ? null
        : this.createEdgeLabelData(graphEdge.labels.firstOrDefault()),
      labelIsPlaceholder: false,
      quickStartData: graphEdge.tag.quickStartData,
      relationshipType: graphEdge.tag.relationshipType,
      displayOrder: graphEdge.tag.displayOrder,
      layout: {
        x: bounds.x,
        y: bounds.y,
        width: bounds.width,
        height: bounds.height
      },
      entityTypeId: graphEdge.tag.entityTypeId
    };
  }

  private convertPort(graphPort: IPort): EdgePortDto {
    let ratios = DiagramUtils.calculatePortRatios(graphPort);
    return {
      x: ratios.x,
      y: ratios.y
    };
  }

  public static convertEdgeStyle(graphEdge: IEdge): EdgeStyleDto {
    if (graphEdge.style instanceof ArcEdgeStyle) {
      return {
        stroke: DiagramWriter.convertStroke(graphEdge.style.stroke),
        sourceArrow: DiagramWriter.convertArrow(
          graphEdge.style.sourceArrow as Arrow
        ),
        targetArrow: DiagramWriter.convertArrow(
          graphEdge.style.targetArrow as Arrow
        ),
        visualType: EdgeVisualType.Arc,
        height: Math.round(graphEdge.style.height),
        bridge: graphEdge.tag.style.bridge
      };
    } else if (graphEdge.style instanceof PolylineEdgeStyle) {
      return {
        stroke: DiagramWriter.convertStroke(graphEdge.style.stroke),
        sourceArrow: DiagramWriter.convertArrow(
          graphEdge.style.sourceArrow as Arrow
        ),
        targetArrow: DiagramWriter.convertArrow(
          graphEdge.style.targetArrow as Arrow
        ),
        visualType:
          graphEdge.style.smoothingLength > 0
            ? EdgeVisualType.Curved
            : graphEdge.tag.style.visualType,
        bridge: graphEdge.tag.style.bridge
      };
    } else if (graphEdge.style instanceof BezierEdgeStyle) {
      return {
        stroke: DiagramWriter.convertStroke(graphEdge.style.stroke),
        sourceArrow: DiagramWriter.convertArrow(
          graphEdge.style.sourceArrow as Arrow
        ),
        targetArrow: DiagramWriter.convertArrow(
          graphEdge.style.targetArrow as Arrow
        ),
        visualType: EdgeVisualType.Curved,
        bridge: graphEdge.tag.style.bridge
      };
    }
    throw new Error(`unsupported edgestyle ${graphEdge.style}`);
  }

  public static convertFontStyle(fontStyle: FontStyle): FontStyleStringValues {
    switch (+fontStyle) {
      case FontStyle.INHERIT:
        return 'inherit';
      case FontStyle.ITALIC:
        return 'italic';
      case FontStyle.NORMAL:
        return 'normal';
      case FontStyle.OBLIQUE:
        return 'oblique';
    }
    console.warn(`Unknown font style  ${fontStyle}`);
    return 'normal';
  }

  public static convertFontWeight(
    fontWeight: FontWeight
  ): FontWeightStringValues {
    switch (+fontWeight) {
      case FontWeight.NORMAL:
        return 'normal';
      case FontWeight.BOLD:
        return 'bold';
      case FontWeight.BOLDER:
        return 'bolder';
      case FontWeight.INHERIT:
        return 'inherit';
      case FontWeight.ITEM100:
        return 'item100';
      case FontWeight.ITEM200:
        return 'item200';
      case FontWeight.ITEM300:
        return 'item300';
      case FontWeight.ITEM400:
        return 'item400';
      case FontWeight.ITEM500:
        return 'item500';
      case FontWeight.ITEM600:
        return 'item600';
      case FontWeight.ITEM700:
        return 'item700';
      case FontWeight.ITEM800:
        return 'item800';
      case FontWeight.ITEM900:
        return 'item900';
      case FontWeight.LIGHTER:
        return 'lighter';
    }

    console.warn(`Unknown font weight ${fontWeight}`);
    return 'normal';
  }

  public static convertTextDecoration(
    textDecoration: TextDecoration
  ): TextDecorationStringValues {
    switch (+textDecoration) {
      case TextDecoration.BLINK:
        return 'blink';
      case TextDecoration.LINE_THROUGH:
        return 'line-through';
      case TextDecoration.NONE:
        return 'none';
      case TextDecoration.OVERLINE:
        return 'overline';
      case TextDecoration.UNDERLINE:
        return 'underline';
    }
    console.warn(`Unknown text decoration ${textDecoration}`);
    return 'none';
  }

  public static convertFontFamily(fontFamily: string): string {
    return fontFamily.slice(1, -1);
  }

  private convertBends(graphBends: IListEnumerable<IBend>): any[] {
    return graphBends.map((bend) => this.convertBend(bend)).toArray();
  }
  private convertBend(graphBend: IBend): any {
    return {
      x: graphBend.location.x,
      y: graphBend.location.y
    };
  }

  public static convertArrow(arrow: Arrow): any {
    return {
      type: defaultArrowStyle.find((x) => x.type == arrow.type).name,
      scale: arrow.scale,
      fill: this.convertFill(arrow.fill)
    };
  }

  public static convertStroke(stroke: Stroke): StrokeDto {
    {
      return {
        dashStyle: DiagramWriter.convertDashStyle(stroke.dashStyle),
        fill: DiagramWriter.convertFill(stroke.fill),
        thickness: stroke.thickness
      };
    }
  }

  public static convertFill(fill: Fill): SolidColorFillDto | LinearGradientDto {
    if (fill instanceof SolidColorFill) {
      return {
        type: FillType.SolidColor,
        color: this.convertColor(fill.color)
      };
    } else if (fill instanceof LinearGradient) {
      return {
        type: FillType.LinearGradient,
        startPoint: new PointDto(fill.startPoint.x, fill.startPoint.y),
        endPoint: new PointDto(fill.endPoint.x, fill.endPoint.y),
        spreadMethod: fill.spreadMethod as unknown as GradientSpreadMethod,
        gradientStops: fill.gradientStops
          .map((x) => {
            return {
              color: this.convertColor(x.color),
              offset: x.offset
            } as GradientStopDto;
          })
          .toArray()
      };
    }
    return null;
  }

  public static convertColor(color: Color): string {
    return RgbaToHex(color.r, color.g, color.b, color.a);
  }

  public static convertColorAsRgb(color: Color): string {
    return RgbToHex(color.r, color.g, color.b);
  }

  public static convertDashStyle(dashStyle: DashStyle): {
    dashes: number[];
    offset: number;
  } {
    return {
      dashes: dashStyle.dashes == null ? null : dashStyle.dashes.toArray(),
      offset: dashStyle.offset
    };
  }

  private initializeTag(element: { tag: object }): void {
    if (!element) {
      return;
    }
    if (!element.tag) {
      element.tag = {
        uuid: this.getElementUuid(element)
      };
    }
  }

  private getElementUuid(element): string {
    if (!element) {
      throw 'element is invalid';
    }
    if (!element.tag) {
      element.tag = {};
    }

    return element.tag.uuid || (element.tag.uuid = generateUuid());
  }

  private getElementOriginalUuid(element: INode | IEdge): string {
    return element.tag.originalUuid;
  }

  public static fromGraph(graph: IGraph, diagram: DiagramDto): DiagramDto {
    let diagramElements = new DiagramWriter().write(graph);
    diagram.nodes = diagramElements.nodes;
    diagram.edges = diagramElements.edges;
    return diagram;
  }

  public static fromGraphComponent(
    graphComponent: GraphComponent,
    diagram: DiagramDto
  ): DiagramDto {
    return DiagramWriter.fromGraph(graphComponent.graph, diagram);
  }
}
