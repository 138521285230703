import ExportPageElement from './ExportPageElement';
import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  PageElementPosition
} from '@/api/models';
import JPoint from '@/core/common/JPoint';
import JSize from '@/core/common/JSize';
import JRect from '@/core/common/JRect';
import { fitRectIntoBounds } from '@/core/utils/common.utils';
import ExportConfig from '@/core/config/ExportConfig';
import ExportUtils from '@/core/services/export/ExportUtils';
import LegendLayoutUtils, {
  LayoutElementDetails
} from '@/components/DiagramLegend/LegendLayoutUtils';

type FinalSvgData = { exportSize: JSize; finalSvg: SVGElement };
type ProcessedElementDetails = {
  elementWidth: number;
  elementHeight: number;
  element: SVGElement;
  staticPosition: PageElementPosition;
};

export default class GraphExportHelper {
  private static readonly graphSvgName = 'graph-svg';

  private static getDefaultExportSize(
    document: DocumentDto,
    page: DocumentPageDto
  ): JSize {
    const exportSize = new JSize(
      document.pageStyle.width * ExportConfig.pointToPixelFactor,
      document.pageStyle.height * ExportConfig.pointToPixelFactor
    );

    if (page.contentType === DocumentPageContentType.MasterLegend) {
      if (document.headerStyle.show) {
        exportSize.height -=
          document.headerStyle.height * ExportConfig.pointToPixelFactor;
      }
      if (document.footerStyle.show) {
        exportSize.height -=
          document.footerStyle.height * ExportConfig.pointToPixelFactor;
      }
      if (page.showTitle) {
        exportSize.height -= page.titleHeight * ExportConfig.pointToPixelFactor;
      }
    }
    return exportSize;
  }

  private static createSvgContainer(size: JSize): SVGElement {
    const svg = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svg.setAttributeNS(
      'http://www.w3.org/2000/svg',
      'xlink',
      'http://www.w3.org/1999/xlink'
    );
    svg.setAttribute('viewBox', `0 0 ${size.width} ${size.height}`);
    this.setSvgElementSize(svg, size);

    return svg;
  }

  private static async processAdditionalElements(
    additionalElements: ExportPageElement[],
    exportSize: JSize,
    useStaticPosition: boolean,
    mainSvgElement?: SVGElement,
    logoDetails?: LayoutElementDetails
  ): Promise<{ processedElements: SVGElement[]; exportSize: JSize }> {
    const processedElements: SVGElement[] = [];
    const elements: ProcessedElementDetails[] = [];
    const pageSize = exportSize.clone();
    const mainSvgSize = exportSize.clone();

    if (useStaticPosition) {
      const orderedElements = (additionalElements ?? []).sort(
        (a, b) => b.type - a.type
      );

      let logoSize = JSize.EMPTY;
      const logoPosition =
        logoDetails?.staticPosition ?? PageElementPosition.Unset;
      if (logoDetails) {
        logoSize = new JSize(
          logoDetails.bounds.width * exportSize.width,
          logoDetails.bounds.height * exportSize.height
        ).multiply(ExportConfig.pointToPixelFactor);
      }

      for (const e of orderedElements) {
        const element = await e.toSvgAsync();
        const { staticPosition } = e.options;

        let elementWidth = parseFloat(element.getAttribute('width'));
        let elementHeight = parseFloat(element.getAttribute('height'));

        if (
          elementWidth > exportSize.width ||
          elementHeight > exportSize.height
        ) {
          const fittedElementSize = fitRectIntoBounds(
            new JSize(elementWidth, elementHeight),
            exportSize
          );

          elementWidth = fittedElementSize.width;
          elementHeight = fittedElementSize.height;
        }

        switch (staticPosition) {
          case PageElementPosition.Top:
          case PageElementPosition.TopLeft:
          case PageElementPosition.TopRight:
            if (mainSvgSize.height + elementHeight > pageSize.height) {
              mainSvgSize.height -=
                mainSvgSize.height + elementHeight - pageSize.height;
            }
            if (
              (logoPosition === PageElementPosition.TopLeft ||
                logoPosition === PageElementPosition.TopRight) &&
              elementWidth + logoSize.width > pageSize.width
            ) {
              elementWidth = pageSize.width - logoSize.width;
            }
            break;
          case PageElementPosition.Right:
            if (mainSvgSize.width + elementWidth > pageSize.width) {
              mainSvgSize.width -=
                mainSvgSize.width + elementWidth - pageSize.width;
            }
            if (
              (logoPosition === PageElementPosition.TopRight ||
                logoPosition === PageElementPosition.BottomRight) &&
              elementHeight + logoSize.height > pageSize.height
            ) {
              elementHeight = pageSize.height - logoSize.height;
            }
            break;
          case PageElementPosition.Bottom:
          case PageElementPosition.BottomLeft:
          case PageElementPosition.BottomRight:
            if (mainSvgSize.height + elementHeight > pageSize.height) {
              mainSvgSize.height -=
                mainSvgSize.height + elementHeight - pageSize.height;
            }
            if (
              (logoPosition === PageElementPosition.BottomLeft ||
                logoPosition === PageElementPosition.BottomRight) &&
              elementWidth + logoSize.width > pageSize.width
            ) {
              elementWidth = pageSize.width - logoSize.width;
            }
            break;
          case PageElementPosition.Left:
            if (mainSvgSize.width + elementWidth > pageSize.width) {
              mainSvgSize.width -=
                mainSvgSize.width + elementWidth - pageSize.width;
            }
            if (
              (logoPosition === PageElementPosition.TopLeft ||
                logoPosition === PageElementPosition.BottomLeft) &&
              elementHeight + logoSize.height > pageSize.height
            ) {
              elementHeight = pageSize.height - logoSize.height;
            }
            break;
        }

        GraphExportHelper.setSvgElementSize(mainSvgElement, mainSvgSize);

        GraphExportHelper.setSvgElementSize(
          element,
          new JSize(elementWidth, elementHeight)
        );

        elements.push({
          elementWidth,
          elementHeight,
          element,
          staticPosition
        });
      }

      elements.forEach((elementData) => {
        const { element, elementWidth, elementHeight, staticPosition } =
          elementData;
        let x = 0;
        let y = 0;

        switch (staticPosition) {
          case PageElementPosition.TopLeft:
            GraphExportHelper.moveSvgElement(
              mainSvgElement,
              new JPoint(0, elementHeight),
              exportSize
            );
            if (logoPosition === PageElementPosition.TopLeft) {
              x += logoSize.width;
            }
            break;
          case PageElementPosition.Top:
            GraphExportHelper.moveSvgElement(
              mainSvgElement,
              new JPoint(0, elementHeight),
              exportSize
            );

            x = exportSize.width / 2 - elementWidth / 2;
            if (logoPosition === PageElementPosition.TopLeft) {
              x = Math.max(logoSize.width, x);
            } else if (logoPosition === PageElementPosition.TopRight) {
              x = Math.min(
                exportSize.width - logoSize.width,
                (exportSize.width - logoSize.width) / 2 - elementWidth / 2
              );
            }
            break;
          case PageElementPosition.TopRight:
            GraphExportHelper.moveSvgElement(
              mainSvgElement,
              new JPoint(0, elementHeight),
              exportSize
            );

            x = exportSize.width - elementWidth;
            if (logoPosition === PageElementPosition.TopRight) {
              x -= logoSize.width;
            }
            break;
          case PageElementPosition.BottomLeft:
            y = exportSize.height - elementHeight;
            if (logoPosition === PageElementPosition.BottomLeft) {
              x += logoSize.width;
            }
            break;
          case PageElementPosition.Bottom:
            x = exportSize.width / 2 - elementWidth / 2;
            y = exportSize.height - elementHeight;

            if (logoPosition === PageElementPosition.BottomLeft) {
              x = Math.max(logoSize.width, x);
            } else if (logoPosition === PageElementPosition.BottomRight) {
              x = Math.min(
                exportSize.width - logoSize.width,
                (exportSize.width - logoSize.width) / 2 - elementWidth / 2
              );
            }
            break;
          case PageElementPosition.BottomRight:
            x = exportSize.width - elementWidth;
            y = exportSize.height - elementHeight;

            if (logoPosition === PageElementPosition.BottomRight) {
              x -= logoSize.width;
            }
            break;
          case PageElementPosition.Left:
            GraphExportHelper.moveSvgElement(
              mainSvgElement,
              new JPoint(elementWidth, 0),
              exportSize
            );

            y = exportSize.height / 2 - elementHeight / 2;
            if (logoPosition === PageElementPosition.TopLeft) {
              y = Math.max(logoSize.height, y);
            } else if (logoPosition === PageElementPosition.BottomLeft) {
              y = Math.min(
                exportSize.height - logoSize.height,
                (exportSize.height - logoSize.height) / 2 - elementHeight / 2
              );
            }
            break;
          case PageElementPosition.Right:
            x = exportSize.width - elementWidth;
            y = exportSize.height / 2 - elementHeight / 2;
            if (logoPosition === PageElementPosition.TopRight) {
              y = Math.max(logoSize.height, y);
            } else if (logoPosition === PageElementPosition.BottomRight) {
              y = Math.min(
                exportSize.height - logoSize.height,
                (exportSize.height - logoSize.height) / 2 - elementHeight / 2
              );
            }
            break;
        }

        element.setAttribute('x', `${Math.max(0, x)}`);
        element.setAttribute('y', `${Math.max(0, y)}`);

        processedElements.push(element);
      });
    } else {
      for (const e of additionalElements) {
        const element = await e.toSvgAsync();

        if (<PageElementPosition>e.options.position in PageElementPosition) {
          const position = <PageElementPosition>e.options.position;
          GraphExportHelper.applyStaticPosition(element, position, exportSize);
        } else {
          const position = <JPoint>e.options.position;

          element.setAttribute('x', `${position.x + 1}`);
          element.setAttribute('y', `${position.y + 1}`);
        }

        processedElements.push(element);
      }
    }

    return { processedElements, exportSize };
  }

  public static applyStaticPosition(
    element: SVGElement,
    position: PageElementPosition,
    exportSize: JSize
  ): void {
    let x = 0;
    let y = 0;
    const width = parseFloat(element.getAttribute('width'));
    const height = parseFloat(element.getAttribute('height'));

    switch (position) {
      case PageElementPosition.TopLeft:
        GraphExportHelper.moveSvgElement(
          element,
          new JPoint(0, height),
          exportSize
        );
        break;
      case PageElementPosition.TopRight:
        GraphExportHelper.moveSvgElement(
          element,
          new JPoint(0, height),
          exportSize
        );
        x = exportSize.width - width;
        break;
      case PageElementPosition.BottomLeft:
        y = exportSize.height - height;
        break;
      case PageElementPosition.BottomRight:
        x = exportSize.width - width;
        y = exportSize.height - height;
        break;
    }

    element.setAttribute('x', `${Math.max(0, x)}`);
    element.setAttribute('y', `${Math.max(0, y)}`);
  }

  public static async finalizeAdditionalSvgElements(
    document: DocumentDto,
    page: DocumentPageDto,
    additionalElements: ExportPageElement[]
  ): Promise<{ exportSize: JSize; finalSvg: SVGElement }> {
    const { processedElements, exportSize } =
      await GraphExportHelper.processAdditionalElements(
        additionalElements,
        GraphExportHelper.getDefaultExportSize(document, page),
        false
      );
    const finalSvg = this.createSvgContainer(exportSize);
    processedElements.forEach((element) => finalSvg.append(element));

    return { exportSize, finalSvg };
  }
  public static async finalizeSvgForPrint(
    document: DocumentDto,
    page: DocumentPageDto,
    svgElement: SVGElement,
    additionalElements: ExportPageElement[],
    targetRect: JRect,
    pageType: DocumentPageType
  ): Promise<{ exportSize: JSize; finalSvg: SVGElement }> {
    let exportSize = new JSize(
      document.pageStyle.width * ExportConfig.pointToPixelFactor,
      document.pageStyle.height * ExportConfig.pointToPixelFactor
    );
    let svgSize = new JSize(targetRect.width, targetRect.height);

    //TODO: Uncomment to restore functionality
    // const documentView =
    //   store.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`];
    // if (documentView === DocumentView.PrintPreview) {
    //   const printPreview = page?.diagram?.diagramViews?.printPreview;
    //   if (printPreview) {
    //     const offsetX =
    //       pageSize.width * printPreview.data.exportRect.location.x;
    //     const offsetY =
    //       pageSize.height * printPreview.data.exportRect.location.y;

    //     this.setSvgElementSize(
    //       svgElement,
    //       new JSize(
    //         pageSize.width * printPreview.data.exportRect.size.width,
    //         pageSize.height * printPreview.data.exportRect.size.height
    //       )
    //     );
    //     this.moveSvgElement(svgElement, new JPoint(offsetX, offsetY), pageSize);
    //   }
    // } else {

    // Calculate minimal diagram size (either from pageType or use config default)
    if (pageType !== undefined) {
      exportSize = ExportUtils.calculateBodyPartSize(
        document,
        page,
        'diagram'
      ).multiply(ExportConfig.pointToPixelFactor);
    }

    const finalSvg = GraphExportHelper.createSvgContainer(exportSize);
    const padding = ExportUtils.calculatePadding(document, page, 'diagram');
    svgSize = fitRectIntoBounds(
      new JSize(
        svgSize.width + padding.left + padding.right,
        svgSize.height + padding.top + padding.bottom
      ),
      new JSize(exportSize.width, exportSize.height)
    );

    this.setSvgElementSize(svgElement, svgSize);

    this.moveSvgElement(
      svgElement,
      new JPoint(
        (exportSize.width - svgSize.width) / 2,
        (exportSize.height - svgSize.height) / 2
      ),
      exportSize
    );
    //}

    svgElement.setAttribute(
      'viewBox',
      `0 0 ${targetRect.width} ${targetRect.height}`
    );

    this.cleanupAttributes(svgElement);

    svgElement.setAttribute('data-name', GraphExportHelper.graphSvgName);
    finalSvg.append(svgElement);

    if (additionalElements && additionalElements.length) {
      const { processedElements } =
        await GraphExportHelper.processAdditionalElements(
          additionalElements,
          exportSize,
          false,
          svgElement
        );
      processedElements.forEach((element) => finalSvg.append(element));
    }

    return { exportSize, finalSvg };
  }

  public static async finalizeSvgForWebView(
    document: DocumentDto,
    page: DocumentPageDto,
    mainSvgElement: SVGElement,
    additionalElements: ExportPageElement[]
  ): Promise<FinalSvgData> {
    const pageSize = ExportUtils.calculateBodyPartSize(
      document,
      page,
      'diagram'
    ).multiply(ExportConfig.pointToPixelFactor);

    const finalSvg = this.createSvgContainer(pageSize);

    GraphExportHelper.setSvgElementSize(mainSvgElement, pageSize);

    const logoDetails = await LegendLayoutUtils.getLogoDetails(pageSize, true);

    if (additionalElements && additionalElements.length) {
      const { processedElements } =
        await GraphExportHelper.processAdditionalElements(
          additionalElements,
          pageSize,
          !document.hasSteps,
          mainSvgElement,
          logoDetails
        );
      processedElements.forEach((element) => finalSvg.append(element));
    }

    finalSvg.append(mainSvgElement);

    return { exportSize: pageSize, finalSvg };
  }

  /**
   * Make sure that the final SVG being passed into PDF, PNG and other exports
   * does not contain any unnecessary attributes or attributes that may break it
   */
  private static cleanupAttributes(svgElement: SVGElement): void {
    svgElement.removeAttribute('style');
    svgElement.querySelector('clipPath')?.remove();
    svgElement.querySelector('g[clip-path]')?.removeAttribute('clip-path');
  }

  /**
   * Move the SVG element
   */
  private static moveSvgElement(
    svgElement: SVGElement,
    offset: JPoint,
    exportSize: JSize
  ): void {
    if (!svgElement) return;

    const x = parseFloat(svgElement.getAttribute('x') || '0') + offset.x;
    const y = parseFloat(svgElement.getAttribute('y') || '0') + offset.y;
    const width = parseFloat(svgElement.getAttribute('width') || '0');
    const height = parseFloat(svgElement.getAttribute('height') || '0');

    if (x + width > exportSize.width) {
      svgElement.setAttribute('x', `${exportSize.width - width}`);
    } else if (x < 0) {
      svgElement.setAttribute('x', '0');
    } else {
      svgElement.setAttribute('x', `${x}`);
    }

    if (y + height > exportSize.height) {
      svgElement.setAttribute('y', `${exportSize.height - height}`);
    } else if (y < 0) {
      svgElement.setAttribute('y', '0');
    } else {
      svgElement.setAttribute('y', `${y}`);
    }
  }

  /**
   * Update SVG element size including the viewBox attribute
   */
  private static setSvgElementSize(
    svgElement: SVGElement,
    newSize: JSize
  ): void {
    if (!svgElement) return;

    svgElement.setAttribute('width', newSize.width.toString());
    svgElement.setAttribute('height', newSize.height.toString());
  }
}
