import { PluginObject } from 'vue';

import { TButtonVariants } from '@/components/shared/AppButton/types';

export interface IPopupBase {
  title: string;
  text: string | object | Function; // VNode
  okText?: string;
  closeOnEsc?: boolean;
  closeOnBackdrop?: boolean;
  bodyClasses?: string;
  minWidth?: number;
  maxWidth?: number;
  isConfirm?: boolean;
  okVariant?: string;
  showIcon?: boolean;
  customButtons?: ICustomButtons[];
}

export interface ICustomButtons {
  text: string;
  action?: string;
  variant?: TButtonVariants;
  fullWidth?: boolean;
}

export type TMsgBoxTypes = 'info' | 'warning' | 'error' | 'success';
export interface IMsgBox extends IPopupBase {
  type?: TMsgBoxTypes;
}
export interface IConfirm extends IPopupBase {
  denyText?: string;
}

export type Confirmation = (arg: IConfirm) => Promise<boolean | string>;
export type MsgBox = (arg: IMsgBox) => Promise<boolean>;

export const list = {
  list: []
};

const removeById = (id: string | number) => {
  const idx = list.list.findIndex((x) => x.id === id);
  list.list.splice(idx, 1);
};

const createNewPopUp = (confirmOptions: IPopupBase, isConfirm: boolean) => {
  const id = Date.now();

  return new Promise<boolean | string>((resolve, reject) => {
    const newPopup = {
      ...(!isConfirm && { okText: 'Ok' }),
      ...confirmOptions,
      promise: {
        resolve
      },
      id,
      isConfirm
    };

    list.list.push(newPopup);

    console.debug('PopUpPlugin list: ', list);
  })
    .then((sum) => {
      console.debug('PopUpPlugin: resolve', id);
      removeById(id);
      return sum;
    })
    .catch((err) => {
      console.debug('PopUpPlugin: reject', id);
      removeById(id);
      throw err;
    });
};

export const confirm = (
  confirmOptions: IPopupBase
): Promise<boolean | string> => createNewPopUp(confirmOptions, true);
export const msgBox = (confirmOptions: IPopupBase): Promise<boolean | string> =>
  createNewPopUp(confirmOptions, false);

export const PopUpPlugin: PluginObject<any> = {
  install(Vue) {
    console.debug('Installing popups [$confirm] [$msgBox]...');
    Vue.prototype.$confirm = confirm;
    Vue.prototype.$msgBox = msgBox;
  }
};
