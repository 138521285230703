const origin =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '');

type FontDefinition = {
  normal: string;
  bold: string;
  italics: string;
  bolditalics: string;
  // Values needed to calculate the correct line height
  // Use https://opentype.js.org/font-inspector.html
  ascender: number; // Horizontal Header - ascender
  descender: number; // Horizontal Header - descender
  unitsPerEm: number; // Font Header - unitsPerEm
};

export const customPdfFonts: Record<string, FontDefinition> = {
  'Arial': {
    normal: origin + '/media/fonts/pdf/Arial.ttf',
    bold: origin + '/media/fonts/pdf/Arialbd.ttf',
    italics: origin + '/media/fonts/pdf/Ariali.ttf',
    bolditalics: origin + '/media/fonts/pdf/Arialbi.ttf',
    ascender: 1854,
    descender: -434,
    unitsPerEm: 2048
  },
  'ArialNarrow': {
    normal: origin + '/media/fonts/pdf/ArialN.ttf',
    bold: origin + '/media/fonts/pdf/ArialNbd.ttf',
    italics: origin + '/media/fonts/pdf/ArialNi.ttf',
    bolditalics: origin + '/media/fonts/pdf/ArialNbi.ttf',
    ascender: 1916,
    descender: -434,
    unitsPerEm: 2048
  },
  'Arial Narrow': {
    normal: origin + '/media/fonts/pdf/ArialN.ttf',
    bold: origin + '/media/fonts/pdf/ArialNbd.ttf',
    italics: origin + '/media/fonts/pdf/ArialNi.ttf',
    bolditalics: origin + '/media/fonts/pdf/ArialNbi.ttf',
    ascender: 1916,
    descender: -434,
    unitsPerEm: 2048
  },
  'Calibri': {
    normal: origin + '/media/fonts/pdf/Calibri.ttf',
    bold: origin + '/media/fonts/pdf/Calibribd.ttf',
    italics: origin + '/media/fonts/pdf/Calibrii.ttf',
    bolditalics: origin + '/media/fonts/pdf/Calibribi.ttf',
    ascender: 1536,
    descender: -512,
    unitsPerEm: 2048
  },
  'CourierNew': {
    normal: origin + '/media/fonts/pdf/Courier.ttf',
    bold: origin + '/media/fonts/pdf/Courierbd.ttf',
    italics: origin + '/media/fonts/pdf/Courieri.ttf',
    bolditalics: origin + '/media/fonts/pdf/Courierbi.ttf',
    ascender: 1705,
    descender: -615,
    unitsPerEm: 2048
  },
  'Courier New': {
    normal: origin + '/media/fonts/pdf/Courier.ttf',
    bold: origin + '/media/fonts/pdf/Courierbd.ttf',
    italics: origin + '/media/fonts/pdf/Courieri.ttf',
    bolditalics: origin + '/media/fonts/pdf/Courierbi.ttf',
    ascender: 1705,
    descender: -615,
    unitsPerEm: 2048
  },
  'Georgia': {
    normal: origin + '/media/fonts/pdf/Georgia.ttf',
    bold: origin + '/media/fonts/pdf/Georgiabd.ttf',
    italics: origin + '/media/fonts/pdf/Georgiai.ttf',
    bolditalics: origin + '/media/fonts/pdf/Georgiabi.ttf',
    ascender: 1878,
    descender: -449,
    unitsPerEm: 2048
  },
  'TimesNewRoman': {
    normal: origin + '/media/fonts/pdf/Times.ttf',
    bold: origin + '/media/fonts/pdf/Timesbd.ttf',
    italics: origin + '/media/fonts/pdf/Timesi.ttf',
    bolditalics: origin + '/media/fonts/pdf/Timesbi.ttf',
    ascender: 1825,
    descender: -443,
    unitsPerEm: 2048
  },
  'Times New Roman': {
    normal: origin + '/media/fonts/pdf/Times.ttf',
    bold: origin + '/media/fonts/pdf/Timesbd.ttf',
    italics: origin + '/media/fonts/pdf/Timesi.ttf',
    bolditalics: origin + '/media/fonts/pdf/Timesbi.ttf',
    ascender: 1825,
    descender: -443,
    unitsPerEm: 2048
  },
  'TrebuchetMs': {
    normal: origin + '/media/fonts/pdf/Trebuchet.ttf',
    bold: origin + '/media/fonts/pdf/Trebuchetbd.ttf',
    italics: origin + '/media/fonts/pdf/Trebucheti.ttf',
    bolditalics: origin + '/media/fonts/pdf/Trebuchetbi.ttf',
    ascender: 1923,
    descender: -455,
    unitsPerEm: 2048
  },
  'Trebuchet MS': {
    normal: origin + '/media/fonts/pdf/Trebuchet.ttf',
    bold: origin + '/media/fonts/pdf/Trebuchetbd.ttf',
    italics: origin + '/media/fonts/pdf/Trebucheti.ttf',
    bolditalics: origin + '/media/fonts/pdf/Trebuchetbi.ttf',
    ascender: 1923,
    descender: -455,
    unitsPerEm: 2048
  },
  'Verdana': {
    normal: origin + '/media/fonts/pdf/Verdana.ttf',
    bold: origin + '/media/fonts/pdf/Verdanabd.ttf',
    italics: origin + '/media/fonts/pdf/Verdanai.ttf',
    bolditalics: origin + '/media/fonts/pdf/Verdanabi.ttf',
    ascender: 2059,
    descender: -430,
    unitsPerEm: 2048
  },
  'Ebrima': {
    normal: origin + '/media/fonts/pdf/Ebrima.ttf',
    bold: origin + '/media/fonts/pdf/Ebrimabd.ttf',
    italics: origin + '/media/fonts/pdf/Ebrima.ttf',
    bolditalics: origin + '/media/fonts/pdf/Ebrimabd.ttf',
    ascender: 2210,
    descender: -514,
    unitsPerEm: 2048
  },
  'Minion Pro': {
    normal: origin + '/media/fonts/pdf/MinionPro-Regular.otf',
    bold: origin + '/media/fonts/pdf/MinionPro-Bold.otf',
    italics: origin + '/media/fonts/pdf/MinionPro-It.otf',
    bolditalics: origin + '/media/fonts/pdf/MinionPro-BoldIt.otf',
    ascender: 2210,
    descender: -514,
    unitsPerEm: 2048
  },
  'Symbola': {
    normal: origin + '/media/fonts/pdf/Symbola.ttf',
    bold: origin + '/media/fonts/pdf/Symbola.ttf',
    italics: origin + '/media/fonts/pdf/Symbola.ttf',
    bolditalics: origin + '/media/fonts/pdf/Symbola.ttf',
    ascender: 1606,
    descender: -442,
    unitsPerEm: 2048
  }
};
