import ExportOptions from '../ExportOptions';
import ExportPageElement from '../ExportPageElement';
import AdditionalElementProvider from './AdditionalElementProvider';
import {
  DiagramDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentView
} from '@/api/models';
import ExportPage from '../ExportPage';
import { DiagramSize } from '@/view/pages/document/DiagramSize';
import BackgroundDomService from '../../BackgroundDomService';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import CachingService from '../../caching/CachingService';
import CacheType from '../../caching/CacheType';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import ExportUtils from '../ExportUtils';
import JSize from '@/core/common/JSize';
import ExportConfig from '@/core/config/ExportConfig';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT_VIEW
} from '@/core/services/store/document.module';
import IDiagramLegend from '@/components/DiagramLegend/IDiagramLegend';

export default class LegendAsImageProvider extends AdditionalElementProvider {
  constructor(options: ExportOptions, exportPage: ExportPage) {
    super(options, exportPage);
  }

  public async get(): Promise<ExportPageElement[]> {
    const exportElements: ExportPageElement[] = [];
    if (
      this.exportPage.page.contentType == DocumentPageContentType.MasterLegend
    ) {
      const masterLegendExport = await this.exportMasterLegendOrGetFromCache(
        this.exportPage.page
      );
      exportElements.push(masterLegendExport);
    } else {
      // Get all diagrams on the current page
      const diagrams: DiagramDto[] = [];
      if (this.exportPage.page.diagram) {
        diagrams.push(this.exportPage.page.diagram);
      }
      if (this.exportPage.page.subPageRefs) {
        for (const ref of this.exportPage.page.subPageRefs) {
          diagrams.push(ref.diagram);
        }
      }

      for (const diagram of diagrams) {
        const filteredDiagram = { ...diagram };
        if (filteredDiagram.legend && this.options.withFilters) {
          const legendDefinition = <ILegendDefinition>(
            JSON.parse(filteredDiagram.legend)
          );
          legendDefinition.items = legendDefinition.items.filter(
            (x) => !x.isFiltered && x.show
          );
          filteredDiagram.legend = JSON.stringify(legendDefinition);
        }

        const diagramLegendExport =
          await this.exportDiagramLegendOrGetFromCache(
            filteredDiagram,
            this.exportPage.page
          );
        exportElements.push(diagramLegendExport);
      }
    }

    return Promise.resolve(exportElements);
  }

  private async exportDiagramLegendOrGetFromCache(
    diagram: DiagramDto,
    page: DocumentPageDto
  ): Promise<ExportPageElement> {
    const diagramSize =
      this.exportPage.page.pageType == DocumentPageType.Diagram
        ? DiagramSize.Medium
        : DiagramSize.Small;
    const cacheKey = CachingService.generateKey(
      CacheType.DiagramLegendExport,
      diagram.id,
      diagram.legend,
      diagramSize,
      this.getParentSize().toString()
    );
    return CachingService.getOrSetMutexAsync(cacheKey, async () => {
      return { data: await this.export(page, diagram, diagramSize) };
    });
  }

  private async exportMasterLegendOrGetFromCache(
    page: DocumentPageDto
  ): Promise<ExportPageElement> {
    const diagramSize = DiagramSize.Medium;
    const cacheKey = CachingService.generateKey(
      CacheType.MasterLegendExport,
      page.id,
      page.content,
      diagramSize
    );
    return CachingService.getOrSetMutexAsync(cacheKey, async () => {
      return { data: await this.export(page, null, diagramSize) };
    });
  }

  getParentSize(): JSize {
    const documentView =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`];

    if (documentView === DocumentView.Web && !this.options.document.hasSteps) {
      const containerBounds = document
        .querySelector(`.${ExportConfig.innerBodyContainerClass}`)
        .getBoundingClientRect();
      const padding = ExportUtils.calculatePadding(
        this.options.document,
        this.exportPage.page,
        'diagram'
      );

      return new JSize(
        containerBounds.width - padding.left - padding.right,
        containerBounds.height - padding.left - padding.right
      );
    }

    // Required for split pages
    return ExportUtils.calculateBodyPartSize(
      this.options.document,
      this.exportPage.page,
      'diagram'
    ).multiply(ExportConfig.pointToPixelFactor);
  }

  public async renderLegend(
    page: DocumentPageDto,
    diagram: DiagramDto,
    diagramSize: DiagramSize = DiagramSize.Large,
    pageSize?: JSize
  ): Promise<{
    legendInstance: IDiagramLegend;
    legendContainer: Element;
    parentSize: JSize;
  }> {
    const parentSize = pageSize ?? this.getParentSize();
    const legendInstance = await LegendUtils.createLegendComponent({
      page,
      diagram,
      parentSize,
      diagramSize,
      isExport: true,
      isReadOnly: true
    });

    // Add legend to document to render & apply styles
    const legendContainer = BackgroundDomService.createElement('div');

    legendContainer.append(legendInstance.$el);
    BackgroundDomService.appendElement(legendContainer);

    await legendInstance.setLegendStyle();

    if (legendInstance.layout?.position && this.options.document.hasSteps) {
      const r = legendInstance.$el?.getBoundingClientRect();
      const y = legendInstance.layout.position.y * parentSize.height;

      // Update master legend and mirroring pages
      if (r.height + y > parentSize.height) {
        await legendInstance.ensureValidPosition();
      }
    }

    return { legendInstance, legendContainer, parentSize };
  }

  private async export(
    page: DocumentPageDto,
    diagram: DiagramDto,
    diagramSize: DiagramSize
  ): Promise<ExportPageElement> {
    const { legendInstance, legendContainer } = await this.renderLegend(
      page,
      diagram,
      diagramSize
    );

    const convertSymbolsToImages = true;
    const legendExport: ExportPageElement = legendInstance.export(
      diagram?.id ?? page?.id,
      convertSymbolsToImages
    );

    legendExport.destroy = (): void => {
      BackgroundDomService.removeElement(legendContainer);
    };
    legendInstance.$destroy();

    return legendExport;
  }
}
