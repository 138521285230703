import ISearchParameter from '../parameters/ISearchParameter';
import ISpecification from '../specifications/ISpecification';
import NotSpecification from '../specifications/NotSpecification';
import { FilterScope } from './FilterScope';
import { FilterType } from './FilterType';
import IFilterDefinition from './IFilterDefinition';

export default abstract class Filter {
  public abstract type: FilterType;
  public abstract scope: FilterScope;
  public abstract parameter: ISearchParameter;
  protected abstract _specification: ISpecification;

  get specification() {
    if (this.type == FilterType.Exclude) {
      return new NotSpecification(this._specification);
    }
    return this._specification;
  }

  public serialize(): IFilterDefinition {
    return {
      scope: FilterScope[this.scope],
      parameter: this.parameter,
      type: this.type
    };
  }
}
