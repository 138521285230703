<template>
  <div class="container-fluid landing flex-grow-1 h-full overflow-y-auto px-0">
    <RouterView />
    <AppModal v-model="showApplicationErrorModal">
      <template #header>
        <h3 class="text-warning flex flex-grow items-center">
          <i class="las la-wrench la-2x"></i>{{ applicationError.title }}
        </h3>
      </template>
      <!-- C O N T E N T -->
      <p class="my-4">
        {{ applicationError.message }}
      </p>
      <AppButton
        v-if="applicationError.refresh"
        variant="purple"
        full-width
        size="lg"
        :disabled="reloading"
        @click="reload"
      >
        <i
          class="las la-sync"
          :class="{ 'rotate-animate': reloading }"
          style="width: 16px; height: 16px"
        />
        {{ $t('RELOAD') }}
      </AppButton>

      <template #footer>
        <AppButton variant="cyan" @click="showApplicationErrorModal = false">
          {{ $t('CLOSE') }}
        </AppButton>
      </template>
    </AppModal>
    <ModalIdle
      :show-modal-idle="showModalIdle"
      @toggle="showModalIdle = $event"
    />

    <MessageUsersModal />

    <!-- Confirmation Dialogs Mounting Area -->
    <MountingPortal mountTo="#popups" name="confirms" append>
      <AppConfirmation />
    </MountingPortal>

    <!-- Notification Toasts Mounting Area -->
    <MountingPortal mountTo="#notifications" name="notifications" append>
      <AppNotification />
    </MountingPortal>

    <PortalTarget :name="$portalNames.PopUps" multiple />
    <PortalTarget :name="$portalNames.QuickPanel" multiple />
    <Debug v-if="debugEnabled"></Debug>

    <AppLoadingOverlay :loading="globalLoading" />
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'socicon/css/socicon.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';
@import 'vue-simple-suggest/dist/styles.css';
@import 'vue-select/dist/vue-select.css';
@import 'famfamfam-flags/dist/sprite/famfamfam-flags.min.css';
// Main demo style scss
@import 'assets/sass/style.vue';
</style>

<script lang="ts">
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import vSelect from 'vue-select';
import { EventBusActions } from './core/services/events/eventbus.service';
import ModalIdle from '@/components/ModalIdle/ModalIdle.vue';
import AuthService from './core/services/auth/AuthService';
import Debug from '@/components/DebugComponent/Debug.vue';
import { DEBUG_NAMESPACE } from '@/core/services/store/debug.module';

import { Vue, Component } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import {
  GET_GLOBAL_LOADING,
  GLOBAL_NAMESPACE,
  SET_SEASON_THEME
} from './core/services/store/global.module';
import MessageUsersModal from './view/modals/MessageUsersModal.vue';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
Vue.component('v-select', vSelect);

@Component({
  name: 'MetronicVue',
  components: {
    ModalIdle,
    Debug,
    MessageUsersModal
  }
})
export default class MetronicVue extends Vue {
  reloading = false;
  applicationError = {
    refresh: false,
    message: '',
    title: '',
    navigate: null
  };
  showApplicationErrorModal = false;
  showModalIdle = false;

  @State('enabled', { namespace: DEBUG_NAMESPACE })
  debugEnabled: boolean;

  @Getter('layoutConfig')
  layoutConfig;

  @Getter('isAuthenticated')
  isAuthenticated: boolean;

  @Mutation(SET_SEASON_THEME, { namespace: GLOBAL_NAMESPACE })
  setSeasonTheme: (theme: string) => void;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_GLOBAL_LOADING}`)
  globalLoading: boolean;

  beforeMount() {
    this.eventBusAction(EventBusActions.APPLICATION_IDLE_ONIDLE, (payload) => {
      // Show IdleModal if enabled and logged in
      const sessionTimeout =
        this.$store.getters?.currentSettings.sessionTimeout;
      if (sessionTimeout) {
        if (
          AuthService.isLoggedIn &&
          sessionTimeout.showLockScreenWhenTimedOut
        ) {
          if (document.hidden) {
            this.$router.push({
              name: 'logout'
            });
          } else this.showModalIdle = true;
        }
        // Logout user if IdleModal not enabled
        if (sessionTimeout.showLockScreenWhenTimedOut === false) {
          this.$router
            .push({
              name: 'logout'
            })
            .catch((failure) => {
              if (
                !isNavigationFailure(failure, NavigationFailureType.aborted)
              ) {
                console.error(failure);
              }
            });
        }
      }
    });
    this.eventBusAction(EventBusActions.APPLICATION_ERROR, (payload) => {
      this.applicationError = payload;
      this.showApplicationErrorModal = true;
    });
  }

  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    // Set Season Theme
    this.setSeasonTheme(localStorage.getItem('seasonalTheme') as string);
  }

  reload() {
    this.reloading = true;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
</script>
