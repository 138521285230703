﻿import {
  CanvasComponent,
  ConcurrencyController,
  Cursor,
  EventRecognizers,
  GraphComponent,
  GraphEditorInputMode,
  GraphItemTypes,
  ICanvasContext,
  IInputModeContext,
  InputModeEventArgs,
  Insets,
  Key,
  KeyEventArgs,
  KeyEventRecognizers,
  ModifierKeys,
  MouseButtons,
  MouseEventArgs,
  MouseEventRecognizers,
  MoveViewportInputMode
} from 'yfiles';
import { MoveViewportState } from './MoveViewportState';
import { debounce } from '@/core/common/DebounceDecorator';

/**
 *  To allow for 3 stage visual which progresses
 *  Initial state - default pointer
 *  Press CTRL - Cursor is grab
 *  User initialises grab with left click - Cursor is grabbing
 */
export default class JigsawMoveViewportInputMode extends MoveViewportInputMode {
  private keyDownListener = this.onKeyDown.bind(this);
  private keyUpListener = this.onKeyUp.bind(this);
  private mouseDownListener = this.onMouseDown.bind(this);
  private mouseUpListener = this.onMouseUp.bind(this);
  private mouseWheelListener = this.onMouseWheel.bind(this);

  private _isDragging: boolean;
  private armed = false;

  public get isDragging(): boolean {
    return this._isDragging;
  }

  private _isZooming: boolean;
  public get isZooming(): boolean {
    return this._isZooming;
  }
  constructor() {
    super();
  }

  private onKeyDown(sender: CanvasComponent, evt: KeyEventArgs): void {
    if (evt.key === Key.CTRL) {
      this.controller.preferredCursor = Cursor.GRAB;
      this.armed = true;
    } else if (this.armed) {
      this.armed = false;
      this.controller.preferredCursor = Cursor.DEFAULT;
    }
  }

  private onKeyUp(sender: CanvasComponent, evt: KeyEventArgs): void {
    if (evt.key === Key.CTRL && this.armed) {
      this.controller.preferredCursor = Cursor.DEFAULT;
      this.armed = false;
    }
  }

  private onMouseDown(sender: GraphComponent, args: MouseEventArgs): void {
    if (args.buttons !== MouseButtons.RIGHT) return;
    const inputMode = sender.inputMode as GraphEditorInputMode;
    const items = inputMode
      .findItems(args.location, [GraphItemTypes.ALL])
      .toArray();

    if (items.length > 0) return;

    this.setMode(MoveViewportState.Grab);
  }

  private onMouseUp(sender: GraphComponent, args: MouseEventArgs): void {
    if (args.changedButtons !== MouseButtons.RIGHT) return;
    this.setMode(MoveViewportState.Default);
  }

  private onMouseWheel(sender: GraphComponent, args: MouseEventArgs): void {
    if ((args.modifiers & ModifierKeys.CONTROL) != ModifierKeys.CONTROL) {
      return;
    }
    this._isZooming = true;
    this.onZoomEnded();
  }

  @debounce(200)
  private onZoomEnded(): void {
    this._isZooming = false;
    this.graphComponent.invalidate();
  }

  private get graphComponent(): GraphComponent {
    return this.inputModeContext.canvasComponent as GraphComponent;
  }

  public setMode(moveViewportState: MoveViewportState): void {
    const inputMode = this.graphComponent.inputMode as GraphEditorInputMode;

    switch (moveViewportState) {
      case MoveViewportState.Default:
        this.pressedRecognizer = EventRecognizers.createAndRecognizer(
          MouseEventRecognizers.LEFT_IS_DOWN,
          KeyEventRecognizers.CTRL_IS_DOWN
        );
        this.dragCursor = Cursor.GRABBING;
        inputMode.defaultCursor = Cursor.DEFAULT;
        inputMode.marqueeSelectionInputMode.enabled = true;
        break;
      case MoveViewportState.Grab:
        this.pressedRecognizer = EventRecognizers.createOrRecognizer(
          MouseEventRecognizers.LEFT_IS_DOWN,
          MouseEventRecognizers.RIGHT_IS_DOWN,
          MouseEventRecognizers.RIGHT_DRAG
        );
        this.dragCursor = Cursor.GRABBING;
        inputMode.defaultCursor = Cursor.GRAB;
        inputMode.marqueeSelectionInputMode.enabled = false;
        break;
    }
  }

  install(context: IInputModeContext, controller: ConcurrencyController): void {
    // Change default autoDragInsets values including left sidebar and header to fix auto-scroll speed on drag
    context.canvasComponent.autoDragInsets = new Insets(60, 60, 60, 60);
    super.install(context, controller);

    this.setMode(MoveViewportState.Default);

    const graphComponent = context.canvasComponent as GraphComponent;

    graphComponent.canvasContext.canvasComponent.addKeyDownListener(
      this.keyDownListener
    );

    graphComponent.canvasContext.canvasComponent.addKeyUpListener(
      this.keyUpListener
    );

    graphComponent.addMouseDownListener(this.mouseDownListener);

    graphComponent.addMouseUpListener(this.mouseUpListener);
    graphComponent.addMouseWheelListener(this.mouseWheelListener);
  }

  uninstall(context: IInputModeContext): void {
    const graphComponent = context.canvasComponent as GraphComponent;
    graphComponent.canvasContext.canvasComponent.removeKeyDownListener(
      this.keyDownListener
    );

    graphComponent.canvasContext.canvasComponent.removeKeyUpListener(
      this.keyUpListener
    );

    graphComponent.removeMouseDownListener(this.mouseDownListener);

    graphComponent.removeMouseUpListener(this.mouseUpListener);
    graphComponent.removeMouseWheelListener(this.mouseWheelListener);
    super.uninstall(context);
  }

  onDragStarting(evt: InputModeEventArgs): void {
    this._isDragging = true;
    super.onDragStarting(evt);
  }
  onDragCanceling(evt: InputModeEventArgs): void {
    this._isDragging = false;
    super.onDragCanceling(evt);
  }

  onDragFinishing(evt: InputModeEventArgs): void {
    this._isDragging = false;
    super.onDragFinishing(evt);
  }
  onDragFinished(evt: InputModeEventArgs): void {
    this._isDragging = false;
    super.onDragFinished(evt);
    this.graphComponent.invalidate();
  }
}
const isViewPortChanging = (canvasContext: ICanvasContext): boolean => {
  if (canvasContext == null) {
    console.warn('canvasContext required');
    return false;
  }

  if (
    canvasContext.canvasComponent?.inputMode == null ||
    !(canvasContext.canvasComponent?.inputMode instanceof GraphEditorInputMode)
  ) {
    return false;
  }

  const moveViewPortInputMode = (
    canvasContext.canvasComponent.inputMode as GraphEditorInputMode
  ).moveViewportInputMode as JigsawMoveViewportInputMode;
  if (!moveViewPortInputMode) {
    return false;
  }
  return moveViewPortInputMode.isDragging || moveViewPortInputMode.isZooming;
};

export { isViewPortChanging };
