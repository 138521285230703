<template>
  <div :aria-disabled="disabled" class="aria-disabled:cursor-not-allowed">
    <div
      class="flex items-center gap-x-4 aria-disabled:pointer-events-none aria-disabled:opacity-50"
      :class="variantClasses"
      :aria-disabled="disabled"
      @click="$emit('click', $event)"
    >
      <slot name="prepend" />

      <slot name="badge">
        <span
          v-if="badgeBackgroundColor || badgeClassColor"
          class="inline-flex"
          :class="badgeClasses"
          :style="badgeStyles"
        />
      </slot>
      <slot name="title">
        <span
          v-if="title"
          :class="[fontSize, titleCustomClasses]"
          class="inline-flex text-14 leading-none"
        >
          {{ title }}
        </span>
      </slot>

      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CSSProperties } from 'vue/types/jsx';

type TSizes = 'xs' | 'sm' | 'md' | 'lg';

type TVariants = 'default' | 'none';

const sizes = {
  xs: 'px-6 py-2',
  sm: 'px-8 py-3',
  md: 'px-10 py-4',
  lg: 'px-12 py-5'
};

const badgeSizes = {
  xs: 'w-8 h-8',
  sm: 'w-12 h-12',
  md: 'w-12 h-12',
  lg: 'w-14 h-14'
};

const variants = {
  default: 'text-main-dark border border-main-dark-10',
  dark: 'text-main-dark border border-main-dark',
  none: ''
};

@Component({
  name: 'AppColorBadge'
})
export default class AppColorBadge extends Vue {
  @Prop({
    default: 'default',
    type: String,
    validator: (prop: TVariants) => Object.keys(variants).includes(prop)
  })
  variant: TVariants;

  @Prop({
    default: 'sm',
    type: String,
    validator: (prop: TSizes) => Object.keys(sizes).includes(prop)
  })
  size: TSizes;

  @Prop({ default: '', type: String })
  title: string;

  @Prop({ default: 'text-14', type: String })
  fontSize: string;

  @Prop({ default: '', type: String })
  badgeBackgroundColor: string;

  @Prop({ default: 'bg-main-dark-10', type: String })
  badgeClassColor: string;

  @Prop({ default: 'rounded-full', type: String })
  wrapRoundedClass: string;

  @Prop({ default: 'rounded-full', type: String })
  badgeRoundedClass: string;

  @Prop({ default: '', type: String })
  badgeCustomClasses: string;

  @Prop({ default: '', type: String })
  titleCustomClasses: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  get badgeStyles(): CSSProperties {
    return {
      backgroundColor: this.badgeBackgroundColor
    };
  }

  get variantClasses(): string[] {
    return [variants[this.variant], sizes[this.size], this.wrapRoundedClass];
  }

  get badgeClasses(): string[] {
    return [
      badgeSizes[this.size],
      this.badgeClassColor,
      this.badgeRoundedClass,
      this.badgeCustomClasses
    ];
  }
}
</script>
