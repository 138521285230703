import {
  ILabel,
  ILabelModelParameter,
  INode,
  BaseClass,
  Point,
  ILabelModel,
  IEdge
} from 'yfiles';
import JigsawEdgeLabelModel from './JigsawEdgeLabelModel';

export default class JigsawEdgeLabelModelParameter extends BaseClass<ILabelModelParameter>(
  ILabelModelParameter
) {
  /**
   *
   * @param _model
   * @param options
   */

  private _segmentIndex: number = null;
  private _ratio: number = null;
  private _distance: number = null;
  private _left: boolean = false;

  public get segmentIndex(): number {
    return this._segmentIndex;
  }
  public get ratio(): number {
    return this._ratio;
  }
  public get distance(): number {
    return this._distance;
  }
  public get left(): boolean {
    return this._left;
  }

  constructor(
    private readonly _model: JigsawEdgeLabelModel,
    readonly options: {
      readonly ratio: number;
      readonly segmentIndex: number;
      readonly distance: number;
      readonly left: boolean;
    }
  ) {
    super();

    this._ratio = options.ratio;
    this._segmentIndex = options.segmentIndex;
    this._distance = options.distance;
    this._left = options.left;
  }

  public clone(): this {
    return this;
  }

  get model(): ILabelModel {
    return this._model;
  }

  public supports(label: ILabel): boolean {
    return label.owner instanceof IEdge;
  }
}
