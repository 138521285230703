<template>
  <div>
    <slot name="activator" :isOpen="valueSync" :toggle="toggle"></slot>

    <AppTransitionExpand>
      <div v-show="valueSync">
        <slot />
      </div>
    </AppTransitionExpand>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppCollapse'
})
export default class AppCollapse extends Vue {
  @Prop({ default: null })
  value: boolean;

  private localValue = false;

  get valueSync() {
    if (this.value == null) {
      return this.localValue;
    }
    return this.value;
  }

  set valueSync(value) {
    if (this.value == null) {
      this.localValue = value;
    } else {
      this.localValue = value;
      this.$emit('input', value);
    }
  }

  toggle() {
    this.valueSync = !this.valueSync;
  }
}
</script>
