import {
  CreateOrEditThemeElementDto,
  DashStyleType,
  EdgeLabelPosition,
  EdgeStyleDto,
  EdgeVisualType,
  ElementType,
  ImageNodeStyleDto,
  INodeStyleDto,
  LabelStyleDto,
  NodeLabelPosition,
  NodeVisualType,
  ThemeElementDto
} from '@/api/models';
import {
  ArcEdgeStyle,
  GraphComponent,
  ICommand,
  IEdge,
  IEdgeStyle,
  ILabel,
  INode,
  INodeStyle,
  Insets,
  Point,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  Size,
  SvgExport
} from 'yfiles';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import DiagramUtils from './DiagramUtils';
import StyleCreator from './StyleCreator';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import JigsawInteriorNodeLabelModel from '@/core/services/graph/label-models/JigsawInteriorNodeLabelModel';
import CKEditorUtils from './CKEditorUtils';
import { DefaultColors } from '@/core/common/DefaultColors';
import i18n from '@/core/plugins/vue-i18n';

export default class ElementSvgRenderUtils {
  public static createSvgFromThemeElement(
    themeElement: ThemeElementDto | CreateOrEditThemeElementDto,
    labelText?: string
  ): Promise<string> {
    switch (themeElement.elementType) {
      case ElementType.Node:
        return ElementSvgRenderUtils.createSvgFromNodeStyle(
          themeElement.style,
          null,
          labelText,
          themeElement.nodeLabelPosition || NodeLabelPosition.InteriorCenter
        );
      case ElementType.Edge:
        return ElementSvgRenderUtils.createSvgFromEdgeStyle(
          themeElement.style,
          themeElement.behaviourId
        );
    }

    throw `Unsupported element type ${themeElement?.elementType}`;
  }

  public static createSvgFromGraphNodeStyle(
    nodeStyle: INodeStyle,
    width?: number,
    height?: number,
    padding?: number,
    includeLabel?: boolean,
    labelStyle?: LabelStyleDto,
    labelPosition: NodeLabelPosition = NodeLabelPosition.InteriorBottom
  ): string {
    if (!width) {
      width = 30;
    }
    if (!height) {
      height = 30;
    }
    if (!padding && padding !== 0) {
      padding = 10;
    }
    if (nodeStyle instanceof JigsawNodeStyle) {
      // we don't want to render the top level node style, this will include all the indicators
      // take the base style
      nodeStyle = nodeStyle.baseStyle;
    }

    // another GraphComponent is utilized to export a visual of the given style
    const exportComponent = new GraphComponent();
    const exportGraph = exportComponent.graph;
    // we create a node in this GraphComponent that should be exported as SVG
    let node: INode = exportGraph.createNode(
      new Rect(0, 0, width, height),
      nodeStyle
    );

    const labelPostionParameter = DiagramUtils.getLabelModelParameter(
      node,
      null,
      labelPosition
    );

    if (includeLabel) {
      const labelText = CKEditorUtils.createHtmlStringFromStyle(
        labelStyle?.fill,
        { color: labelStyle?.font.backgroundColor },
        labelStyle?.font,
        i18n.t('LABEL')
      );
      exportGraph.addLabel(
        node,
        labelText,
        labelPostionParameter || JigsawInteriorNodeLabelModel.CENTER,
        StyleCreator.createLabelStyle()
      );
    }
    exportComponent.updateContentRect(new Insets(padding));
    // the SvgExport can export the content of any GraphComponent
    const svgExport = new SvgExport(exportComponent.contentRect);
    const svg = svgExport.exportSvg(exportComponent);
    this.replaceUsesWithDefs(svg);
    const svgString = SvgExport.exportSvgString(svg);
    return SvgExport.encodeSvgDataUrl(svgString);
  }

  public static createSvgFromNodeStyle(
    nodeStyle: INodeStyleDto,
    size?: Size,
    labelText?: string,
    labelPosition?: NodeLabelPosition
  ): Promise<string> {
    let width;
    let height;

    if (size) {
      width = size.width;
      height = size.height;
    }

    if (!width) {
      width = 60;
    }

    const includeLabel = !!labelText;

    return new Promise<string>((resolve) => {
      let style = StyleCreator.createNodeStyle(nodeStyle, []);
      if (nodeStyle.visualType == NodeVisualType.Image) {
        // images need to be scaled correctly, to maintain aspect ratio.
        // this now has to be promise based.
        let img = new Image();
        img.onload = (): void => {
          let scale: number, newWidth: number, newHeight: number;
          let imgWidth = img.width;
          let imgHeight = img.height;
          if (imgWidth < width) {
            scale = width / imgWidth;
          } else {
            scale = width / imgHeight;
          }
          newWidth = imgWidth * scale;
          newHeight = imgHeight * scale;
          resolve(this.createSvgFromGraphNodeStyle(style, newWidth, newHeight));
        };
        img.src = (nodeStyle as ImageNodeStyleDto).imageUrl;
      } else {
        // if the node has a fixed with/height we need to display the preview correctly.
        // for example, an oval.
        if (!size) {
          size = DiagramUtils.getNodeSize(nodeStyle);
          width = size.width;
          height = size.height;
        }

        resolve(
          this.createSvgFromGraphNodeStyle(
            style,
            width,
            height,
            null,
            includeLabel,
            (nodeStyle as any).labelStyle,
            labelPosition
          )
        );
      }
    });
  }

  public static createSvgFromGraphEdgeStyle(
    edgeStyle: IEdgeStyle,
    includeLabel?: boolean,
    labelStyle?: LabelStyleDto,
    length?: number,
    addBend?: boolean,
    padding?: number,
    defaultLabel?: string
  ): string {
    if (!length && length !== 0) {
      length = 30;
    }
    if (!padding && padding !== 0) {
      padding = 20;
    }

    const exportComponent = new GraphComponent();
    const exportGraph = exportComponent.graph;
    let n1 = exportGraph.createNode(new Rect(0, 0, 0, 0), new ShapeNodeStyle());

    let x = length;
    let y = length;

    // Fix arc's at the same height so they are generated consistently for elements like legend
    if (edgeStyle instanceof ArcEdgeStyle) {
      edgeStyle.height = 15;
    }
    // The svg image height will be greater than the width,
    // and this will break the PPT export if an item has a label that spans
    // more than two rows. To fix this, we shrink the height a bit
    const heightCorrection = 4;

    const n2 = exportGraph.createNode(
      new Rect(x, y - heightCorrection, 1, 0),
      new ShapeNodeStyle()
    );

    const edge = exportGraph.createEdge(n1, n2, edgeStyle);

    if (addBend) {
      exportGraph.addBend(edge, new Point(0, length - heightCorrection));
    }

    if (includeLabel) {
      const labelText = CKEditorUtils.createHtmlStringFromStyle(
        labelStyle?.fill,
        { color: labelStyle?.font.backgroundColor },
        labelStyle?.font,
        defaultLabel || 'Label ABC'
      );
      exportGraph.addLabel(
        edge,
        labelText,
        DiagramUtils.getLabelModelParameter(edge),
        StyleCreator.createLabelStyle()
      );
    }

    exportComponent.updateContentRect(new Insets(padding));
    const svgExport = new SvgExport(exportComponent.contentRect);
    const svg = svgExport.exportSvg(exportComponent);
    this.replaceUsesWithDefs(svg);
    const svgString = SvgExport.exportSvgString(svg);
    return SvgExport.encodeSvgDataUrl(svgString);
  }

  public static createSvgFromEdgeStyle(
    edgeStyle: EdgeStyleDto,
    behaviourId: number,
    length?: number,
    includeLabel?: boolean,
    defaultLabel?: string
  ): Promise<string> {
    let style = StyleCreator.createEdgeStyle(edgeStyle);
    let addBend = behaviourId !== 1;
    let ret = this.createSvgFromGraphEdgeStyle(
      style,
      includeLabel,
      edgeStyle.labelStyle,
      length,
      addBend,
      null,
      defaultLabel
    );
    return Promise.resolve<string>(ret);
  }

  public static async createEdgeLabelPositionPreviewSvg(
    themeElement: CreateOrEditThemeElementDto,
    edgeLabelPosition: EdgeLabelPosition
  ): Promise<string> {
    const graphComponent = new GraphComponent();
    const { graph } = graphComponent;
    const edgeLength = 110;
    const nodeSize = 30;

    // Create the nodes
    const shapeNodeStyle = new ShapeNodeStyle({
      shape: ShapeNodeShape.RECTANGLE,
      fill: DefaultColors.BLUE,
      stroke: DefaultColors.TRANSPARENT
    });

    const center = graph.createNode(
      new Rect(
        edgeLength + nodeSize,
        edgeLength + nodeSize,
        nodeSize,
        nodeSize
      ),
      shapeNodeStyle
    );

    const north = graph.createNode(
      new Rect(center.layout.x, 0, nodeSize, nodeSize),
      shapeNodeStyle
    );

    const south = graph.createNode(
      new Rect(
        center.layout.x,
        (edgeLength + nodeSize) * 2,
        nodeSize,
        nodeSize
      ),
      shapeNodeStyle
    );

    const east = graph.createNode(
      new Rect(
        (edgeLength + nodeSize) * 2,
        center.layout.y,
        nodeSize,
        nodeSize
      ),
      shapeNodeStyle
    );

    const west = graph.createNode(
      new Rect(0, center.layout.y, nodeSize, nodeSize),
      shapeNodeStyle
    );

    // Create the edges
    const edgeStyle = StyleCreator.createEdgeStyle(themeElement.style);
    const centerNorthEdge = graph.createEdge(center, north, edgeStyle);
    const centerSouthEdge = graph.createEdge(center, south, edgeStyle);
    const centerEastEdge = graph.createEdge(center, east, edgeStyle);
    const centerWestEdge = graph.createEdge(center, west, edgeStyle);

    // Add edge labels
    const addLabel = (edge: IEdge, labelNumber: number): ILabel =>
      graph.addLabel(
        edge,
        `${i18n.t('LABEL')} - ${labelNumber}`,
        DiagramUtils.getEdgeLabelParameter(edge, edgeLabelPosition),
        StyleCreator.createLabelStyle()
      );

    addLabel(centerNorthEdge, 1);
    addLabel(centerEastEdge, 2);
    addLabel(centerSouthEdge, 3);
    addLabel(centerWestEdge, 4);

    ICommand.FIT_GRAPH_BOUNDS.execute(null, graphComponent);

    const svgExport = new SvgExport(graphComponent.contentRect);
    const svg = svgExport.exportSvg(graphComponent);
    const svgString = SvgExport.exportSvgString(svg);

    return SvgExport.encodeSvgDataUrl(svgString);
  }

  public static createIconFromNode(node: INode): string {
    const size = this.calculateIconSize(node);

    if (node.tag.isGroupNode) {
      const dashArray =
        node.tag.grouping.strokeDash == DashStyleType.Dash ? '2,1' : null;
      const fill = node.tag.grouping.fillColor ?? 'transparent';
      const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style="opacity:.6">     
        <rect x="0" y="0" width = "22" height="22"
        stroke="${node.tag.grouping.strokeColor}"
        stroke-width="2"
        stroke-dasharray="${dashArray}"
        fill="${fill}">
        </rect>
      </svg>`;

      return SvgExport.encodeSvgDataUrl(svgString);
    }
    if (node.tag.style.visualType == NodeVisualType.Image) {
      return (node.tag.style as ImageNodeStyleDto).imageUrl;
    }

    return this.createSvgFromGraphNodeStyle(
      node.style,
      size.width,
      size.height,
      3
    );
  }

  public static createIconFromEdge(edge: IEdge): string {
    const size = this.calculateIconSize(edge);

    //Copy edge style identity, losing Edge linkage
    const edgeStyleDto = DiagramWriter.convertEdgeStyle(edge);
    const edgeStyle = StyleCreator.createEdgeStyle(edgeStyleDto);

    return this.createSvgFromGraphEdgeStyle(
      edgeStyle,
      false,
      null,
      size.width,
      edge.bends?.size > 0 ||
        edge.tag.style.visualType === EdgeVisualType.Elbow ||
        edge.tag.style.visualType === EdgeVisualType.Curved,
      0,
      ''
    );
  }

  private static calculateIconSize(element: INode | IEdge): Size {
    if (element instanceof INode) {
      if (element.layout.width > element.layout.height) {
        return new Size(90, 60);
      } else {
        return new Size(60, 60);
      }
    } else if (element instanceof IEdge) {
      return new Size(30, 30);
    }
  }

  /**
   * Replaces all instances of <use href="#id">...</use> with
   * the corresponding element in <defs> that matches the id
   * This is needed for jsPdf to work correctly
   */
  private static replaceUsesWithDefs(svg: Element): void {
    svg.removeAttribute('style');
    svg.querySelectorAll('use[*|href]').forEach((use) => {
      const id = use.getAttribute('href');
      const def = svg.querySelector(id);
      if (def) {
        use.parentNode.insertBefore(def, use);
        for (let attr of use.attributes) {
          def.setAttribute(attr.name, attr.value);
        }
        def.removeAttribute('href');
        def.removeAttribute('id');
        use.remove();
      }
    });
  }
}
