<template>
  <ImportFile
    :dropzone-options="dropzoneOptions"
    :import-type="ImportType.PowerPoint"
  >
    <template v-slot="{ onClick }">
      <div
        class="sidebar-item h-48 tall:h-60"
        data-automation-id="start-creating-button-powerpoint-import"
        @click="onClick"
      >
        <div class="flex h-24 w-24 min-w-24 items-center justify-center">
          <AppImage
            src="media/svg/icons/Files/Import_icon_ppt.svg"
            class="text-white"
          />
        </div>
        <div class="flex w-full items-center truncate">
          <p class="truncate pl-16 text-15 leading-none text-white">
            {{ $t('IMPORT_POWERPOINT') }}
          </p>
        </div>
      </div>
    </template>
  </ImportFile>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ImportFile from '@/view/pages/hub/components/imports/ImportFile.vue';
import StartCreatingButton from '@/view/pages/hub/home/StartCreatingButton.vue';
import { ImportType } from '@/api/models';
@Component({
  name: 'ImportFromPowerPoint',
  components: {
    StartCreatingButton,
    ImportFile
  }
})
class ImportFromPowerPoint extends Vue {
  public dropzoneOptions = { acceptedFiles: '.ppt,.pptx' };
  public ImportType = ImportType;
}
export default ImportFromPowerPoint;
</script>
