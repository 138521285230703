import {
  CreateOrEditDataPropertyDto,
  DataExportDataPropertyDto,
  DataPropertyDto,
  FileAttachmentDto
} from '@/api/models';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import FileAttachmentDataExporter from './FileAttachmentDataExporter';

export default class DataPropertyDataExporter {
  public static getData(
    property: DataPropertyDto | CreateOrEditDataPropertyDto,
    displayValue: string,
    attachments: FileAttachmentDto[]
  ): DataExportDataPropertyDto {
    const dto = new DataExportDataPropertyDto(property.id);
    const definition = DataPropertyUtils.getDefinition(
      property.dataPropertyDefinitionId
    );
    dto.definition = definition?.label;
    dto.text = displayValue;

    dto.attachments = [];
    for (let attachment of attachments) {
      if (
        attachment.dataPropertyUuid == property.uuid ||
        attachment.dataPropertyDefinitionId == property.dataPropertyDefinitionId
      ) {
        const attachmentDto = FileAttachmentDataExporter.getData(attachment);
        dto.attachments.push(attachmentDto);
      }
    }
    return dto;
  }
}
