import {
  IGraph,
  Rect,
  INode,
  Point,
  ILabelOwner,
  IPort,
  FreeNodePortLocationModel,
  Mapper,
  ImageNodeStyle,
  PortDirections,
  ILabelModelParameter,
  IEdge,
  OrientedRectangle,
  ILabel,
  SimpleLabel,
  EdgeSegmentLabelModel
} from 'yfiles';
import {
  DiagramNodeDto,
  DiagramEdgeDto,
  DiagramDto,
  EdgePortDto,
  LayoutDto,
  ImageNodeStyleDto,
  TextFit
} from '@/api/models';
import INodeTag from '@/core/common/INodeTag';
import IEdgeTag from '@/core/common/IEdgeTag';
import StyleCreator from '@/core/utils/StyleCreator';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { RotatableNodeStyleDecorator } from '../RotatableNodes';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import GroupNodeStyle from '@/core/styles/GroupNodeStyle';
import ILabelTag from '@/core/common/ILabelTag';
import JigsawRichTextLabelStyle from '@/core/styles/JigsawRichTextLabelStyle';
import JigsawInteriorNodeLabelModel from '../label-models/JigsawInteriorNodeLabelModel';
import JigsawExteriorNodeLabelModel from '../label-models/JigsawExteriorNodeLabelModel';
import INodeLabelData from './INodeLabelData';
import JigsawInteriorNodeLabelModelParameter from '../label-models/JigsawInteriorNodeLabelModelParameter';
import JigsawExteriorNodeLabelModelParameter from '../label-models/JigsawExteriorNodeLabelModelParameter';
import { LabelModelType } from '../label-models/LabelModelType';
import IEdgeLabelData from './IEdgeLabelData';
import diagramConfig from '@/core/config/diagram.definition.config';
import JigsawEdgeLabelModel from '../label-models/JigsawEdgeLabelModel';
import JigsawEdgeLabelModelParameter from '../label-models/JigsawEdgeLabelModelParameter';
import { AnnotationType } from '@/core/common/AnnotationType';
import TextBoxLabelModel from '../label-models/TextBoxLabelModel';
import cloneDeep from 'lodash/cloneDeep';
import { nonReactive } from '@/core/utils/common.utils';

/**
 * Take a DiagramDto and writes it to a graph
 */
export default class DiagramReader {
  graph: IGraph;
  diagram: DiagramDto;

  constructor(graph: IGraph, diagram: DiagramDto) {
    this.graph = graph;
    this.diagram = diagram;
  }

  render(): void {
    if (this.diagram?.nodes) {
      const orderedNodes = orderBy(this.diagram.nodes, ['data.displayOrder']);
      this.addNodes(orderedNodes as DiagramNodeDto[]);
    }
    if (this.diagram?.edges) {
      const orderedEdges = orderBy(this.diagram.edges, ['data.displayOrder']);
      this.addEdges(orderedEdges);
    }
    this.graph.tag = { diagramId: this.diagram.id };
  }

  private addNodes(nodes: DiagramNodeDto[]): void {
    const mapper = new Mapper<string, INode>();
    nodes
      .filter((n) => n.isGroupNode)
      .forEach((groupNode) => {
        mapper.set(groupNode.groupUuid, this.addNode(groupNode));
      });
    nodes
      .filter((n) => !n.isGroupNode)
      .forEach((node) => {
        const newNode = this.addNode(node);
        if (node.groupUuid) {
          const parent = mapper.get(node.groupUuid);
          if (parent) {
            this.graph.setParent(newNode, parent);
          } else {
            console.warn(
              `Could not find parent for node group id ${node.groupUuid}`
            );
            // Here we have an orphaned grouped node, remove it's group.
            newNode.tag.groupUuid = null;
          }
        }
      });
  }

  private addNode(node: DiagramNodeDto): INode {
    let tag = DiagramReader.createNodeTagFromData(node);
    tag.dataProperties.forEach((item) => (item.isIncluded = true));
    let nodeStyle: JigsawNodeStyle = null;

    if (node.isGroupNode) {
      nodeStyle = new JigsawNodeStyle(GroupNodeStyle.INSTANCE, []);
    } else {
      nodeStyle = StyleCreator.createNodeStyle(node.style, []);
      if (tag.isAnnotation && nodeStyle.baseStyle instanceof ImageNodeStyle) {
        nodeStyle = new RotatableNodeStyleDecorator(
          nodeStyle,
          (node.style as ImageNodeStyleDto).rotation
        ) as any;
      }
    }

    const createdNode = nonReactive(
      this.graph.createNode({
        layout: this.getNodeLayout(node.layout),
        style: nodeStyle,
        tag: tag
      })
    );

    if (!node.isGroupNode) {
      const decorators = StyleCreator.getNodeDecorators(createdNode);
      const unwrapped = DiagramUtils.unwrapNodeStyle(createdNode);
      for (let i = 0; i < decorators.length; i++) {
        unwrapped.addDecorator(decorators[i]);
      }
      const labelData = node.data?.labelData as INodeLabelData;

      const labelTag = DiagramReader.createLabelTagFromData(labelData);
      if (labelData) {
        labelData.wrapTextInShape = tag.labelData.wrapTextInShape ?? false;
      }
      this.addLabel(createdNode, node.label, labelData, labelTag);
    }

    return createdNode;
  }

  private tryGetEdgeLabelModelParameter(
    label: ILabel,
    labelOwner: IEdge,
    labelData: IEdgeLabelData
  ): ILabelModelParameter {
    if (labelData?.labelPosition) {
      return new JigsawEdgeLabelModelParameter(
        new JigsawEdgeLabelModel(),
        labelData.labelPosition
      );
    }
    if (labelData?.layout) {
      const layout = labelData.layout;
      const orientedRectangle = new OrientedRectangle(
        layout.anchorX,
        layout.anchorY,
        layout.width,
        layout.height,
        layout.upX,
        layout.upY
      );

      const model = new JigsawEdgeLabelModel();
      return model.findBestParameter(label, model, orientedRectangle);
    }
    return DiagramUtils.getLabelModelParameter(labelOwner);
  }
  private tryGetNodeLabelModelParameter(
    labelOwner: INode,
    labelData: INodeLabelData
  ): ILabelModelParameter {
    if (labelData == null) {
      return DiagramUtils.getLabelModelParameter(labelOwner);
    }
    let labelModelParameter:
      | JigsawInteriorNodeLabelModelParameter
      | JigsawExteriorNodeLabelModelParameter = null;

    if (labelOwner.tag.annotationType == AnnotationType.Text) {
      return new TextBoxLabelModel().createDefaultParameter();
    }
    // check we have a model type, and at least an offet or a position vector
    if (
      labelData?.modelType != LabelModelType.Unknown &&
      (labelData.offset || labelData.positionVector)
    ) {
      let labelModel:
        | JigsawInteriorNodeLabelModel
        | JigsawExteriorNodeLabelModel = null;
      if (labelData.modelType == LabelModelType.Interior) {
        labelModel = new JigsawInteriorNodeLabelModel();
      } else {
        labelModel = new JigsawExteriorNodeLabelModel();
      }

      if (!labelData.positionVector) {
        labelModelParameter = labelModel.createDefaultParameter() as any;
      } else {
        const offset = labelData.offset
          ? new Point(labelData.offset.x, labelData.offset.y)
          : null;
        const positionVector = new Point(
          labelData.positionVector.x,
          labelData.positionVector.y
        );
        labelModelParameter = labelModel.createParameterFromVectorAndOffset(
          positionVector,
          offset
        );
      }
    } else {
      labelModelParameter = JigsawInteriorNodeLabelModel.CENTER;
    }
    return labelModelParameter;
  }
  private addLabel(
    labelOwner: ILabelOwner,
    labelText: string,
    labelData?: INodeLabelData | IEdgeLabelData,
    tag?: ILabelTag
  ): void {
    if (labelText === undefined || labelText == null) {
      return;
    }

    let labelModelParameter: ILabelModelParameter = null;
    let style = new JigsawRichTextLabelStyle();
    if (labelOwner instanceof INode) {
      labelModelParameter = this.tryGetNodeLabelModelParameter(
        labelOwner,
        labelData as INodeLabelData
      );
    } else if (labelOwner instanceof IEdge) {
      labelModelParameter = this.tryGetEdgeLabelModelParameter(
        new SimpleLabel(
          labelOwner,
          labelText,
          new EdgeSegmentLabelModel({
            distance: diagramConfig.label.distanceToEdge,
            autoRotation: false
          }).createDefaultParameter()
        ),
        labelOwner,
        labelData as IEdgeLabelData
      );
    } else {
      throw 'unknown label owner';
    }

    const label = nonReactive(
      this.graph.addLabel({
        owner: labelOwner,
        text: labelText,
        layoutParameter: labelModelParameter,
        style: style,
        tag: tag ?? DiagramReader.createLabelTagFromData()
      })
    );
  }

  public static createLabelTagFromData(labelData?: INodeLabelData): ILabelTag {
    return {};
  }

  public static createNodeTagFromData(node: DiagramNodeDto): INodeTag {
    const entityName = node.data.name;
    return {
      definitionCustomised: node.data.definitionCustomised,
      id: node.id,
      isFixedInLayout: node.data.isFixedInLayout,
      isLocked: node.data.isLocked,
      isGroupNode: node.isGroupNode,
      groupUuid: node.groupUuid,
      grouping: node.data.grouping,
      style: node.style,
      uuid: node.uuid,
      originalUuid: node.originalUuid,
      decorationStates: cloneDeep(node.data.decorationStates),
      dataProperties: cloneDeep(node.dataProperties) ?? [],
      dataPropertyTags: cloneDeep(node.dataPropertyTags) ?? [],
      isAnnotation: node.data.isAnnotation,
      name: entityName,
      attachments: node.attachments ?? [],
      annotationType: node.data.annotationType,
      displayOrder: node.data.displayOrder,
      isIncluded: isNil(node?.isIncluded) ? true : node.isIncluded,
      hovered: false,
      dataPropertyDisplayTypes:
        cloneDeep(node.data.dataPropertyDisplayTypes) ?? [],
      labelIsPlaceholder: node.data.labelIsPlaceholder ?? false,
      dataPropertyStyle: cloneDeep(node.data?.dataPropertyStyle) ?? {
        isActive: false
      },
      labelData: {
        textFit:
          node.data.labelData?.textFit ??
          DiagramUtils.getNodeDefaultTextFit(node.data.annotationType),
        wrapTextInShape: node.data.labelData?.wrapTextInShape ?? false
      },
      highlight: node.highlight,
      isResized: node.data.isResized ?? false,
      indicatorsPosition: node.data.indicatorsPosition,
      quickStartData: cloneDeep(node.data.quickStartData),
      entityTypeId: node.data.entityTypeId,
      sequenceNumber: node.data.sequenceNumber,
      originalName: node.data.originalName,
      originalSequenceNumber: node.data.originalSequenceNumber
    };
  }

  public static createEdgeTagFromData(edge: DiagramEdgeDto): IEdgeTag {
    const entityName = edge.data.name;
    const layout = edge.data.layout;
    return {
      autoCreated: edge.data.autoCreated,
      definitionCustomised: edge.data.definitionCustomised,
      edited: edge.data.edited,
      id: edge.id,
      quickStartData: cloneDeep(edge.data.quickStartData),
      isAnnotation: edge.data.isAnnotation,
      isFixedInLayout: edge.data.isFixedInLayout,
      placeholder: edge.data.placeholder,
      sourcePortFixed: edge.data.sourcePortFixed,
      targetPortFixed: edge.data.targetPortFixed,
      style: edge.style,
      uuid: edge.uuid,
      originalUuid: edge.originalUuid,
      dataProperties: edge.dataProperties ?? [],
      dataPropertyTags: edge.dataPropertyTags ?? [],
      name: entityName,
      attachments: edge.attachments ?? [],
      busid: edge.data.busid,
      isIncluded: isNil(edge?.isIncluded) ? true : edge.isIncluded,
      isOrphan: edge.data.isOrphan,
      sourcePortDirection: edge.data.sourcePortDirection ?? PortDirections.ANY,
      targetPortDirection: edge.data.targetPortDirection ?? PortDirections.ANY,
      labelIsPlaceholder: edge.data.labelIsPlaceholder ?? false,
      labelData: {
        textFit:
          edge.data.labelData?.textFit ?? DiagramUtils.getEdgeDefaultTextFit()
      },
      highlight: edge.highlight,
      relationshipType: edge.data.relationshipType,
      displayOrder: edge.data.displayOrder,
      layout: layout
        ? new LayoutDto(layout.x, layout.y, layout.width, layout.height)
        : null,
      entityTypeId: edge.data.entityTypeId
    };
  }

  private getNodeLayout(nodeLayout: LayoutDto): Rect {
    return new Rect(
      nodeLayout.x,
      nodeLayout.y,
      nodeLayout.width,
      nodeLayout.height
    );
  }

  private addEdges(edges: any[]): void {
    edges.forEach((edge) => {
      this.addEdge(edge);
    });
  }

  private addEdge(edge: DiagramEdgeDto): void {
    const sourceNode: INode = this.getNodeByUuid(edge.sourceNodeUuid);
    const targetNode: INode = this.getNodeByUuid(edge.targetNodeUuid);
    const sourcePort: IPort = this.getPort(sourceNode, edge.sourcePort);
    const targetPort: IPort = this.getPort(targetNode, edge.targetPort);

    const edgeStyle = StyleCreator.createEdgeStyle(edge.style);
    const tag = DiagramReader.createEdgeTagFromData(edge);
    tag.dataProperties.forEach((item) => (item.isIncluded = true));

    const createdEdge = nonReactive(
      this.graph.createEdge({
        sourcePort: sourcePort,
        targetPort: targetPort,
        style: edgeStyle,
        tag: tag
      })
    );

    this.graph.addBends(createdEdge, DiagramReader.createBends(edge));

    const labelData: INodeLabelData = edge.data.labelData;

    this.addLabel(
      createdEdge,
      edge.label,
      labelData,
      DiagramReader.createLabelTagFromData(labelData)
    );
  }

  public static createBends(edge: DiagramEdgeDto): Point[] {
    if (!edge.bends) {
      return [];
    }
    return (<Array<any>>edge.bends).map((bend) => new Point(bend.x, bend.y));
  }

  private getPort(node: INode, port: EdgePortDto): IPort {
    return nonReactive(
      this.graph.addPort(
        node,
        FreeNodePortLocationModel.INSTANCE.createParameterForRatios(
          new Point(port.x, port.y)
        )
      )
    );
  }

  private getNodeByUuid(uuid: string): INode {
    return this.graph.nodes.find((x) => x.tag.uuid == uuid);
  }

  static toGraphFromDiagram(graph: IGraph, diagram: DiagramDto): void {
    new DiagramReader(graph, diagram).render();
  }
}
