export enum SvgElementTypes {
  Node,
  Edge,
  CycleShapeDecorator,
  EdgeCreationDecorator,
  LabelEditDecorator,
  QuickBuildDecorator,
  QuickStartDeleteDecorator,
  LabelElement,
  Anchor
}

export const AutomationSelectors = {
  DOCUMENT_SELECT: 'document-select',
  FOLDER_SELECT: 'folder-select',
  DATA_PROPERTIES_ADD: 'data-properties-add',
  DATA_PROPERTIES_SYMBOL_SELECT: 'data-properties-symbol-select',
  DATA_PROPERTIES_LOCK: 'data-properties-lock',
  DATA_PROPERTIES_UNLOCK: 'data-properties-unlock',
  DATA_PROPERTIES_CLOSE: 'data-properties-close',
  LEGEND_REGENERATE: 'legend-regenerate',
  LEGEND_CANCEL_EDIT: 'legend-cancel-edit',
  LEGEND_SAVE_CHANGED: 'legend-save-changes',
  LEGEND_EDIT: 'legend-edit',
  LEGEND_REORDER: 'legend-reorder',
  LEGEND_REMOVE_FROM: 'legend-remove-from',
  LEGEND_SHOW_ITEM: 'legend-show-item',
  PALETTE_ITEM_SELECT_X: 'palette-item-select-',
  QUICKSTART_ACTIVATE: 'quickstart-activate',
  QUICKSTART_DEACTIVATE: 'quickstart-deactivate',
  SHARING_APPROVE_REQUEST: 'sharing-approve-request',
  SHARING_DECLINE_REQUEST: 'sharing-decline-request',
  NODE_ELEMENT_SELECT: 'node-element-select',
  EDGE_ELEMENT_SELECT: 'edge-element-select',
  EDGE_ANCHOR_POINT: 'edge-anchor-point',
  NODE_CYCLE_SHAPE_DECORATOR: 'node-cycle-shape-decorator',
  NODE_EDGE_CREATE_DECORATOR: 'node-edge-create-decorator',
  NODE_QUICK_BUILD_DECORATOR: 'node-quickbuild-decorator',
  LABEL_EDIT_DECORATOR: 'node-labeledit-decorator',
  QUICK_START_DELETE: 'quick-start-delete-decorator',
  LABEL_ELEMENT_SELECT: 'label-element-select',
  GRID_VIEW_TOGGLE: 'grid-view-toggle',
  LIST_VIEW_TOGGLE: 'list-view-toggle',
  TILE_VIEW_TOGGLE: 'tile-view-toggle',
  EXPORT_TOGGLE_INCLUDE_DATA: 'export-include-toggle',
  EDGE_MENU: 'edge-menu',
  EXPORT_TOGGLE_INCLUDE_ATTACHMENTS: 'export-include-attachments-toggle',
  ELEMENTS_TAB: 'elements-tab',
  RELATIONSHIP_LINES_TAB: 'relationship-lines-tab',
  TEXTBOXES_TAB: 'textboxes-tab',
  SYMBOLS_TAB: 'symbols-tab',
  LOGO_TAB: 'logo-tab',
  STYLE_PALETTE_TAB: 'style-palette-tab',
  TABLE_STYLE_PALETTE_TAB: 'table-style-palette-tab',
  EXPORT_SETUP_TAB: 'export-setup-tab',
  TIMELINES_TAB: 'timelines-tab',
  SETTINGS_TAB: 'settings-tab',
  ELEMENT_DETAILS_SAVE_BUTTON: 'element-details-save-button',
  ELEMENT_DETAILS_CANCEL_BUTTON: 'element-details-cancel-button',
  ELEMENT_DETAILS_DELETE_BUTTON: 'element-details-delete-button',
  ELEMENT_DETAILS_NAME_FIELD: 'element-details-name-field',
  ELEMENT_DETAILS_DISPLAY_NAME_FIELD: 'element-details-display-name-field',
  ELEMENT_DETAILS_DEFAULT_ENTITY_TYPE_FIELD:
    'element-details-default-entity-type-field',
  ELEMENT_DETAILS_RELATED_ENTITY_TYPES_FIELD:
    'element-details-related-entity-types-field',
  ELEMENT_DETAILS_DEFAULT_LABEL_FIELD: 'element-details-default-label-field',
  ELEMENT_DETAILS_SHAPE_TYPE_FIELD_VISIBLE_ON_SHAPE_COMPOSITE_JIGSAWPATHSHAPE_NODES:
    'element-details-shape-type-field-visible-on-shape-composite-jigsawpathshape-nodes',
  ELEMENT_DETAILS_SHAPE_FIELD: 'element-details-shape-field',
  ELEMENT_DETAILS_PRESET_FIELD: 'element-details-preset-field',
  ELEMENT_DETAILS_SIZE_FIELD: 'element-details-size-field',
  ELEMENT_DETAILS_FILL_TYPE_FIELD: 'element-details-fill-type-field',
  ELEMENT_DETAILS_SOLID_FILL_PICKER: 'element-details-solid-fill-picker',
  ELEMENT_DETAILS_BICOLOR_LEFT_COLOR_PICKER:
    'element-details-bicolor-left-color-picker',
  ELEMENT_DETAILS_BICOLOR_RIGHT_COLOR_PICKER:
    'element-details-bicolor-right-color-picker',
  ELEMENT_DETAILS_TRICOLOR_LEFT_COLOR_PICKER:
    'element-details-tricolor-left-color-picker',
  ELEMENT_DETAILS_TRICOLOR_MIDDLE_COLOR_PICKER:
    'element-details-tricolor-middle-color-picker',
  ELEMENT_DETAILS_TRICOLOR_RIGHT_COLOR_PICKER:
    'element-details-tricolor-right-color-picker',
  ELEMENT_DETAILS_STROKE_OUTLINE_PICKER:
    'element-details-stroke-outline-picker',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_LEFT_CORNER_LEFT:
    'element-details-internal-shape-position-left-corner-left',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_LEFT_CORNER_RIGHT:
    'element-details-internal-shape-position-left-corner-right',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_TOP_CORNER_LEFT:
    'element-details-internal-shape-position-top-corner-left',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_TOP_CORNER_RIGHT:
    'element-details-internal-shape-position-top-corner-right',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_RIGHT_CORNER_LEFT:
    'element-details-internal-shape-position-right-corner-left',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_RIGHT_CORNER_RIGHT:
    'element-details-internal-shape-position-right-corner-right',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_BOTTOM_CORNER_LEFT:
    'element-details-internal-shape-position-bottom-corner-left',
  ELEMENT_DETAILS_INTERNAL_SHAPE_POSITION_BOTTOM_CORNER_RIGHT:
    'element-details-internal-shape-position-bottom-corner-right',
  ELEMENT_DETAILS_SHAPE_TYPE_FIELD_VISIBLE_ON_IMAGE_NODE:
    'element-details-stroke-outline-picker',
  ELEMENT_DETAILS_IMAGE_FILE: 'element-details-image-file',
  ELEMENT_DETAILS_LINE_TYPE_FIELD: 'element-details-line-type-field',
  ELEMENT_DETAILS_RELATIONSHIP_TYPE_FIELD:
    'element-details-relationship-type-field',
  ELEMENT_DETAILS_ARROW_TYPE_LEFT_PICKER:
    'element-details-arrow-type-left-picker',
  ELEMENT_DETAILS_ARROW_TYPE_RIGHT_PICKER:
    'element-details-arrow-type-right-picker',
  ELEMENT_DETAILS_BRIDGE_AT_INTERSECTIONS_SWITCH:
    'element-details-bridge-at-intersections-switch',
  ELEMENT_DETAILS_STROKE_LINE_PICKER: 'element-details-stroke-line-picker',
  ELEMENT_DETAILS_EDGE_LABEL_POSITION: 'element-details-edge-label-position',
  ELEMENT_DETAILS_INDICATORS_POSITION_PICKER:
    'element-details-indicators-position-picker',
  ELEMENT_DETAILS_NODE_LABEL_POSITION_PICKER:
    'element-details-node-label-position-picker',
  ELEMENT_DETAILS_LABEL_STYLE: 'element-details-label-style'
};
export default class AutomationUtils {
  public static attachAutomationIdToSvg(
    svgElement: SVGElement,
    elementType: SvgElementTypes,
    variant?: string
  ): SVGElement {
    let selectorName: string;
    switch (elementType) {
      case SvgElementTypes.Node: {
        selectorName = AutomationSelectors.NODE_ELEMENT_SELECT;
        const svgPathElement = svgElement.getElementsByTagName('path')[0];
        if (svgPathElement != null) {
          svgPathElement.setAttribute(
            'data-automation-id',
            variant ? `${selectorName}-${variant}` : selectorName
          );
          return;
        }
        break;
      }
      case SvgElementTypes.Edge:
        selectorName = AutomationSelectors.EDGE_ELEMENT_SELECT;
        break;
      case SvgElementTypes.Anchor:
        selectorName = AutomationSelectors.EDGE_ANCHOR_POINT;
        break;

      case SvgElementTypes.CycleShapeDecorator:
        selectorName = AutomationSelectors.NODE_CYCLE_SHAPE_DECORATOR;
        break;

      case SvgElementTypes.EdgeCreationDecorator:
        selectorName = AutomationSelectors.NODE_EDGE_CREATE_DECORATOR;
        break;

      case SvgElementTypes.QuickBuildDecorator:
        selectorName = AutomationSelectors.NODE_QUICK_BUILD_DECORATOR;
        break;

      case SvgElementTypes.QuickStartDeleteDecorator:
        selectorName = AutomationSelectors.QUICK_START_DELETE;
        break;

      case SvgElementTypes.LabelElement:
        selectorName = AutomationSelectors.LABEL_ELEMENT_SELECT;
        break;
      case SvgElementTypes.LabelEditDecorator:
        selectorName = AutomationSelectors.LABEL_EDIT_DECORATOR;
        break;
    }
    svgElement.setAttribute(
      'data-automation-id',
      variant ? `${selectorName}-${variant}` : selectorName
    );
  }
}
