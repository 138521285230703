import AuthService from '@/core/services/auth/AuthService';
import appConfig from '@/core/config/appConfig';
import { TimelineDto } from '@/api/models';
import i18n from '@/core/plugins/vue-i18n';

export default class TimelineUtils {
  public static async redirectToTimeline(id?: number): Promise<void> {
    const accessToken = await AuthService.getAccessToken();

    // Store access token in cookie
    // Short lived - cookie expires in 1 minute
    document.cookie = `timelineSession=${accessToken};secure;max-age=60`;

    // Redirect to Timelines App
    window.location.href = this.getTimelineUrl(id);
  }

  public static getTimelineUrl(id: number): string {
    const isLocal = window.location.hostname === 'localhost';
    var link =
      (isLocal ? 'https://localhost:8085' : '/timelines') +
      `${appConfig.timeline.endpoint}`;
    if (id !== undefined) {
      link += `?id=${id}`;
    }
    return link;
  }

  public static generateLink(documentId: number | string): string {
    const isLocal = window.location.hostname === 'localhost';
    if (isLocal) {
      return `https://localhost:8085/#/timeline?id=${documentId}`;
    }
    return `${window.location.origin}/timelines/#/timeline?id=${documentId}`;
  }

  public static getInitialTimeline(): TimelineDto {
    const timelineContent = new TimelineDto(
      false,
      i18n.t('NEW_TIMELINE'),
      'Timeline document',
      TimelineUtils.getInitialTimelineJSON()
    );
    return timelineContent;
  }

  public static getInitialTimelineJSON(): string {
    const timelineJSON = {
      success: true,
      project: {
        calendar: 'project',
        displayOptions: {
          isTimeAxisSolidBlock: false,
          isNonWorkingDays: true,
          datePosition: 1,
          displayLogo: false,
          displayAttachments: true,
          displayDependency: 1,
          connectorLabelPosition: 3,
          swimlaneWidth: 140
        },
        logo: {
          logoPosition: 1,
          logoX: null,
          logoY: null
        }
      },
      calendars: {
        rows: [
          {
            id: 'project',
            name: 'Project Calendar',
            unspecifiedTimeIsWorking: true,
            intervals: []
          },
          {
            id: 'workinghours',
            name: 'Working Hours',
            unspecifiedTimeIsWorking: true,
            intervals: []
          }
        ]
      },
      resources: {
        rows: [
          {
            id: 1,
            name: `<p style="font-size:10pt;"><span style="font-family:Arial;font-size:10pt;">${i18n.t(
              'SWIMLANE_1'
            )}</span></p>`,
            displayName: i18n.t('SWIMLANE_1'),
            calendar: 'workinghours',
            isTimeAxis: false
          },
          {
            id: 2,
            name: '',
            displayName: '',
            calendar: 'project',
            isTimeAxis: true
          },
          {
            id: 3,
            name: `<p style="font-size:10pt;"><span style="font-family:Arial;font-size:10pt;">${i18n.t(
              'SWIMLANE_2'
            )}</span></p>`,
            displayName: i18n.t('SWIMLANE_2'),
            calendar: 'workinghours',
            isTimeAxis: false
          }
        ]
      }
    };
    return JSON.stringify(timelineJSON);
  }

  public static getDefaultTimelineThemeJson(): string {
    const defaultTimelineThemeJson = {
      hideEventAutoEdit: false,
      businessDayWarning: false,
      businessDayWarningText: '',
      timelineThemeEventShapes: [
        {
          id: 1,
          name: 'Rounded Rectangle (Single Date)',
          eventType: 'Date',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 2,
          name: 'Rectangle (Single Date)',
          eventType: 'Date',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 3,
          name: 'Diamond (Single Date)',
          eventType: 'Date',
          shape: 'Diamond',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: true,
          connector: {
            type: 'singleDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 4,
          name: 'Rounded Rectangle (Range)',
          eventType: 'Range',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: true,
          connector: {
            type: 'rangeDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 5,
          name: 'Rectangle (Range)',
          eventType: 'Range',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: true,
          connector: {
            type: 'rangeDate-straight-fromMiddle',
            thickness: 2,
            lineStyle: 'solid',
            color: '#150AFF',
            arrowheadType: 'triangle',
            arrowheadSize: 5,
            arrowheadColor: '#150AFF',
            labelFontFamily: 'Arial',
            labelFontSize: 10,
            labelBold: false,
            labelItalic: false,
            labelUnderline: false,
            labelFontColor: '#000000'
          }
        },
        {
          id: 6,
          name: 'Rounded Rectangle (Phase)',
          eventType: 'Phase',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        },
        {
          id: 7,
          name: 'Rectangle (Phase)',
          eventType: 'Phase',
          shape: 'Rectangle',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        },
        {
          id: 8,
          name: 'Arrow Left (Phase)',
          eventType: 'Phase',
          shape: 'ArrowLeft',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        },
        {
          id: 9,
          name: 'Arrow Right (Phase)',
          eventType: 'Phase',
          shape: 'ArrowRight',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        },
        {
          id: 10,
          name: 'Double Arrow (Phase)',
          eventType: 'Phase',
          shape: 'DoubleArrow',
          default: false,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        },
        {
          id: 11,
          name: 'Rounded Rectangle (TextBox)',
          eventType: 'TextBox',
          shape: 'RoundedRectangle',
          default: true,
          eventBoxFill: '#FFFFFF',
          eventBoxOutlineColor: '#919191',
          eventBoxOutlineStyle: 'solid',
          eventBoxOutlineWidth: 1,
          shadow: true,
          titleFontFamily: 'Arial',
          titleFontSize: 10,
          titleBold: false,
          titleItalic: false,
          titleUnderline: false,
          titleFontColor: '#000000',
          showConnector: false
        }
      ],
      timelineThemeDependencySettings: {
        lineThickness: 2,
        labelFontFamily: 'Arial',
        labelFontSize: 10,
        labelBold: false,
        labelItalic: false,
        labelUnderline: false
      }
    };
    return JSON.stringify(defaultTimelineThemeJson);
  }
}
