import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentSubPageDto,
  PageDesignDto,
  PageElementPosition
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import JPoint from '@/core/common/JPoint';
import JRect from '@/core/common/JRect';
import JSize from '@/core/common/JSize';
import ExportConfig from '@/core/config/ExportConfig';
import FilterDecorators from '@/core/styles/decorators/FilterDecorators';
import { JurisdictionUtils } from '@/core/styles/decorators/JurisdictionDecorator';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  GraphComponent,
  GraphObstacleProvider,
  IGraph,
  VoidNodeStyle
} from 'yfiles';
import GraphCopierHelper from '../graph/GraphCopierHelper';
import JigsawGraphModelManager from '../graph/JigsawGraphModelManager';
import NodeIndicatorService from '../graph/NodeIndicatorService';
import { ExportFormat } from './ExportFormat';
import isNil from 'lodash/isNil';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import ExportHighlightService from './ExportHighlightService';
import { customPdfFonts } from './pdf/PdfFonts';
import BackgroundDomService from '../BackgroundDomService';
import { convertUrlToDataUrl } from '@/core/utils/common.utils';
import JInsets from '@/core/common/JInsets';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import config from '@/core/config/diagram.definition.config';
import { HtmlStylesToInlineOptions } from './HtmlStylesToInlineOptions';
import Vue from 'vue';
import {
  GET_ALL_PAGE_DESIGNS,
  PAGE_DESIGN_NAMESPACE
} from '../store/page-design.module';
import StepsDesignerUtils from '@/view/pages/administration/steps-designer/utils/StepsDesignerUtils';
import { PageType } from '@/view/pages/administration/steps-designer/PageDesignGroup';

export default class ExportUtils {
  public static get pageDesigns(): PageDesignDto[] {
    return Vue.$globalStore.getters[
      `${PAGE_DESIGN_NAMESPACE}/${GET_ALL_PAGE_DESIGNS}`
    ];
  }

  private static readonly convertedLineHeightAttribute =
    'data-converted-line-height';
  private static readonly computedLineHeightAttribute =
    'data-computed-line-height';

  public static calculateBodyPartSize(
    document: DocumentDto,
    page: DocumentPageDto,
    part: 'all' | 'diagram' | 'content',
    withUsableMargins: boolean = true,
    subPageIndex: number = null
  ): JSize {
    let subPageRef: DocumentPageDto | DocumentSubPageDto = page;
    if (subPageIndex !== null && subPageIndex >= 0) {
      const currentSubPageRef = page.subPageRefs?.find(
        (sp) => sp.subPageIndex === subPageIndex
      );
      if (currentSubPageRef) {
        subPageRef = currentSubPageRef;
      }
    }

    const titleHeight = this.calculatePageTitleHeight(
      subPageRef,
      subPageRef.showTitle
    );
    const pageMargins = this.calculatePageMargins(document, page);
    let width =
      document.pageStyle.width - (pageMargins.left + pageMargins.right);
    let height =
      document.pageStyle.height -
      (pageMargins.top + pageMargins.bottom + titleHeight);
    if (page.pageType == DocumentPageType.Split) {
      if (part == 'diagram') {
        width *= document.pageStyle.splitRatio;
      } else if (part == 'content') {
        width *= 1 - document.pageStyle.splitRatio;
      }
    }

    if (part == 'diagram') {
      const padding = this.calculatePadding(document, page, 'diagram');
      width -= padding.left + padding.right;
      height -= padding.top + padding.bottom;
    }

    if (page.contentType == DocumentPageContentType.Html && part == 'content') {
      const padding = this.calculatePadding(document, page, 'htmlContent');
      width -= padding.left + padding.right;
      height -= padding.top + padding.bottom;
    }

    if (withUsableMargins) {
      width -= ExportConfig.usablePageAreaMargins.width;
      height -= ExportConfig.usablePageAreaMargins.height;
    }

    return new JSize(width, height);
  }

  public static calculatePageScaleForPageSize(
    pageSize: JSize,
    isSidebarOpen: boolean,
    hasSteps: boolean
  ): number {
    const outerPageContainer = window.document.querySelector(
      `.${ExportConfig.outerPageContainerClass}`
    );
    if (!outerPageContainer) return 1;

    const phantomPageHeight = 12 * 2;
    const pageBreakHeight = 40 * 2;
    const containerBounds = outerPageContainer.getBoundingClientRect();
    let panelsWidth = 300; // ~ toolbar size and spacing
    let pageScale = 1;
    let dataPanelWidth = 0;

    if (isSidebarOpen) {
      // 200 - ~ toolbar size and spacing (just when sidebar is open)
      panelsWidth = config.sideBarWidth + config.sideBarThumbWidth + 200;
    }
    if (!hasSteps) {
      dataPanelWidth = 160; // ~ datapanelToolbar size + spacing
    }

    const availableHeight =
      window.document.body.clientHeight -
      containerBounds.top -
      phantomPageHeight -
      pageBreakHeight -
      config.diagramControlsHeight;

    const availableWidth =
      window.document.body.clientWidth -
      containerBounds.left -
      panelsWidth -
      dataPanelWidth;
    const availableSizeRatio = availableWidth / availableHeight;
    const pageSizeRatio = pageSize.width / pageSize.height;

    // Rect is more landscape than bounds - fit to width
    if (availableSizeRatio < pageSizeRatio) {
      const scaledHeight = availableWidth / pageSizeRatio;
      pageScale = scaledHeight / pageSize.height;
    } else {
      const scaledWidth = availableHeight * pageSizeRatio;
      pageScale = scaledWidth / pageSize.width;
    }

    return pageScale;
  }

  public static calculatePageMargins(
    document: DocumentDto,
    page: DocumentPageDto
  ): JInsets {
    if (
      !document.hasSteps ||
      page.contentType == DocumentPageContentType.Layout
    ) {
      return new JInsets(0);
    }

    const left = document.pageStyle.fullMargins.left;
    const right = document.pageStyle.fullMargins.right;

    const showHeaderWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      page,
      DocumentContentArea.Header
    );
    const showFooterWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      page,
      DocumentContentArea.Footer
    );

    const pageContainsTextPane =
      page.contentType === DocumentPageContentType.Html;

    let top = 0;
    if (page.showHeader || showHeaderWidget || pageContainsTextPane) {
      top = document.pageStyle.innerMargins.top + document.headerStyle.height;
    } else {
      top = document.pageStyle.fullMargins.top;
    }

    let bottom = 0;
    if (page.showFooter || showFooterWidget || pageContainsTextPane) {
      bottom =
        document.pageStyle.innerMargins.bottom + document.footerStyle.height;
    } else {
      bottom = document.pageStyle.fullMargins.bottom;
    }

    return new JInsets(left, top, right, bottom);
  }

  public static calculatePageMarginsRatio(
    document: DocumentDto,
    page: DocumentPageDto
  ): number {
    const pageMargins = ExportUtils.calculatePageMargins(document, page);
    return (
      1 - (pageMargins.left + pageMargins.right) / document.pageStyle.width
    );
  }

  public static calculatePadding(
    document: DocumentDto,
    page: DocumentPageDto,
    area: 'diagram' | 'htmlContent'
  ): JInsets {
    const padding =
      area == 'diagram'
        ? ExportConfig.minDiagramPadding.clone()
        : ExportConfig.minHtmlContentPadding.clone();

    const pageDesign = this.pageDesigns?.find(
      (pageDesign) =>
        pageDesign.layoutType === page.layoutType &&
        pageDesign.contentType === page.contentType &&
        pageDesign.pageType === page.pageType &&
        pageDesign.contentColumns === (page.contentColumns ?? 0)
    );

    const paddingDto =
      document.pageStyle?.paddingLegacy?.find(
        (x) =>
          x.pageType == page.pageType &&
          x.layoutType == page.layoutType &&
          x.contentType == page.contentType &&
          x.contentColumns == page.contentColumns
      ) ?? pageDesign?.pageStyle?.padding;

    const value =
      area == 'diagram'
        ? paddingDto?.diagramPadding
        : paddingDto?.htmlContentPadding;

    if (value) {
      if (value.left > padding.left) {
        padding.left = value.left;
      }
      if (value.top > padding.top) {
        padding.top = value.top;
      }
      if (value.right > padding.right) {
        padding.right = value.right;
      }
      if (value.bottom > padding.bottom) {
        padding.bottom = value.bottom;
      }
    }
    return padding;
  }

  public static calculateHtmlContentGap(document: DocumentDto): number {
    const pageStyle =
      this.pageDesigns?.find(
        (pageDesign) =>
          StepsDesignerUtils.mapPageType(pageDesign) === PageType.TextText
      )?.pageStyle ?? document.pageStyle;

    if (
      pageStyle?.htmlContentColumnGap > ExportConfig.minHtmlContentColumnGap
    ) {
      return pageStyle?.htmlContentColumnGap;
    }
    return ExportConfig.minHtmlContentColumnGap;
  }

  public static getAdditionalElementPosition(
    contentRect: JRect,
    position: PageElementPosition | JPoint,
    imageHeight: number,
    imageWidth: number
  ): JPoint {
    if (<PageElementPosition>position in PageElementPosition) {
      switch (position) {
        case PageElementPosition.Bottom:
        case PageElementPosition.BottomLeft:
          return new JPoint(
            contentRect.bottomLeft.x,
            contentRect.bottomLeft.y + 10
          );
        case PageElementPosition.BottomRight:
          return new JPoint(
            contentRect.bottomRight.x - imageWidth,
            contentRect.bottomRight.y + 10
          );
        case PageElementPosition.Top:
        case PageElementPosition.Left:
        case PageElementPosition.TopLeft:
          return new JPoint(
            contentRect.topLeft.x,
            contentRect.topLeft.y - imageHeight - 10
          );
        case PageElementPosition.Right:
        case PageElementPosition.TopRight:
          return new JPoint(
            contentRect.topRight.x - imageWidth,
            contentRect.topRight.y - imageHeight - 10
          );
      }
    } else {
      return new JPoint(
        contentRect.width * 0.01 * (<JPoint>position).x,
        contentRect.height * 0.01 * (<JPoint>position).y
      );
    }
    return JPoint.ORIGIN;
  }

  public static copyGraphComponent(
    sourceGraph: IGraph,
    withFilters: boolean,
    format: ExportFormat,
    lowDetail: boolean = false,
    withHighlight: boolean = false
  ): GraphComponent {
    const graphComponentCopy = new GraphComponent();
    graphComponentCopy.graphModelManager = new JigsawGraphModelManager(
      graphComponentCopy
    );

    if (!lowDetail) {
      DiagramUtils.configureBridges(graphComponentCopy);

      //Turn off bridging for annotation arrows (divider lines etc) for PDF export to match graph service GraphObstacleProvider
      const annotationEdgeObstacleProvider = new GraphObstacleProvider();
      annotationEdgeObstacleProvider.queryEdges = false;

      graphComponentCopy.graph.decorator.edgeDecorator.obstacleProviderDecorator.setFactory(
        (e) => {
          return e.tag.isAnnotation;
        },
        () => annotationEdgeObstacleProvider
      );
    }
    GraphCopierHelper.copyGraph(sourceGraph, graphComponentCopy.graph);
    graphComponentCopy.invalidate();

    graphComponentCopy.graph.nodes.forEach((node) => {
      const style = DiagramUtils.unwrapNodeStyle(node);
      if (lowDetail) {
        style.removeAllDecorators();
      } else {
        style.removeDecorator(FilterDecorators.INSTANCE.$class);
      }
      if (
        node &&
        node.tag.isAnnotation &&
        (node.tag.annotationType == AnnotationType.EdgeToNowhereNode ||
          node.tag.annotationType == AnnotationType.ArrowHead)
      ) {
        graphComponentCopy.graph.setStyle(node, new VoidNodeStyle());
      }
    });

    if (!lowDetail) {
      // Set indicator and data property decorator state
      graphComponentCopy.graph.nodes.forEach((node) => {
        NodeIndicatorService.syncIndicators(node);
        JurisdictionUtils.setJurisdictionDecorationState(
          node,
          graphComponentCopy
        );
      });
    }

    if (withHighlight) {
      const highlightService = new ExportHighlightService(graphComponentCopy);
      highlightService.highlightElements();
      highlightService.dispose();
    }

    if (withFilters) {
      const excludedNodes = graphComponentCopy.graph.nodes
        .filter((n) => n.tag?.isIncluded === false)
        .toArray();
      for (let node of excludedNodes) {
        graphComponentCopy.graph.remove(node);
      }

      const excludedEdges = graphComponentCopy.graph.edges
        .filter((e) => e.tag?.isIncluded === false)
        .toArray();
      for (let edge of excludedEdges) {
        graphComponentCopy.graph.remove(edge);
      }
    } else {
      for (let node of graphComponentCopy.graph.nodes) {
        node.tag.isIncluded = true;
      }

      for (let edge of graphComponentCopy.graph.edges) {
        edge.tag.isIncluded = true;
      }
    }

    // Group nodes are currently rendered as empty blocks in Visio
    // Remove all group nodes and background group visuals until we can sort this out
    if (format == ExportFormat.Visio) {
      graphComponentCopy.graph.nodes
        .filter((n) => n.tag?.isGroupNode)
        .toArray()
        .forEach((node) => {
          graphComponentCopy.graph.remove(node);
        });
    }

    graphComponentCopy.graph.nodes.toArray().forEach((node) => {
      if (node.layout.width == 0) {
        graphComponentCopy.graph.remove(node);
      }
    });

    graphComponentCopy.updateContentRect();
    return graphComponentCopy;
  }

  public static async htmlStylesToInline(
    html: string,
    options: HtmlStylesToInlineOptions
  ): Promise<string> {
    if (!html) {
      return null;
    }
    // Create container element
    const element = BackgroundDomService.createElement('div');
    element.style.position = 'absolute';
    element.classList.add(...options.containerClassList);
    if (!options.containerSize?.isEmpty) {
      element.style.width = options.containerSize.width + 'pt';
      element.style.height = options.containerSize.height + 'pt';
    }
    if (options.containerColumns > 0) {
      element.style.columnCount = options.containerColumns.toString();
      element.style.columnFill = 'auto';
      element.style.columnGap = options.containerColumnGap + 'pt';
      element.style.widows = '1';
      element.style.orphans = '1';
    }
    element.innerHTML = html;

    // Append the element into the dom so that styles can be calculated.
    BackgroundDomService.appendElement(element);
    await this.inlineElementStyles(element, options);
    BackgroundDomService.removeElement(element);
    return element.innerHTML;
  }

  private static async inlineElementStyles(
    element: HTMLElement,
    options: HtmlStylesToInlineOptions,
    depth = 0
  ): Promise<void> {
    if (!element) {
      throw new Error('No element specified.');
    }

    if (options.setDimensionData && options.dimensionDataDepthLimit >= depth) {
      this.inlineElementDimensionData(element);
    }

    if (options.recursive) {
      for (const child of element.children) {
        await this.inlineElementStyles(
          child as HTMLElement,
          options,
          depth + 1
        );
      }
    }

    if (options.convertImageUrls && element.tagName == 'IMG') {
      const imageElement = element as HTMLImageElement;
      if (imageElement?.src) {
        const dataUrl = await convertUrlToDataUrl(imageElement.src);
        element.setAttribute('src', dataUrl);
      }
    }

    const computedStyle = getComputedStyle(element);
    const props = options.properties || computedStyle;

    for (const property in props) {
      const propertyName = props[property];

      // Setting font-* properties on paragraph elements breaks PPT exports
      // These will be set on the inner spans instead
      if (
        element.tagName == 'P' &&
        element.childElementCount > 0 &&
        propertyName.startsWith('font-')
      ) {
        continue;
      }

      // Temp fix for pdf export for text-decoration property
      if (propertyName === 'text-decoration-line') {
        element.style['text-decoration'] =
          computedStyle.getPropertyValue(propertyName);
      } else if (propertyName === 'font-weight') {
        const value = computedStyle.getPropertyValue(propertyName);
        element.style[propertyName] = value === '700' ? 'bold' : value;
      } else {
        element.style[propertyName] =
          computedStyle.getPropertyValue(propertyName);
      }

      if (!options.maintainPixelValues) {
        element.style[propertyName] = this.convertPixelValueToPoints(
          element.style[propertyName]
        );
      }
    }

    if (
      element.tagName == 'SPAN' &&
      element.parentElement &&
      (options.updateLineHeights == 'convert' ||
        (options.updateLineHeights == 'compute' &&
          !element.parentElement.hasAttribute(
            this.computedLineHeightAttribute
          )))
    ) {
      const fontSize = parseFloat(computedStyle.fontSize);
      // Try to take lineHeight from parent element
      const parentComputedStyle = getComputedStyle(element.parentElement);
      const parentFontSize = parseFloat(parentComputedStyle.fontSize);
      // Only use parent's lineHeight if its font size is greater than the span's (CSS works in mysterious ways)
      let lineHeight = 0;
      if (parentFontSize > fontSize) {
        lineHeight = this.parseStyleLineHeight(parentComputedStyle);
      }
      // Fallback to current element's lineHeight if parent value is not set
      if (Number.isNaN(lineHeight) || lineHeight === 0) {
        lineHeight = this.parseStyleLineHeight(computedStyle);
      }
      if (lineHeight > 0) {
        const fontFamily = computedStyle.fontFamily;
        const unitlessLineHeight = lineHeight / fontSize;
        if (options.updateLineHeights == 'convert') {
          const convertedLineHeight = this.convertLineHeight(
            unitlessLineHeight,
            fontFamily
          );
          element.setAttribute(
            this.convertedLineHeightAttribute,
            convertedLineHeight.toString()
          );
        } else if (options.updateLineHeights == 'compute') {
          element.parentElement.style.lineHeight =
            unitlessLineHeight.toString();
          element.parentElement.setAttribute(
            this.computedLineHeightAttribute,
            'true'
          );
        }
      }
    }

    if (element.tagName == 'TD' || element.tagName == 'TH') {
      let backgroundColor = computedStyle.backgroundColor;
      if (!backgroundColor || backgroundColor.startsWith('rgba')) {
        const tableComputedStyle = getComputedStyle(element.closest('table'));
        backgroundColor = tableComputedStyle.backgroundColor;
      }
      if (!backgroundColor.startsWith('rgba')) {
        element.style.backgroundColor = backgroundColor;
      }
      if (!element.textContent) {
        element.innerHTML = '&nbsp;';
      }
      const tableCellStyle = { ...computedStyle };
      element.style.border = '';
      element.style.borderColor = tableCellStyle.borderColor;
      element.style.borderStyle = tableCellStyle.borderStyle;
      element.style.borderWidth = this.adjustForDpi(tableCellStyle.borderWidth);
    } else if (element.tagName == 'TABLE') {
      const tableStyle = { ...computedStyle };
      element.style.border = '';
      element.style.borderColor = tableStyle.borderColor;
      element.style.borderStyle = tableStyle.borderStyle;
      element.style.borderWidth = this.adjustForDpi(tableStyle.borderWidth);
    }
  }

  private static inlineElementDimensionData(element: HTMLElement): void {
    const decimals = 3;
    if (
      element.nodeName === 'FIGURE' &&
      element.firstChild.nodeName === 'TABLE'
    ) {
      const table = element.firstChild as HTMLTableElement;
      for (const tableItem of table.childNodes) {
        for (const tr of tableItem.childNodes) {
          const tableRow = tr as HTMLTableRowElement;
          for (const td of tableRow.childNodes) {
            const tableCell = td as HTMLTableCellElement;
            if (!tableCell.textContent) {
              tableCell.innerHTML = '&nbsp;';
            }
            const bounds = tableCell.getBoundingClientRect();
            tableCell.dataset.width = (
              bounds.width / ExportConfig.pointToPixelFactor
            ).toFixed(decimals);
          }
          const bounds = tableRow.getBoundingClientRect();
          tableRow.dataset.height = (
            bounds.height / ExportConfig.pointToPixelFactor
          ).toFixed(decimals);
        }
      }
    }

    if (BackgroundDomService.isHtmlElement(element)) {
      const bounds = element.getBoundingClientRect();
      element.dataset.width = (
        bounds.width / ExportConfig.pointToPixelFactor
      ).toFixed(decimals);
      element.dataset.height = (
        bounds.height / ExportConfig.pointToPixelFactor
      ).toFixed(decimals);
      element.dataset.left = (
        bounds.left / ExportConfig.pointToPixelFactor
      ).toFixed(decimals);
      element.dataset.top = (
        bounds.top / ExportConfig.pointToPixelFactor
      ).toFixed(decimals);
    }
  }

  private static convertPixelValueToPoints(value: string): string {
    if (/^\d+(\.\d{1,})?px$/.test(value)) {
      const pixelValue = value.substring(0, value.length - 2);
      const pointValue =
        Math.round(
          (Number(pixelValue) / ExportConfig.pointToPixelFactor) * 10
        ) / 10;

      value = `${pointValue}pt`;
    }
    return value;
  }

  /**
   * Convert CSS line-height value to PDF/PPT lineHeight, taking into account font properties (ascender & descender)
   * Details: https://github.com/bpampuch/pdfmake/issues/845#issuecomment-1049577198
   * @param lineHeight CSS line-height value
   * @param fontFamily CSS font-family
   * @returns Adjusted line height
   */
  public static convertLineHeight(
    lineHeight: number,
    fontFamily: string
  ): number {
    const font = fontFamily.trim().replace(/"|'/g, '').split(',')[0];
    const fontDefinition = customPdfFonts[font];
    if (!fontDefinition) {
      console.error(`Missing font definition for ${font}`);
      return lineHeight;
    }
    const { ascender, descender, unitsPerEm } = fontDefinition;
    return lineHeight / (ascender / unitsPerEm - descender / unitsPerEm);
  }

  /**
   * Apply converted line-height values to the html content
   * Only used by LegacyPdfExportProvider
   * @param html Html content
   * @returns Html content with converted line-height values
   */
  public static applyLineHeights(html: string): string {
    if (!html) {
      return html;
    }
    const regex = new RegExp(
      `<span[^>]+style="[^"]+"[^>]+${this.convertedLineHeightAttribute}="(?<height>[\\d.]+)"`,
      'g'
    );
    // Apply converted line-height to span elements
    html = html.replace(regex, (match: string, lineHeight: string) => {
      let result = match.replace(/line-height:\s*[\d.]+;?/, '');
      result = result.replace('style="', `style="line-height: ${lineHeight}; `);
      return result;
    });
    // Remove line-height from paragraph elements (only needed on spans)
    html = html.replace(
      /<p[^>]+style="[^"]*line-height:\s*[\d.]+;?[^"]*"/g,
      (match: string) => {
        return match.replace(/line-height:\s*[\d.]+;?/, '');
      }
    );
    return html;
  }

  private static parseStyleLineHeight(style: CSSStyleDeclaration): number {
    // Default is fontSize * 1.2 (https://developer.mozilla.org/en-US/docs/Web/CSS/line-height)
    const defaultMultiplier = 1.2;
    let lineHeight = parseFloat(style.lineHeight);
    if (isNaN(lineHeight)) {
      lineHeight = parseFloat(style.fontSize) * defaultMultiplier;
    }
    if (isNaN(lineHeight)) {
      lineHeight =
        ExportConfig.defaultContentFontStyle.fontSize *
        ExportConfig.pointToPixelFactor *
        defaultMultiplier;
    }
    return lineHeight;
  }

  public static shouldIncludeLegend(
    document: DocumentDto,
    page: DocumentPageDto
  ): boolean {
    return (
      (page.contentType == DocumentPageContentType.MasterLegend &&
        !isNil(page.content)) ||
      (page.contentType != DocumentPageContentType.MasterLegend &&
        // TODO Revert-legend-node
        //!document.hasSteps &&
        document.legendPosition != PageElementPosition.Unset &&
        page.showLegend &&
        !isNil(page.diagram?.legend))
    );
  }

  public static shouldIncludeLogo(
    document: DocumentDto,
    page: DocumentPageDto
  ): boolean {
    return document.logoPosition != PageElementPosition.Unset && page.showLogo;
  }

  /**
   * Creates the initial page content that should be used
   * @returns
   */
  public static getInitialPageContent(): string | null {
    return `<p><span>${ZERO_WIDTH_SPACE}</span></p>`;
  }

  /**
   * If maxTitleHeight > 0 - means that page is in flipbook group and at least one of pages in the group has showTitle == true
   * In this scenario should be used maxTitleHeight for all pages in the group
   * If maxTitleHeight == 0 - it means that page is not in flipbook group or page is in the group but all pages in this group have showTitle == false
   * In this scenario should be used titleHeight if page has showTitle == true
   * @returns
   */
  public static calculatePageTitleHeight(
    page: DocumentPageDto | DocumentSubPageDto,
    showTitle: boolean
  ): number {
    if (page.maxTitleHeight) {
      return page.maxTitleHeight;
    }
    return showTitle ? page.titleHeight : 0;
  }

  /**
   * Some values need to account for the current screen scale and/or browser zoom
   * Example: border-width: 2px, when on 110% zoom, will turn into ~1.81px with getComputedStyles
   */
  public static adjustForDpi(value: string): string {
    const dpi = window.devicePixelRatio;
    if (dpi === 1 || !value) {
      return value;
    }
    const match = value.match(/^(?<value>[\d.]+)(?<unit>pt|px)?$/);
    if (!match?.length) {
      return value;
    }
    return (
      (Number(match.groups['value']) * dpi).toFixed(1) +
      (match.groups['unit'] ?? '')
    );
  }
}
