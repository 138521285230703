import JRect from '@/core/common/JRect';
import JSize from '@/core/common/JSize';
import {
  convertUrlToDataUrl,
  ensureFullUri,
  fitRectIntoBounds
} from '@/core/utils/common.utils';
import ImageLayoutItem from './Items/ImageLayoutItem';
import { LayoutItemType } from './Items/LayoutItemType';
import LayoutSerializer from './LayoutSerializer';
import ExportConfig from '@/core/config/ExportConfig';
import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageLayoutType,
  DocumentPageType,
  FontDto,
  FontStyleDto,
  InsetsDto,
  LayoutItemRotation,
  PageDesignDto,
  PageElementPosition,
  PageTitleDto
} from '@/api/models';
import appConfig from '@/core/config/appConfig';
import InputModeContext from './InputModes/InputModeContext';
import cloneDeep from 'lodash/cloneDeep';
import JInsets from '@/core/common/JInsets';
import {
  TextElementFontData,
  TextElementTemplate,
  TextTemplateByPresetArgs,
  TPreset,
  TPresetDictionary
} from './TextElementPresets';
import { measureElement, wrapInTag } from '@/core/utils/html.utils';
import BackgroundDomService from '@/core/services/BackgroundDomService';
import HtmlLayoutItem from './Items/HtmlLayoutItem';
import WidgetLayoutItem from './Items/WidgetLayoutItem';
import LayoutEditorConfig from './LayoutEditorConfig';
import JPoint from '@/core/common/JPoint';
import Vue from 'vue';
import {
  GET_FONT_PRESETS,
  GET_SELECTED_PRESET_FONT_STYLE,
  STEPS_DESIGN_CONTROLS_NAMESPACE
} from '@/core/services/store/steps-design-controls.module';
import i18n from '@/core/plugins/vue-i18n';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import CachingService from '@/core/services/caching/CachingService';
import CacheType from '@/core/services/caching/CacheType';
import LayoutItem from './Items/LayoutItem';
import stepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import { PageType } from '@/view/pages/administration/steps-designer/PageDesignGroup';
import { PageDesignConfig } from '@/view/pages/administration/steps-designer/StepsDesignerTypes';

export default class LayoutUtils {
  public static get textPresets(): Array<FontStyleDto> {
    return Vue.$globalStore.getters[
      `${STEPS_DESIGN_CONTROLS_NAMESPACE}/${GET_FONT_PRESETS}`
    ];
  }

  public static exportCroppedImage(
    item: ImageLayoutItem,
    imageData: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      if (appConfig.debugMode) {
        img.crossOrigin = 'Anonymous';
      }

      img.onload = (): void => {
        const itemScale = new JSize(
          img.width / item.originalImageLayout.width,
          img.height / item.originalImageLayout.height
        );
        const canvas = document.createElement('canvas');
        canvas.width =
          img.width -
          (item.cropArea.left + item.cropArea.right) * itemScale.width;
        canvas.height =
          img.height -
          (item.cropArea.top + item.cropArea.bottom) * itemScale.height;
        canvas
          .getContext('2d')
          .drawImage(
            img,
            -item.cropArea.left * itemScale.width,
            -item.cropArea.top * itemScale.height,
            img.width,
            img.height
          );
        resolve(canvas.toDataURL());
      };
      img.onerror = (e): void => reject(e);
      img.src = imageData;
    });
  }

  public static isItemOutOfBounds(
    context: InputModeContext,
    item: LayoutItem
  ): boolean {
    return (
      item.layout.x > context.contentSize.width ||
      item.layout.y > context.contentSize.height ||
      item.layout.x + item.layout.width < 0 ||
      item.layout.y + item.layout.height < 0
    );
  }

  public static getOutOfBoundsOffset(
    item: ImageLayoutItem,
    contentSize: JSize
  ): JInsets {
    return new JInsets(
      item.layout.x,
      item.layout.y,
      contentSize.width - (item.layout.x + item.layout.width),
      contentSize.height - (item.layout.y + item.layout.height)
    );
  }

  public static async cropOutOfBoundImage(
    item: ImageLayoutItem,
    contentSize: JSize
  ): Promise<void> {
    const imageSrc = ensureFullUri(item.imageSrc);
    const imageData = await convertUrlToDataUrl(imageSrc);
    const outOfBoundsOffset = this.getOutOfBoundsOffset(
      item as ImageLayoutItem,
      contentSize
    );
    item.cropArea = new JInsets(0);

    // assign the current layout as the originalImageLayout to crop based on the current size
    item.originalImageLayout = item.layout.clone();

    let needsCropping = false;
    if (outOfBoundsOffset.top < 0) {
      item.cropArea.top = Math.abs(outOfBoundsOffset.top);
      item.layout.height += outOfBoundsOffset.top;
      item.layout.y = 0;
      needsCropping = true;
    }
    if (outOfBoundsOffset.left < 0) {
      item.cropArea.left = Math.abs(outOfBoundsOffset.left);
      item.layout.width += outOfBoundsOffset.left;
      item.layout.x = 0;
      needsCropping = true;
    }
    if (outOfBoundsOffset.bottom < 0) {
      item.cropArea.bottom = Math.abs(outOfBoundsOffset.bottom);
      item.layout.height += outOfBoundsOffset.bottom;
      needsCropping = true;
    }
    if (outOfBoundsOffset.right < 0) {
      item.cropArea.right = Math.abs(outOfBoundsOffset.right);
      item.layout.width += outOfBoundsOffset.right;
      needsCropping = true;
    }

    if (needsCropping) {
      item.imageSrc = await LayoutUtils.exportCroppedImage(item, imageData);
    }
  }

  public static async cropLayoutItems(
    layout: string,
    areaSize: JSize
  ): Promise<LayoutItem[]> {
    const updatedLayoutItems = LayoutSerializer.deserializeFromJson(layout);
    for (let item of updatedLayoutItems) {
      if (item.type === LayoutItemType.Image) {
        await this.ensureImageElementSrc(item as ImageLayoutItem);
        await LayoutUtils.cropOutOfBoundImage(
          item as ImageLayoutItem,
          areaSize
        );
      }
    }

    return updatedLayoutItems;
  }

  public static applyResizeToOriginalImage(
    element: ImageLayoutItem,
    initialLayout: JRect,
    newLayout: JRect
  ): ImageLayoutItem {
    if (element.originalImageLayout) {
      const elementLayout = cloneDeep(element.originalImageLayout);
      const ratioOfInitialToOriginalHeight =
        initialLayout.height / elementLayout.height;
      const ratioOfInitialToOriginalWidth =
        initialLayout.width / elementLayout.width;

      elementLayout.height =
        elementLayout.height +
        (newLayout.height - initialLayout.height) /
          ratioOfInitialToOriginalHeight;

      elementLayout.width =
        elementLayout.width +
        (newLayout.width - initialLayout.width) / ratioOfInitialToOriginalWidth;

      const topRatio =
        element.cropArea.top / element.originalImageLayout.height;
      const bottomRatio =
        element.cropArea.bottom / element.originalImageLayout.height;

      const leftRatio =
        element.cropArea.left / element.originalImageLayout.width;
      const rightRatio =
        element.cropArea.right / element.originalImageLayout.width;

      element.cropArea.top = elementLayout.height * topRatio;
      element.cropArea.bottom = elementLayout.height * bottomRatio;
      element.cropArea.left = elementLayout.width * leftRatio;
      element.cropArea.right = elementLayout.width * rightRatio;

      // apply any changes to location to original image when resizing
      elementLayout.x = newLayout.x - element.cropArea.left;

      elementLayout.y = newLayout.y - element.cropArea.top;

      element.originalImageLayout = elementLayout;
    }
    return element;
  }

  public static buildHtmlStringFromTemplate(
    template: TextElementTemplate
  ): string {
    let fontStyles = '';
    if (template?.data?.styles) {
      let styles = Object.entries(template.data.styles);
      while (styles.length > 0) {
        const styleItem = styles.shift();
        fontStyles += `${styleItem[0]}:${styleItem[1]};`;
      }
    }
    if (fontStyles) {
      fontStyles = ` style="${fontStyles}"`;
    }
    let placeholder = template?.data?.text;

    if (template?.data?.isUnderline) {
      placeholder = wrapInTag(placeholder, 'u');
    }
    if (template?.data?.isStrikeThrough) {
      placeholder = wrapInTag(placeholder, 's');
    }
    if (template?.data?.isBold) {
      placeholder = wrapInTag(placeholder, 'strong');
    }
    if (template?.data?.isItalic) {
      placeholder = wrapInTag(placeholder, 'i');
    }
    let styles: string = '';
    const fontSize = template?.data?.styles['font-size'];
    if (template?.styles) {
      styles = ` style="${template.styles}"`;
    } else if (fontSize) {
      // Currently default data from ckEditor comes as
      // '<p class="steps-normal-font" style="font-size:11pt;"><span style="color:#000000;font-family:Arial;font-size:11pt;">Add text...</span></p>'
      styles = ` style="font-size:${fontSize};"`;
    }
    let classes: string = '';
    if (template?.classes) {
      classes = ` class="${template.classes}"`;
    }

    return `<${template.tagName}${classes}${styles}><span${fontStyles}>${placeholder}</span></${template.tagName}>`;
  }

  /**
   * calculates the area size of a layoutElement of type text or widget
   * @param item the item we want to measure
   * @param maxWidth the maxWidth we wish to to check against, default is the biggest int in js
   * @returns item size
   */
  public static getElementMeasurements(
    item: HtmlLayoutItem,
    maxWidth: number = Number.MAX_SAFE_INTEGER
  ): JSize {
    const wrapper = BackgroundDomService.createElement('div');
    // add extra width to unresized items to offset the padding (in order not to break the text)
    let extraWidth = 0;

    // constrain the width, so the height is calculated, skip max width check for widget items
    const isMaxWidth =
      item.layout.width === maxWidth && !(item instanceof WidgetLayoutItem);
    let isRotated = false;

    if (item.resized || isMaxWidth) {
      wrapper.style.width = `${item.layout.width}pt`;
    } else {
      if (item instanceof WidgetLayoutItem) {
        // never wrap widget content
        wrapper.style.width = 'max-content';
        // Adding a small width correction to prevent unwanted wrapping.
        extraWidth = 2;
        if (item.type === LayoutItemType.PageNumber) {
          isRotated = LayoutUtils.applyRotation(wrapper, item);
        }
      } else {
        wrapper.style.width = 'fit-content';
        // add padding to the width calculation
        extraWidth = item.padding;
      }
    }
    wrapper.style.wordBreak = 'break-all';
    // apply correct class so styles match
    wrapper.style.padding = item.padding + 'pt';
    wrapper.className = 'document-page-content layout-content';
    // grab current html
    wrapper.innerHTML = item.html;
    let size = measureElement(wrapper);
    if (isRotated) {
      size = new JSize(size.height, size.width);
    }
    size.width = size.width / ExportConfig.pointToPixelFactor + extraWidth;
    size.height = size.height / ExportConfig.pointToPixelFactor;

    return size.round();
  }

  public static applyTemplateToHtmlElement(
    element: HTMLElement,
    template: TextElementTemplate
  ): HTMLElement {
    if (template.data) {
      if (template.data.isBold) {
        element.style.fontWeight = 'bold';
      }
      if (template.data.isItalic) {
        element.style.fontStyle = 'italic';
      }
      if (template.data.isUnderline) {
        element.style.textDecoration = 'underline';
      }

      for (const [key, value] of Object.entries(template.data?.styles)) {
        element.style[key] = value;
      }
    }
    return element;
  }

  public static fitRect(
    innerRect: JRect,
    outerRect: JRect,
    padding: number = 2
  ): JRect {
    if (innerRect.width > outerRect.width - padding) {
      innerRect.width = outerRect.width - padding;
    }
    if (innerRect.height > outerRect.height - padding) {
      innerRect.height = outerRect.height - padding;
    }

    if (innerRect.x < outerRect.x + padding) {
      innerRect.x = outerRect.x + padding;
    }
    if (innerRect.y < outerRect.y + padding) {
      innerRect.y = outerRect.y + padding;
    }
    if (innerRect.maxX >= outerRect.maxX - padding) {
      innerRect.x = outerRect.maxX - innerRect.width - padding;
    }
    if (innerRect.maxY > outerRect.maxY - padding) {
      innerRect.y = outerRect.maxY - innerRect.height - padding;
    }

    return innerRect;
  }

  public static biggestZIndex(items: LayoutItem[]): number {
    if (items.length === 0) {
      return 0;
    }
    const zIndexes = items.map((item: LayoutItem) => item.zIndex);
    return Math.max(...zIndexes);
  }

  public static smallestZIndex(items: LayoutItem[]): number {
    if (items.length === 0) {
      return 0;
    }
    const zIndexes = items.map((item: LayoutItem) => item.zIndex);
    return Math.min(...zIndexes);
  }

  public static toLocalCoordinates(
    context: InputModeContext,
    location: JPoint
  ): JPoint {
    const containerBounds = context.container.getBoundingClientRect();
    const containerLoc = new JPoint(containerBounds.x, containerBounds.y);
    location = location.subtract(containerLoc); // get absolute location
    location = location.divide(context.scale); // apply scale
    location = location.divide(ExportConfig.pointToPixelFactor); // convert pixels to points
    location = new JPoint(Math.round(location.x), Math.round(location.y));
    return location;
  }

  public static calculateImageSize(
    size: JSize,
    context: InputModeContext,
    applySpacing = false
  ): JSize {
    let spacing = 0;

    if (applySpacing) {
      spacing = LayoutEditorConfig.image.imageSpacingOnPaste;
    }

    if (
      size.width > context.contentSize.width - spacing ||
      size.height > context.contentSize.height - spacing
    ) {
      const fittedSize = fitRectIntoBounds(
        new JSize(size.width, size.height),
        new JSize(
          context.contentSize.width - spacing,
          context.contentSize.height - spacing
        )
      );
      return new JSize(fittedSize.width, fittedSize.height);
    }
    return size;
  }

  public static getPositionCoordinates(
    position: PageElementPosition,
    itemSize: JSize,
    containerSize: JSize
  ): JPoint {
    const xOffset = 8;
    const containerCenterX = Math.round(
      containerSize.width / 2 - itemSize.width / 2
    );
    const containerCenterY = Math.round(
      containerSize.height / 2 - itemSize.height / 2
    );

    let itemPosition: JPoint = null;
    switch (position) {
      case PageElementPosition.Left:
      case PageElementPosition.BottomLeft:
      case PageElementPosition.TopLeft:
        itemPosition = new JPoint(xOffset, containerCenterY);
        break;
      case PageElementPosition.Top:
      case PageElementPosition.Bottom:
        itemPosition = new JPoint(containerCenterX, containerCenterY);
        break;

      case PageElementPosition.Right:
      case PageElementPosition.TopRight:
      case PageElementPosition.BottomRight:
        itemPosition = new JPoint(
          containerSize.width - itemSize.width - xOffset,
          containerCenterY
        );
        break;
    }

    return itemPosition;
  }

  public static getContentAreaByPageElementPosition(
    position: PageElementPosition
  ): DocumentContentArea {
    const footerPositions = [
      PageElementPosition.Bottom,
      PageElementPosition.BottomLeft,
      PageElementPosition.BottomRight
    ];

    return footerPositions.includes(position)
      ? DocumentContentArea.Footer
      : DocumentContentArea.Header;
  }

  public static getContentAreaByPageElementPositionAndLayoutType(
    position: PageElementPosition,
    layoutType: DocumentPageLayoutType
  ): DocumentContentArea {
    if (layoutType !== DocumentPageLayoutType.None) {
      return DocumentContentArea.BodyLayout;
    }

    return this.getContentAreaByPageElementPosition(position);
  }

  public static getContentAreaSize(
    pageDesignOrDocument: PageDesignDto | DocumentDto,
    area: DocumentContentArea
  ): JSize {
    const containerSize = new JSize(pageDesignOrDocument.pageStyle.width, 0);
    switch (area) {
      case DocumentContentArea.Header:
        containerSize.height = pageDesignOrDocument.headerStyle.height;
        break;
      case DocumentContentArea.Footer:
        containerSize.height = pageDesignOrDocument.footerStyle.height;
        break;
      case DocumentContentArea.BodyContent:
      case DocumentContentArea.BodyLayout:
      case DocumentContentArea.Background:
        containerSize.height = pageDesignOrDocument.pageStyle.height;
        break;

      default:
        throw 'Unsupported content area';
    }

    return containerSize;
  }

  public static getContentAreaByItemType(
    pageDesignOrPage: DocumentPageDto | PageDesignDto,
    itemType: LayoutItemType,
    visibilityCheck = false
  ): DocumentContentArea | null {
    let layoutOrContent: string;
    if ('bodyLayout' in pageDesignOrPage) {
      layoutOrContent = pageDesignOrPage.bodyLayout;
    } else if ('content' in pageDesignOrPage) {
      layoutOrContent = pageDesignOrPage.content;
    }

    const cacheKey = CachingService.generateKey(
      CacheType.ContentAreaByItemType,
      pageDesignOrPage.id,
      itemType,
      visibilityCheck,
      pageDesignOrPage.headerLayout,
      pageDesignOrPage.footerLayout,
      layoutOrContent
    );

    return CachingService.getOrSet(cacheKey, () => {
      const headerLayoutItems = LayoutSerializer.deserializeFromJson(
        pageDesignOrPage.headerLayout
      );

      if (pageDesignOrPage.layoutType !== DocumentPageLayoutType.None) {
        const layoutItems =
          LayoutSerializer.deserializeFromJson(layoutOrContent);
        if (
          layoutItems.some(
            (i) => i.type == itemType && (visibilityCheck ? !i.hidden : true)
          )
        ) {
          return { data: DocumentContentArea.BodyLayout };
        }
      }

      if (
        headerLayoutItems.some(
          (i) => i.type == itemType && (visibilityCheck ? !i.hidden : true)
        )
      ) {
        return { data: DocumentContentArea.Header };
      }
      const footerLayoutItems = LayoutSerializer.deserializeFromJson(
        pageDesignOrPage.footerLayout
      );
      if (
        footerLayoutItems.some(
          (i) => i.type == itemType && (visibilityCheck ? !i.hidden : true)
        )
      ) {
        return { data: DocumentContentArea.Footer };
      }

      return { data: null };
    });
  }

  public static getTextTemplateByPreset(
    args?: TextTemplateByPresetArgs
  ): TextElementTemplate {
    let fontPreset = this.getGlobalFontPresetSelection()?.style;
    if (!fontPreset) {
      fontPreset = this.textPresets.find(
        (p) => p.title.toLocaleLowerCase() === args?.preset
      )?.style;
    }
    if (!fontPreset) {
      fontPreset = this.textPresets.find(
        (p) => p.title.toLocaleLowerCase() === 'normal'
      )?.style;
    }
    if (!fontPreset) {
      fontPreset = { ...ExportConfig.defaultContentFontStyle };
    }

    const fontData = this.getFontDataFromPreset(
      fontPreset,
      i18n.t(args?.text ?? 'ADD_TEXT_ELLIPSES')
    );

    switch (args?.preset) {
      case 'heading':
        return {
          tagName: 'h1',
          classes: 'steps-heading-font',
          styles: args?.styles,
          data: fontData
        };
      case 'subheading':
        return {
          tagName: 'h4',
          classes: 'steps-heading-font',
          styles: args?.styles,
          data: fontData
        };
      default:
        return {
          tagName: 'p',
          classes: 'steps-normal-font',
          styles: args?.styles,
          data: fontData
        };
    }
  }

  public static getFontDataFromPreset(
    fontPreset: FontDto,
    content: string
  ): TextElementFontData {
    const fontData = {
      text: content,
      isBold: fontPreset.fontWeight === 'Bold',
      isItalic: fontPreset.fontStyle === 'Italic',
      isUnderline: fontPreset.textDecoration === 'Underline',
      styles: {}
    };
    if (fontPreset.backgroundColor) {
      fontData.styles['background-color'] = fontPreset.backgroundColor;
    }
    if (fontPreset.color) {
      fontData.styles['color'] = fontPreset.color;
    }
    // order is important to match the ckEditor getData for default text
    fontData.styles = {
      ...fontData.styles,
      ...{
        'font-family': fontPreset.fontFamily,
        'font-size': fontPreset.fontSize + 'pt'
      }
    };

    return fontData;
  }

  public static getDefaultHtmlForTextBoxItem(
    textPresetData?: TextTemplateByPresetArgs
  ): string {
    const template = textPresetData.styles
      ? (JSON.parse(textPresetData.styles) as TextElementTemplate)
      : this.getTextTemplateByPreset(textPresetData);
    return LayoutUtils.buildHtmlStringFromTemplate(template);
  }

  public static getTPresetDictionary(): TPresetDictionary {
    return {
      heading1: 'heading',
      heading4: 'subheading',
      customParagraph: 'normal'
    };
  }

  private static getGlobalFontPresetSelection(): FontStyleDto {
    return Vue.$globalStore.getters[
      `${STEPS_DESIGN_CONTROLS_NAMESPACE}/${GET_SELECTED_PRESET_FONT_STYLE}`
    ];
  }

  public static async ensureImageElementSrc(
    item: ImageLayoutItem
  ): Promise<void> {
    if (
      item.cropArea &&
      (!item.imageSrc || !item.imageSrc.startsWith('data:'))
    ) {
      const cacheKey = this.generateCroppedImageCacheKey(item);
      item.imageSrc = await CachingService.getOrSetAsync(cacheKey, async () => {
        const img = await convertUrlToDataUrl(
          ensureFullUri(item.originalImageSrc ?? item.imageSrc)
        );
        const croppedImage = await LayoutUtils.exportCroppedImage(item, img);
        return { data: croppedImage };
      });
    }
  }

  public static generateCroppedImageCacheKey(item: ImageLayoutItem): string {
    const baseSrc = item.originalImageSrc ?? item.imageSrc;

    return CachingService.generateKey(
      CacheType.CroppedImage,
      baseSrc,
      item.cropArea?.bottom ?? 0,
      item.cropArea?.left ?? 0,
      item.cropArea?.right ?? 0,
      item.cropArea?.top ?? 0
    );
  }

  public static getLayoutItemMaxWidth(
    context: InputModeContext,
    item: LayoutItem
  ): number {
    return context.contentSize.width - item.layout.x - item.padding;
  }

  public static getLayoutItemRotation(item: LayoutItem): number {
    let rotation = 0;
    if (
      item instanceof WidgetLayoutItem &&
      item.type === LayoutItemType.PageNumber
    ) {
      if (item.rotation === LayoutItemRotation.Left) {
        rotation = -90;
      }
    }

    return rotation;
  }

  public static applyRotation(
    element: HTMLElement,
    item: WidgetLayoutItem
  ): boolean {
    const rotation = LayoutUtils.getLayoutItemRotation(item);
    if (rotation) {
      element.style.transform = `rotate(${rotation}deg)`;
      element.style.transformOrigin = 'center';
      return true;
    }
    return false;
  }

  public static getDefaultPageTitle(): PageTitleDto {
    return new PageTitleDto(
      stepsDesignerConfig.get.pageDesign.showTitle,
      stepsDesignerConfig.get.pageDesign.titleHeight,
      stepsDesignerConfig.get.pageDesign.titleHeight,
      stepsDesignerConfig.get.pageDesign.titleLayout
    );
  }

  public static reverseLeftRight(insets: InsetsDto): void {
    const originalLeft = insets.left;
    insets.left = insets.right;
    insets.right = originalLeft;
  }
}
