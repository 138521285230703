import {
  PageElementPosition,
  DocumentPageLayoutType,
  DocumentPageContentType,
  DocumentPageType,
  PageStyleBoundariesPresetType,
  HeaderFooterStyleDto,
  PageStyleDto,
  PageDesignDto,
  DiagramPosition
} from '@/api/models';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import i18n from '@/core/plugins/vue-i18n';
import moment from 'moment';
import appConfig from '@/core/config/appConfig';
import {
  PageType,
  PageDesignGroup
} from '@/view/pages/administration/steps-designer/PageDesignGroup';
import ExportConfig from './ExportConfig';
import JInsets from '../common/JInsets';
import cloneDeep from 'lodash/cloneDeep';
import FeaturesService from '../services/FeaturesService';
import { Features } from '../common/Features';

class StepsDesignerConfig {
  _config: any;

  get get(): any {
    if (!this._config) {
      this._config = {
        minSplitRatio: ExportConfig.minSplitRatio,
        defaultDividerStyle: ExportConfig.defaultDividerStyle,
        pageSizeOptions: {
          default: {
            label: 'A4_PAGE_SIZE',
            width: 842,
            height: 595,
            previewScaleFactor: 1.05
          },
          wide: {
            label: 'WIDE_PAGE_SIZE',
            width: 960,
            height: 540,
            previewScaleFactor: 1.1
          },
          letter: {
            label: 'LETTER_PAGE_SIZE',
            width: 792,
            height: 612,
            previewScaleFactor: 1.05
          }
        },
        textElementPresets: [
          {
            label: 'HEADING_TEXT_PRESET',
            type: 'heading',
            iconSize: 20,
            addAt: { x: 50, y: 50 }
          },
          {
            label: 'SUBHEADING_TEXT_PRESET',
            type: 'subheading',
            iconSize: 16,
            addAt: { x: 50, y: 150 }
          },
          {
            label: 'NORMAL_TEXT_PRESET',
            type: 'normal',
            iconSize: 14,
            addAt: { x: 50, y: 200 }
          }
        ],
        pageDesignControlTabs: [
          {
            label: i18n.t('CONTENT'),
            value: 'content',
            automationSelector: 'steps-designer-edit-mode-content'
          },
          {
            label: i18n.t('MARGINS'),
            value: 'margins',
            automationSelector: 'steps-designer-edit-mode-margins'
          }
        ],
        pageDesignControls: [
          {
            name: 'EditModeControl',
            allowedIn: [DocumentPageLayoutType.None]
          },
          {
            name: 'DiagramPanePositionControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'content'
          },
          {
            name: 'HeaderControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'content'
          },
          {
            name: 'FooterControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'content'
          },
          {
            name: 'DateControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'content'
          },
          {
            name: 'PageDividerVisibilityControl',
            allowedIn: [DocumentPageLayoutType.None],
            isVisible: () => FeaturesService.hasFeature(Features.TextPages),
            tab: 'content'
          },
          {
            name: 'PageTitleControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'content'
          },
          {
            name: 'AssetsControl',
            allowedIn: [
              DocumentPageLayoutType.None,
              DocumentPageLayoutType.Closing,
              DocumentPageLayoutType.Cover,
              DocumentPageLayoutType.Filler
            ],
            tab: 'content'
          },
          {
            name: 'TextControl',
            allowedIn: [
              DocumentPageLayoutType.None,
              DocumentPageLayoutType.Closing,
              DocumentPageLayoutType.Cover,
              DocumentPageLayoutType.Filler
            ],
            tab: 'content'
          },
          {
            name: 'TextPresetControl',
            allowedIn: [
              DocumentPageLayoutType.Closing,
              DocumentPageLayoutType.Cover,
              DocumentPageLayoutType.Filler
            ],
            tab: 'content'
          },
          {
            name: 'HeaderFooterHeightControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'margins'
          },
          {
            name: 'PageDividerControl',
            allowedIn: [DocumentPageLayoutType.None],
            pageTypes: [PageType.DiagramText],
            tab: 'margins'
          },
          {
            name: 'ContentGapControl',
            allowedIn: [DocumentPageLayoutType.None],
            tab: 'margins'
          },
          {
            name: 'PaddingControl',
            allowedIn: [DocumentPageLayoutType.None],
            pageTypes: [PageType.DiagramText, PageType.Text, PageType.TextText],
            tab: 'margins',
            props: {
              label: 'TEXT',
              type: PageType.Text
            }
          },
          {
            name: 'PaddingControl',
            allowedIn: [DocumentPageLayoutType.None],
            pageTypes: [PageType.Diagram, PageType.DiagramText],
            tab: 'margins',
            props: {
              label: 'DIAGRAM',
              type: PageType.Diagram
            }
          },
          {
            name: 'PageTitleMinimumHeightControl',
            allowedIn: [DocumentPageLayoutType.None],
            pageTypes: [
              PageType.Diagram,
              PageType.DiagramText,
              PageType.Text,
              PageType.TextText
            ],
            tab: 'margins'
          }
        ],

        dateFormatPresets: {
          [appConfig.supportedLocales.enGb.toLowerCase()]: [
            {
              id: 1,
              label: `YYYY-MM-DD`,
              format: `YYYY-MM-DD`,
              template: `<placeholder name="date">${moment().format(
                'YYYY-MM-DD'
              )}</placeholder>`
            },
            {
              id: 2,
              label: `DD-MM-YY`,
              format: `DD-MM-YY`,
              template: `<placeholder name="date">${moment().format(
                'DD-MM-YY'
              )}</placeholder>`
            },
            {
              id: 3,
              label: `DD.MM.YY`,
              format: `DD.MM.YY`,
              template: `<placeholder name="date">${moment().format(
                'DD.MM.YY'
              )}</placeholder>`
            },
            {
              id: 4,
              label: `DD/MM/YY`,
              format: `DD/MM/YY`,
              template: `<placeholder name="date">${moment().format(
                'DD/MM/YY'
              )}</placeholder>`
            },
            {
              id: 5,
              label: `DD-MM-YYYY`,
              format: `DD-MM-YYYY`,
              template: `<placeholder name="date">${moment().format(
                'DD-MM-YYYY'
              )}</placeholder>`
            },
            {
              id: 6,
              label: `DD.MM.YYYY`,
              format: `DD.MM.YYYY`,
              template: `<placeholder name="date">${moment().format(
                'DD.MM.YYYY'
              )}</placeholder>`
            },
            {
              id: 7,
              label: `DD/MM/YYYY`,
              format: `DD/MM/YYYY`,
              template: `<placeholder name="date">${moment().format(
                'DD/MM/YYYY'
              )}</placeholder>`
            },
            {
              id: 8,
              label: `DD ${i18n.t('MONTH')}, ${i18n.t('YEAR')}`,
              format: `DD MMMM, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'DD MMMM, YYYY'
              )}</placeholder>`
            },
            {
              id: 9,
              label: `DD Mon ${i18n.t('YEAR')}`,
              format: `DD MMM YYYY`,
              template: `<placeholder name="date">${moment().format(
                'DD MMM YYYY'
              )}</placeholder>`
            },
            {
              id: 10,
              label: `${i18n.t('MONTH')} ${i18n.t('YEAR')}`,
              format: `MMMM YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM YYYY'
              )}</placeholder>`
            },
            {
              id: 11,
              label: `${i18n.t('MONTH')} DD, ${i18n.t('YEAR')}`,
              format: `MMMM DD, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM DD, YYYY'
              )}</placeholder>`
            }
          ],
          [appConfig.supportedLocales.enUS.toLowerCase()]: [
            {
              id: 1,
              label: `YYYY-MM-DD`,
              format: `YYYY-MM-DD`,
              template: `<placeholder name="date">${moment().format(
                'YYYY-MM-DD'
              )}</placeholder>`
            },
            {
              id: 2,
              label: `MM-DD-YY`,
              format: `MM-DD-YY`,
              template: `<placeholder name="date">${moment().format(
                'MM-DD-YY'
              )}</placeholder>`
            },
            {
              id: 3,
              label: `MM.DD.YY`,
              format: `MM.DD.YY`,
              template: `<placeholder name="date">${moment().format(
                'MM.DD.YY'
              )}</placeholder>`
            },
            {
              id: 4,
              label: `MM/DD/YY`,
              format: `MM/DD/YY`,
              template: `<placeholder name="date">${moment().format(
                'MM/DD/YY'
              )}</placeholder>`
            },
            {
              id: 5,
              label: `MM-DD-YYYY`,
              format: `MM-DD-YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM-DD-YYYY'
              )}</placeholder>`
            },
            {
              id: 6,
              label: `MM.DD.YYYY`,
              format: `MM.DD.YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM.DD.YYYY'
              )}</placeholder>`
            },
            {
              id: 7,
              label: `MM/DD/YYYY`,
              format: `MM/DD/YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM/DD/YYYY'
              )}</placeholder>`
            },
            {
              id: 8,
              label: `${i18n.t('MONTH')} DD, ${i18n.t('YEAR')}`,
              format: `MMMM DD, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM DD, YYYY'
              )}</placeholder>`
            },
            {
              id: 9,
              label: `Mon DD ${i18n.t('YEAR')}`,
              format: `MMM DD, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMM DD, YYYY'
              )}</placeholder>`
            },
            {
              id: 10,
              label: `${i18n.t('MONTH')} ${i18n.t('YEAR')}`,
              format: `MMMM YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM YYYY'
              )}</placeholder>`
            }
          ],
          [appConfig.supportedLocales.frCA.toLowerCase()]: [
            {
              id: 1,
              label: `YYYY-MM-DD`,
              format: `YYYY-MM-DD`,
              template: `<placeholder name="date">${moment().format(
                'YYYY-MM-DD'
              )}</placeholder>`
            },
            {
              id: 2,
              label: `MM-DD-YY`,
              format: `MM-DD-YY`,
              template: `<placeholder name="date">${moment().format(
                'MM-DD-YY'
              )}</placeholder>`
            },
            {
              id: 3,
              label: `MM.DD.YY`,
              format: `MM.DD.YY`,
              template: `<placeholder name="date">${moment().format(
                'MM.DD.YY'
              )}</placeholder>`
            },
            {
              id: 4,
              label: `MM/DD/YY`,
              format: `MM/DD/YY`,
              template: `<placeholder name="date">${moment().format(
                'MM/DD/YY'
              )}</placeholder>`
            },
            {
              id: 5,
              label: `MM-DD-YYYY`,
              format: `MM-DD-YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM-DD-YYYY'
              )}</placeholder>`
            },
            {
              id: 6,
              label: `MM.DD.YYYY`,
              format: `MM.DD.YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM.DD.YYYY'
              )}</placeholder>`
            },
            {
              id: 7,
              label: `MM/DD/YYYY`,
              format: `MM/DD/YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MM/DD/YYYY'
              )}</placeholder>`
            },
            {
              id: 8,
              label: `${i18n.t('MONTH')} DD, ${i18n.t('YEAR')}`,
              format: `MMMM DD, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM DD, YYYY'
              )}</placeholder>`
            },
            {
              id: 9,
              label: `Mon DD ${i18n.t('YEAR')}`,
              format: `MMM DD, YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMM DD, YYYY'
              )}</placeholder>`
            },
            {
              id: 10,
              label: `${i18n.t('MONTH')} ${i18n.t('YEAR')}`,
              format: `MMMM YYYY`,
              template: `<placeholder name="date">${moment().format(
                'MMMM YYYY'
              )}</placeholder>`
            }
          ]
        },
        pageNumberPresets: [
          {
            id: 1,
            label: `${i18n.t('PAGE')} # `,
            format: `${i18n.t('PAGE')} 1`,
            template: `${i18n.t(
              'PAGE'
            )} <placeholder name="pageNumber">1</placeholder>`
          },
          {
            id: 2,
            label: `${i18n.t('PAGE')} # ${i18n.t('OF')} ${i18n.t('Total')} `,
            format: `${i18n.t('PAGE')} 1 ${i18n.t('OF')} 10`,
            template: `${i18n.t(
              'PAGE'
            )} <placeholder name="pageNumber">1</placeholder> ${i18n.t(
              'OF'
            )} <placeholder name="totalPages">10</placeholder>`
          },
          {
            id: 3,
            label: `${i18n.t('PAGE')} #/${i18n.t('Total')} `,
            format: `${i18n.t('PAGE')} 1/10`,
            template: `${i18n.t(
              'PAGE'
            )} <placeholder name="pageNumber">1</placeholder>/<placeholder name="totalPages">10</placeholder>`
          },
          {
            id: 4,
            label: `Pg.#`,
            format: 'Pg.1',
            template: 'Pg.<placeholder name="pageNumber">1</placeholder>'
          },
          {
            id: 5,
            label: `#`,
            format: '1',
            template: '<placeholder name="pageNumber">1</placeholder>'
          },
          {
            id: 6,
            label: `# ${i18n.t('OF')} ${i18n.t('Total')}`,
            format: `1 ${i18n.t('OF')} 10`,
            template: `<placeholder name="pageNumber">1</placeholder> ${i18n.t(
              'OF'
            )} <placeholder name="totalPages">10</placeholder>`
          },
          {
            id: 7,
            label: `#/${i18n.t('Total')} `,
            format: '1/10',
            template: `<placeholder name="pageNumber">1</placeholder>/<placeholder name="totalPages">10</placeholder>`
          }
        ],

        positionConfig: [
          {
            iconName: 'position-top-left',
            addTo: 'header',
            label: 'TOP_LEFT',
            position: PageElementPosition.TopLeft
          },
          {
            iconName: 'position-top-center',
            addTo: 'header',
            label: 'TOP',
            position: PageElementPosition.Top
          },
          {
            iconName: 'position-top-right',
            addTo: 'header',
            label: 'TOP_RIGHT',
            position: PageElementPosition.TopRight
          },
          {
            iconName: 'position-bottom-left',
            addTo: 'footer',
            label: 'BOTTOM_LEFT',
            position: PageElementPosition.BottomLeft
          },
          {
            iconName: 'position-bottom-center',
            addTo: 'footer',
            label: 'BOTTOM',
            position: PageElementPosition.Bottom
          },
          {
            iconName: 'position-bottom-right',
            addTo: 'footer',
            label: 'BOTTOM_RIGHT',
            position: PageElementPosition.BottomRight
          }
        ],
        diagramMarginsPreset: {
          [PageStyleBoundariesPresetType.None]: null,
          [PageStyleBoundariesPresetType.Normal]: new JInsets(72.00011), //2.54 cm
          [PageStyleBoundariesPresetType.Narrow]: new JInsets(36.000055), //1.27 cm
          [PageStyleBoundariesPresetType.Moderate]: new JInsets(
            54.141815, //1.91 cm
            72.00011, //2.54 cm
            54.141815, //1.91 cm
            72.00011 //2.54 cm
          ),
          [PageStyleBoundariesPresetType.Wide]: new JInsets(
            144.00022, //5.08 cm
            72.00011, //2.54 cm
            144.00022, //5.08 cm
            72.00011 //2.54 cm
          ),
          [PageStyleBoundariesPresetType.Custom]: null
        },
        pageStylePresetLabels: {
          [PageStyleBoundariesPresetType.None]: i18n.t('NONE'),
          [PageStyleBoundariesPresetType.Normal]: i18n.t('NORMAL'),
          [PageStyleBoundariesPresetType.Narrow]: i18n.t('NARROW'),
          [PageStyleBoundariesPresetType.Moderate]: i18n.t('MODERATE'),
          [PageStyleBoundariesPresetType.Wide]: i18n.t('WIDE'),
          [PageStyleBoundariesPresetType.Custom]: i18n.t('CUSTOM')
        },
        allowedLayoutItemTypesHeaderFooter: [
          LayoutItemType.Text,
          LayoutItemType.CreationBox,
          LayoutItemType.PageNumber,
          LayoutItemType.Date,
          LayoutItemType.Image
        ],
        allowedLayoutItemTypesTitle: [
          LayoutItemType.Text,
          LayoutItemType.CreationBox
        ],
        allowedLayoutItemTypesConfigurableArea: [
          LayoutItemType.Text,
          LayoutItemType.Image,
          LayoutItemType.CreationBox
        ],
        allowedLayoutItemTypesDefault: [
          LayoutItemType.Text,
          LayoutItemType.Image,
          LayoutItemType.CreationBox
        ],
        pageTypeConfig: {
          [PageType.Diagram]: {
            category: PageDesignGroup.DIAGRAM_PAGE,
            contentType: DocumentPageContentType.None,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Diagram,
            type: PageType.Diagram,
            contentColumns: 0,
            isDefault: true
          },
          [PageType.DiagramText]: {
            category: PageDesignGroup.DIAGRAM_TEXT,
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Split,
            type: PageType.DiagramText,
            contentColumns: 0,
            isDefault: false
          },
          [PageType.Text]: {
            category: PageDesignGroup.TEXT,
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Content,
            type: PageType.Text,
            contentColumns: 0,
            isDefault: false
          },
          [PageType.TextText]: {
            category: PageDesignGroup.TEXT_TEXT,
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Content,
            type: PageType.TextText,
            contentColumns: 2,
            isDefault: false
          },
          [PageType.Cover]: {
            category: PageDesignGroup.COVER_PAGE,
            contentType: DocumentPageContentType.Layout,
            layoutType: DocumentPageLayoutType.Cover,
            pageType: DocumentPageType.Content,
            type: PageType.Cover,
            isDefault: true
          },
          [PageType.Filler]: {
            category: PageDesignGroup.FILLER_PAGE,
            contentType: DocumentPageContentType.Layout,
            layoutType: DocumentPageLayoutType.Filler,
            pageType: DocumentPageType.Content,
            type: PageType.Filler,
            isDefault: true
          },
          [PageType.Closing]: {
            category: PageDesignGroup.CLOSING_PAGE,
            contentType: DocumentPageContentType.Layout,
            layoutType: DocumentPageLayoutType.Closing,
            pageType: DocumentPageType.Content,
            type: PageType.Closing,
            isDefault: true
          },
          [PageType.MasterLegend]: {
            category: PageDesignGroup.MASTER_LEGEND_PAGE,
            contentType: DocumentPageContentType.MasterLegend,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Content,
            type: PageType.MasterLegend
          }
        },
        defaultPadding: [
          {
            contentType: DocumentPageContentType.None,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Diagram,
            diagramPadding: ExportConfig.minDiagramPadding.clone(),
            contentColumns: 0,
            diagramPaddingPreset: PageStyleBoundariesPresetType.None
          },
          {
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Split,
            diagramPadding: ExportConfig.minDiagramPadding.clone(),
            htmlContentPadding: ExportConfig.minHtmlContentPadding.clone(),
            contentColumns: 0,
            diagramPaddingPreset: PageStyleBoundariesPresetType.None,
            htmlContentPaddingPreset: PageStyleBoundariesPresetType.None
          },
          {
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Content,
            contentColumns: 0,
            htmlContentPadding: ExportConfig.minHtmlContentPadding.clone(),
            htmlContentPaddingPreset: PageStyleBoundariesPresetType.None
          },
          {
            contentType: DocumentPageContentType.Html,
            layoutType: DocumentPageLayoutType.None,
            pageType: DocumentPageType.Content,
            contentColumns: 2,
            htmlContentPadding: ExportConfig.minHtmlContentPadding.clone(),
            htmlContentPaddingPreset: PageStyleBoundariesPresetType.None
          }
        ],
        pageDesign: {
          maxUploadSize: 3,
          defaultHeaderFooterStyle: {
            show: false,
            height: 50,
            backgroundColor: null,
            borderColor: null,
            borderWidth: null,
            borderStyle: null
          } as HeaderFooterStyleDto,
          defaultPageStyle: {
            width: 842,
            height: 595,
            innerMargins: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            fullMargins: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            splitRatio: 0.6,
            htmlContentColumnGap: ExportConfig.minHtmlContentColumnGap,
            htmlContentDividerStyle: cloneDeep(
              ExportConfig.defaultDividerStyle
            ),
            splitDividerStyle: cloneDeep(ExportConfig.defaultDividerStyle),
            padding: null,
            innerMarginsPreset: PageStyleBoundariesPresetType.None,
            fullMarginsPreset: PageStyleBoundariesPresetType.None
          } as PageStyleDto,
          headerFooterConstraints: {
            minHeight: 50,
            maxHeight: 150
          },
          minInnerMargins: {
            height: 395,
            width: 395
          },
          htmlContentDividerVisibility: false,
          splitDividerVisibility: false,
          showTitle: false,
          titleHeight: 50,
          titleLayout: null,
          titleMaxAllowedHeight: 200
        },
        minInnerBoxWidth: 116, // 48 + 48 + 20 : handleWidth + handleWidth + 20 px extra
        minInnerBoxHeight: 146, // 48 + 48 + 50 : handleHeight + handleHeight + 50 px extra
        defaultPageNumber: {
          type: 'PageNumber',
          layout: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          },
          presetId: 1,
          position: 4,
          rotation: 0
        },
        defaultComponentsStyles: JSON.parse(
          appConfig?.defaultContentConfig?.defaultComponentsStyles
        )
      };
    }
    return this._config;
  }

  public createDefaultPageStyle(): PageStyleDto {
    const pageStyleConfig = cloneDeep(this.get.pageDesign.defaultPageStyle);

    return {
      ...new PageStyleDto(
        pageStyleConfig.width,
        pageStyleConfig.height,
        pageStyleConfig.splitRatio,
        pageStyleConfig.htmlContentColumnGap
      ),
      ...pageStyleConfig
    } as PageStyleDto;
  }

  createDefaultPageDesign(): PageDesignDto {
    const defaultPageDesignSettings = structuredClone(this.get.pageDesign);

    const pageDesign = new PageDesignDto(
      0,
      false,
      defaultPageDesignSettings.splitDividerVisibility,
      defaultPageDesignSettings.htmlContentDividerVisibility,
      null,
      null
    );

    pageDesign.pageTitle = null;

    pageDesign.attachments = [];
    pageDesign.defaultDiagramPosition = DiagramPosition.Left;

    pageDesign.footerStyle = structuredClone(
      defaultPageDesignSettings.defaultHeaderFooterStyle
    );

    pageDesign.headerStyle = structuredClone(
      defaultPageDesignSettings.defaultHeaderFooterStyle
    );
    pageDesign.htmlContentDividerVisibility =
      defaultPageDesignSettings.htmlContentDividerVisibility;
    pageDesign.splitDividerVisibility =
      defaultPageDesignSettings.splitDividerVisibility;

    pageDesign.pageStyle = this.createDefaultPageStyle();

    return pageDesign;
  }
}

export default new StepsDesignerConfig();
