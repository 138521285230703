import PageLayoutHandler from '../../services/export/PageLayoutHandler';
import Vue from 'vue';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import merge from 'lodash/merge';
import IEditorConfig from '../../common/IEditorConfig';
import BasicEditorConfig from './basicEditorConfig';
import CKEditorUtils from '@/core/utils/CKEditorUtils';
import {
  PAGE_DESIGN_NAMESPACE,
  GET_SELECTED_PAGE_DESIGN_SET
} from '@/core/services/store/page-design.module';
import { PageDesignSetDto } from '@/api/models';

class PageLayoutEditorConfig implements IEditorConfig {
  get selectedStepsDesignSet(): PageDesignSetDto {
    return Vue.$globalStore.getters[
      `${PAGE_DESIGN_NAMESPACE}/${GET_SELECTED_PAGE_DESIGN_SET}`
    ];
  }
  getConfig() {
    const config = {
      removePlugins: [
        'ParagraphFontSizeSync',
        'ImageCaption',
        'ImageStyle',
        'ImageToolbar',
        'MediaEmbed',
        'HorizontalLine',
        // Disable images for now
        'Image',
        'ExtendedImage',
        'ImageResize',
        'ImageUpload',
        'LinkUI'
      ],
      link: {
        linkToolTipIds: {
          tooltip: 'linkToolTipContainer',
          preview: 'linkPreviewContainer',
          removeLinkBtn: 'removeLinkBtn',
          editLinkBtn: 'editLinkBtn'
        },
        addLinkToolTipIds: {
          tooltip: 'AddLinkToolTipContainer',
          addLinkBtn: 'addLinkBtn'
        },
        addTargetToExternalLinks: false,
        internalLinkClicked: CKEditorUtils.onInternalLinkClicked,
        editLinkClicked: CKEditorUtils.onEditLinkClicked,
        decorators: {
          isExternal: {
            mode: 'automatic',
            callback: (url: string): boolean =>
              url && (url.startsWith('http://') || url.startsWith('https://')),
            classes: [
              'underline',
              'text-accent-purple',
              'cursor-pointer',
              'hover:bg-accent-purple-20'
            ],
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          },
          isInternal: {
            mode: 'automatic',
            callback: (url: string): boolean => {
              return url && !isNaN(parseInt(url));
            },
            classes: [
              'underline',
              'text-accent-purple',
              'cursor-pointer',
              'hover:bg-accent-purple-20'
            ],
            attributes: {
              target: '_self',
              isInternal: true,
              rel: 'noopener noreferrer'
            }
          }
        }
      },
      pageLayout: {
        delay: 200,
        getIsEnabled: (editor) => PageLayoutHandler.isPageLayoutEnabled(editor),
        getPageSize: (editor) => PageLayoutHandler.getPageSize(editor),
        getPageIndex: (editor) => PageLayoutHandler.getPageIndex(editor),
        getPagination: (editor) => PageLayoutHandler.splitContent(editor),
        paragraphsWhiteSpace: () => PageLayoutHandler.paragraphsWhiteSpace(),
        canRemovePage: (editor, pageIndex) =>
          PageLayoutHandler.canRemovePage(editor, pageIndex),
        onPageAdded: (editor, pageIndex) =>
          PageLayoutHandler.onPageAdded(editor, pageIndex),
        onPageRemoved: (editor, pageIndex) =>
          PageLayoutHandler.onPageRemoved(editor, pageIndex),
        onPageJumped: (editor, pageIndex) =>
          PageLayoutHandler.onPageJumped(editor, pageIndex)
      },
      table: {
        // Disabled for now (custom table colors plugin)
        // extendedTable: () => {
        //   const currrentDocument = Vue.$globalStore.state.document.document;
        //   const config = merge(
        //     {
        //       color1: 'initial',
        //       color2: 'initial',
        //       header: 'initial',
        //     },
        //     currrentDocument?.tableSwatch?.colors
        //   );
        //   return config;
        // },
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'tableCellProperties',
          'tableProperties'
          // Removed for now to avoid confusion
          // 'removeTableBorders',
        ],
        defaultBorder: {
          color: '#bfbfbf',
          style: 'solid',
          width: '1pt'
        }
      },
      toolbar: {
        onDropdownOpen: CKEditorUtils.onDropdownOpen,
        items: [
          'fontFamily',
          'fontSize',
          '|',
          'specialCharacters',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          'insertPageBreak',
          '-',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'lineHeight',
          'customIndentBlock',
          'customOutdentBlock',
          'insertTable',
          'link'
        ]
      },
      defaultFormatting: {
        font: this.selectedStepsDesignSet?.defaultFont
      }
    };

    const mergedConfig = ConfigurationBuilder.configure(
      BasicEditorConfig.getConfig(),
      config
    );
    return mergedConfig;
  }
}

let instance = new PageLayoutEditorConfig();
export default instance;
