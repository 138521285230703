import {
  Fill,
  IInputModeContext,
  INode,
  IRenderContext,
  NodeStyleBase,
  Point,
  Stroke,
  SvgVisual,
  Visual
} from 'yfiles';
import { DividingLineType } from '@/api/models';

const RenderCacheKey = 'RenderCache';
const rendererMappings: any = {
  [DividingLineType.Horizontal]: {
    renderer: horizontalLineRenderer
  },
  [DividingLineType.Vertical]: {
    renderer: verticalLineRenderer
  }
};

interface IDividingLineNodeStyleOptions {
  type: DividingLineType;
  stroke?: Stroke;
}

interface IDividingLineTypeRendererOptions {
  style: DividingLineNodeStyle;
  context: IRenderContext;
  node: INode;
  oldVisual?: SvgVisual;
}
const englargedHitRadius = 10;
export default class DividingLineNodeStyle extends NodeStyleBase {
  private static defaultOptions: IDividingLineNodeStyleOptions = {
    type: DividingLineType.Horizontal,
    stroke: new Stroke(Fill.BLACK, 1)
  };
  /**
   *
   */
  constructor(private options: IDividingLineNodeStyleOptions) {
    super();

    this.options = Object.assign(
      {},
      DividingLineNodeStyle.defaultOptions,
      options
    );
  }

  public get type(): DividingLineType {
    return this.options.type;
  }

  public get stroke(): Stroke {
    return this.options.stroke;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    const renderer: (options: IDividingLineTypeRendererOptions) => SvgVisual =
      rendererMappings[this.type].renderer;

    const visual = renderer({
      context: context,
      node: node,
      style: this,
      oldVisual: null
    });
    this.updateRenderCache(visual, node);
    return visual;
  }
  updateVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode
  ): Visual {
    const renderer: (options: IDividingLineTypeRendererOptions) => SvgVisual =
      rendererMappings[this.type].renderer;

    const visual = renderer({
      context: context,
      node: node,
      style: this,
      oldVisual: oldVisual
    });
    this.updateRenderCache(oldVisual, node);

    return visual;
  }

  protected isHit(
    context: IInputModeContext,
    location: Point,
    node: INode
  ): boolean {
    return node.layout
      .toRect()
      .getEnlarged(englargedHitRadius)
      .contains(location);
  }

  private updateRenderCache(visual: SvgVisual, node: INode): void {
    visual[RenderCacheKey] = {
      layout: {
        x: node.layout.x,
        y: node.layout.y,
        width: node.layout.width,
        height: node.layout.height
      }
    };
  }

  clone(): any {
    return new DividingLineNodeStyle({
      type: this.options.type,
      stroke: this.stroke.clone()
    });
  }
}

function horizontalLineRenderer(
  options: IDividingLineTypeRendererOptions
): SvgVisual {
  const node = options.node;
  const layout = node.layout;
  let oldVisual = options.oldVisual;
  const svgElement =
    oldVisual?.svgElement ??
    document.createElementNS('http://www.w3.org/2000/svg', 'path');
  if (!oldVisual) {
    options.style.stroke.applyTo(svgElement, options.context);
  }
  svgElement.setAttribute(
    'd',
    `M${layout.x} ${layout.center.y} L${layout.maxX} ${layout.center.y}Z`
  );

  if (oldVisual == null) {
    oldVisual = new SvgVisual(svgElement);
  }
  return oldVisual;
}

function verticalLineRenderer(
  options: IDividingLineTypeRendererOptions
): SvgVisual {
  const node = options.node;
  const layout = node.layout;
  let oldVisual = options.oldVisual;
  const svgElement =
    oldVisual?.svgElement ??
    document.createElementNS('http://www.w3.org/2000/svg', 'path');

  if (!oldVisual) {
    options.style.stroke.applyTo(svgElement, options.context);
  }
  svgElement.setAttribute(
    'd',
    `M${layout.center.x} ${layout.y} L${layout.center.x} ${layout.maxY}Z`
  );

  if (oldVisual == null) {
    oldVisual = new SvgVisual(svgElement);
  }
  return oldVisual;
}
