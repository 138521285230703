import {
  DataExportDataPropertyDto,
  DataExportEdgeDto,
  FileAttachmentDto
} from '@/api/models';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import { IEdge } from 'yfiles';
import { CorporateEdgeRelationshipProvider } from '../../corporate/CorporateEdgeRelationshipProvider';
import FileAttachmentDataExporter from './FileAttachmentDataExporter';
import { extractTextWithLinebreaks } from '@/core/utils/html.utils';

export default class EdgeDataExporter {
  public static getData(
    edge: IEdge,
    dataProperties: DataExportDataPropertyDto[],
    attachments: FileAttachmentDto[]
  ): DataExportEdgeDto {
    const dto = new DataExportEdgeDto(
      edge.tag?.uuid,
      edge.sourceNode?.tag?.uuid,
      edge.targetNode?.tag?.uuid
    );
    dto.text =
      CorporateEdgeRelationshipProvider.getRelationshipDisplayString(edge);
    dto.symbol = ElementSvgRenderUtils.createIconFromEdge(edge);
    dto.properties = dataProperties;
    dto.name = edge.tag?.name;
    dto.label = extractTextWithLinebreaks(edge.labels.firstOrDefault()?.text);
    dto.attachments = [];
    for (let attachment of attachments) {
      if (
        !attachment.dataPropertyDefinitionId &&
        !attachment.dataPropertyUuid &&
        !attachment.dataPropertyValue
      ) {
        const attachmentDto = FileAttachmentDataExporter.getData(attachment);
        dto.attachments.push(attachmentDto);
      }
    }
    return dto;
  }
}
