class DocumentCounts {
    constructor(public totalCount: number, public stepsCount: number, public structureDiagramCount: number) {}
}
class DocumentActivity {
    constructor(public created ? : string, public lastCreated ? : string, public lastViewed ? : string, public lastUpdated ? : string, public lastRestored ? : string, public lastPrinted ? : string, public lastDeleted ? : string, public lastExportedPng ? : string, public lastExportedPpt ? : string, public lastExportedPdf ? : string, public lastExportedSvg ? : string, public lastExportedVisio ? : string, public lastExportedXls ? : string) {}
}
class CurrentAccount {
    constructor(public documentCounts ? : DocumentCounts, public documentActivity ? : DocumentActivity) {}
}
class IsTenantAvailableInput {
    constructor(public tenancyName: string) {}
}
enum TenantAvailabilityState {
    Available = 1, InActive = 2, NotFound = 3
}
class IsTenantAvailableOutput {
    constructor(public state ? : TenantAvailabilityState, public tenantId ? : number, public serverRootAddress ? : string) {}
}
class ResolveTenantIdInput {
    constructor(public c ? : string) {}
}
class RegisterInput {
    constructor(public name: string, public surname: string, public userName: string, public emailAddress: string, public password: string, public captchaResponse ? : string) {}
}
class RegisterOutput {
    constructor(public canLogin: boolean) {}
}
class SendPasswordResetCodeInput {
    constructor(public emailAddress: string, public link ? : string) {}
}
class ResetPasswordInput {
    constructor(public userId: number, public resetCode ? : string, public password ? : string, public returnUrl ? : string, public singleSignIn ? : string, public c ? : string) {}
}
class ResetPasswordOutput {
    constructor(public canLogin: boolean, public invalidResetCode: boolean, public userName ? : string) {}
}
class SendEmailActivationLinkInput {
    constructor(public emailAddress: string) {}
}
class ActivateEmailInput {
    constructor(public userId: number, public confirmationCode ? : string, public c ? : string) {}
}
class ImpersonateInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class ImpersonateOutput {
    constructor(public impersonationToken ? : string, public tenancyName ? : string) {}
}
class DelegatedImpersonateInput {
    constructor(public userDelegationId: number) {}
}
class SwitchToLinkedAccountInput {
    constructor(public targetUserId: number, public targetTenantId ? : number) {}
}
class SwitchToLinkedAccountOutput {
    constructor(public switchAccountToken ? : string, public tenancyName ? : string) {}
}
class AuditLogListDto {
    constructor(public executionTime: string, public executionDuration: number, public id: number, public userId ? : number, public userName ? : string, public impersonatorTenantId ? : number, public impersonatorUserId ? : number, public serviceName ? : string, public methodName ? : string, public parameters ? : string, public clientIpAddress ? : string, public clientName ? : string, public browserInfo ? : string, public exception ? : string, public customData ? : string) {}
}
class PagedResultDtoOfAuditLogListDto {
    constructor(public totalCount: number, public items ? : AuditLogListDto[]) {}
}
class FileDto {
    constructor(public fileName: string, public fileToken: string, public fileType ? : string) {}
}
class NameValueDto {
    constructor(public name ? : string, public value ? : string) {}
}
enum EntityChangeType {
    Created = 0, Updated = 1, Deleted = 2
}
class EntityChangeListDto {
    constructor(public changeTime: string, public entityChangeSetId: number, public id: number, public userId ? : number, public userName ? : string, public entityTypeFullName ? : string, public changeType ? : EntityChangeType, public changeTypeName ? : string) {}
}
class PagedResultDtoOfEntityChangeListDto {
    constructor(public totalCount: number, public items ? : EntityChangeListDto[]) {}
}
class EntityPropertyChangeDto {
    constructor(public entityChangeId: number, public id: number, public newValue ? : string, public originalValue ? : string, public propertyName ? : string, public propertyTypeFullName ? : string, public tenantId ? : number) {}
}
class CacheDto {
    constructor(public name ? : string) {}
}
class ListResultDtoOfCacheDto {
    constructor(public items ? : CacheDto[]) {}
}
class EntityDtoOfString {
    constructor(public id ? : string) {}
}
class FindUsersInput {
    constructor(public excludeCurrentUser: boolean, public maxResultCount: number, public skipCount: number, public tenantId ? : number, public filter ? : string) {}
}
class PagedResultDtoOfNameValueDto {
    constructor(public totalCount: number, public items ? : NameValueDto[]) {}
}
class GetDefaultEditionNameOutput {
    constructor(public name ? : string) {}
}
class DataPropertyDto {
    constructor(public index: number, public id: number, public uuid ? : string, public value ? : any, public dataPropertyDefinitionId ? : number, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public personId ? : number, public isHidden ? : boolean, public definitionItemGroupId ? : number) {}
}
class GetDataPropertyForViewDto {
    constructor(public dataProperty ? : DataPropertyDto, public diagramName ? : string) {}
}
class PagedResultDtoOfGetDataPropertyForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyForViewDto[]) {}
}
class CreateOrEditDataPropertyDto {
    constructor(public index: number, public uuid ? : string, public value ? : any, public dataPropertyDefinitionId ? : number, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public isHidden ? : boolean, public definitionItemGroupId ? : number, public id ? : number) {}
}
enum DataPropertyType {
    Text = 0, Number = 1, Date = 2, Dropdown = 3, MultiSelect = 4, URL = 5, Boolean = 6, People = 7, Tags = 8
}
enum DataPropertyValueScope {
    System = 0, Diagram = 1, Decorator = 2, EntityType = 3, People = 4
}
class DataPropertyDefinitionItemDto {
    constructor(public itemValue ? : string, public isHidden ? : boolean, public itemInitials ? : string, public dataPropertyDefinitionId ? : number, public imageData ? : string, public parentItemId ? : number, public aliases ? : string, public color ? : string, public id ? : number) {}
}
class DataPropertyDefinitionDto {
    constructor(public min ? : number, public max ? : number, public controlType ? : DataPropertyType, public valueScope ? : DataPropertyValueScope, public label ? : string, public dataPropertyDefinitionItems ? : DataPropertyDefinitionItemDto[], public documentId ? : number, public hint ? : string, public childDefinitions ? : DataPropertyDefinitionDto[], public entityTypeIds ? : number[], public index ? : number, public id ? : number) {}
}
class GetDataPropertyDefinitionsByDocumentIdOutput {
    constructor(public lastModificationTime ? : string, public definitions ? : DataPropertyDefinitionDto[]) {}
}
class GetDataPropertyDefinitionForViewDto {
    constructor(public dataPropertyDefinition ? : DataPropertyDefinitionDto) {}
}
class CreateOrEditDataPropertyDefinitionDto {
    constructor(public key ? : string, public dataPropertyDefinitionItems ? : DataPropertyDefinitionItemDto[], public min ? : number, public max ? : number, public controlType ? : DataPropertyType, public valueScope ? : DataPropertyValueScope, public label ? : string, public documentId ? : number, public hint ? : string, public childDefinitions ? : CreateOrEditDataPropertyDefinitionDto[], public entityTypeIds ? : number[], public index ? : number, public id ? : number) {}
}
class GetDataPropertyDefinitionForEditOutput {
    constructor(public dataPropertyDefinition ? : CreateOrEditDataPropertyDefinitionDto) {}
}
enum DataPropertyTagContext {
    Property = 0, Value = 1, Attachment = 2
}
class DataPropertyTagDto {
    constructor(public context ? : DataPropertyTagContext, public name ? : string, public color ? : string, public diagramNodeId ? : number, public diagramEdgeId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public dataPropertyDefinitionId ? : number, public fileAttachmentId ? : number, public id ? : number) {}
}
class GetDataPropertyTagForViewDto {
    constructor(public dataPropertyTag ? : DataPropertyTagDto) {}
}
class PagedResultDtoOfGetDataPropertyTagForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyTagForViewDto[]) {}
}
class DataPropertyValueDto {
    constructor(public id: number, public dataPropertyItemId ? : number) {}
}
class GetDataPropertyValueForViewDto {
    constructor(public dataPropertyValue ? : DataPropertyValueDto, public dataPropertyItemItemValue ? : string) {}
}
class PagedResultDtoOfGetDataPropertyValueForViewDto {
    constructor(public totalCount: number, public items ? : GetDataPropertyValueForViewDto[]) {}
}
class CreateOrEditDataPropertyValueDto {
    constructor(public dataPropertyItemId ? : number, public id ? : number) {}
}
class GetDataPropertyValueForEditOutput {
    constructor(public dataPropertyValue ? : CreateOrEditDataPropertyValueDto, public dataPropertyItemItemValue ? : string) {}
}
class DataPropertyValueDataPropertyItemLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDataPropertyValueDataPropertyItemLookupTableDto {
    constructor(public totalCount: number, public items ? : DataPropertyValueDataPropertyItemLookupTableDto[]) {}
}
class InsetsDto {
    constructor(public top: number, public left: number, public bottom: number, public right: number) {}
}
enum PageStyleBoundariesPresetType {
    None = 0, Normal = 1, Narrow = 2, Moderate = 3, Wide = 4, Custom = 5
}
class PageStylePaddingDto {
    constructor(public diagramPadding ? : InsetsDto, public htmlContentPadding ? : InsetsDto, public diagramPaddingPreset ? : PageStyleBoundariesPresetType, public htmlContentPaddingPreset ? : PageStyleBoundariesPresetType) {}
}
enum DocumentPageType {
    Diagram = 0, Split = 1, Content = 2, Timeline = 3
}
enum DocumentPageContentType {
    None = 0, Html = 1, Layout = 2, MasterLegend = 3
}
enum DocumentPageLayoutType {
    None = 0, Cover = 1, Filler = 2, Closing = 3
}
class PageStylePaddingLegacyDto {
    constructor(public contentColumns: number, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public layoutType ? : DocumentPageLayoutType, public diagramPadding ? : InsetsDto, public htmlContentPadding ? : InsetsDto, public diagramPaddingPreset ? : PageStyleBoundariesPresetType, public htmlContentPaddingPreset ? : PageStyleBoundariesPresetType) {}
}
enum FillType {
    SolidColor = 0, LinearGradient = 1
}
class IFillDto {
    constructor(public type ? : FillType) {}
}
enum DashStyleType {
    Solid = 0, Dash = 1, Dot = 2, DashDot = 3, DashDotDot = 4
}
class DashStyleDto {
    constructor(public dashes ? : number[], public offset ? : number, public type ? : DashStyleType) {}
}
class StrokeDto {
    constructor(public thickness: number, public fill ? : IFillDto, public dashStyle ? : DashStyleDto) {}
}
class PageStyleDto {
    constructor(public width: number, public height: number, public splitRatio: number, public htmlContentColumnGap: number, public fullMargins ? : InsetsDto, public innerMargins ? : InsetsDto, public padding ? : PageStylePaddingDto, public paddingLegacy ? : PageStylePaddingLegacyDto[], public splitDividerStyle ? : StrokeDto, public htmlContentDividerStyle ? : StrokeDto, public innerMarginsPreset ? : PageStyleBoundariesPresetType, public fullMarginsPreset ? : PageStyleBoundariesPresetType) {}
}
class HeaderFooterStyleDto {
    constructor(public show: boolean, public height ? : number, public backgroundColor ? : string, public borderColor ? : string, public borderWidth ? : number, public borderStyle ? : string) {}
}
class LayoutDto {
    constructor(public x: number, public y: number, public width: number, public height: number) {}
}
enum NodeVisualType {
    Shape = 0, Image = 1, Composite = 2, Group = 3, DividingLine = 4, TextBox = 5, JigsawPathShape = 6, None = 7
}
class INodeStyleDto {
    constructor(public visualType ? : NodeVisualType) {}
}
class FileAttachmentDto {
    constructor(public fileId: string, public id: number, public filename ? : string, public mimeType ? : string, public description ? : string, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public timelineId ? : number, public personId ? : number, public timelineEventId ? : number, public dataPropertyDefinitionId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public path ? : string, public fileData ? : string) {}
}
class PowerPointDiagramNodeDto {
    constructor(public isGroupNode: boolean, public children ? : PowerPointDiagramNodeDto[], public svgPath ? : string, public uuid ? : string, public originalUuid ? : string, public data ? : any, public layout ? : LayoutDto, public style ? : INodeStyleDto, public groupUuid ? : string, public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
class FontDto {
    constructor(public fontSize: number, public fontFamily ? : string, public fontStyle ? : string, public fontWeight ? : string, public textDecoration ? : string, public color ? : string, public backgroundColor ? : string) {}
}
class PointDto {
    constructor(public x: number, public y: number) {}
}
class LabelStyleDto {
    constructor(public font ? : FontDto, public fill ? : IFillDto, public offsets ? : PointDto) {}
}
class ArrowStyleDto {
    constructor(public scale: number, public type ? : string, public fill ? : IFillDto) {}
}
enum EdgeVisualType {
    Curved = 0, Straight = 1, Elbow = 2, Arc = 3
}
class EdgeStyleDto {
    constructor(public bridge: boolean, public labelStyle ? : LabelStyleDto, public stroke ? : StrokeDto, public sourceArrow ? : ArrowStyleDto, public targetArrow ? : ArrowStyleDto, public visualType ? : EdgeVisualType, public height ? : number) {}
}
enum PortDirection {
    Unknown = 0, South = 1, North = 2, West = 4, East = 8
}
class EdgePortDto {
    constructor(public x: number, public y: number, public portDirection ? : PortDirection) {}
}
enum PathCommandTypes {
    MOVE_TO = 0, LINE_TO = 1, QUAD_TO = 2, CUBIC_TO = 3, CLOSE = 4
}
class PathCommand {
    constructor(public type ? : PathCommandTypes, public point ? : PointDto) {}
}
class PowerPointDiagramEdgeDto {
    constructor(public svgPath ? : string, public layout ? : LayoutDto, public labelLayout ? : LayoutDto, public uuid ? : string, public originalUuid ? : string, public direction ? : string, public data ? : any, public style ? : EdgeStyleDto, public bends ? : any, public sourcePort ? : EdgePortDto, public targetPort ? : EdgePortDto, public sourceNodeUuid ? : string, public targetNodeUuid ? : string, public pathCommands ? : PathCommand[], public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum DiagramPosition {
    Left = 0, Right = 1
}
enum TCellContentType {
    String = 0, PngImage = 1
}
class TCellContentDto {
    constructor(public contentType ? : TCellContentType, public data ? : any, public options ? : any) {}
}
class TCellDto {
    constructor(public content ? : TCellContentDto, public horizontalMerge ? : boolean, public gridSpan ? : number) {}
}
class TRowOptionsDto {
    constructor(public height: number, public isHeaderRow: boolean) {}
}
class TRowDto {
    constructor(public cells ? : TCellDto[], public options ? : TRowOptionsDto) {}
}
class TColumnOptionsDto {
    constructor(public width: number, public insets ? : InsetsDto) {}
}
class TColumnDto {
    constructor(public options ? : TColumnOptionsDto) {}
}
enum PageElementPosition {
    Unset = 0, TopLeft = 1, TopRight = 2, BottomLeft = 3, BottomRight = 4, Top = 5, Right = 6, Bottom = 7, Left = 8, Free = 9
}
class TDataDto {
    constructor(public isLegend: boolean, public rows ? : TRowDto[], public columns ? : TColumnDto[], public location ? : PointDto, public position ? : PageElementPosition) {}
}
enum LayoutItemRotation {
    Default = 0, Left = 1
}
class LayoutItemDto {
    constructor(public padding: number, public zIndex: number, public resized: boolean, public hidden: boolean, public type ? : string, public layout ? : LayoutDto, public rotation ? : LayoutItemRotation, public imageSrc ? : string, public html ? : string) {}
}
class LayoutDefinitionDto {
    constructor(public width: number, public height: number, public items ? : LayoutItemDto[]) {}
}
class NodeGroupDto {
    constructor(public strokeWidth: number, public svgPaths ? : string[], public fillColor ? : string, public strokeColor ? : string, public dashType ? : DashStyleType) {}
}
class PowerPointExportSlideDto {
    constructor(public slideWidth: number, public slideHeight: number, public htmlContentColumns: number, public htmlContentColumnGap: number, public showHeader: boolean, public showFooter: boolean, public showLogo: boolean, public showLegend: boolean, public showDivider: boolean, public showTitle: boolean, public slideNumber: number, public forceReservePageTitleSpace: boolean, public documentPageType ? : DocumentPageType, public documentPageContentType ? : DocumentPageContentType, public diagramInsets ? : InsetsDto, public pageMargins ? : InsetsDto, public nodes ? : PowerPointDiagramNodeDto[], public edges ? : PowerPointDiagramEdgeDto[], public htmlContent ? : string, public htmlContentPadding ? : InsetsDto, public diagramPadding ? : InsetsDto, public diagramPosition ? : DiagramPosition, public tables ? : TDataDto[], public titleLayout ? : LayoutDefinitionDto, public headerLayout ? : LayoutDefinitionDto, public footerLayout ? : LayoutDefinitionDto, public backgroundLayout ? : LayoutDefinitionDto, public bodyLayout ? : LayoutDefinitionDto, public groups ? : NodeGroupDto[]) {}
}
class PowerPointExportDto {
    constructor(public pageStyle ? : PageStyleDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public slides ? : PowerPointExportSlideDto[], public logoFileAttachmentId ? : string, public logoPosition ? : PageElementPosition, public defaultDiagramFont ? : FontDto, public defaultContentFont ? : FontDto) {}
}
class PowerPointFileDto {
    constructor(public base64String ? : string, public metaData ? : any) {}
}
class DataExportFileAttachmentDto {
    constructor(public id: number, public fileId ? : string, public fileName ? : string, public mimeType ? : string) {}
}
class DataExportDataPropertyDto {
    constructor(public id: number, public text ? : string, public definition ? : string, public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportNodeDto {
    constructor(public uuid: string, public annotationType: number, public entityTypeId: number, public text ? : string, public symbol ? : string, public name ? : string, public properties ? : DataExportDataPropertyDto[], public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportEdgeDto {
    constructor(public uuid: string, public sourceNodeUuid: string, public targetNodeUuid: string, public name ? : string, public text ? : string, public symbol ? : string, public label ? : string, public properties ? : DataExportDataPropertyDto[], public attachments ? : DataExportFileAttachmentDto[]) {}
}
class DataExportDiagramDto {
    constructor(public diagramId: number, public includeDataProperties: boolean, public includeAttachments: boolean, public diagramName ? : string, public nodes ? : DataExportNodeDto[], public edges ? : DataExportEdgeDto[]) {}
}
class DataExportDocumentDto {
    constructor(public documentId: number, public documentName ? : string, public documentFile ? : string, public documentFileName ? : string, public documentMimeType ? : string, public fileExtension ? : string, public diagrams ? : DataExportDiagramDto[]) {}
}
class DiagramImportRowValueDto {
    constructor(public id: number, public data ? : string, public diagramImportRowId ? : number, public diagramTemplateId ? : number) {}
}
class DiagramImportRowDto {
    constructor(public processed: string, public id: number, public name ? : string, public diagramImportId ? : number, public values ? : DiagramImportRowValueDto[]) {}
}
class GetDiagramImportRowForViewDto {
    constructor(public diagramImportRow ? : DiagramImportRowDto, public diagramImportName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportRowForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportRowForViewDto[]) {}
}
class CreateOrEditDiagramImportRowDto {
    constructor(public processed: string, public name ? : string, public diagramImportId ? : number, public id ? : number) {}
}
class GetDiagramImportRowForEditOutput {
    constructor(public diagramImportRow ? : CreateOrEditDiagramImportRowDto, public diagramImportName ? : string) {}
}
class DiagramImportRowDiagramImportLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowDiagramImportLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowDiagramImportLookupTableDto[]) {}
}
class GetDiagramImportRowValueForViewDto {
    constructor(public diagramImportRowValue ? : DiagramImportRowValueDto, public diagramImportRowName ? : string, public diagramTemplateName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportRowValueForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportRowValueForViewDto[]) {}
}
class CreateOrEditDiagramImportRowValueDto {
    constructor(public data ? : string, public diagramImportRowId ? : number, public diagramTemplateId ? : number, public id ? : number) {}
}
class GetDiagramImportRowValueForEditOutput {
    constructor(public diagramImportRowValue ? : CreateOrEditDiagramImportRowValueDto, public diagramImportRowName ? : string, public diagramTemplateName ? : string) {}
}
class DiagramImportRowValueDiagramImportRowLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowValueDiagramImportRowLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowValueDiagramImportRowLookupTableDto[]) {}
}
class DiagramImportRowValueDiagramTemplateLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportRowValueDiagramTemplateLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportRowValueDiagramTemplateLookupTableDto[]) {}
}
class DiagramImportDto {
    constructor(public exportReady: boolean, public generateDiagram: boolean, public rowCount: number, public creationTime: string, public id: number, public diagramId ? : number, public name ? : string, public fileImportObjectId ? : string, public fileExportObjectId ? : string, public exportType ? : string) {}
}
class GetDiagramImportForViewDto {
    constructor(public diagramImport ? : DiagramImportDto, public diagramName ? : string) {}
}
class PagedResultDtoOfGetDiagramImportForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramImportForViewDto[]) {}
}
class CreateOrEditDiagramImportDto {
    constructor(public diagramId ? : number, public id ? : number) {}
}
class GetDiagramImportForEditOutput {
    constructor(public diagramImport ? : CreateOrEditDiagramImportDto, public diagramName ? : string) {}
}
class DiagramImportDiagramLookupTableDto {
    constructor(public id: number, public displayName ? : string) {}
}
class PagedResultDtoOfDiagramImportDiagramLookupTableDto {
    constructor(public totalCount: number, public items ? : DiagramImportDiagramLookupTableDto[]) {}
}
class SizeDto {
    constructor(public width: number, public height: number) {}
}
class DiagramLayoutDto {
    constructor(public location ? : PointDto, public size ? : SizeDto, public insets ? : InsetsDto, public offsets ? : PointDto) {}
}
class DiagramViewDto {
    constructor(public zoom: number, public location ? : PointDto, public data ? : any) {}
}
enum DocumentView {
    Web = 0, Print = 1, PrintPreview = 2
}
class DiagramViewsDto {
    constructor(public web ? : DiagramViewDto, public print ? : DiagramViewDto, public printPreview ? : DiagramViewDto, public selectedView ? : DocumentView) {}
}
class DiagramGroupSettingsDto {
    constructor(public margin: number, public glueExtent: number, public minimumBridgeSize: number) {}
}
class DiagramNodeDto {
    constructor(public isGroupNode: boolean, public uuid ? : string, public originalUuid ? : string, public data ? : any, public layout ? : LayoutDto, public style ? : INodeStyleDto, public groupUuid ? : string, public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
class DiagramEdgeDto {
    constructor(public uuid ? : string, public originalUuid ? : string, public direction ? : string, public data ? : any, public style ? : EdgeStyleDto, public bends ? : any, public sourcePort ? : EdgePortDto, public targetPort ? : EdgePortDto, public sourceNodeUuid ? : string, public targetNodeUuid ? : string, public pathCommands ? : PathCommand[], public label ? : string, public isIncluded ? : boolean, public dataProperties ? : DataPropertyDto[], public dataPropertyTags ? : DataPropertyTagDto[], public elementBehaviourId ? : number, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum DiagramTemplateType {
    Text = 0, Table = 1, Header = 2, Footer = 3, FileName = 4
}
class DiagramTemplateDto {
    constructor(public isConditional: boolean, public diagramId: number, public id: number, public name ? : string, public elementUuid ? : string, public templateType ? : DiagramTemplateType) {}
}
class QuickStartChildMappingDto {
    constructor(public parentId: number, public childId: number) {}
}
enum QuickStartState {
    Initial = 0, InProgress = 1, Complete = 2
}
class QuickStartStateDataDto {
    constructor(public maxId ? : number, public below ? : QuickStartChildMappingDto[], public above ? : QuickStartChildMappingDto[], public left ? : QuickStartChildMappingDto[], public right ? : QuickStartChildMappingDto[], public state ? : QuickStartState) {}
}
enum FlipbookState {
    Disabled = 0, Enabled = 1, DisabledByUser = 2
}
class DiagramDto {
    constructor(public isTemplate: boolean, public id: number, public name ? : string, public description ? : string, public legend ? : any, public layout ? : DiagramLayoutDto, public diagramViews ? : DiagramViewsDto, public groupSettings ? : DiagramGroupSettingsDto, public nodes ? : DiagramNodeDto[], public edges ? : DiagramEdgeDto[], public templates ? : DiagramTemplateDto[], public attachments ? : FileAttachmentDto[], public dataProperties ? : DataPropertyDto[], public quickStartStateData ? : QuickStartStateDataDto, public flipbookState ? : FlipbookState) {}
}
class GetDiagramForViewDto {
    constructor(public diagram ? : DiagramDto) {}
}
class CloneDiagramDto {
    constructor(public documentId: number, public id: number, public diagram ? : DiagramDto) {}
}
class AllConnection {
    constructor(public fig0_id: number, public fig1_id: number, public line_id ? : number[]) {}
}
class SemanticConnection {
    constructor(public fig0_id: number, public fig1_id: number, public line_id ? : number[]) {}
}
class NodeConnection {
    constructor(public fig_0: number, public fig_1: number) {}
}
class Connections {
    constructor(public all_connections ? : AllConnection[], public semantic_connections ? : SemanticConnection[], public node_connections ? : NodeConnection[]) {}
}
class Cv {
    constructor(public num_classes: number, public boxes ? : [][], public labels ? : string[], public keypoints ? : [][], public scores ? : null[], public ids ? : number[], public connections ? : Connections) {}
}
class Ocr {
    constructor(public labels ? : string[], public boxes ? : [][], public confidences ? : null[]) {}
}
class AIOutputDto {
    constructor(public cv ? : Cv, public ocr ? : Ocr) {}
}
class FileBase64Dto {
    constructor(public fileName ? : string, public mimeType ? : string, public base64Data ? : string) {}
}
class GetDiagramTemplateForViewDto {
    constructor(public diagramTemplate ? : DiagramTemplateDto) {}
}
class PagedResultDtoOfGetDiagramTemplateForViewDto {
    constructor(public totalCount: number, public items ? : GetDiagramTemplateForViewDto[]) {}
}
class CreateOrEditDiagramTemplateDto {
    constructor(public isConditional: boolean, public name ? : string, public diagramId ? : number, public elementUuid ? : string, public templateType ? : DiagramTemplateType, public id ? : number) {}
}
class GetDiagramTemplateForEditOutput {
    constructor(public diagramTemplate ? : CreateOrEditDiagramTemplateDto) {}
}
class UserDto {
    constructor(public id: number, public name ? : string, public surname ? : string, public emailAddress ? : string) {}
}
enum DocumentAccessLevel {
    View = 0, Edit = 1, EditShare = 2, None = -1
}
class DocumentAccessDto {
    constructor(public documentId: number, public userId: number, public isAccessed: boolean, public user ? : UserDto, public accessLevel ? : DocumentAccessLevel) {}
}
class DocumentAccessRequestDto {
    constructor(public documentId: number, public userId: number, public user ? : UserDto) {}
}
class PendingAccessRequestDto {
    constructor(public documentId: number, public userId: number, public creationTime: string, public documentLastModificationTime: string, public documentName ? : string, public documentDefaultAccessLevel ? : DocumentAccessLevel, public user ? : UserDto, public documentThumbFileAttachmentId ? : number, public documentThumbnailPath ? : string) {}
}
class GetPendingAccessRequestsOutputDto {
    constructor(public pendingAccessRequests ? : PendingAccessRequestDto[], public mostRecentRequest ? : string) {}
}
class RequestDocumentAccessDto {
    constructor(public documentId: number, public message ? : string) {}
}
class RequestDocumentAccessOutputDto {
    constructor(public documentId: number, public creationTime: string, public documentAccessLevel ? : DocumentAccessLevel, public documentName ? : string, public documentOwner ? : UserDto) {}
}
class GrantDocumentAccessDto {
    constructor(public documentId: number, public userId: number, public accessLevel ? : DocumentAccessLevel, public message ? : string) {}
}
class ChangeDocumentAccessDto {
    constructor(public documentId: number, public userId: number) {}
}
class ChangeDocumentIsPublicDto {
    constructor(public documentId: number, public isPublic: boolean) {}
}
class ChangeDocumentDefaultAccessLevelDto {
    constructor(public documentId: number, public accessLevel ? : DocumentAccessLevel) {}
}
class TimelineDto {
    constructor(public isTemplate: boolean, public name ? : string, public description ? : string, public timelineJson ? : string, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum PageOrientation {
    Portrait = 0, Landscape = 1
}
class FontStyleDto {
    constructor(public isDefault: boolean, public cssElement ? : string, public model ? : string, public title ? : string, public style ? : FontDto) {}
}
class DocumentSubPageDto {
    constructor(public pageId: number, public subPageIndex: number, public titleHeight: number, public maxTitleHeight: number, public showTitle: boolean, public diagramId ? : number, public page ? : DocumentPageDto, public diagram ? : DiagramDto, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public titleLayout ? : string, public id ? : number) {}
}
class DocumentPageDto {
    constructor(public documentId: number, public order: number, public contentColumns: number, public titleHeight: number, public maxTitleHeight: number, public showTitle: boolean, public showLogo: boolean, public showLegend: boolean, public showHeader: boolean, public showFooter: boolean, public isPristine: boolean, public showPageNumber: boolean, public showDate: boolean, public showDivider: boolean, public diagramId ? : number, public name ? : string, public description ? : string, public diagram ? : DiagramDto, public timelineId ? : number, public timeline ? : TimelineDto, public content ? : string, public bodyLayout ? : string, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public titleLayout ? : string, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public layoutType ? : DocumentPageLayoutType, public orientation ? : PageOrientation, public diagramPosition ? : DiagramPosition, public filterDefinition ? : string, public stepsFontPresets ? : FontStyleDto[], public subPageRefs ? : DocumentSubPageDto[], public id ? : number) {}
}
class GetDocumentPageForViewDto {
    constructor(public documentPage ? : DocumentPageDto) {}
}
class DocumentFilterDto {
    constructor(public order: number, public isSystem: boolean, public name ? : string, public definition ? : string, public documentId ? : number, public id ? : number) {}
}
class CreateOrEditDocumentPageDto {
    constructor(public documentId: number, public order: number, public contentColumns: number, public showLogo: boolean, public showLegend: boolean, public titleHeight: number, public maxTitleHeight: number, public showTitle: boolean, public showHeader: boolean, public showFooter: boolean, public isPristine: boolean, public showPageNumber: boolean, public showDate: boolean, public showDivider: boolean, public name ? : string, public description ? : string, public content ? : string, public bodyLayout ? : string, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public titleLayout ? : string, public filters ? : DocumentFilterDto[], public diagramId ? : number, public diagram ? : DiagramDto, public timelineId ? : number, public timeline ? : TimelineDto, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public layoutType ? : DocumentPageLayoutType, public orientation ? : PageOrientation, public diagramPosition ? : DiagramPosition, public filterDefinition ? : string, public stepsFontPresets ? : FontStyleDto[], public subPageRefs ? : DocumentSubPageDto[], public id ? : number) {}
}
class CreateOrEditDocumentPageOutput {
    constructor(public pageId: number, public diagramId ? : number, public lastModificationTime ? : string) {}
}
class StepsPagePropertiesDto {
    constructor(public showHeader: boolean, public showFooter: boolean, public showLegend: boolean, public showLogo: boolean, public showDate: boolean, public showPageNumber: boolean, public order: number, public titleHeight: number, public maxTitleHeight: number, public showTitle: boolean, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public layoutType ? : DocumentPageLayoutType, public content ? : string, public bodyLayout ? : string, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public titleLayout ? : string, public stepsFontPresets ? : FontStyleDto[], public id ? : number) {}
}
enum SidebarCategory {
    Text = 0, Shapes = 1, Lines = 2, Images = 3, Themes = 4, Layout = 5, Pages = 6, CustomClipArt = 7, ManualCloseAction = 8
}
enum DocumentPaletteCategory {
    Elements = 0, ClipArt = 1, Annotate = 2, Logos = 3, Text = 4, Lines = 5, Images = 6, CustomClipArt = 7
}
class DocumentPaletteItemDto {
    constructor(public fileId: string, public fileAttachmentId: number, public id: number, public name ? : string, public sidebarCategory ? : SidebarCategory, public documentPaletteCategory ? : DocumentPaletteCategory, public subcategory ? : string, public path ? : string) {}
}
enum DocumentProfileHistoryReason {
    None = 0, Created = 1, Updated = 2, Viewed = 3, Deleted = 4, Exported = 5, Printed = 6, Restored = 7, Pdf = 8, Ppt = 9, Png = 10, Visio = 11, Svg = 12, Xls = 13
}
class CreateDocumentProfileHistoryReasonDto {
    constructor(public documentId: number, public reason ? : DocumentProfileHistoryReason) {}
}
class DocumentProfileHistoryDto {
    constructor(public documentId: number, public creationTime: string, public id: number, public name ? : string, public client ? : string, public matter ? : string, public reason ? : string, public user ? : string, public creatorUserId ? : number) {}
}
class PagedResultDtoOfDocumentProfileHistoryDto {
    constructor(public totalCount: number, public items ? : DocumentProfileHistoryDto[]) {}
}
enum AttachmentType {
    Logo = 0, LayoutImage = 1, TimelineImage = 2, PersonImage = 3
}
class DocumentAttachmentDto {
    constructor(public documentId: number, public fileAttachmentId: number, public document ? : DocumentDto, public fileAttachment ? : FileAttachmentDto, public attachmentType ? : AttachmentType, public id ? : number) {}
}
class NodeStyleOverrideDto {
    constructor(public fill ? : IFillDto, public stroke ? : StrokeDto) {}
}
class DataPropertyStyleDto {
    constructor(public dataPropertyDefinitionId: number, public value ? : any, public style ? : NodeStyleOverrideDto) {}
}
class QuickBuildSettingsDto {
    constructor(public showAdvancedLayoutSettings: boolean, public minimumLayerDistance: number, public minimumNodeDistance: number) {}
}
class DocumentDto {
    constructor(public lastSelectedThemeId: number, public isPublic: boolean, public isPristine: boolean, public hasSteps: boolean, public ownerUserId: number, public importProcessed: boolean, public autoSave: boolean, public hasTimelines: boolean, public usePresetProperties: boolean, public isDeleted: boolean, public creationTime: string, public id: number, public pages ? : DocumentPageDto[], public dataPropertyDefinitions ? : DataPropertyDefinitionDto[], public filters ? : DocumentFilterDto[], public thumbFileAttachment ? : FileAttachmentDto, public thumbFileAttachmentId ? : number, public pageDesignSetId ? : number, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public pdfFileId ? : string, public pdfData ? : string, public pageStyle ? : PageStyleDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public headerFooterDate ? : string, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachments ? : DocumentAttachmentDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public lockedByUser ? : UserDto, public lockedCreated ? : string, public defaultPageType ? : DocumentPageType, public defaultAccessLevel ? : DocumentAccessLevel, public access ? : DocumentAccessDto[], public accessRequests ? : DocumentAccessRequestDto[], public dataPropertyStyles ? : DataPropertyStyleDto[], public importId ? : number, public folderId ? : number, public flipbookState ? : FlipbookState, public externalFileId ? : string, public matter ? : string, public client ? : string, public quickBuildSettings ? : QuickBuildSettingsDto, public name ? : string, public description ? : string, public ownerUser ? : UserDto, public lastUpdatedBy ? : string, public deleterUserId ? : number, public deletionTime ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class DocumentProfileDocumentHistoryDto {
    constructor(public totalCount: number, public document ? : DocumentDto, public history ? : DocumentProfileHistoryDto[]) {}
}
class DocumentProfilingInputDto {
    constructor(public maxResultCount: number, public skipCount: number, public documentId ? : number, public client ? : string, public matter ? : string, public reason ? : DocumentProfileHistoryReason, public filter ? : string, public sorting ? : string) {}
}
class EditDocumentProfileDto {
    constructor(public documentId: number, public name: string, public client ? : string, public matter ? : string) {}
}
class EditDocumentAutoSaveDto {
    constructor(public documentId: number, public autoSave: boolean) {}
}
class EditDocumentUsePresetPropertiesDto {
    constructor(public documentId: number, public usePresetProperties: boolean) {}
}
class MarkAsFavouriteRequest {
    constructor(public itemId: number) {}
}
class DocumentSharingDetailsDto {
    constructor(public id: number, public isPublic: boolean, public ownerUserId: number, public name ? : string, public defaultAccessLevel ? : DocumentAccessLevel, public ownerUser ? : UserDto) {}
}
class DocumentPreviewDetailsDto {
    constructor(public id: number, public creationTime: string, public isDeleted: boolean, public name ? : string, public lastModificationTime ? : string, public deletionTime ? : string, public thumbnailPath ? : string) {}
}
class DocumentActivityBy {
    constructor(public user ? : DocumentActivity, public account ? : DocumentActivity) {}
}
enum EdgeLabelPosition {
    Center = 0, Left = 1, Right = 2
}
enum NodeLabelPosition {
    InteriorCenter = 0, InteriorBottom = 1, InteriorTop = 2, ExteriorBottom = 3, ExteriorTop = 4, ExteriorLeft = 5, ExteriorRight = 6
}
enum IndicatorsPosition {
    BottomRight = 0, BottomLeft = 1, BottomCenter = 2, TopLeft = 3, TopRight = 4, TopCenter = 5
}
enum ElementType {
    Node = 0, Edge = 1
}
enum RelationshipType {
    Ownership = 0, Cashflow = 1, Contract = 2, Placeholder = 3, Other = 4
}
class ElementBehaviourDto {
    constructor(public id: number, public name ? : string, public elementType ? : ElementType, public data ? : any) {}
}
class ThemeElementDto {
    constructor(public themeId: number, public systemOwned: boolean, public inShapeCycle: boolean, public sortOrder: number, public id: number, public name ? : string, public displayName ? : string, public defaultLabel ? : string, public style ? : any, public edgeLabelPosition ? : EdgeLabelPosition, public nodeLabelPosition ? : NodeLabelPosition, public indicatorsPosition ? : IndicatorsPosition, public elementType ? : ElementType, public relationshipType ? : RelationshipType, public behaviourId ? : number, public behaviour ? : ElementBehaviourDto, public defaultEntityTypeId ? : number, public suggestedEntityTypeIds ? : number[]) {}
}
class ThemeAttachmentDto {
    constructor(public themeId: number, public fileAttachmentId: number, public fileAttachment ? : FileAttachmentDto, public attachmentType ? : AttachmentType, public id ? : number) {}
}
class ThemeDataPropertyItemDto {
    constructor(public dataPropertyDefinitionItemId: number, public imageData ? : string, public itemValue ? : string, public itemInitials ? : string, public id ? : number) {}
}
class ThemeTextBoxDto {
    constructor(public themeId: number, public sortOrder: number, public id: number, public name ? : string, public fontStyle ? : LabelStyleDto) {}
}
class ThemeDto {
    constructor(public systemOwned: boolean, public isEditable: boolean, public isApplicable: boolean, public isDefault: boolean, public showBackgroundPanel: boolean, public creationTime: string, public sortOrder: number, public name ? : string, public creatorUserId ? : number, public swatch ? : any, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public elements ? : ThemeElementDto[], public attachments ? : ThemeAttachmentDto[], public themeDataPropertyItems ? : ThemeDataPropertyItemDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public lastModificationTime ? : string, public textBoxes ? : ThemeTextBoxDto[], public appVersion ? : string, public timelineTheme ? : string, public id ? : number) {}
}
class ErrorDto {
    constructor(public errorMessage ? : string, public errorCode ? : number) {}
}
class GetDocumentForViewDto {
    constructor(public isReadonly: boolean, public document ? : DocumentDto, public accessLevel ? : DocumentAccessLevel, public documentActivityBy ? : DocumentActivityBy, public currentTheme ? : ThemeDto, public error ? : ErrorDto) {}
}
class CreateOrEditDocumentDto {
    constructor(public name: string, public isPristine: boolean, public hasSteps: boolean, public hasTimelines: boolean, public themeId: number, public autoSave: boolean, public usePresetProperties: boolean, public description ? : string, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public pageStyle ? : PageStyleDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public headerFooterDate ? : string, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachments ? : DocumentAttachmentDto[], public pageDesignSetId ? : number, public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public lastModificationTime ? : string, public defaultPageType ? : DocumentPageType, public dataPropertyStyles ? : DataPropertyStyleDto[], public folderId ? : number, public importId ? : number, public flipbookState ? : FlipbookState, public thumb ? : string, public quickBuildSettings ? : QuickBuildSettingsDto, public id ? : number) {}
}
class CreateOrEditDocumentOutput {
    constructor(public id: number, public lastModificationTime ? : string) {}
}
class CloneDocumentDto {
    constructor(public document ? : DocumentDto, public thumb ? : string) {}
}
class EntityDto {
    constructor(public id: number) {}
}
class RestoreDeletedEntityRequestDto {
    constructor(public entityId: number) {}
}
class GetElementBehaviourForViewDto {
    constructor(public elementBehaviour ? : ElementBehaviourDto) {}
}
class PagedResultDtoOfGetElementBehaviourForViewDto {
    constructor(public totalCount: number, public items ? : GetElementBehaviourForViewDto[]) {}
}
class EmailTemplateDto {
    constructor(public id: number, public key ? : string, public languageName ? : string, public description ? : string, public subject ? : string, public body ? : string) {}
}
class EmailRecipientDto {
    constructor(public name ? : string, public email ? : string) {}
}
class PrepareEmailTemplateRequestDto {
    constructor(public to ? : EmailRecipientDto[], public key ? : string, public languageName ? : string, public substitutions ? : any) {}
}
class PreparedEmailDto {
    constructor(public to ? : EmailRecipientDto[], public cc ? : EmailRecipientDto[], public bcc ? : EmailRecipientDto[], public body ? : string, public subject ? : string) {}
}
class EntityTypeDto {
    constructor(public systemOwned: boolean, public id: number, public name ? : string, public type ? : ElementType, public dataPropertyDefinitionIds ? : number[], public themeElementIds ? : number[]) {}
}
class CreateOrEditEntityTypeDto {
    constructor(public systemOwned: boolean, public name ? : string, public type ? : ElementType, public dataPropertyDefinitionIds ? : number[], public themeElementIds ? : number[], public id ? : number) {}
}
class GetFileAttachmentForViewDto {
    constructor(public fileAttachment ? : FileAttachmentDto, public diagramName ? : string, public timelineName ? : string) {}
}
class PagedResultDtoOfGetFileAttachmentForViewDto {
    constructor(public totalCount: number, public items ? : GetFileAttachmentForViewDto[]) {}
}
class CreateOrEditFileAttachmentDto {
    constructor(public fileId: string, public filename ? : string, public mimeType ? : string, public description ? : string, public diagramId ? : number, public diagramNodeId ? : number, public diagramEdgeId ? : number, public timelineId ? : number, public timelineEventId ? : number, public dataPropertyDefinitionId ? : number, public dataPropertyUuid ? : string, public dataPropertyValue ? : string, public attachmentType ? : AttachmentType, public id ? : number) {}
}
class GetFileAttachmentForEditOutput {
    constructor(public fileAttachment ? : CreateOrEditFileAttachmentDto, public diagramName ? : string, public timelineName ? : string) {}
}
class UploadTimelinesFileOutput {
    constructor(public url ? : string) {}
}
enum FolderItemType {
    Folder = 0, File = 1
}
enum AccessType {
    Owner = 0, Shared = 1, IndirectShare = 2, None = 3
}
class IFolderItemDto {
    constructor(public id: number, public isFavourite: boolean, public name ? : string, public type ? : FolderItemType, public lastModificationTime ? : string, public deletionTime ? : string, public removalTime ? : string, public pathSegments ? : string[], public path ? : string, public sharedWith ? : UserDto[], public accessType ? : AccessType, public externalFileId ? : string, public externalFileDetails ? : any, public matter ? : string, public client ? : string) {}
}
enum FolderSearchType {
    Folder = 0, Favourites = 1, Templates = 2, Deleted = 3, Shared = 4
}
class SearchFolderInputDto {
    constructor(public sorting ? : string, public searchFilter ? : string, public searchType ? : FolderSearchType) {}
}
class SearchFolderResultDto {
    constructor(public items ? : IFolderItemDto[]) {}
}
class ListFolderContentsInputDto {
    constructor(public limit: number, public offset: number, public folderId ? : number, public sorting ? : string) {}
}
enum FolderAccessLevel {
    View = 0, Edit = 1, EditShare = 2, None = -1
}
class FolderItemDto {
    constructor(public childItemCount: number, public isFavourite: boolean, public id: number, public type ? : FolderItemType, public creatorUser ? : UserDto, public lastModificationTime ? : string, public sharedWith ? : UserDto[], public creationTime ? : string, public deletionTime ? : string, public parent ? : FolderItemDto, public removalTime ? : string, public currentUserAccessLevel ? : FolderAccessLevel, public pathSegments ? : string[], public path ? : string, public accessType ? : AccessType, public externalFileId ? : string, public externalFileDetails ? : any, public matter ? : string, public client ? : string, public name ? : string) {}
}
class FolderItemListResultDto {
    constructor(public hasMore: boolean, public items ? : IFolderItemDto[], public folder ? : FolderItemDto) {}
}
class CreateFolderDto {
    constructor(public name ? : string, public parentFolderId ? : number) {}
}
class RenameFolderRequestDto {
    constructor(public id: number, public name ? : string) {}
}
class DeleteFolderDto {
    constructor(public id: number) {}
}
class MoveItemDto {
    constructor(public id: number, public type ? : FolderItemType) {}
}
class MoveItemsRequestDto {
    constructor(public items ? : MoveItemDto[], public folderId ? : number) {}
}
class CopyItemDto {
    constructor(public id: number, public type ? : FolderItemType) {}
}
class CopyItemsRequestDto {
    constructor(public items ? : CopyItemDto[], public folderId ? : number) {}
}
class GrantFolderAccessDto {
    constructor(public folderId: number, public userId: number, public accessLevel ? : FolderAccessLevel, public message ? : string) {}
}
class RevokeFolderAccessDto {
    constructor(public folderId: number, public userId: number) {}
}
class FolderAccessDto {
    constructor(public folderId: number, public userId: number, public user ? : UserDto, public accessLevel ? : FolderAccessLevel) {}
}
class GeneralSettingsEditDto {
    constructor(public timezone ? : string, public timezoneForComparison ? : string) {}
}
class SessionTimeOutSettingsEditDto {
    constructor(public isEnabled: boolean, public timeOutSeconds: number, public showTimeOutNotificationSecond: number, public showLockScreenWhenTimedOut: boolean) {}
}
class HostUserManagementSettingsEditDto {
    constructor(public isEmailConfirmationRequiredForLogin: boolean, public smsVerificationEnabled: boolean, public isCookieConsentEnabled: boolean, public isQuickThemeSelectEnabled: boolean, public useCaptchaOnLogin: boolean, public sessionTimeOutSettings ? : SessionTimeOutSettingsEditDto) {}
}
class EmailSettingsEditDto {
    constructor(public smtpPort: number, public smtpEnableSsl: boolean, public useCloudCredentials: boolean, public enableEmailSending: boolean, public useAuthentication: boolean, public defaultFromAddress ? : string, public defaultFromDisplayName ? : string, public smtpHost ? : string, public smtpUserName ? : string, public smtpPassword ? : string, public smtpDomain ? : string) {}
}
class TenantManagementSettingsEditDto {
    constructor(public allowSelfRegistration: boolean, public isNewRegisteredTenantActiveByDefault: boolean, public useCaptchaOnRegistration: boolean, public defaultEditionId ? : number) {}
}
class PasswordComplexitySetting {
    constructor(public requireDigit: boolean, public requireLowercase: boolean, public requireNonAlphanumeric: boolean, public requireUppercase: boolean, public requiredLength: number) {}
}
class UserLockOutSettingsEditDto {
    constructor(public isEnabled: boolean, public maxFailedAccessAttemptsBeforeLockout: number, public defaultAccountLockoutSeconds: number) {}
}
class TwoFactorLoginSettingsEditDto {
    constructor(public isEnabledForApplication: boolean, public isEnabled: boolean, public isEmailProviderEnabled: boolean, public isSmsProviderEnabled: boolean, public isRememberBrowserEnabled: boolean, public isGoogleAuthenticatorEnabled: boolean) {}
}
class SecuritySettingsEditDto {
    constructor(public allowPasswordReset: boolean, public allowOneConcurrentLoginPerUser: boolean, public useDefaultPasswordComplexitySettings: boolean, public passwordComplexity ? : PasswordComplexitySetting, public defaultPasswordComplexity ? : PasswordComplexitySetting, public userLockOut ? : UserLockOutSettingsEditDto, public twoFactorLogin ? : TwoFactorLoginSettingsEditDto) {}
}
class HostBillingSettingsEditDto {
    constructor(public legalName ? : string, public address ? : string) {}
}
class OtherSettingsEditDto {
    constructor(public isQuickThemeSelectEnabled: boolean) {}
}
class FacebookExternalLoginProviderSettings {
    constructor(public appId ? : string, public appSecret ? : string) {}
}
class GoogleExternalLoginProviderSettings {
    constructor(public clientId ? : string, public clientSecret ? : string, public userInfoEndpoint ? : string) {}
}
class TwitterExternalLoginProviderSettings {
    constructor(public consumerKey ? : string, public consumerSecret ? : string) {}
}
class MicrosoftExternalLoginProviderSettings {
    constructor(public clientId ? : string, public clientSecret ? : string) {}
}
class ExternalLoginProviderSettingsEditDto {
    constructor(public facebook ? : FacebookExternalLoginProviderSettings, public google ? : GoogleExternalLoginProviderSettings, public twitter ? : TwitterExternalLoginProviderSettings, public microsoft ? : MicrosoftExternalLoginProviderSettings) {}
}
class HostSettingsEditDto {
    constructor(public general ? : GeneralSettingsEditDto, public userManagement ? : HostUserManagementSettingsEditDto, public email ? : EmailSettingsEditDto, public tenantManagement ? : TenantManagementSettingsEditDto, public security ? : SecuritySettingsEditDto, public billing ? : HostBillingSettingsEditDto, public otherSettings ? : OtherSettingsEditDto, public externalLoginProviderSettings ? : ExternalLoginProviderSettingsEditDto) {}
}
class SendTestEmailInput {
    constructor(public emailAddress: string) {}
}
class SendTestLdapInput {
    constructor(public domain: string, public port: number, public container: string, public userNameAttribute: string, public userName: string, public password: string, public searchFilter: string) {}
}
class SendTestIManageApiInput {
    constructor(public serverUrl: string) {}
}
class ClientAutoSaveSettingsDto {
    constructor(public isEnabled: boolean, public timeOutSeconds: number) {}
}
class ClientDocumentSettingsDto {
    constructor(public isPublicByDefault: boolean, public jsonExportEnabled: boolean, public hardDeleteEnabled: boolean, public softDeleteRetentionPeriodDays: number, public softDeletePollPeriodSeconds: number, public defaultDiagramView ? : DocumentView) {}
}
class EmailRecipientsDto {
    constructor(public toEmail ? : string, public ccEmail ? : string, public bccEmail ? : string) {}
}
class TourGuideConfigurationDto {
    constructor(public enabled: boolean, public captureUserMetrics: boolean, public allowTourGuiding: boolean, public enableInformationCenter: boolean, public reEnableTourGuidingAfter ? : string, public reEnableTourGuidingAfterUtc ? : string) {}
}
class EmailSettingsDto {
    constructor(public enableEmailSending: boolean) {}
}
class IManageSettingsDto {
    constructor(public isEnabled: boolean, public integrateHub: boolean) {}
}
class DocumentProfilingDto {
    constructor(public isEnabled: boolean, public promptOnSave: boolean, public singleInputClientMatter: boolean, public clientLabel ? : string, public matterLabel ? : string, public clientInputMask ? : string, public matterInputMask ? : string, public documentNameValue ? : string) {}
}
class SeasonalThemingDto {
    constructor(public isEnabled: boolean) {}
}
class PasswordConfigDto {
    constructor(public allowPasswordReset: boolean, public requireDigit: boolean, public requireLowercase: boolean, public requireNonAlphanumeric: boolean, public requireUppercase: boolean, public requiredLength: number, public maxLength: number) {}
}
class OttConfigDto {
    constructor(public expiryMinutes: number) {}
}
class ClientHostSettingsDto {
    constructor(public sessionTimeout ? : SessionTimeOutSettingsEditDto, public autoSaveSettings ? : ClientAutoSaveSettingsDto, public documentSettings ? : ClientDocumentSettingsDto, public feedbackSettings ? : EmailRecipientsDto, public licenceSettings ? : EmailRecipientsDto, public tourGuideSettings ? : TourGuideConfigurationDto, public emailSettings ? : EmailSettingsDto, public iManageSettings ? : IManageSettingsDto, public documentProfilingSettings ? : DocumentProfilingDto, public seasonalThemingSettings ? : SeasonalThemingDto, public passwordConfig ? : PasswordConfigDto, public ottConfigDto ? : OttConfigDto) {}
}
class App {
    constructor(public id ? : string) {}
}
class IManageUser {
    constructor(public customer_id ? : string, public email ? : string, public id ? : string, public name ? : string, public ssid ? : string, public user_type ? : string) {}
}
class Version {
    constructor(public name ? : string, public url ? : string, public version ? : string) {}
}
class Library {
    constructor(public is_classic_client_compatible: boolean, public alias ? : string) {}
}
class Work {
    constructor(public preferred_library ? : string, public libraries ? : Library[]) {}
}
class GetUserInformationModel {
    constructor(public auth_status ? : string, public app ? : App, public capabilities ? : string[], public dms_version ? : string, public user ? : IManageUser, public versions ? : Version[], public work ? : Work) {}
}
class GetAvailableLibrariesModel {
    constructor(public id ? : string, public library_id ? : string, public preferred ? : string) {}
}
class GetRecentFoldersModel {
    constructor(public workspace_id ? : string, public workspace_name ? : string, public activity_date ? : string, public edit_date ? : string, public default_security ? : string, public name ? : string, public has_subfolders ? : string, public is_content_saved_search ? : string, public has_documents ? : string, public parent_id ? : string, public id ? : string, public folder_type ? : string, public database ? : string, public is_external_as_normal ? : string, public owner_description ? : string, public is_container_saved_search ? : string, public owner ? : string, public wstype ? : string) {}
}
class Target {
    constructor(public database ? : string, public id ? : string, public subtype ? : string, public wstype ? : string) {}
}
class GetMyMattersModel {
    constructor(public has_subfolders: boolean, public is_container_saved_search: boolean, public is_content_saved_search: boolean, public is_external_as_normal: boolean, public category_type ? : string, public database ? : string, public default_security ? : string, public edit_date ? : string, public id ? : string, public name ? : string, public owner ? : string, public owner_description ? : string, public parent_id ? : string, public subtype ? : string, public target ? : Target, public view_type ? : string, public wstype ? : string) {}
}
class GetHubDetailModel {
    constructor(public user ? : GetUserInformationModel, public availableLibraries ? : GetAvailableLibrariesModel[], public recentFolders ? : GetRecentFoldersModel[], public myMatters ? : GetMyMattersModel[], public workspaceFolders ? : GetMyMattersModel[]) {}
}
class SearchMatterModel {
    constructor(public has_subfolders: boolean, public is_external: boolean, public is_external_as_normal: boolean, public is_declared: boolean, public is_hipaa: boolean, public create_date ? : string, public database ? : string, public default_security ? : string, public edit_date ? : string, public id ? : string, public name ? : string, public owner_description ? : string, public owner ? : string, public custom1_description ? : string, public custom1 ? : string, public custom1_ssid ? : string, public custom2_ssid ? : string, public custom2_description ? : string, public custom2 ? : string, public document_number ? : string, public iwl ? : string, public retain_days ? : string, public version ? : string, public wstype ? : string) {}
}
class GetWorkspaceFoldersModel {
    constructor(public has_documents: boolean, public has_subfolders: boolean, public is_container_saved_search: boolean, public is_content_saved_search: boolean, public is_external: boolean, public is_external_as_normal: boolean, public database ? : string, public default_security ? : string, public edit_date ? : string, public folder_type ? : string, public id ? : string, public inherited_default_security ? : string, public name ? : string, public owner_description ? : string, public owner ? : string, public parent_id ? : string, public view_type ? : string, public workspace_id ? : string, public workspace_name ? : string, public wstype ? : string) {}
}
class GetFolderContentsModel {
    constructor(public has_documents: boolean, public has_subfolders: boolean, public is_container_saved_search: boolean, public is_content_saved_search: boolean, public is_external: boolean, public is_external_as_normal: boolean, public database ? : string, public default_security ? : string, public edit_date ? : string, public folder_type ? : string, public id ? : string, public inherited_default_security ? : string, public name ? : string, public owner_description ? : string, public owner ? : string, public parent_id ? : string, public view_type ? : string, public workspace_id ? : string, public workspace_name ? : string, public wstype ? : string) {}
}
class GetDialogUrlModel {
    constructor(public dialog_filepicker ? : string, public dialog_folderpicker ? : string, public dialog_filesave ? : string, public dialog_webui ? : string, public dialog_logout ? : string) {}
}
class GetDialogTokenModel {
    constructor(public dialog_token ? : string, public urls ? : GetDialogUrlModel) {}
}
class DocProfile {
    constructor(public size: number, public access ? : string, public comment ? : string, public database ? : string, public name ? : string, public type ? : string, public type_description ? : string, public wstype ? : string) {}
}
class Profile {
    constructor(public warnings_for_required_and_disabled_fields: boolean, public doc_profile ? : DocProfile) {}
}
class SaveDocumentModel {
    constructor(public name ? : string, public comment ? : string, public content ? : string, public fileContent ? : string, public profile ? : Profile, public file ? : string) {}
}
class AuthorInfo {
    constructor(public is_external: boolean) {}
}
class LastUserInfo {
    constructor(public is_external: boolean) {}
}
class OperatorInfo {
    constructor(public is_external: boolean) {}
}
class SaveDocumentResponseModel {
    constructor(public create_date: string, public document_number: number, public edit_date: string, public edit_profile_date: string, public email_type: boolean, public file_create_date: string, public file_edit_date: string, public indexable: boolean, public is_checked_out: boolean, public is_declared: boolean, public is_external: boolean, public is_external_as_normal: boolean, public is_hipaa: boolean, public is_in_use: boolean, public is_restorable: boolean, public retain_days: number, public size: number, public version: number, public wopi_file_size_warning: boolean, public access ? : string, public author ? : string, public author_description ? : string, public author_info ? : AuthorInfo, public checksum ? : string, public class_name ? : string, public class_description ? : string, public content_type ? : string, public database ? : string, public default_security ? : string, public extension ? : string, public id ? : string, public iwl ? : string, public last_user ? : string, public last_user_description ? : string, public last_user_info ? : LastUserInfo, public name ? : string, public operator ? : string, public operator_description ? : string, public operator_info ? : OperatorInfo, public type ? : string, public type_description ? : string, public workspace_id ? : string, public workspace_name ? : string, public wstype ? : string) {}
}
class LockDocumentModel {
    constructor(public comments ? : string, public due_date ? : string, public location ? : string, public path ? : string) {}
}
class UpdateDocumentModel {
    constructor(public name ? : string) {}
}
class GetDocumentCheckoutModel {
    constructor(public checkout_date ? : string, public error ? : ErrorDto) {}
}
class Operations {
    constructor(public archive: boolean, public copy: boolean, public create_new_version: boolean, public declare: boolean, public del: boolean, public freeze: boolean, public lock: boolean, public move: boolean, public relate: boolean, public replace: boolean, public restore: boolean, public set_security: boolean, public undeclare: boolean, public unfreeze: boolean, public unlock: boolean, public update: boolean) {}
}
class GetDocumentProfileModel {
    constructor(public create_date: string, public custom25: boolean, public custom26: boolean, public custom27: boolean, public custom28: boolean, public document_number: number, public edit_date: string, public edit_profile_date: string, public file_create_date: string, public file_edit_date: string, public has_attachment: boolean, public is_in_use: boolean, public is_declared: boolean, public is_checked_out: boolean, public is_hipaa: boolean, public is_restorable: boolean, public size: number, public version: number, public wopi_file_size_limit: number, public wopi_file_size_warning: boolean, public access ? : string, public author ? : string, public author_description ? : string, public author_info ? : AuthorInfo, public class_name ? : string, public custom1 ? : string, public custom1_description ? : string, public custom2 ? : string, public custom29 ? : string, public custom29_description ? : string, public custom2_description ? : string, public custom3 ? : string, public custom30 ? : string, public custom30_description ? : string, public custom3_description ? : string, public custom4 ? : string, public custom4_description ? : string, public database ? : string, public default_security ? : string, public extension ? : string, public id ? : string, public iwl ? : string, public last_user ? : string, public last_user_description ? : string, public last_user_info ? : LastUserInfo, public name ? : string, public operations ? : Operations, public operator ? : string, public operator_description ? : string, public operator_info ? : OperatorInfo, public type ? : string, public workspace_id ? : string, public wstype ? : string, public lock_type ? : string, public co_authors ? : string[], public error ? : ErrorDto, public errorMessage ? : string, public errorCode ? : number) {}
}
class GetDocumentPathDetailModel {
    constructor(public edit_date: string, public has_subfolders: boolean, public is_external: boolean, public is_external_as_normal: boolean, public create_date ? : string, public database ? : string, public default_security ? : string, public description ? : string, public id ? : string, public last_user ? : string, public last_user_description ? : string, public name ? : string, public owner ? : string, public owner_description ? : string, public document_number ? : number, public is_declared ? : boolean, public is_hipaa ? : boolean, public iwl ? : string, public retain_days ? : number, public version ? : number, public wstype ? : string, public folder_type ? : string, public has_documents ? : boolean, public inherited_default_security ? : string, public is_container_saved_search ? : boolean, public is_content_saved_search ? : boolean, public parent_id ? : string, public view_type ? : string, public workspace_id ? : string, public workspace_name ? : string) {}
}
class GetDocumentPathModel {
    constructor(public data ? : [][], public error ? : ErrorDto) {}
}
enum HttpStatusCode {
    Continue = 100, SwitchingProtocols = 101, Processing = 102, EarlyHints = 103, OK = 200, Created = 201, Accepted = 202, NonAuthoritativeInformation = 203, NoContent = 204, ResetContent = 205, PartialContent = 206, MultiStatus = 207, AlreadyReported = 208, IMUsed = 226, MultipleChoices = 300, Ambiguous = 301, MovedPermanently = 302, Moved = 303, Found = 304, Redirect = 305, SeeOther = 306, RedirectMethod = 307, NotModified = 308, UseProxy = 400, Unused = 401, TemporaryRedirect = 402, RedirectKeepVerb = 403, PermanentRedirect = 404, BadRequest = 405, Unauthorized = 406, PaymentRequired = 407, Forbidden = 408, NotFound = 409, MethodNotAllowed = 410, NotAcceptable = 411, ProxyAuthenticationRequired = 412, RequestTimeout = 413, Conflict = 414, Gone = 415, LengthRequired = 416, PreconditionFailed = 417, RequestEntityTooLarge = 421, RequestUriTooLong = 422, UnsupportedMediaType = 423, RequestedRangeNotSatisfiable = 424, ExpectationFailed = 426, MisdirectedRequest = 428, UnprocessableEntity = 429, Locked = 431, FailedDependency = 451, UpgradeRequired = 500, PreconditionRequired = 501, TooManyRequests = 502, RequestHeaderFieldsTooLarge = 503, UnavailableForLegalReasons = 504, InternalServerError = 505, NotImplemented = 506, BadGateway = 507, ServiceUnavailable = 508, GatewayTimeout = 510, HttpVersionNotSupported = 511
}
class GetApiInformationModel {
    constructor(public auth_status ? : string, public capabilities ? : string[], public dms_version ? : string, public versions ? : Version[]) {}
}
class GetApiModel {
    constructor(public data ? : GetApiInformationModel, public errorMessage ? : string, public errorCode ? : number) {}
}
class SpreadsheetColumnDto {
    constructor(public columnName ? : string, public columnValues ? : string[]) {}
}
class ImportSpreadsheet {
    constructor(public binaryObjectId: string, public id: number, public name ? : string) {}
}
enum ImportDefinitionColumnType {
    Unique = 0, PointsToParent = 1, Jurisdiction = 2, Node = 3, Symbol = 4, Fill = 5, Outline = 6, NodeDataProperties = 7, EdgeLabels = 8, Label = 9, Edge = 10, IgnoreRow = 11, EdgeDataProperties = 12, IgnoreColumn = 13
}
enum MappingType {
    Jurisdiction = 0, Shape = 1, Symbol = 2, Line = 3, Fill = 4, Outline = 5
}
class MappingValueDto {
    constructor(public sourceValue ? : string, public targetValue ? : string, public id ? : number) {}
}
class MappingDto {
    constructor(public id: number, public mappingName ? : string, public mappingType ? : MappingType, public themeId ? : number, public mappingValues ? : MappingValueDto[]) {}
}
class ImportDefinitionColumnDto {
    constructor(public importDefinitionId: number, public addToLabel: boolean, public multiColumnMapping: boolean, public columnIndex: number, public id: number, public importDefinition ? : ImportDefinitionDto, public column ? : string, public columnType ? : ImportDefinitionColumnType, public mapping ? : MappingDto, public regEx ? : string, public customLabelFormat ? : string, public evaluationString ? : string, public metadata ? : string) {}
}
class ImportDefinitionDto {
    constructor(public name ? : string, public themeId ? : number, public theme ? : ThemeDto, public columns ? : ImportDefinitionColumnDto[], public creatorUserId ? : number, public user ? : UserDto, public metadata ? : string, public importSpreadsheetId ? : number, public id ? : number) {}
}
class ProcessFileRequestDto {
    constructor(public importId: number, public documentId: number, public fonts ? : string[]) {}
}
class ImportValidationErrorDto {
    constructor(public rowIndex: number, public value ? : string, public columnName ? : string, public message ? : string) {}
}
enum ImportType {
    Excel = 0, PowerPoint = 1
}
class ImportResultDto {
    constructor(public importId: number, public importFailed: boolean, public lastModificationTime ? : string, public importValidationErrors ? : ImportValidationErrorDto[], public importType ? : ImportType) {}
}
class ProcessImportedFileDto {
    constructor(public binaryObjectId: string, public importDefinitionId: number, public name ? : string, public password ? : string, public importType ? : ImportType) {}
}
class ImportedFileResultDto {
    constructor(public importId: number, public theme ? : ThemeDto, public name ? : string) {}
}
class ImportDto {
    constructor(public binaryObjectId: string, public records: number, public processed: number, public errors: number, public id: number, public name ? : string, public importDefinition ? : ImportDefinitionDto, public creatorUser ? : UserDto, public type ? : ImportType) {}
}
class PagedResultDtoOfImportDto {
    constructor(public totalCount: number, public items ? : ImportDto[]) {}
}
enum InformationBoardItemType {
    Text = 0, Image = 1, Video = 2
}
class InformationBoardItemDto {
    constructor(public title ? : string, public subtext ? : string, public type ? : InformationBoardItemType, public resourceUrl ? : string, public externalUrl ? : string) {}
}
class GetTranslationsOutput {
    constructor(public targetCulture ? : string, public translations ? : any) {}
}
class ApplicationLanguageListDto {
    constructor(public isDisabled: boolean, public isDeleted: boolean, public creationTime: string, public id: number, public tenantId ? : number, public name ? : string, public displayName ? : string, public icon ? : string, public deleterUserId ? : number, public deletionTime ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class GetLanguagesOutput {
    constructor(public defaultLanguageName ? : string, public items ? : ApplicationLanguageListDto[]) {}
}
class ApplicationLanguageEditDto {
    constructor(public name: string, public isEnabled: boolean, public id ? : number, public icon ? : string) {}
}
class ComboboxItemDto {
    constructor(public isSelected: boolean, public value ? : string, public displayText ? : string) {}
}
class GetLanguageForEditOutput {
    constructor(public language ? : ApplicationLanguageEditDto, public languageNames ? : ComboboxItemDto[], public flags ? : ComboboxItemDto[]) {}
}
class CreateOrUpdateLanguageInput {
    constructor(public language ? : ApplicationLanguageEditDto) {}
}
class SetDefaultLanguageInput {
    constructor(public name: string) {}
}
class LanguageTextListDto {
    constructor(public key ? : string, public baseValue ? : string, public targetValue ? : string) {}
}
class PagedResultDtoOfLanguageTextListDto {
    constructor(public totalCount: number, public items ? : LanguageTextListDto[]) {}
}
class UpdateLanguageTextInput {
    constructor(public languageName: string, public sourceName: string, public key: string, public value: string) {}
}
class LicenseUserDto {
    constructor(public userId: number, public license: boolean) {}
}
class LicenseUserResultDto {
    constructor(public exceeded: boolean, public licensedUsers: number) {}
}
class LicenceFromHostDto {
    constructor(public currentLicence ? : string, public token ? : string) {}
}
class CreateOrEditLicenseDto {
    constructor(public licenseInfo: string) {}
}
class GetLicenseForEditOutput {
    constructor(public isValid: boolean, public license ? : CreateOrEditLicenseDto) {}
}
class LicenseDto {
    constructor(public id: number, public licenseInfo ? : string, public display ? : string) {}
}
enum LicenseTypes {
    Restricted = 0, Unrestricted = 1
}
class GetLicenseForViewDto {
    constructor(public licensedUsers: number, public unlicensedUsers: number, public licenses: number, public unlicensed: boolean, public license ? : LicenseDto, public features ? : string[], public licenseType ? : LicenseTypes, public unlicenceUserExpiry ? : string, public unlicenceUsers ? : string[]) {}
}
class LicenceDetails {
    constructor(public maxUsers: number, public licensedUsers: number, public isValid: boolean, public isUnlicenceUserExpired: boolean, public licenceType ? : LicenseTypes, public customer ? : string, public features ? : string[], public unlicenceUserExpiry ? : string, public unlicenceUsers ? : string[], public licence ? : string) {}
}
enum UserNotificationState {
    Unread = 0, Read = 1
}
class NotificationData {
    constructor(public type ? : string, public properties ? : any) {}
}
enum NotificationSeverity {
    Info = 0, Success = 1, Warn = 2, Error = 3, Fatal = 4
}
class TenantNotification {
    constructor(public creationTime: string, public id: string, public tenantId ? : number, public notificationName ? : string, public data ? : NotificationData, public entityType ? : string, public entityTypeName ? : string, public entityId ? : any, public severity ? : NotificationSeverity) {}
}
class UserNotification {
    constructor(public userId: number, public id: string, public tenantId ? : number, public state ? : UserNotificationState, public notification ? : TenantNotification) {}
}
class GetNotificationsOutput {
    constructor(public unreadCount: number, public totalCount: number, public items ? : UserNotification[]) {}
}
class EntityDtoOfGuid {
    constructor(public id: string) {}
}
class NotificationSubscriptionWithDisplayNameDto {
    constructor(public name: string, public isSubscribed: boolean, public displayName ? : string, public description ? : string) {}
}
class GetNotificationSettingsOutput {
    constructor(public receiveNotifications: boolean, public notifications ? : NotificationSubscriptionWithDisplayNameDto[]) {}
}
class NotificationSubscriptionDto {
    constructor(public name: string, public isSubscribed: boolean) {}
}
class UpdateNotificationSettingsInput {
    constructor(public receiveNotifications: boolean, public notifications ? : NotificationSubscriptionDto[]) {}
}
class OrganizationUnitDto {
    constructor(public memberCount: number, public roleCount: number, public creationTime: string, public id: number, public parentId ? : number, public code ? : string, public displayName ? : string, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number) {}
}
class ListResultDtoOfOrganizationUnitDto {
    constructor(public items ? : OrganizationUnitDto[]) {}
}
class OrganizationUnitUserListDto {
    constructor(public addedTime: string, public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public profilePictureId ? : string) {}
}
class PagedResultDtoOfOrganizationUnitUserListDto {
    constructor(public totalCount: number, public items ? : OrganizationUnitUserListDto[]) {}
}
class OrganizationUnitRoleListDto {
    constructor(public addedTime: string, public id: number, public displayName ? : string, public name ? : string) {}
}
class PagedResultDtoOfOrganizationUnitRoleListDto {
    constructor(public totalCount: number, public items ? : OrganizationUnitRoleListDto[]) {}
}
class CreateOrganizationUnitInput {
    constructor(public displayName: string, public parentId ? : number) {}
}
class UpdateOrganizationUnitInput {
    constructor(public id: number, public displayName: string) {}
}
class MoveOrganizationUnitInput {
    constructor(public id: number, public newParentId ? : number) {}
}
class UsersToOrganizationUnitInput {
    constructor(public organizationUnitId: number, public userIds ? : number[]) {}
}
class RolesToOrganizationUnitInput {
    constructor(public organizationUnitId: number, public roleIds ? : number[]) {}
}
class FindOrganizationUnitUsersInput {
    constructor(public organizationUnitId: number, public maxResultCount: number, public skipCount: number, public filter ? : string) {}
}
class FindOrganizationUnitRolesInput {
    constructor(public organizationUnitId: number, public maxResultCount: number, public skipCount: number, public filter ? : string) {}
}
class ListItemStyleDto {
    constructor(public level: number, public style ? : string, public color ? : string) {}
}
class ListDefaultFormattingDto {
    constructor(public listType ? : string, public listStyles ? : ListItemStyleDto[]) {}
}
class ListSpacingDto {
    constructor(public top: number, public bottom: number) {}
}
class ListComponentDto {
    constructor(public defaultFormatting ? : ListDefaultFormattingDto[], public spacing ? : ListSpacingDto) {}
}
class PageDesignSetComponentsDto {
    constructor(public listSettings ? : ListComponentDto) {}
}
class PageDesignAttachmentDto {
    constructor(public pageDesignId: number, public fileAttachmentId: number, public fileAttachment ? : FileAttachmentDto, public attachmentType ? : AttachmentType, public id ? : number) {}
}
class PageTitleDto {
    constructor(public show: boolean, public height: number, public minHeight: number, public titleLayout ? : string) {}
}
class PageDesignDto {
    constructor(public order: number, public isDefault: boolean, public splitDividerVisibility: boolean, public htmlContentDividerVisibility: boolean, public contentColumns: number, public creationTime: string, public pageDesignSetId ? : number, public headerLayout ? : string, public footerLayout ? : string, public backgroundLayout ? : string, public pageStyle ? : PageStyleDto, public headerStyle ? : HeaderFooterStyleDto, public footerStyle ? : HeaderFooterStyleDto, public bodyLayout ? : string, public layoutType ? : DocumentPageLayoutType, public attachments ? : PageDesignAttachmentDto[], public fontPresets ? : FontStyleDto[], public defaultDiagramPosition ? : DiagramPosition, public pageTitle ? : PageTitleDto, public pageType ? : DocumentPageType, public contentType ? : DocumentPageContentType, public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number, public id ? : number) {}
}
class PageDesignSetDto {
    constructor(public order: number, public isDefault: boolean, public version: number, public isLatestVersion: boolean, public creationTime: string, public name ? : string, public uuid ? : string, public defaultFont ? : FontDto, public components ? : PageDesignSetComponentsDto, public pageDesigns ? : PageDesignDto[], public lastModificationTime ? : string, public lastModifierUserId ? : number, public creatorUserId ? : number, public id ? : number) {}
}
class PagedResultDtoOfPageDesignSetDto {
    constructor(public totalCount: number, public items ? : PageDesignSetDto[]) {}
}
class CreatePageDesignSetResponseDto {
    constructor(public id: number, public uuid ? : string) {}
}
class PersonDto {
    constructor(public id: number, public firstName ? : string, public lastName ? : string, public pictureAttachmentId ? : number, public pictureAttachment ? : FileAttachmentDto, public profilePictureUrl ? : string, public dataProperties ? : DataPropertyDto[]) {}
}
class CreateOrEditPersonDto {
    constructor(public firstName ? : string, public lastName ? : string, public pictureAttachmentId ? : number, public dataProperties ? : CreateOrEditDataPropertyDto[], public id ? : number) {}
}
class KeyValuePairOfStringString {
    constructor(public key ? : string, public value ? : string) {}
}
class FlatPermissionWithLevelDto {
    constructor(public level: number, public isGrantedByDefault: boolean, public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string) {}
}
class ListResultDtoOfFlatPermissionWithLevelDto {
    constructor(public items ? : FlatPermissionWithLevelDto[]) {}
}
class CurrentUserProfileEditDto {
    constructor(public name: string, public surname: string, public userName: string, public emailAddress: string, public isLicensed: boolean, public hasDocuments: boolean, public userId ? : number, public timezone ? : string, public authenticationSource ? : string, public roles ? : string[], public permissions ? : string[], public language ? : string, public documentCounts ? : DocumentCounts, public features ? : string[], public customerId ? : string, public documentActivity ? : DocumentActivity) {}
}
class UpdateGoogleAuthenticatorKeyOutput {
    constructor(public qrCodeSetupImageUrl ? : string) {}
}
class SendVerificationSmsInputDto {
    constructor(public phoneNumber ? : string) {}
}
class VerifySmsCodeInputDto {
    constructor(public code ? : string, public phoneNumber ? : string) {}
}
class ChangePasswordInput {
    constructor(public currentPassword: string, public newPassword: string) {}
}
class UpdateProfilePictureInput {
    constructor(public fileToken: string, public x: number, public y: number, public width: number, public height: number) {}
}
class GetPasswordComplexitySettingOutput {
    constructor(public setting ? : PasswordComplexitySetting) {}
}
class GetProfilePictureOutput {
    constructor(public profilePicture ? : string) {}
}
class ChangeUserLanguageDto {
    constructor(public language: string) {}
}
class RoleListDto {
    constructor(public isStatic: boolean, public isDefault: boolean, public creationTime: string, public id: number, public name ? : string, public displayName ? : string) {}
}
class ListResultDtoOfRoleListDto {
    constructor(public items ? : RoleListDto[]) {}
}
class RoleEditDto {
    constructor(public displayName: string, public isDefault: boolean, public id ? : number) {}
}
class FlatPermissionDto {
    constructor(public isGrantedByDefault: boolean, public parentName ? : string, public name ? : string, public displayName ? : string, public description ? : string) {}
}
class GetRoleForEditOutput {
    constructor(public role ? : RoleEditDto, public permissions ? : FlatPermissionDto[], public grantedPermissionNames ? : string[]) {}
}
class CreateOrUpdateRoleInput {
    constructor(public grantedPermissionNames: [], public role ? : RoleEditDto) {}
}
class UserLoginInfoDto {
    constructor(public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public profilePictureId ? : string) {}
}
class EditionInfoDto {
    constructor(public isHighestEdition: boolean, public isFree: boolean, public id: number, public displayName ? : string, public trialDayCount ? : number, public monthlyPrice ? : number, public annualPrice ? : number) {}
}
class TenantLoginInfoDto {
    constructor(public isInTrialPeriod: boolean, public creationTime: string, public id: number, public tenancyName ? : string, public name ? : string, public logoId ? : string, public logoFileType ? : string, public customCssId ? : string, public subscriptionEndDateUtc ? : string, public edition ? : EditionInfoDto, public subscriptionDateString ? : string, public creationTimeString ? : string) {}
}
class ApplicationInfoDto {
    constructor(public allowTenantsToChangeEmailSettings: boolean, public userDelegationIsEnabled: boolean, public releaseDate: string, public currency ? : string, public currencySign ? : string, public features ? : any, public instanceId ? : string, public version ? : string) {}
}
class ThemeLayoutSettingsDto {
    constructor(public layoutType ? : string) {}
}
class ThemeHeaderSettingsDto {
    constructor(public desktopFixedHeader: boolean, public mobileFixedHeader: boolean, public headerMenuArrows: boolean, public headerSkin ? : string, public minimizeDesktopHeaderType ? : string) {}
}
class ThemeSubHeaderSettingsDto {
    constructor(public fixedSubHeader: boolean, public subheaderStyle ? : string) {}
}
class ThemeMenuSettingsDto {
    constructor(public fixedAside: boolean, public allowAsideMinimizing: boolean, public defaultMinimizedAside: boolean, public searchActive: boolean, public position ? : string, public asideSkin ? : string, public submenuToggle ? : string) {}
}
class ThemeFooterSettingsDto {
    constructor(public fixedFooter: boolean) {}
}
class ThemeSettingsDto {
    constructor(public theme ? : string, public layout ? : ThemeLayoutSettingsDto, public header ? : ThemeHeaderSettingsDto, public subHeader ? : ThemeSubHeaderSettingsDto, public menu ? : ThemeMenuSettingsDto, public footer ? : ThemeFooterSettingsDto) {}
}
class UiCustomizationSettingsDto {
    constructor(public isLeftMenuUsed: boolean, public isTopMenuUsed: boolean, public isTabMenuUsed: boolean, public allowMenuScroll: boolean, public baseSettings ? : ThemeSettingsDto) {}
}
class GetCurrentLoginInformationsOutput {
    constructor(public user ? : UserLoginInfoDto, public tenant ? : TenantLoginInfoDto, public application ? : ApplicationInfoDto, public theme ? : UiCustomizationSettingsDto) {}
}
class UpdateUserSignInTokenOutput {
    constructor(public signInToken ? : string, public encodedUserId ? : string, public encodedTenantId ? : string) {}
}
class TenantListDto {
    constructor(public isActive: boolean, public creationTime: string, public isInTrialPeriod: boolean, public id: number, public tenancyName ? : string, public name ? : string, public editionDisplayName ? : string, public connectionString ? : string, public subscriptionEndDateUtc ? : string, public editionId ? : number) {}
}
class PagedResultDtoOfTenantListDto {
    constructor(public totalCount: number, public items ? : TenantListDto[]) {}
}
class CreateTenantInput {
    constructor(public tenancyName: string, public name: string, public adminEmailAddress: string, public shouldChangePasswordOnNextLogin: boolean, public sendActivationEmail: boolean, public isActive: boolean, public isInTrialPeriod: boolean, public adminPassword ? : string, public connectionString ? : string, public editionId ? : number, public subscriptionEndDateUtc ? : string) {}
}
class TenantEditDto {
    constructor(public tenancyName: string, public name: string, public isActive: boolean, public isInTrialPeriod: boolean, public id: number, public connectionString ? : string, public editionId ? : number, public subscriptionEndDateUtc ? : string) {}
}
class UpdateTenantFeaturesInput {
    constructor(public id: number, public featureValues: NameValueDto[]) {}
}
class MemberActivity {
    constructor(public cases: number, public closed: number, public name ? : string, public earnings ? : string, public rate ? : string) {}
}
class GetMemberActivityOutput {
    constructor(public memberActivities ? : MemberActivity[]) {}
}
enum SalesSummaryDatePeriod {
    Daily = 1, Weekly = 2, Monthly = 3
}
class SalesSummaryData {
    constructor(public sales: number, public profit: number, public period ? : string) {}
}
class GetDashboardDataOutput {
    constructor(public totalProfit: number, public newFeedbacks: number, public newOrders: number, public newUsers: number, public totalSales: number, public revenue: number, public expenses: number, public growth: number, public transactionPercent: number, public newVisitPercent: number, public bouncePercent: number, public salesSummary ? : SalesSummaryData[], public dailySales ? : number[], public profitShares ? : number[]) {}
}
class GetTopStatsOutput {
    constructor(public totalProfit: number, public newFeedbacks: number, public newOrders: number, public newUsers: number) {}
}
class GetProfitShareOutput {
    constructor(public profitShares ? : number[]) {}
}
class GetDailySalesOutput {
    constructor(public dailySales ? : number[]) {}
}
class GetSalesSummaryOutput {
    constructor(public totalSales: number, public revenue: number, public expenses: number, public growth: number, public salesSummary ? : SalesSummaryData[]) {}
}
class RegionalStatCountry {
    constructor(public sales: number, public averagePrice: number, public totalPrice: number, public countryName ? : string, public change ? : number[]) {}
}
class GetRegionalStatsOutput {
    constructor(public stats ? : RegionalStatCountry[]) {}
}
class GetGeneralStatsOutput {
    constructor(public transactionPercent: number, public newVisitPercent: number, public bouncePercent: number) {}
}
class DocumentConfigurationDto {
    constructor(public isPublicByDefault: boolean, public jsonExportEnabled: boolean, public hardDeleteEnabled: boolean, public hardDeleteWorkerEnabled: boolean, public softDeleteRetentionPeriodDays: number, public softDeletePollPeriodSeconds: number, public defaultDiagramView ? : DocumentView) {}
}
class DiagramConfigurationDto {
    constructor(public performanceIndex: number) {}
}
class IManageConfigurationDto {
    constructor(public isEnabled: boolean, public allowRefreshToken: boolean, public refreshTokenExpirationDays: number, public serverUrl ? : string, public secret ? : string, public clientSecret ? : string, public customFieldMappingClient ? : string, public customFieldMappingMatter ? : string, public customerId ? : string) {}
}
class ClaimsMappingDto {
    constructor(public claim ? : string, public key ? : string) {}
}
class OidcDto {
    constructor(public isEnabled: boolean, public validateIssuer: boolean, public authority ? : string, public responseType ? : string, public clientId ? : string, public clientSecret ? : string, public claimsMapping ? : ClaimsMappingDto[]) {}
}
class SamlDto {
    constructor(public isEnabled: boolean, public allowUnsolicitedResponses: boolean, public entityId ? : string, public metadataUrl ? : string) {}
}
class OdicDuoDto {
    constructor(public isEnabled: boolean, public apiHostname ? : string, public clientId ? : string, public clientSecret ? : string, public claimsMapping ? : ClaimsMappingDto[]) {}
}
class SsoSettingsDto {
    constructor(public allowSocialLoginSettingsPerTenant: boolean, public ottSingleUse: boolean, public externalUsernameRequired: boolean, public externalLoginOnly: boolean, public externalAutoLogin: boolean, public sessionExpirationHours ? : string, public ottLifespanMinutes ? : string, public externalProvider ? : string, public externalLoginText ? : string, public oidc ? : OidcDto, public saml ? : SamlDto, public odicDuo ? : OdicDuoDto) {}
}
class TenantUserManagementSettingsEditDto {
    constructor(public allowSelfRegistration: boolean, public isNewRegisteredUserActiveByDefault: boolean, public isEmailConfirmationRequiredForLogin: boolean, public useCaptchaOnRegistration: boolean, public useCaptchaOnLogin: boolean, public isCookieConsentEnabled: boolean, public isQuickThemeSelectEnabled: boolean, public sessionTimeOutSettings ? : SessionTimeOutSettingsEditDto, public autoSaveSettings ? : ClientAutoSaveSettingsDto) {}
}
class DemoSettingsDto {
    constructor(public isEnabled: boolean, public themesRibbonEnabled: boolean, public maxThemes: number, public timelinesUrl ? : string) {}
}
class LdapSettingsEditDto {
    constructor(public isModuleEnabled: boolean, public isEnabled: boolean, public port: number, public domain ? : string, public userName ? : string, public container ? : string, public userNameAttribute ? : string, public searchFilter ? : string, public password ? : string) {}
}
class LicenseSettingsEditDto {
    constructor(public isLicenceServerEnabled: boolean, public autoAssignLicenses: boolean, public license ? : GetLicenseForViewDto, public licenceString ? : string, public toLicenceEmail ? : string, public ccToLicenceEmail ? : string, public bccToLicenceEmail ? : string) {}
}
class TenantBillingSettingsEditDto {
    constructor(public legalName ? : string, public address ? : string, public taxVatNo ? : string) {}
}
class TenantOtherSettingsEditDto {
    constructor(public isQuickThemeSelectEnabled: boolean) {}
}
class DefaultContentStylesSettingsDto {
    constructor(public defaultComponentsStyles ? : string) {}
}
class TenantSettingsEditDto {
    constructor(public general ? : GeneralSettingsEditDto, public ottConfig ? : OttConfigDto, public document ? : DocumentConfigurationDto, public diagramSettings ? : DiagramConfigurationDto, public iManageSettings ? : IManageConfigurationDto, public documentProfilingSettings ? : DocumentProfilingDto, public seasonalThemingSettings ? : SeasonalThemingDto, public ssoSettings ? : SsoSettingsDto, public userManagement ? : TenantUserManagementSettingsEditDto, public email ? : EmailSettingsEditDto, public demo ? : DemoSettingsDto, public ldap ? : LdapSettingsEditDto, public license ? : LicenseSettingsEditDto, public feedbackSettings ? : EmailRecipientsDto, public security ? : SecuritySettingsEditDto, public billing ? : TenantBillingSettingsEditDto, public otherSettings ? : TenantOtherSettingsEditDto, public externalLoginProviderSettings ? : ExternalLoginProviderSettingsEditDto, public tourGuideSettings ? : TourGuideConfigurationDto, public defaultContentStylesSettings ? : DefaultContentStylesSettingsDto) {}
}
class GetThemeElementForViewDto {
    constructor(public themeElement ? : ThemeElementDto, public themeName ? : string) {}
}
class PagedResultDtoOfGetThemeElementForViewDto {
    constructor(public totalCount: number, public items ? : GetThemeElementForViewDto[]) {}
}
class CreateOrEditThemeElementDto {
    constructor(public themeId: number, public systemOwned: boolean, public sortOrder: number, public name ? : string, public displayName ? : string, public defaultLabel ? : string, public style ? : any, public edgeLabelPosition ? : EdgeLabelPosition, public nodeLabelPosition ? : NodeLabelPosition, public indicatorsPosition ? : IndicatorsPosition, public relationshipType ? : RelationshipType, public elementType ? : ElementType, public behaviourId ? : number, public behaviour ? : ElementBehaviourDto, public defaultEntityTypeId ? : number, public suggestedEntityTypeIds ? : number[], public id ? : number) {}
}
class GetThemeElementForEditOutput {
    constructor(public themeElement ? : CreateOrEditThemeElementDto) {}
}
class ChangeThemeElementsSortOrderInput {
    constructor(public themeId: number, public elementIds ? : number[]) {}
}
class GetThemeForViewDto {
    constructor(public theme ? : ThemeDto, public logo ? : string) {}
}
class PagedResultDtoOfGetThemeForViewDto {
    constructor(public totalCount: number, public items ? : GetThemeForViewDto[]) {}
}
class CloneThemeDto {
    constructor(public id: number, public name ? : string) {}
}
class SetDefaultThemeInput {
    constructor(public themeId: number) {}
}
class GetThemeOutput {
    constructor(public isThemeUsedByOthers: boolean, public theme ? : ThemeDto) {}
}
class CreateOrEditThemeDto {
    constructor(public isEditable: boolean, public isApplicable: boolean, public isDefault: boolean, public showBackgroundPanel: boolean, public sortOrder: number, public name ? : string, public creatorUserId ? : number, public swatch ? : any, public logoPosition ? : PageElementPosition, public legendPosition ? : PageElementPosition, public attachmentType ? : AttachmentType, public elements ? : ThemeElementDto[], public attachments ? : ThemeAttachmentDto[], public themeDataPropertyItems ? : ThemeDataPropertyItemDto[], public tableSwatch ? : any, public fontStyles ? : FontStyleDto[], public textBoxes ? : ThemeTextBoxDto[], public timelineTheme ? : string, public id ? : number) {}
}
class ChangeThemeSortOrderInput {
    constructor(public themeIds ? : number[]) {}
}
class ChangeThemeTextBoxesSortOrderInput {
    constructor(public themeId: number, public textBoxIds ? : number[]) {}
}
class CloneTimelineAttachmentDto {
    constructor(public sourceTimelineId: number, public targetTimelineId: number, public id: number, public sourceTimelineEventId ? : number, public sourceTimelineTextBoxId ? : string, public sourceTimelineResourceId ? : number, public targetTimelineEventId ? : number, public targetTimelineTextBoxId ? : string, public targetTimelineResourceId ? : number) {}
}
class TimelineExportRecordDto {
    constructor(public eventNumber: number, public eventName ? : string, public startDate ? : string, public endDate ? : string, public eventType ? : string, public swimlane ? : string, public dependencies ? : string) {}
}
class TimelineExportDto {
    constructor(public records ? : TimelineExportRecordDto[]) {}
}
class TimelineAttachmentExportDto {
    constructor(public timelineId: number, public timelineFileName ? : string, public timelineFile ? : string) {}
}
class CloneTimelineDto {
    constructor(public documentId: number, public id: number, public timeline ? : TimelineDto) {}
}
class CreateOrEditTimelineDto {
    constructor(public isTemplate: boolean, public documentId: number, public name ? : string, public description ? : string, public timelineJson ? : string, public attachments ? : FileAttachmentDto[], public id ? : number) {}
}
enum SettingScopes {
    Application = 1, Tenant = 2, User = 4, All = 7
}
class ListResultDtoOfNameValueDto {
    constructor(public items ? : NameValueDto[]) {}
}
class ImportUsersResultDto {
    constructor(public success: boolean, public invalidFile ? : FileDto) {}
}
class UserListRoleDto {
    constructor(public roleId: number, public roleName ? : string) {}
}
class UserListDto {
    constructor(public isEmailConfirmed: boolean, public isActive: boolean, public isLicensed: boolean, public creationTime: string, public id: number, public name ? : string, public surname ? : string, public userName ? : string, public emailAddress ? : string, public phoneNumber ? : string, public profilePictureId ? : string, public roles ? : UserListRoleDto[], public lastActivityTime ? : string) {}
}
class PagedResultDtoOfUserListDto {
    constructor(public totalCount: number, public items ? : UserListDto[]) {}
}
class ActivateUsersDto {
    constructor(public active: boolean, public userIds ? : number[]) {}
}
class UserEditDto {
    constructor(public name: string, public surname: string, public emailAddress: string, public isActive: boolean, public shouldChangePasswordOnNextLogin: boolean, public isTwoFactorEnabled: boolean, public isLockoutEnabled: boolean, public isLicensed: boolean, public hasTemporaryAccessTimer: boolean, public id ? : number, public userName ? : string, public phoneNumber ? : string, public password ? : string, public authenticationSource ? : string, public temporaryAccessTimerHours ? : number, public temporaryAccessTimerDays ? : number, public temporaryAccessTimerWeeks ? : number, public lastActivityTime ? : string) {}
}
class UserRoleDto {
    constructor(public roleId: number, public isAssigned: boolean, public inheritedFromOrganizationUnit: boolean, public hide: boolean, public roleName ? : string, public roleDisplayName ? : string) {}
}
class GetUserForEditOutput {
    constructor(public profilePictureId ? : string, public user ? : UserEditDto, public roles ? : UserRoleDto[], public allOrganizationUnits ? : OrganizationUnitDto[], public memberedOrganizationUnits ? : string[]) {}
}
class GetUserPermissionsForEditOutput {
    constructor(public permissions ? : FlatPermissionDto[], public grantedPermissionNames ? : string[]) {}
}
class EntityDtoOfInt64 {
    constructor(public id: number) {}
}
class UpdateUserPermissionsInput {
    constructor(public id: number, public grantedPermissionNames: []) {}
}
class CreateOrUpdateUserInput {
    constructor(public assignedRoleNames: [], public sendActivationEmail: boolean, public setRandomPassword: boolean, public user ? : UserEditDto, public organizationUnits ? : number[]) {}
}
class CreateOrUpdateUserOutput {
    constructor(public forceLogout: boolean) {}
}
class UserDelegationDto {
    constructor(public startTime: string, public endTime: string, public id: number, public username ? : string) {}
}
class PagedResultDtoOfUserDelegationDto {
    constructor(public totalCount: number, public items ? : UserDelegationDto[]) {}
}
class CreateUserDelegationDto {
    constructor(public targetUserId: number, public startTime: string, public endTime: string) {}
}
class LinkToUserInput {
    constructor(public usernameOrEmailAddress: string, public password: string, public tenancyName ? : string) {}
}
class LinkedUserDto {
    constructor(public id: number, public tenantId ? : number, public tenancyName ? : string, public username ? : string) {}
}
class PagedResultDtoOfLinkedUserDto {
    constructor(public totalCount: number, public items ? : LinkedUserDto[]) {}
}
class ListResultDtoOfLinkedUserDto {
    constructor(public items ? : LinkedUserDto[]) {}
}
class UnlinkUserInput {
    constructor(public userId: number, public tenantId ? : number) {}
}
class UserLoginAttemptDto {
    constructor(public creationTime: string, public tenancyName ? : string, public userNameOrEmail ? : string, public clientIpAddress ? : string, public clientName ? : string, public browserInfo ? : string, public result ? : string) {}
}
class ListResultDtoOfUserLoginAttemptDto {
    constructor(public items ? : UserLoginAttemptDto[]) {}
}
class UserResetDto {
    constructor(public email ? : string, public host ? : string) {}
}
class ResetPasswordDto {
    constructor(public code ? : string, public password ? : string) {}
}
class GetLatestWebLogsOutput {
    constructor(public latestWebLogLines ? : string[]) {}
}
enum MissingCellPolicy {
    RETURN_NULL_AND_BLANK = 1, RETURN_BLANK_AS_NULL = 2, CREATE_NULL_AS_BLANK = 3
}
class SpreadsheetVersion {
    constructor(public maxRows: number, public lastRowIndex: number, public maxColumns: number, public lastColumnIndex: number, public maxFunctionArgs: number, public maxConditionalFormats: number, public maxCellStyles: number, public maxTextLength: number, public name ? : string, public defaultExtension ? : string, public lastColumnName ? : string) {}
}
class IWorkbook {
    constructor(public activeSheetIndex: number, public firstVisibleTab: number, public numberOfSheets: number, public numberOfFonts: number, public numCellStyles: number, public numberOfNames: number, public isHidden: boolean, public missingCellPolicy ? : MissingCellPolicy, public spreadsheetVersion ? : SpreadsheetVersion) {}
}
enum NodeShape {
    Circle = 0, Oval = 1, Rectangle = 2, Square = 3, Triangle = 4, RoundedRectangle = 5, Diamond = 6, ShearedRectangle = 7, ShearedRectangle2 = 8, Triangle2 = 9, Star5 = 10, Star6 = 11, Star8 = 12, Trapez = 13, Trapez2 = 14, Hexagon = 15, Octagon = 16, FatArrow = 17, FatArrow2 = 18, Pill = 19, TrapezShort = 20, Diamond2 = 21, None = 22
}
enum JigsawPathShape {
    Pentagon = 0
}
enum NodeSize {
    ExtraExtraSmall = 0, ExtraSmall = 1, Small = 2, Medium = 3, Large = 4, ExtraLarge = 5
}
enum CompositeShape {
    RoundedRectangleWithTriangle = 0, RoundedRectangleWithOval = 1, TriangleWithRectangle = 2, TriangleWithOval = 3, OvalWithRectangle = 4, OvalWithTriangle = 5, RectangleWithReversedTriangle = 6, RectangleWithTriangle = 7, OvalWithTriangle2 = 8, RectangleWithCross = 9, RectangleWithOval = 10, RectanglesWithOval3 = 11, RectangleWithDiamond = 12, RectangleWithRectangle = 13, RectangleWithRectangleWithRectangle = 14, TriangleWithTriangleWithTriangle = 15, OvalWithOvalWithOval = 16, RoundedRectangleWithTriangle2 = 17, TriangleWithTriangle = 18, RectanglesWithOval2 = 20, RectanglesWithTriangle2 = 21, RectanglesWithTriangle3 = 22, SquareWithTriangle = 23, RoundedRectangleWithCross = 24
}
enum TextFit {
    Overflow = 0, ResizeShape = 1, ShrinkText = 2
}
enum DividingLineType {
    Horizontal = 0, Vertical = 1
}
class ShapeNodeStyleDto {
    constructor(public fill ? : IFillDto, public stroke ? : StrokeDto, public shape ? : NodeShape, public size ? : NodeSize, public visualType ? : NodeVisualType, public labelStyle ? : LabelStyleDto) {}
}
class JigsawPathShapeNodeStyleDto {
    constructor(public fill ? : IFillDto, public stroke ? : StrokeDto, public shape ? : JigsawPathShape, public size ? : NodeSize, public visualType ? : NodeVisualType, public labelStyle ? : LabelStyleDto) {}
}
class CompositeNodeStyleShapeDto {
    constructor(public fill ? : IFillDto, public stroke ? : StrokeDto, public shape ? : NodeShape) {}
}
class CompositeStyleDefinitionDto {
    constructor(public nodeStyle ? : CompositeNodeStyleShapeDto, public insets ? : InsetsDto) {}
}
class CompositeNodeStyleDto {
    constructor(public shape ? : CompositeShape, public styleDefinitions ? : CompositeStyleDefinitionDto[], public visualType ? : NodeVisualType, public size ? : NodeSize, public labelStyle ? : LabelStyleDto) {}
}
class ImageNodeStyleDto {
    constructor(public visualType ? : NodeVisualType, public imageUrl ? : string, public imageRatio ? : number, public naturalHeight ? : number, public naturalWidth ? : number, public rotation ? : number, public labelStyle ? : LabelStyleDto) {}
}
class TextBoxNodeStyleDto {
    constructor(public visualType ? : NodeVisualType, public fill ? : IFillDto, public stroke ? : StrokeDto) {}
}
class GroupNodeStyleDto {
    constructor(public visualType ? : NodeVisualType) {}
}
class DividingLineNodeStyleDto {
    constructor(public visualType ? : NodeVisualType, public lineType ? : DividingLineType, public stroke ? : StrokeDto) {}
}
enum CellAlignment {
    Center = 0, Top = 1, Bottom = 2
}
class TCellOptionsDto {
    constructor(public fontSize ? : number, public fontFamily ? : string, public backgroundColour ? : string, public colour ? : string, public alignment ? : CellAlignment) {}
}
class TImageCellOptionsDto {
    constructor(public imageWidth: number, public imageHeight: number, public fontSize ? : number, public fontFamily ? : string, public backgroundColour ? : string, public colour ? : string, public alignment ? : CellAlignment) {}
}
class FileItemDto {
    constructor(public id: number, public isFavourite: boolean, public isPublic: boolean, public hasSteps: boolean, public hasTimelines: boolean, public type ? : FolderItemType, public lockedByUser ? : UserDto, public creatorUser ? : UserDto, public ownerUser ? : UserDto, public lastModifierUser ? : UserDto, public lockedCreated ? : string, public lastModificationTime ? : string, public creationTime ? : string, public deletionTime ? : string, public sharedWith ? : UserDto[], public name ? : string, public removalTime ? : string, public currentUserAccessLevel ? : DocumentAccessLevel, public defaultAccessLevel ? : DocumentAccessLevel, public pathSegments ? : string[], public path ? : string, public thumbnailPath ? : string, public accessType ? : AccessType, public externalFileId ? : string, public externalFileDetails ? : any, public matter ? : string, public client ? : string) {}
}
enum NotificationTypes {
    Success = 0, Info = 1, Warning = 2, Error = 3
}
enum RealtimeMessageType {
    Message = 0, RequestAccess = 1, AccessGranted = 2, DocumentUnLocked = 3
}
class RealtimeMessageDto {
    constructor(public title ? : string, public notificationType ? : NotificationTypes, public payload ? : string, public messageType ? : RealtimeMessageType) {}
}
class UsersRealtimeMessageDto {
    constructor(public userIds ? : number[], public title ? : string, public notificationType ? : NotificationTypes, public payload ? : string, public messageType ? : RealtimeMessageType) {}
}
class JurisdictionColumnMetadataDto {
    constructor(public showJurisdictionDecorator: boolean, public showStateDecorator: boolean, public showJurisdictionText: boolean, public showStateText: boolean, public showSingleTextValue: boolean) {}
}
class DataPropertyColumnMetadataDto {
    constructor(public dataPropertyDefinitionName ? : string, public dataPropertyType ? : DataPropertyType) {}
}
class ImportExcelMetadataDto {
    constructor(public targetSheet ? : string, public type ? : ImportType) {}
}
class GradientStopDto {
    constructor(public offset: number, public color ? : string) {}
}
enum GradientSpreadMethod {
    Pad = 0, Reflect = 1, Repeat = 2
}
class LinearGradientDto {
    constructor(public type ? : FillType, public gradientStops ? : GradientStopDto[], public spreadMethod ? : GradientSpreadMethod, public startPoint ? : PointDto, public endPoint ? : PointDto) {}
}
class SolidColorFillDto {
    constructor(public type ? : FillType, public color ? : string) {}
}
class ZadarResponse < T > {
    public result: T;public success: boolean;public error: ErrorInfo;
}
class ErrorInfo {
    public code: number;
    public message: string;
    public details: string;
    public validationError: ValidationErrorInfo[];
}
class ValidationErrorInfo {
    public message: string;
    public members: string[];
}
export {
    ZadarResponse,
    ErrorInfo,
    ValidationErrorInfo,
    DocumentCounts,
    DocumentActivity,
    CurrentAccount,
    IsTenantAvailableInput,
    TenantAvailabilityState,
    IsTenantAvailableOutput,
    ResolveTenantIdInput,
    RegisterInput,
    RegisterOutput,
    SendPasswordResetCodeInput,
    ResetPasswordInput,
    ResetPasswordOutput,
    SendEmailActivationLinkInput,
    ActivateEmailInput,
    ImpersonateInput,
    ImpersonateOutput,
    DelegatedImpersonateInput,
    SwitchToLinkedAccountInput,
    SwitchToLinkedAccountOutput,
    AuditLogListDto,
    PagedResultDtoOfAuditLogListDto,
    FileDto,
    NameValueDto,
    EntityChangeType,
    EntityChangeListDto,
    PagedResultDtoOfEntityChangeListDto,
    EntityPropertyChangeDto,
    CacheDto,
    ListResultDtoOfCacheDto,
    EntityDtoOfString,
    FindUsersInput,
    PagedResultDtoOfNameValueDto,
    GetDefaultEditionNameOutput,
    DataPropertyDto,
    GetDataPropertyForViewDto,
    PagedResultDtoOfGetDataPropertyForViewDto,
    CreateOrEditDataPropertyDto,
    DataPropertyType,
    DataPropertyValueScope,
    DataPropertyDefinitionItemDto,
    DataPropertyDefinitionDto,
    GetDataPropertyDefinitionsByDocumentIdOutput,
    GetDataPropertyDefinitionForViewDto,
    CreateOrEditDataPropertyDefinitionDto,
    GetDataPropertyDefinitionForEditOutput,
    DataPropertyTagContext,
    DataPropertyTagDto,
    GetDataPropertyTagForViewDto,
    PagedResultDtoOfGetDataPropertyTagForViewDto,
    DataPropertyValueDto,
    GetDataPropertyValueForViewDto,
    PagedResultDtoOfGetDataPropertyValueForViewDto,
    CreateOrEditDataPropertyValueDto,
    GetDataPropertyValueForEditOutput,
    DataPropertyValueDataPropertyItemLookupTableDto,
    PagedResultDtoOfDataPropertyValueDataPropertyItemLookupTableDto,
    InsetsDto,
    PageStyleBoundariesPresetType,
    PageStylePaddingDto,
    DocumentPageType,
    DocumentPageContentType,
    DocumentPageLayoutType,
    PageStylePaddingLegacyDto,
    FillType,
    IFillDto,
    DashStyleType,
    DashStyleDto,
    StrokeDto,
    PageStyleDto,
    HeaderFooterStyleDto,
    LayoutDto,
    NodeVisualType,
    INodeStyleDto,
    FileAttachmentDto,
    PowerPointDiagramNodeDto,
    FontDto,
    PointDto,
    LabelStyleDto,
    ArrowStyleDto,
    EdgeVisualType,
    EdgeStyleDto,
    PortDirection,
    EdgePortDto,
    PathCommandTypes,
    PathCommand,
    PowerPointDiagramEdgeDto,
    DiagramPosition,
    TCellContentType,
    TCellContentDto,
    TCellDto,
    TRowOptionsDto,
    TRowDto,
    TColumnOptionsDto,
    TColumnDto,
    PageElementPosition,
    TDataDto,
    LayoutItemRotation,
    LayoutItemDto,
    LayoutDefinitionDto,
    NodeGroupDto,
    PowerPointExportSlideDto,
    PowerPointExportDto,
    PowerPointFileDto,
    DataExportFileAttachmentDto,
    DataExportDataPropertyDto,
    DataExportNodeDto,
    DataExportEdgeDto,
    DataExportDiagramDto,
    DataExportDocumentDto,
    DiagramImportRowValueDto,
    DiagramImportRowDto,
    GetDiagramImportRowForViewDto,
    PagedResultDtoOfGetDiagramImportRowForViewDto,
    CreateOrEditDiagramImportRowDto,
    GetDiagramImportRowForEditOutput,
    DiagramImportRowDiagramImportLookupTableDto,
    PagedResultDtoOfDiagramImportRowDiagramImportLookupTableDto,
    GetDiagramImportRowValueForViewDto,
    PagedResultDtoOfGetDiagramImportRowValueForViewDto,
    CreateOrEditDiagramImportRowValueDto,
    GetDiagramImportRowValueForEditOutput,
    DiagramImportRowValueDiagramImportRowLookupTableDto,
    PagedResultDtoOfDiagramImportRowValueDiagramImportRowLookupTableDto,
    DiagramImportRowValueDiagramTemplateLookupTableDto,
    PagedResultDtoOfDiagramImportRowValueDiagramTemplateLookupTableDto,
    DiagramImportDto,
    GetDiagramImportForViewDto,
    PagedResultDtoOfGetDiagramImportForViewDto,
    CreateOrEditDiagramImportDto,
    GetDiagramImportForEditOutput,
    DiagramImportDiagramLookupTableDto,
    PagedResultDtoOfDiagramImportDiagramLookupTableDto,
    SizeDto,
    DiagramLayoutDto,
    DiagramViewDto,
    DocumentView,
    DiagramViewsDto,
    DiagramGroupSettingsDto,
    DiagramNodeDto,
    DiagramEdgeDto,
    DiagramTemplateType,
    DiagramTemplateDto,
    QuickStartChildMappingDto,
    QuickStartState,
    QuickStartStateDataDto,
    FlipbookState,
    DiagramDto,
    GetDiagramForViewDto,
    CloneDiagramDto,
    AllConnection,
    SemanticConnection,
    NodeConnection,
    Connections,
    Cv,
    Ocr,
    AIOutputDto,
    FileBase64Dto,
    GetDiagramTemplateForViewDto,
    PagedResultDtoOfGetDiagramTemplateForViewDto,
    CreateOrEditDiagramTemplateDto,
    GetDiagramTemplateForEditOutput,
    UserDto,
    DocumentAccessLevel,
    DocumentAccessDto,
    DocumentAccessRequestDto,
    PendingAccessRequestDto,
    GetPendingAccessRequestsOutputDto,
    RequestDocumentAccessDto,
    RequestDocumentAccessOutputDto,
    GrantDocumentAccessDto,
    ChangeDocumentAccessDto,
    ChangeDocumentIsPublicDto,
    ChangeDocumentDefaultAccessLevelDto,
    TimelineDto,
    PageOrientation,
    FontStyleDto,
    DocumentSubPageDto,
    DocumentPageDto,
    GetDocumentPageForViewDto,
    DocumentFilterDto,
    CreateOrEditDocumentPageDto,
    CreateOrEditDocumentPageOutput,
    StepsPagePropertiesDto,
    SidebarCategory,
    DocumentPaletteCategory,
    DocumentPaletteItemDto,
    DocumentProfileHistoryReason,
    CreateDocumentProfileHistoryReasonDto,
    DocumentProfileHistoryDto,
    PagedResultDtoOfDocumentProfileHistoryDto,
    AttachmentType,
    DocumentAttachmentDto,
    NodeStyleOverrideDto,
    DataPropertyStyleDto,
    QuickBuildSettingsDto,
    DocumentDto,
    DocumentProfileDocumentHistoryDto,
    DocumentProfilingInputDto,
    EditDocumentProfileDto,
    EditDocumentAutoSaveDto,
    EditDocumentUsePresetPropertiesDto,
    MarkAsFavouriteRequest,
    DocumentSharingDetailsDto,
    DocumentPreviewDetailsDto,
    DocumentActivityBy,
    EdgeLabelPosition,
    NodeLabelPosition,
    IndicatorsPosition,
    ElementType,
    RelationshipType,
    ElementBehaviourDto,
    ThemeElementDto,
    ThemeAttachmentDto,
    ThemeDataPropertyItemDto,
    ThemeTextBoxDto,
    ThemeDto,
    ErrorDto,
    GetDocumentForViewDto,
    CreateOrEditDocumentDto,
    CreateOrEditDocumentOutput,
    CloneDocumentDto,
    EntityDto,
    RestoreDeletedEntityRequestDto,
    GetElementBehaviourForViewDto,
    PagedResultDtoOfGetElementBehaviourForViewDto,
    EmailTemplateDto,
    EmailRecipientDto,
    PrepareEmailTemplateRequestDto,
    PreparedEmailDto,
    EntityTypeDto,
    CreateOrEditEntityTypeDto,
    GetFileAttachmentForViewDto,
    PagedResultDtoOfGetFileAttachmentForViewDto,
    CreateOrEditFileAttachmentDto,
    GetFileAttachmentForEditOutput,
    UploadTimelinesFileOutput,
    FolderItemType,
    AccessType,
    IFolderItemDto,
    FolderSearchType,
    SearchFolderInputDto,
    SearchFolderResultDto,
    ListFolderContentsInputDto,
    FolderAccessLevel,
    FolderItemDto,
    FolderItemListResultDto,
    CreateFolderDto,
    RenameFolderRequestDto,
    DeleteFolderDto,
    MoveItemDto,
    MoveItemsRequestDto,
    CopyItemDto,
    CopyItemsRequestDto,
    GrantFolderAccessDto,
    RevokeFolderAccessDto,
    FolderAccessDto,
    GeneralSettingsEditDto,
    SessionTimeOutSettingsEditDto,
    HostUserManagementSettingsEditDto,
    EmailSettingsEditDto,
    TenantManagementSettingsEditDto,
    PasswordComplexitySetting,
    UserLockOutSettingsEditDto,
    TwoFactorLoginSettingsEditDto,
    SecuritySettingsEditDto,
    HostBillingSettingsEditDto,
    OtherSettingsEditDto,
    FacebookExternalLoginProviderSettings,
    GoogleExternalLoginProviderSettings,
    TwitterExternalLoginProviderSettings,
    MicrosoftExternalLoginProviderSettings,
    ExternalLoginProviderSettingsEditDto,
    HostSettingsEditDto,
    SendTestEmailInput,
    SendTestLdapInput,
    SendTestIManageApiInput,
    ClientAutoSaveSettingsDto,
    ClientDocumentSettingsDto,
    EmailRecipientsDto,
    TourGuideConfigurationDto,
    EmailSettingsDto,
    IManageSettingsDto,
    DocumentProfilingDto,
    SeasonalThemingDto,
    PasswordConfigDto,
    OttConfigDto,
    ClientHostSettingsDto,
    App,
    IManageUser,
    Version,
    Library,
    Work,
    GetUserInformationModel,
    GetAvailableLibrariesModel,
    GetRecentFoldersModel,
    Target,
    GetMyMattersModel,
    GetHubDetailModel,
    SearchMatterModel,
    GetWorkspaceFoldersModel,
    GetFolderContentsModel,
    GetDialogUrlModel,
    GetDialogTokenModel,
    DocProfile,
    Profile,
    SaveDocumentModel,
    AuthorInfo,
    LastUserInfo,
    OperatorInfo,
    SaveDocumentResponseModel,
    LockDocumentModel,
    UpdateDocumentModel,
    GetDocumentCheckoutModel,
    Operations,
    GetDocumentProfileModel,
    GetDocumentPathDetailModel,
    GetDocumentPathModel,
    HttpStatusCode,
    GetApiInformationModel,
    GetApiModel,
    SpreadsheetColumnDto,
    ImportSpreadsheet,
    ImportDefinitionColumnType,
    MappingType,
    MappingValueDto,
    MappingDto,
    ImportDefinitionColumnDto,
    ImportDefinitionDto,
    ProcessFileRequestDto,
    ImportValidationErrorDto,
    ImportType,
    ImportResultDto,
    ProcessImportedFileDto,
    ImportedFileResultDto,
    ImportDto,
    PagedResultDtoOfImportDto,
    InformationBoardItemType,
    InformationBoardItemDto,
    GetTranslationsOutput,
    ApplicationLanguageListDto,
    GetLanguagesOutput,
    ApplicationLanguageEditDto,
    ComboboxItemDto,
    GetLanguageForEditOutput,
    CreateOrUpdateLanguageInput,
    SetDefaultLanguageInput,
    LanguageTextListDto,
    PagedResultDtoOfLanguageTextListDto,
    UpdateLanguageTextInput,
    LicenseUserDto,
    LicenseUserResultDto,
    LicenceFromHostDto,
    CreateOrEditLicenseDto,
    GetLicenseForEditOutput,
    LicenseDto,
    LicenseTypes,
    GetLicenseForViewDto,
    LicenceDetails,
    UserNotificationState,
    NotificationData,
    NotificationSeverity,
    TenantNotification,
    UserNotification,
    GetNotificationsOutput,
    EntityDtoOfGuid,
    NotificationSubscriptionWithDisplayNameDto,
    GetNotificationSettingsOutput,
    NotificationSubscriptionDto,
    UpdateNotificationSettingsInput,
    OrganizationUnitDto,
    ListResultDtoOfOrganizationUnitDto,
    OrganizationUnitUserListDto,
    PagedResultDtoOfOrganizationUnitUserListDto,
    OrganizationUnitRoleListDto,
    PagedResultDtoOfOrganizationUnitRoleListDto,
    CreateOrganizationUnitInput,
    UpdateOrganizationUnitInput,
    MoveOrganizationUnitInput,
    UsersToOrganizationUnitInput,
    RolesToOrganizationUnitInput,
    FindOrganizationUnitUsersInput,
    FindOrganizationUnitRolesInput,
    ListItemStyleDto,
    ListDefaultFormattingDto,
    ListSpacingDto,
    ListComponentDto,
    PageDesignSetComponentsDto,
    PageDesignAttachmentDto,
    PageTitleDto,
    PageDesignDto,
    PageDesignSetDto,
    PagedResultDtoOfPageDesignSetDto,
    CreatePageDesignSetResponseDto,
    PersonDto,
    CreateOrEditPersonDto,
    KeyValuePairOfStringString,
    FlatPermissionWithLevelDto,
    ListResultDtoOfFlatPermissionWithLevelDto,
    CurrentUserProfileEditDto,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    VerifySmsCodeInputDto,
    ChangePasswordInput,
    UpdateProfilePictureInput,
    GetPasswordComplexitySettingOutput,
    GetProfilePictureOutput,
    ChangeUserLanguageDto,
    RoleListDto,
    ListResultDtoOfRoleListDto,
    RoleEditDto,
    FlatPermissionDto,
    GetRoleForEditOutput,
    CreateOrUpdateRoleInput,
    UserLoginInfoDto,
    EditionInfoDto,
    TenantLoginInfoDto,
    ApplicationInfoDto,
    ThemeLayoutSettingsDto,
    ThemeHeaderSettingsDto,
    ThemeSubHeaderSettingsDto,
    ThemeMenuSettingsDto,
    ThemeFooterSettingsDto,
    ThemeSettingsDto,
    UiCustomizationSettingsDto,
    GetCurrentLoginInformationsOutput,
    UpdateUserSignInTokenOutput,
    TenantListDto,
    PagedResultDtoOfTenantListDto,
    CreateTenantInput,
    TenantEditDto,
    UpdateTenantFeaturesInput,
    MemberActivity,
    GetMemberActivityOutput,
    SalesSummaryDatePeriod,
    SalesSummaryData,
    GetDashboardDataOutput,
    GetTopStatsOutput,
    GetProfitShareOutput,
    GetDailySalesOutput,
    GetSalesSummaryOutput,
    RegionalStatCountry,
    GetRegionalStatsOutput,
    GetGeneralStatsOutput,
    DocumentConfigurationDto,
    DiagramConfigurationDto,
    IManageConfigurationDto,
    ClaimsMappingDto,
    OidcDto,
    SamlDto,
    OdicDuoDto,
    SsoSettingsDto,
    TenantUserManagementSettingsEditDto,
    DemoSettingsDto,
    LdapSettingsEditDto,
    LicenseSettingsEditDto,
    TenantBillingSettingsEditDto,
    TenantOtherSettingsEditDto,
    DefaultContentStylesSettingsDto,
    TenantSettingsEditDto,
    GetThemeElementForViewDto,
    PagedResultDtoOfGetThemeElementForViewDto,
    CreateOrEditThemeElementDto,
    GetThemeElementForEditOutput,
    ChangeThemeElementsSortOrderInput,
    GetThemeForViewDto,
    PagedResultDtoOfGetThemeForViewDto,
    CloneThemeDto,
    SetDefaultThemeInput,
    GetThemeOutput,
    CreateOrEditThemeDto,
    ChangeThemeSortOrderInput,
    ChangeThemeTextBoxesSortOrderInput,
    CloneTimelineAttachmentDto,
    TimelineExportRecordDto,
    TimelineExportDto,
    TimelineAttachmentExportDto,
    CloneTimelineDto,
    CreateOrEditTimelineDto,
    SettingScopes,
    ListResultDtoOfNameValueDto,
    ImportUsersResultDto,
    UserListRoleDto,
    UserListDto,
    PagedResultDtoOfUserListDto,
    ActivateUsersDto,
    UserEditDto,
    UserRoleDto,
    GetUserForEditOutput,
    GetUserPermissionsForEditOutput,
    EntityDtoOfInt64,
    UpdateUserPermissionsInput,
    CreateOrUpdateUserInput,
    CreateOrUpdateUserOutput,
    UserDelegationDto,
    PagedResultDtoOfUserDelegationDto,
    CreateUserDelegationDto,
    LinkToUserInput,
    LinkedUserDto,
    PagedResultDtoOfLinkedUserDto,
    ListResultDtoOfLinkedUserDto,
    UnlinkUserInput,
    UserLoginAttemptDto,
    ListResultDtoOfUserLoginAttemptDto,
    UserResetDto,
    ResetPasswordDto,
    GetLatestWebLogsOutput,
    MissingCellPolicy,
    SpreadsheetVersion,
    IWorkbook,
    NodeShape,
    JigsawPathShape,
    NodeSize,
    CompositeShape,
    TextFit,
    DividingLineType,
    ShapeNodeStyleDto,
    JigsawPathShapeNodeStyleDto,
    CompositeNodeStyleShapeDto,
    CompositeStyleDefinitionDto,
    CompositeNodeStyleDto,
    ImageNodeStyleDto,
    TextBoxNodeStyleDto,
    GroupNodeStyleDto,
    DividingLineNodeStyleDto,
    CellAlignment,
    TCellOptionsDto,
    TImageCellOptionsDto,
    FileItemDto,
    NotificationTypes,
    RealtimeMessageType,
    RealtimeMessageDto,
    UsersRealtimeMessageDto,
    JurisdictionColumnMetadataDto,
    DataPropertyColumnMetadataDto,
    ImportExcelMetadataDto,
    GradientStopDto,
    GradientSpreadMethod,
    LinearGradientDto,
    SolidColorFillDto
}