import { IModelItem, Point, Rect } from 'yfiles';
import Button from './Button';
import JigsawButtonInputMode from './JigsawButtonInputMode';
import IButtonGroupOptions from './IButtonGroupOptions';
import ButtonCollection from './ButtonCollection';
import ButtonDescriptor from './ButtonDescriptor';

export default class ButtonGroup extends Button {
  public readonly buttons: ButtonCollection;
  constructor(owner: IModelItem, options: IButtonGroupOptions) {
    super(owner, {
      anchor: options.anchor,
      tag: options.tag,
      fill: options.fill,
      stroke: options.stroke,
      strokeWidth: options.strokeWidth,
      cornerRadius: options.cornerRadius,
      onAction: (inputMode, button, location) =>
        this.actionHandler(inputMode, button as ButtonGroup, location),
      canHide: (inputMode, button) => this.canHideHandler(inputMode)
    });

    this.buttons = new ButtonCollection(
      options.buttonOptions.map((options) => {
        return new Button(this.owner, options);
      })
    );
  }

  private canHideHandler(inputMode: JigsawButtonInputMode): boolean {
    return this.buttons.some((b) => b.canHide(inputMode, b));
  }

  private actionHandler(
    inputMode: JigsawButtonInputMode,
    buttonGroup: ButtonGroup,
    location: Point
  ): void {
    const descriptor = inputMode.descriptor as ButtonDescriptor;
    descriptor.initialize(buttonGroup);
    const buttonGroupBounds = descriptor.getButtonGroupBounds(buttonGroup);
    let xOffset = buttonGroupBounds.x;

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];

      const buttonBounds = descriptor.getButtonLayout(button, buttonGroup);
      const rect = new Rect(
        xOffset,
        buttonGroupBounds.y,
        buttonBounds.width,
        buttonBounds.height
      );

      if (rect.contains(location)) {
        button.onAction(inputMode, button, location);
        return;
      }

      xOffset += buttonBounds.width;
    }
  }
}
