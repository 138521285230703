import DocumentPagesApiService from '@/api/DocumentPagesApiService';
import DocumentsApiService from '@/api/DocumentsApiService';
import {
  CreateOrEditDocumentDto,
  CreateOrEditDocumentPageDto,
  CreateOrEditDocumentPageOutput,
  CurrentUserProfileEditDto,
  DiagramDto,
  DiagramNodeDto,
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageLayoutType,
  DocumentPageType,
  FontStyleDto,
  PageDesignDto,
  ZadarResponse
} from '@/api/models';
import IApplicationError from '@/core/common/IApplicatonError';
import Mutex from '@/core/common/Mutex';
import DiagramUtils from '@/core/utils/DiagramUtils';
import router from '@/JigsawVueRouter';
import { GraphComponent } from 'yfiles';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import { ExportType } from '../export/ExportType';
import { ExportFormat } from '../export/ExportFormat';
import ExportOptions from '../export/ExportOptions';
import ExportService from '../export/ExportService';
import GraphInitCompleteEventArgs from '../graph/GraphInitCompleteEventArgs';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  GET_READONLY,
  GET_SAVE_FAILED,
  GET_SELECTED_DIAGRAM,
  GET_SELECTED_PAGE,
  GET_SELECTED_SUBPAGE_INDEX,
  SET_DOCUMENT_MODIFICATION_TIME,
  SET_SAVE_FAILED,
  SET_SELECTED_PAGE_FOOTER,
  SET_SELECTED_PAGE_HEADER,
  UNLOAD_DOCUMENT
} from '../store/document.module';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import DocumentHashHelper from './DocumentHashHelper';
import i18n from '@/core/plugins/vue-i18n';
import DocumentSyncService from './sync/DocumentSyncService';
import { SaveAsOptions } from './SaveAsOptions';
import cloneDeep from 'lodash/cloneDeep';
import { RouterParams } from '@/core/config/routerParams';
import CommonDiagramsGroup from './CommonDiagramsGroup';
import ExportPage from '../export/ExportPage';
import CachingService from '../caching/CachingService';
import CacheType from '../caching/CacheType';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import DiagramChangeHandler from '../graph/DiagramChangeHandler';
import { AxiosError, AxiosResponse } from 'axios';
import RealTimeDocumentService from '@/core/services/signalr/RealTimeDocumentService';
import appConfig from '@/core/config/appConfig';
import ContentPagination from '../export/ContentPagination';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import PageNumberLayoutItem from '@/components/LayoutEditor/Items/PageNumberLayoutItem';
import LayoutItemUtils from '@/components/LayoutEditor/Items/LayoutItemUtils';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import DateLayoutItem from '@/components/LayoutEditor/Items/DateLayoutItem';
import { ICreateWidgetParams } from '@/components/LayoutEditor/Items/ICreateWidgetParams';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import FeaturesService from '@/core/services/FeaturesService';
import { Features } from '@/core/common/Features';
import { getFooterKey, getHeaderKey } from './DocumentConsts';
import { ExportCachePolicy } from '../export/ExportCachePolicy';
import {
  PAGE_DESIGN_NAMESPACE,
  GET_ALL_PAGE_DESIGNS
} from '../store/page-design.module';
import PageLayoutBackgroundCkEditorService from '../editor/PageLayoutBackgroundCkEditorService';
import { StepsFontPresets } from '@/view/pages/administration/steps-designer/StepsFontPresets';
import PageListItem from '@/view/pages/document/page-list/PageListItem';
import CommandManager from '../CommandManager/CommandManager';
import { CommandHandlerType } from '../CommandManager/CommandHandlerType';

class DocumentService {
  public readonly syncService = new DocumentSyncService();
  public readonly saveMutex = new Mutex();
  public readonly subPageHeaderFooterLayoutAvailable = false;

  private backgroundCkEditorService: PageLayoutBackgroundCkEditorService = null;
  private graphComponent: GraphComponent = null;
  private graphService: IGraphService = null;
  private closeMutex = new Mutex();
  private isInitialized = false;

  readonly maxLayoutRerun = 5;

  get selectedPage(): DocumentPageDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ];
  }

  get selectedSubPageIndex(): number {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_SUBPAGE_INDEX}`
    ];
  }

  get selectedDiagram(): DiagramDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_DIAGRAM}`
    ];
  }

  get currentDocument(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  get isReadOnly(): boolean {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_READONLY}`];
  }

  get lastSaveFailed(): boolean {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_SAVE_FAILED}`];
  }

  get documentPages(): DocumentPageDto[] {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`]
      ?.pages;
  }

  get pagesWithDiagram(): DocumentPageDto[] {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`
    ]?.pages.filter((page) => page?.diagram);
  }

  get pageDesigns(): PageDesignDto[] {
    return Vue.$globalStore.getters[
      `${PAGE_DESIGN_NAMESPACE}/${GET_ALL_PAGE_DESIGNS}`
    ];
  }

  get currentUser(): CurrentUserProfileEditDto {
    return Vue.$globalStore.getters[GET_CURRENT_USER];
  }

  get canFlipBook(): boolean {
    return (
      FeaturesService.hasFeature(Features.Flipbook) &&
      this.getDiagramsWithCommonNodes(this.currentDocument).length > 0
    );
  }

  get graphServiceInstance(): IGraphService {
    return this.graphService;
  }

  get isGraphServiceActive(): boolean {
    return this.graphService && !this.graphService.isDisposed;
  }

  get shouldPromptForDocumentProfile(): boolean {
    if (
      appConfig.documentProfiling.promptOnSave &&
      appConfig.documentProfiling.isEnabled &&
      !appConfig.iManage.isEnabled
    ) {
      return (
        (!this.currentDocument?.client ||
          appConfig.documentProfiling.singleInputClientMatter) &&
        !this.currentDocument?.matter
      );
    }
    return false;
  }

  get graphComponentInstance(): GraphComponent {
    return this.graphComponent;
  }

  // TODO revisit DocumentService & DocumentDetails lifecycle
  public init(): void {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    if (!this.backgroundCkEditorService) {
      this.backgroundCkEditorService =
        new PageLayoutBackgroundCkEditorService();
    }

    DocumentHashHelper.clearAllHashes();

    EventBus.$on(EventBusActions.GRAPH_INIT_COMPLETE, (args) => {
      this.graphInitComplete(args);
    });

    EventBus.$on(EventBusActions.GRAPH_DISPOSED, () => {
      this.onGraphDispose();
    });

    router.beforeEach((to, from, next) => {
      if (
        (RouterParams.documentId in from.params &&
          !(RouterParams.documentId in to.params)) ||
        (RouterParams.documentId in from.params &&
          RouterParams.documentId in to.params &&
          from.params[RouterParams.documentId] !=
            to.params[RouterParams.documentId])
      ) {
        this.closeDocument().finally(() => {
          next();
        });
      } else {
        next();
      }
    });

    const documentStateChanged = (): void => {
      if (this.currentDocument) {
        this.syncService.start({
          documentId: this.currentDocument.id,
          isReadOnly: this.isReadOnly
        });
      } else {
        this.syncService.stop();
      }
    };
    Vue.$globalStore.watch(() => this.currentDocument, documentStateChanged);
    Vue.$globalStore.watch(() => this.isReadOnly, documentStateChanged);
  }

  private handleSaveFailed(error?: AxiosError): void {
    Vue.$globalStore.commit(`${DOCUMENT_NAMESPACE}/${SET_SAVE_FAILED}`, true);
    if (error?.isAxiosError !== undefined) {
      if (error.isAxiosError) {
        if (error.response?.status == 409) {
          EventBus.$emit(EventBusActions.DOCUMENT_SAVE_CONFLICT);
        } else {
          let payload: IApplicationError = {
            title: i18n.t('UNABLE_TO_SAVE'),
            message: i18n.t('SAVE_FAILED'),
            refresh: false,
            navigate: 'landing'
          };
          EventBus.$emit(EventBusActions.APPLICATION_ERROR, payload);
        }
      }
    }
  }

  public async saveDocument(
    document: DocumentDto,
    forceSave: boolean = false
  ): Promise<boolean> {
    if (this.isReadOnly) {
      return;
    }
    let hasSaved = false;
    const unlock = await this.saveMutex.lock();
    try {
      this.prepareDocumentForSaving(document);
      const changedPages = this.getChangedPages(document);

      const currentHash = DocumentHashHelper.getCurrentDocumentHash(document);
      const oldHash = DocumentHashHelper.getStoredDocumentHash(document.id);

      if (currentHash != oldHash || changedPages.length > 0 || forceSave) {
        const body = await this.createDocumentBody(document);
        const result = await DocumentsApiService.createOrEdit(body);
        document.lastModificationTime = result.data.result.lastModificationTime;
        DocumentHashHelper.storeDocumentHash(document.id, currentHash);

        // Save changed pages
        for (const changedPage of changedPages) {
          const response = await this.savePage(document, changedPage.page);
          if (response.data.success) {
            DocumentHashHelper.storePageHash(
              changedPage.page.id,
              changedPage.hash
            );
          }
        }
      } else {
        // Nothing has changed, no need to push anything to the server
        // Regardless, still perform the lastModificationTime check to make sure there are no save conflicts
        const lastModificationTime = (
          await DocumentsApiService.getDocumentLastModificationTime({
            documentId: document.id
          })
        ).data.result;
        if (lastModificationTime > document.lastModificationTime) {
          EventBus.$emit(EventBusActions.DOCUMENT_SAVE_CONFLICT);
          this.handleSaveFailed();
          return;
        }
      }

      Vue.$globalStore.commit(
        `${DOCUMENT_NAMESPACE}/${SET_SAVE_FAILED}`,
        false
      );
      hasSaved = true;
    } catch (error) {
      this.handleSaveFailed(error);
    } finally {
      unlock();
    }
    return hasSaved;
  }

  private async savePage(
    document: DocumentDto,
    page: CreateOrEditDocumentPageDto
  ): Promise<AxiosResponse<ZadarResponse<CreateOrEditDocumentPageOutput>>> {
    const response = await DocumentPagesApiService.createOrEdit({
      ...page,
      filters: document.filters
    });
    page.id = response.data.result.pageId;
    page.isPristine = false;
    document.lastModificationTime = response.data.result.lastModificationTime;
    return response;
  }

  public prepareDocumentForSaving(document: DocumentDto): void {
    if (document) {
      for (let order = 0; order < document.pages.length; order++) {
        const page = document.pages[order];
        page.order = order;
      }
    }
    this.serializeSelectedDiagram();
  }

  public serializeSelectedDiagram(): void {
    if (this.selectedDiagram && this.graphComponent && this.graphService) {
      this.graphService
        .getService<DiagramChangeHandler>(DiagramChangeHandler.$class)
        .serializeSelectedDiagram();
    }
  }

  public async createNewDocumentBody(
    document: DocumentDto
  ): Promise<CreateOrEditDocumentDto> {
    return await this.createDocumentBody(document);
  }

  public async createDocumentBody(
    document: DocumentDto
  ): Promise<CreateOrEditDocumentDto> {
    const thumb = await this.generateDocumentThumbnail(document);
    return {
      id: document.id,
      name: document.name,
      isPristine: false,
      hasSteps: document.hasSteps,
      hasTimelines: document.hasTimelines,
      description: document.description,
      themeId: document.lastSelectedThemeId,
      headerLayout: document.headerLayout,
      footerLayout: document.footerLayout,
      backgroundLayout: document.backgroundLayout,
      pageStyle: document.pageStyle,
      headerStyle: document.headerStyle,
      footerStyle: document.footerStyle,
      headerFooterDate: document.headerFooterDate,
      logoPosition: document.logoPosition,
      legendPosition: document.legendPosition,
      attachments: document.attachments,
      tableSwatch: document.tableSwatch,
      fontStyles: document.fontStyles,
      lastModificationTime: document.lastModificationTime,
      defaultPageType: document.defaultPageType,
      dataPropertyStyles: document.dataPropertyStyles,
      autoSave: document.autoSave,
      flipbookState: document.flipbookState,
      thumb: thumb,
      pageDesignSetId: document.pageDesignSetId,
      usePresetProperties: document.usePresetProperties,
      quickBuildSettings: document.quickBuildSettings
    };
  }

  public async generateDocumentThumbnail(
    document: DocumentDto
  ): Promise<string> {
    // Try find the first non-layout page, otherwise fallback to first page
    const page =
      document.pages.find(
        (p) => p.contentType != DocumentPageContentType.Layout
      ) ?? document.pages[0];

    const exportPages = [new ExportPage(page, 0)];
    const options: ExportOptions = {
      type: ExportType.PageThumbnail,
      document: document,
      pages: exportPages,
      format: ExportFormat.Png,
      withData: false,
      withAttachments: false,
      withFilters: false,
      download: false,
      clipboard: false,
      print: false,
      lowDetailDiagram: true,
      lowDetailBackground: true,
      cachePolicy: ExportCachePolicy.Ignore
    };
    return (await ExportService.export(options)) as string;
  }

  public async closeDocument(saveDocument: boolean = true): Promise<void> {
    const unlock = await this.closeMutex.lock();
    try {
      if (this.currentDocument) {
        // if that is a temp document do not save it automatically
        if (this.currentDocument.id === 0) return;
        if (!this.lastSaveFailed) {
          if (saveDocument) {
            await this.saveDocument(this.currentDocument);
          }

          if (
            this.currentDocument.lockedByUser?.id === this.currentUser.userId ||
            !this.currentDocument.lockedByUser
          ) {
            await this.unlockDocument(this.currentDocument);
          }
        }
        await RealTimeDocumentService.deregisterDocument(
          this.currentDocument.id
        );
        this.backgroundCkEditorService?.destroy();
        await Vue.$globalStore.dispatch(
          `${DOCUMENT_NAMESPACE}/${UNLOAD_DOCUMENT}`
        );
      }
    } finally {
      unlock();
    }
  }

  public async unlockDocument(document: DocumentDto): Promise<any> {
    if (!document || this.isReadOnly) {
      return;
    }
    return await DocumentsApiService.unlockDocument({ id: document.id });
  }

  public isDocumentDirty(document: DocumentDto): boolean {
    // Documents and pages should have a hash created when loaded. Should not be null
    if (!document) {
      return false;
    }
    const currentDocumentHash =
      DocumentHashHelper.getCurrentDocumentHash(document);
    const oldDocumentHash = DocumentHashHelper.getStoredDocumentHash(
      document.id
    );

    if (oldDocumentHash && oldDocumentHash !== currentDocumentHash) {
      return true;
    }

    for (const page of document.pages) {
      const currentPageHash = DocumentHashHelper.getCurrentPageHash(page);
      const oldPageHash = DocumentHashHelper.getStoredPageHash(page.id);

      if (oldPageHash && oldPageHash !== currentPageHash) {
        return true;
      }
    }
    return false;
  }

  public getChangedPages(
    document: DocumentDto
  ): { page: DocumentPageDto; hash: string }[] {
    const changedPages = [];
    for (const page of document.pages) {
      const currentHash = DocumentHashHelper.getCurrentPageHash(page);
      const oldHash = DocumentHashHelper.getStoredPageHash(page.id);
      if (currentHash != oldHash) {
        changedPages.push({ page: page, hash: currentHash });
      }
    }
    return changedPages;
  }

  public async cloneCurrentDocument(options: SaveAsOptions): Promise<number> {
    this.prepareDocumentForSaving(this.currentDocument);
    let selectedPage = cloneDeep(this.selectedPage);
    let document = cloneDeep(this.currentDocument) as DocumentDto;
    const index = (document.pages as Array<any>).findIndex(
      (p) => p.id == selectedPage.id
    );
    document.pages[index] = selectedPage;
    document.name = options.documentName;

    document = await this.prepareDocumentPagesForCloning(document, options);

    let thumb = null;
    if (options.updateThumbnail) {
      thumb = await this.generateDocumentThumbnail(document);
    }
    const documentResult = await DocumentsApiService.clone({
      document: document,
      thumb: thumb
    });

    this.updateDocumentModificationTime();

    return documentResult.data.result;
  }

  public updateDocumentModificationTime(): void {
    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_MODIFICATION_TIME}`,
      new Date()
    );
  }

  public documentHasFilters(document: DocumentDto): boolean {
    return (
      ExportService.dataExportService.currentTagFilters?.length > 0 ||
      document.pages.some(
        (page) => page.diagram && DiagramUtils.diagramHasFilters(page.diagram)
      )
    );
  }

  /**
   * Returns the default content type for the given @pageType
   * @param pageType
   * @returns
   */
  public getDefaultPageContentType(
    pageType: DocumentPageType
  ): DocumentPageContentType {
    switch (pageType) {
      case DocumentPageType.Content:
        return DocumentPageContentType.Html;
      case DocumentPageType.Diagram:
      case DocumentPageType.Timeline:
        return DocumentPageContentType.None;
      case DocumentPageType.Split:
        return DocumentPageContentType.Html;
      default:
        throw `Unknown page type ${pageType}`;
    }
  }

  public getCommonDiagramsGroupPages(
    document: DocumentDto,
    page: DocumentPageDto
  ): DocumentPageDto[] {
    if (!this.currentDocument) {
      return [];
    }

    const commonDiagramsGroup = this.getCommonDiagramGroupFromPage(
      document,
      page
    );
    return this.currentDocument.pages.filter(
      (p) => p?.diagram?.id && commonDiagramsGroup?.contains(p.diagram.id)
    );
  }

  /**
   * Returns groups of diagrams that are related by a common (anchor) node
   */
  public getDiagramsWithCommonNodes(
    document: DocumentDto,
    pageType: DocumentPageType = null
  ): CommonDiagramsGroup[] {
    const pages = document.pages.filter((p) => !!p.diagram);
    if (pages.length === 0) {
      return [];
    }
    pages.sort((a, b) => a.order - b.order);

    const cacheKey = CachingService.generateKey(
      CacheType.DiagramsWithCommonNodes,
      pages.map((p) => p.diagram.cacheKey),
      pages.map((p) => p.order),
      pageType
    );

    return CachingService.getOrSet<CommonDiagramsGroup[]>(cacheKey, () => {
      const groups: CommonDiagramsGroup[] = [];
      let currentGroup: CommonDiagramsGroup = new CommonDiagramsGroup(
        pages[0].pageType
      );
      let lastPageOrder = pages[0].order;
      for (const page of pages) {
        const diagram = page.diagram;
        if (
          currentGroup.pageType != page.pageType ||
          // If there is non diagram page between pages with common nodes - create new group
          page.order - lastPageOrder > 1 ||
          (currentGroup.size > 0 && !currentGroup.sharesCommonNodes(diagram))
        ) {
          currentGroup = new CommonDiagramsGroup(page.pageType);
        }
        currentGroup.add(diagram);

        if (!groups.includes(currentGroup)) {
          groups.push(currentGroup);
        }
        lastPageOrder = page.order;
      }

      return {
        data: groups.filter(
          (g) => g.size > 1 && (g.pageType == pageType || pageType === null)
        )
      };
    });
  }

  /**
   * Returns common diagram group for a specific page
   */
  public getCommonDiagramGroupFromPage(
    document: DocumentDto,
    page: DocumentPageDto
  ): CommonDiagramsGroup {
    if (!page.diagram) {
      return null;
    }
    return this.getCommonDiagramGroup(document, page.diagram, page.pageType);
  }

  /**
   * Returns common diagram group for a diagram and page type
   */
  public getCommonDiagramGroup(
    document: DocumentDto,
    diagram: DiagramDto,
    pageType: DocumentPageType = null
  ): CommonDiagramsGroup {
    const commonDiagramGroups = this.getDiagramsWithCommonNodes(
      document,
      pageType
    );
    return commonDiagramGroups.find((group) => group.contains(diagram.id));
  }

  /**
   * Get common nodes related to the current diagram (share the same uuid or originalUuid)
   * @param nodeUuids When provided, limit nodes to specific uuids, otherwise include all nodes
   * @param pages When provided, limit diagrams to specific pages, otherwise include all descendent diagrams
   * @param shouldExistInAllDiagrams If true - common node should exist in all diagrams,
   *                                 otherwise node should exist at least in 2 diagrams
   */
  public getCommonNodesForCurrentDiagram(
    nodeUuids: string[] = null,
    pages: DocumentPageDto[] = null,
    shouldExistInAllDiagrams = true
  ): DiagramNodeDto[] {
    const diagram = this.selectedDiagram;
    if (!diagram) {
      return [];
    }
    const commonDiagramGroups = this.getDiagramsWithCommonNodes(
      this.currentDocument,
      this.selectedPage.pageType
    );
    const group = commonDiagramGroups.find((g) => g.contains(diagram.id));
    if (!group) {
      return [];
    }

    const commonDiagrams = group.commonDiagrams;
    const currentDiagramIndex = commonDiagrams.indexOf(diagram);
    let relatedDiagrams: DiagramDto[];
    if (!pages) {
      relatedDiagrams = commonDiagrams.splice(currentDiagramIndex + 1);
    } else {
      relatedDiagrams = commonDiagrams.filter(
        (d) => d != diagram && pages.some((p) => p.diagram == d)
      );
    }
    return DiagramUtils.findCommonNodes(
      [diagram, ...relatedDiagrams],
      nodeUuids,
      shouldExistInAllDiagrams
    );
  }

  public getTotalPagesCount(): number {
    const lastPage =
      this.currentDocument.pages[this.currentDocument.pages.length - 1];
    return (
      ContentPagination.getPageNumber(this.currentDocument, lastPage) +
      (ContentPagination.getPageCount(lastPage) - 1)
    );
  }

  public syncHeaderFooterDate(document: DocumentDto, date?: Date): void {
    if (!date) {
      date = new Date();
    }
    for (const page of document.pages) {
      LayoutWidgetUtils.setDateData(page, date);
    }
    const dateString = date.toISOString();
    if (document.headerFooterDate != dateString) {
      document.headerFooterDate = dateString;
      EventBus.$emit(EventBusActions.DATE_CHANGED);
    }
  }

  public syncPageNumberContent(document: DocumentDto): void {
    let pageNumber = 1;
    for (const page of document.pages) {
      const subPageCount = ContentPagination.getPageCount(page);
      for (let subPageIndex = 0; subPageIndex < subPageCount; subPageIndex++) {
        LayoutWidgetUtils.setPageNumberData(
          page,
          pageNumber,
          this.getTotalPagesCount(),
          false
        );
        pageNumber++;
      }
    }
  }

  private graphInitComplete(args: GraphInitCompleteEventArgs): void {
    this.graphComponent = args.graphComponent;
    this.graphService = args.graphService;
  }

  private onGraphDispose(): void {
    this.graphComponent = null;
    this.graphService = null;
  }

  private async prepareDocumentPagesForCloning(
    document: DocumentDto,
    options: SaveAsOptions
  ): Promise<DocumentDto> {
    for (let order = 0; order < document.pages.length; order++) {
      const page = document.pages[order];

      if (page?.diagram) {
        page.filterDefinition = null;
        if (options.elementsPredicate) {
          const map = <T>(item: T): T & { isIncluded: boolean } => ({
            ...item,
            isIncluded: true
          });

          const nodesPredicate = page.diagram.nodes.filter(
            options.elementsPredicate
          );

          // Apply groups (if exists) for elementsPredicate
          const groupUuidsForNodesPredicate = nodesPredicate
            .filter((node) => node.groupUuid)
            .map((node) => node.groupUuid);
          const groupsForNodesPredicate = page.diagram.nodes.filter(
            (node) =>
              node.isGroupNode &&
              groupUuidsForNodesPredicate.includes(node.groupUuid)
          );

          page.diagram.nodes = [
            ...nodesPredicate,
            ...groupsForNodesPredicate
          ].map(map);

          const nodePredicateUuids = nodesPredicate.map((n) => n.uuid);
          page.diagram.edges = page.diagram.edges
            .filter(options.elementsPredicate)
            .filter(
              (e) =>
                nodePredicateUuids.includes(e.sourceNodeUuid) &&
                nodePredicateUuids.includes(e.targetNodeUuid)
            ) // Prevent adding edge without source and target node
            .map(map);
        } else {
          page.diagram.nodes.forEach((node) => {
            node.isIncluded = true;
          });
          page.diagram.edges.forEach((edge) => {
            edge.isIncluded = true;
          });
        }
      }
    }

    return document;
  }

  private syncLayoutItemsVisibility(layout: string, show: boolean): string {
    const layoutItems = LayoutSerializer.deserializeFromJson(layout);
    for (const item of layoutItems) {
      if (
        item.type != LayoutItemType.Date &&
        item.type != LayoutItemType.PageNumber
      ) {
        item.hidden = !show;
      }
    }
    return LayoutSerializer.serializeToJson(layoutItems);
  }

  public syncHeaderFooterLayoutItemsVisibility(
    document: DocumentDto,
    area: DocumentContentArea.Header | DocumentContentArea.Footer | null = null
  ): void {
    // S Y N C  D O C U M E N T
    if (area === null || area === DocumentContentArea.Header) {
      document.headerLayout = this.syncLayoutItemsVisibility(
        document.headerLayout,
        document.headerStyle.show
      );
    }
    if (area === null || area === DocumentContentArea.Footer) {
      document.footerLayout = this.syncLayoutItemsVisibility(
        document.footerLayout,
        document.footerStyle.show
      );
    }

    // S Y N C  P A G E S
    for (const page of document.pages) {
      if (page.contentType == DocumentPageContentType.Layout) continue;
      if (area === null || area === DocumentContentArea.Header) {
        const headerLayout = this.syncLayoutItemsVisibility(
          page.headerLayout,
          document.headerStyle.show
        );

        if (page == this.selectedPage) {
          Vue.$globalStore.dispatch(
            `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_HEADER}`,
            headerLayout
          );
        } else {
          page.headerLayout = headerLayout;
        }
      }

      if (area === null || area === DocumentContentArea.Footer) {
        const footerLayout = this.syncLayoutItemsVisibility(
          page.footerLayout,
          document.footerStyle.show
        );

        if (page == this.selectedPage) {
          Vue.$globalStore.dispatch(
            `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_FOOTER}`,
            footerLayout
          );
        } else {
          page.footerLayout = footerLayout;
        }
      }
    }
  }

  public syncHeaderFooterWidgetItems(
    document: DocumentDto,
    params: Omit<ICreateWidgetParams, 'area'>
  ): void {
    const {
      type,
      widgetPreset = LayoutWidgetUtils.datePresetsArray[0],
      widgetPosition
    } = params;

    for (const page of document.pages) {
      if (page.contentType == DocumentPageContentType.Layout) continue;
      let widgetItemPosition = null;

      const headerLayoutItems = LayoutSerializer.deserializeFromJson(
        page.headerLayout
      );
      const footerLayoutItems = LayoutSerializer.deserializeFromJson(
        page.footerLayout
      );

      if (type === LayoutItemType.Date) {
        let dateItem = headerLayoutItems.find(
          (i) => i.type == LayoutItemType.Date
        ) as DateLayoutItem;

        if (!dateItem) {
          dateItem = footerLayoutItems.find(
            (i) => i.type == LayoutItemType.Date
          ) as DateLayoutItem;
          widgetItemPosition = 'footer';
        } else {
          widgetItemPosition = 'header';
        }

        if (dateItem) {
          LayoutWidgetUtils.updateItemLayout(dateItem, document);
        } else {
          dateItem = new DateLayoutItem({
            hidden: false,
            html: widgetPreset.template,
            presetId: widgetPreset.id,
            position: widgetPosition,
            format: widgetPreset.format
          });
          LayoutItemUtils.createDateItem(headerLayoutItems, dateItem);
          LayoutItemUtils.updateItemLocation(
            dateItem,
            widgetPosition,
            LayoutUtils.getContentAreaSize(
              document,
              LayoutUtils.getContentAreaByPageElementPosition(widgetPosition)
            )
          );
          widgetItemPosition = 'header';
        }
      }

      if (type === LayoutItemType.PageNumber) {
        let pageNumberItem = footerLayoutItems.find(
          (i) => i.type == LayoutItemType.PageNumber
        ) as PageNumberLayoutItem;

        if (!pageNumberItem) {
          pageNumberItem = headerLayoutItems.find(
            (i) => i.type == LayoutItemType.PageNumber
          ) as PageNumberLayoutItem;
          widgetItemPosition = 'header';
        } else {
          widgetItemPosition = 'footer';
        }

        if (pageNumberItem) {
          LayoutWidgetUtils.updateItemLayout(pageNumberItem, document);
        } else {
          pageNumberItem = new PageNumberLayoutItem({
            hidden: false,
            html: widgetPreset.template,
            presetId: widgetPreset.id,
            position: widgetPosition
          });
          LayoutItemUtils.createPageNumberItem(
            footerLayoutItems,
            pageNumberItem
          );
          LayoutItemUtils.updateItemLocation(
            pageNumberItem,
            widgetPosition,
            LayoutUtils.getContentAreaSize(
              document,
              LayoutUtils.getContentAreaByPageElementPosition(widgetPosition)
            )
          );
          widgetItemPosition = 'footer';
        }
      }

      if (widgetItemPosition === 'header') {
        if (page == this.selectedPage) {
          Vue.$globalStore.dispatch(
            `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_HEADER}`,
            LayoutSerializer.serializeToJson(headerLayoutItems)
          );
          EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
            key: getHeaderKey(page.id, this.selectedSubPageIndex),
            content: this.selectedPage.headerLayout
          });
        } else {
          page.headerLayout =
            LayoutSerializer.serializeToJson(headerLayoutItems);
        }
      } else {
        if (page == this.selectedPage) {
          Vue.$globalStore.dispatch(
            `${DOCUMENT_NAMESPACE}/${SET_SELECTED_PAGE_FOOTER}`,
            LayoutSerializer.serializeToJson(footerLayoutItems)
          );
          EventBus.$emit(EventBusActions.DOCUMENT_CONTENT_SET, {
            key: getFooterKey(page.id, this.selectedSubPageIndex),
            content: this.selectedPage.footerLayout
          });
        } else {
          page.footerLayout =
            LayoutSerializer.serializeToJson(footerLayoutItems);
        }
      }
    }
  }

  public getFontPresetsByLayoutType(
    stepsFontPresets: StepsFontPresets[],
    layoutType: DocumentPageLayoutType
  ): FontStyleDto[] {
    return stepsFontPresets.find((p) => p.layoutType == layoutType)
      ?.fontPresets;
  }

  public async runPageLayoutOnBackgroundPages(
    pages: DocumentPageDto[] = []
  ): Promise<void> {
    if (!this.backgroundCkEditorService) return;
    if (!pages.length) {
      pages = this.currentDocument.pages;
    }

    pages = pages.filter(
      (p) =>
        p != this.selectedPage &&
        p.contentType == DocumentPageContentType.Html &&
        p.content
    );
    if (!pages.length) {
      return;
    }

    await this.backgroundCkEditorService.init();

    for (const page of pages) {
      this.backgroundCkEditorService.pageId = page.id;
      this.backgroundCkEditorService.columns = page.contentColumns;
      this.backgroundCkEditorService.content = page.content;
      this.backgroundCkEditorService.runPageLayout();
      page.content = this.backgroundCkEditorService.content;
    }
    this.backgroundCkEditorService.content = '';
  }

  updateRouterPageNumberParam(pageNumber: number): void {
    if (
      pageNumber.toString() ===
      router.currentRoute.params[RouterParams.pageNumber]
    ) {
      return;
    }
    router.push({
      name: router.routeNames.documentDetails,
      params: {
        [RouterParams.documentId]:
          router.currentRoute.params[RouterParams.documentId],
        [RouterParams.pageNumber]: pageNumber.toString()
      }
    });
  }

  async navigateToPageNumber(
    pageListItems: Array<PageListItem>,
    callback: Function,
    args?: Record<string, any>
  ): Promise<boolean> {
    if (
      isNaN(args.value) ||
      (router.currentRoute.params[RouterParams.pageNumber] === args.value &&
        !args.force)
    ) {
      return;
    }
    const pageToGo = pageListItems.find(
      (p) => p.pageNumber === Number(args.value)
    );
    if (pageToGo) {
      await callback(pageToGo.page, 0);
      return true;
    } else {
      let pageNumber = Number(args.value) - 1;
      let pageToGo = null;
      while (pageNumber > 1) {
        const page = pageListItems.find((p) => p.pageNumber === pageNumber);
        if (page) {
          pageToGo = page;
          break;
        }
        pageNumber--;
      }
      if (pageToGo && pageToGo.subPageCount > Number(args.value) - pageNumber) {
        await callback(pageToGo.page, Number(args.value) - pageNumber);
        return true;
      }
    }
  }

  public isDiagramFocused(): boolean {
    const activeCommandHandler =
      CommandManager.INSTANCE.getActiveCommandHandlerType();
    return (
      (!this.currentDocument.hasSteps && this.isGraphServiceActive) ||
      (this.currentDocument.hasSteps &&
        this.isGraphServiceActive &&
        (activeCommandHandler == CommandHandlerType.Diagram ||
          activeCommandHandler == CommandHandlerType.MultiNodeCKEditor))
    );
  }
}

const instance = new DocumentService();
export default instance;
