//@ts-nocheck
import GraphElementsComparer from '@/core/utils/GraphElementsComparer';
import clamp from 'lodash/clamp';
import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  Point,
  OrientedRectangle,
  ILookup,
  IEnumerable,
  List,
  Size,
  BaseClass,
  IEdge,
  IPoint,
  PathType,
  ArcEdgeStyle,
  ILabelSnapContextHelper,
  LabelSnapContextHelper
} from 'yfiles';
import JigsawEdgeLabelModelParameter from './JigsawEdgeLabelModelParameter';
import DiagramUtils from '@/core/utils/DiagramUtils';
export default class JigsawEdgeLabelModel extends BaseClass<
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
>(ILabelModel, ILabelModelParameterProvider, ILabelModelParameterFinder) {
  /**
   * Defines at what distance from the edge the label should snap to @property snapDistance
   */
  private snapThreshold = 15;

  /**
   * Enables snapping along three 'tracks', disable this to allow free placement within @property maxDistance
   */
  private enableSnapping = true;

  /**
   * Label boundaries that we should take into account when calculating label distance
   */
  private edgeLabelOffset = 2;

  /**
   * Returns instances of the support interfaces (which are actually the model instance itself)
   */
  lookup<T>(type: Class<T>): T {
    if (type === ILabelModelParameterProvider.$class) {
      // If we request a ILabelModelParameterProvider AND we use discrete label candidates, we return the label model
      // itself, otherwise, null is returned, which means that continuous label positions are supported.
      return this;
    } else if (type === ILabelModelParameterFinder.$class) {
      // If we request a ILabelModelParameterProvider, we return the label model itself, so we can always retrieve a
      // matching parameter for a given actual position.
      return this;
    }
    if (type == ILabelSnapContextHelper.$class) {
      return LabelSnapContextHelper.INSTANCE;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const labelSize = label.preferredSize;
    if (!(layoutParameter instanceof JigsawEdgeLabelModelParameter)) {
      throw 'layoutParameter must be of type JigsawExteriorNodeLabelModelParameter';
    }
    if (!label.owner) {
      return IOrientedRectangle.EMPTY;
    }
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    const points = JigsawEdgeLabelModel.getEdgePoints(label.owner);
    const normalizedSegmentIndex = JigsawEdgeLabelModel.normalizeSegmentIndex(
      layoutParameter.segmentIndex,
      points
    );
    const labelPosition = this.getLabelPosition(
      label,
      points,
      layoutParameter.ratio,
      normalizedSegmentIndex,
      layoutParameter.left,
      this.getSnapDistance(
        label,
        normalizedSegmentIndex,
        layoutParameter.distance
      )
    );
    const labelRect = this.createLabelOrientedRectangle(
      labelPosition,
      labelSize
    );
    return this.applyPaddingAtBoundary(
      label,
      labelSize,
      layoutParameter,
      labelRect,
      points,
      normalizedSegmentIndex
    );
  }

  private applyPaddingAtBoundary(
    label: ILabel,
    labelSize: Size,
    layoutParameter: JigsawEdgeLabelModelParameter,
    labelRect: IOrientedRectangle,
    points: Point[],
    segmentIndex: number
  ): IOrientedRectangle {
    if ((label.owner as IEdge).style instanceof ArcEdgeStyle) {
      const arcSegmentInfo = this.getArcLabelSegmentAndRatio(
        points,
        layoutParameter.ratio
      );
      segmentIndex = arcSegmentInfo.segmentIndex;
    }
    const isVertical = this.isSegmentVertical(points, segmentIndex);
    const paddingV = this.edgeLabelOffset + labelSize.height / 2;
    const paddingH = this.edgeLabelOffset + labelSize.width / 2;

    const intersectsSource = label.owner.sourceNode.layout
      .toRect()
      .intersects(labelRect);

    if (intersectsSource) {
      if (isVertical) {
        labelRect.anchorY += paddingV;
      } else {
        labelRect.anchorX += labelRect.anchorX <= 0 ? -paddingH : paddingH;
      }
      return labelRect;
    }

    const intersectsTarget = label.owner.targetNode.layout
      .toRect()
      .intersects(labelRect);
    if (intersectsTarget) {
      if (isVertical) {
        labelRect.anchorY -= paddingV;
      } else {
        labelRect.anchorX += labelRect.anchorX <= 0 ? paddingH : -paddingH;
      }
    }
    return labelRect;
  }

  private isSegmentVertical(points: Point[], index: number): boolean {
    const start = points[index];
    const end = points[index + 1];
    return Math.abs(end.x) == Math.abs(start.x);
  }

  private createLabelOrientedRectangle(
    position: Point,
    size: Size
  ): IOrientedRectangle {
    return new OrientedRectangle(
      position.toMutablePoint(),
      size.toMutableSize()
    );
  }

  private getArcLabelSegmentAndRatio(
    points: IPoint[],
    ratio: number
  ): {
    segmentIndex: number;
    ratio: number;
  } {
    const totalSegments = points.length - 1;

    const segmentIndex = totalSegments * ratio;

    const segmentRatio = segmentIndex % 1;

    return {
      ratio: segmentRatio,
      segmentIndex: Math.floor(segmentIndex)
    };
  }

  /**
   *
   * @param label The label for which we are calculating the position
   * @param points The points along the label's edge, ports, bends etc, this is used to break up the edge into segments
   * @param ratio The ratio along the segmentIndex where the label should be placed
   * @param normalizedSegmentIndex The segment index
   * @param left Which side of the edge the label is placed
   * @param distance The distance from the edge at which the label should be placed
   * @returns
   */

  private getLabelPosition(
    label: ILabel,
    points: IPoint[],
    ratio: number,
    normalizedSegmentIndex: number,
    left: boolean,
    distance: number
  ): Point {
    const labelSize = label.preferredSize;
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }

    if (label.owner.style instanceof ArcEdgeStyle) {
      // arcs are treated as a single segment
      // we ignore the segmentIndex passed in and calculcate it
      // the incoming ratio is actually a ratio along the whole line
      // we convert the incoming ratio into a segment and ratio relative to that segment
      const arcSegmentInfo = this.getArcLabelSegmentAndRatio(points, ratio);
      normalizedSegmentIndex = arcSegmentInfo.segmentIndex;
      ratio = arcSegmentInfo.ratio;
    }

    // The start of the segment
    const start = points[normalizedSegmentIndex];
    // The end of the segment
    const end = points[normalizedSegmentIndex + 1];
    // difference between x/y coordinates for stand and end
    const xDiff = end.x - start.x;
    const yDiff = end.y - start.y;

    // if the label is on the "left" of the line, then is distance should be flipped to a negative value
    distance = left ? distance * -1 : distance;

    const maxDistance = this.getMaxDistance(label, normalizedSegmentIndex);

    //clamp the distance  between the negative and positive maxDistance
    distance = clamp(distance, maxDistance * -1, maxDistance);

    // calculate the angle of the line in degrees 0-360
    let angle =
      ((((-(Math.atan2(start.x - end.x, start.y - end.y) * (180 / Math.PI)) %
        360) +
        360) %
        360) *
        Math.PI) /
      180;

    // calculate a position along the edge using ratio
    const vectorX = start.x + xDiff * ratio - labelSize.width / 2;
    const vectorY = start.y + yDiff * ratio + labelSize.height / 2;

    // apply  a distance from the line at the correct angle to generate a point
    const midPointX = vectorX + distance * Math.cos(angle);
    const midPointY = vectorY + distance * Math.sin(angle);

    return new Point(midPointX, midPointY);
  }

  /**
   * Get the maximum distance a label can be away from the edge.
   * Currently width or height of the label depending on it's orientation / 2
   * plus some padding (edgeLabelOffset)
   * @param label
   * @param initialSegmentIndex
   * @returns
   */
  getMaxDistance(label: ILabel, initialSegmentIndex: number): number {
    const { isHorizontal } = DiagramUtils.getEdgeLabelSegmentInfo(
      label,
      initialSegmentIndex
    );
    return (
      (isHorizontal ? label.preferredSize.height : label.preferredSize.width) /
        2 +
      this.edgeLabelOffset
    );
  }

  createDefaultParameter(): ILabelModelParameter {
    return this.createParameterForSegment(0, 0.5, 0, false);
  }

  createParameterForSegment(
    segmentIndex: number,
    ratio: number,
    distance: number,
    left: boolean
  ): JigsawEdgeLabelModelParameter {
    return new JigsawEdgeLabelModelParameter(this, {
      ratio: ratio,
      segmentIndex: segmentIndex,
      distance: distance,
      left: left
    });
  }

  createMidpointParameter(
    edge: IEdge,
    distance = 0,
    left = false
  ): JigsawEdgeLabelModelParameter {
    const points = JigsawEdgeLabelModel.getEdgePoints(edge);

    if (edge.style instanceof ArcEdgeStyle) {
      return this.createParameterForSegment(0, 0.5, distance, left);
    }
    // Straight line
    if (points.length == 2) {
      return this.createParameterForSegment(0, 0.5, distance, left);
    }

    if (points.length > 3) {
      let midPoint = points.length / 2;
      let ratio = midPoint % 1;

      // adjust for equal number of points on both side, we place the label slightly before the center
      // then offset it half way along that segment.
      if (ratio == 0) {
        ratio = 0.5;
      }
      const segmentIndex = Math.floor(midPoint - 1);
      return new JigsawEdgeLabelModel().createParameterForSegment(
        segmentIndex,
        ratio,
        distance,
        left
      );
    }

    // When there is only two segments (3 points), choose the longest segment
    if (points.length == 3) {
      // get the longest segment index
      let longestSegmentIndex = this.getLongestSegmentIndex(points);
      return this.createParameterForSegment(
        longestSegmentIndex,
        0.5,
        distance,
        left
      );
    }

    return this.createDefaultParameter() as unknown as JigsawEdgeLabelModelParameter;
  }

  private getLongestSegmentIndex(points: IPoint[]): number {
    let longestSegmentIndex = 0;
    let longestSegmentLength = 0;

    for (let index = 0; index < points.length - 1; index++) {
      const p1 = points[index];
      const p2 = points[index + 1];
      const distance = p1.distanceTo(p2);
      if (distance > longestSegmentLength) {
        longestSegmentIndex = index;
        longestSegmentLength = distance;
      }
    }
    return longestSegmentIndex;
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }

  public static normalizeSegmentIndex(
    initialSegmentIndex: number,
    points: IPoint[]
  ): number {
    let segmentIndex = initialSegmentIndex;
    // the segment index can be less than 1, this indicates a percentage a long the whole path, the true segment index is dynamically chosen
    if (!Number.isInteger(segmentIndex)) {
      segmentIndex = Math.ceil((points.length - 1) * segmentIndex) - 1;
    }
    // the segment no longer exists, fallback to 0
    if (segmentIndex >= points.length - 1) {
      segmentIndex = 0;
    }
    return segmentIndex;
  }

  public static getEdgePoints(edge: IEdge): IPoint[] {
    const points = [];
    const path = edge.style.renderer
      .getPathGeometry(edge, edge.style)
      .getPath();
    const cursor = path.createCursor();
    while (cursor.moveNext()) {
      switch (cursor.pathType) {
        case PathType.MOVE_TO:
        case PathType.LINE_TO:
          points.push(cursor.currentEndPoint);
      }
    }

    if (points.length == 0) {
      // When no points are available from the SVG path, could be caused by nothing to draw
      // fall back to ports
      points.push(edge.sourcePort.location, edge.targetPort.location);
    }
    return points;
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    if (!(label.owner instanceof IEdge)) {
      throw 'Label owner must be IEdge';
    }
    const points = JigsawEdgeLabelModel.getEdgePoints(label.owner);
    const totalSegments = points.length - 1;

    let closestSegmentIndex = 0;
    let distance = Number.MAX_SAFE_INTEGER;
    let closestSegmentStart = null;
    let closestSegmentEnd = null;
    const labelSize = label.layout.toSize();

    // Create a new point from the layout X/Y, then append have the label width so it't doesn't jump
    // and stays anchored to the cursor
    // comment the .add to see the behavior we're preventing.
    let point = new Point(layout.anchorX, layout.anchorY).add(
      new Point(labelSize.width / 2, -labelSize.height / 2)
    );

    for (let index = 0; index < totalSegments; index++) {
      const segmentStart = points[index];
      const segmentEnd = points[index + 1];
      const distanceToSegment = this.getDistanceToSegment(
        point.x,
        point.y,
        segmentStart.x,
        segmentStart.y,
        segmentEnd.x,
        segmentEnd.y
      );

      if (distanceToSegment < distance) {
        distance = distanceToSegment;
        closestSegmentIndex = index;
        closestSegmentStart = segmentStart;
        closestSegmentEnd = segmentEnd;
      }
    }
    let ratioInfo = this.getSegmentRatioInfo(
      new Point(point.x, point.y),
      closestSegmentStart,
      closestSegmentEnd
    );
    const { min, max } = this.minMaxRatio();
    let ratio = clamp(ratioInfo.ratio, min, max);

    if (label.owner.style instanceof ArcEdgeStyle) {
      // Arc Edges have all segments combined into a single segment and the ratio is along the whole line
      ratio = (closestSegmentIndex + ratio) / totalSegments;
    }
    return new JigsawEdgeLabelModelParameter(this, {
      segmentIndex: closestSegmentIndex,
      ratio: ratio,
      distance: this.getSnapDistance(label, closestSegmentIndex, distance),
      left: ratioInfo.left
    });
  }

  private getSnapDistance(
    label: ILabel,
    initialSegmentIndex: number,
    distance: number
  ): number {
    // If they user hasn't dragged the label beyond the snap threshhold, we do nothing.
    if (distance < this.snapThreshold) {
      return 0;
    }

    const maxDistance = this.getMaxDistance(label, initialSegmentIndex);

    if (!this.enableSnapping) {
      //clamp the distance between the negative and positive maxDistance
      return clamp(distance, maxDistance * -1, maxDistance);
    }

    // if the max distance is less than the threshold, we use the snapThreshold instead
    return maxDistance < this.snapThreshold ? this.snapThreshold : maxDistance;
  }

  private getSegmentRatioInfo(
    point: IPoint,
    segmentA: IPoint,
    segmentB: IPoint
  ): IRatioInfo {
    const atob = { x: segmentB.x - segmentA.x, y: segmentB.y - segmentA.y };
    const atop = { x: point.x - segmentA.x, y: point.y - segmentA.y };
    const len = atob.x * atob.x + atob.y * atob.y;
    let dot = atop.x * atob.x + atop.y * atob.y;
    const t = GraphElementsComparer.pointsEqual(segmentA, segmentB)
      ? 0
      : Math.min(1, Math.max(0, dot / len));
    dot =
      (segmentB.x - segmentA.x) * (point.y - segmentA.y) -
      (segmentB.y - segmentA.y) * (point.x - segmentA.x);

    return {
      point: {
        x: segmentA.x + atob.x * t,
        y: segmentA.y + atob.y * t
      },
      left: dot < 1,
      dot: dot,
      ratio: t
    };
  }

  private getDistanceToSegment(
    x: number,
    y: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): number {
    const xDiff = x - x1;
    const yDiff = y - y1;
    const segmentXDiff = x2 - x1;
    const segmentYDiff = y2 - y1;

    const dot = xDiff * segmentXDiff + yDiff * segmentYDiff;
    const lengthSquared =
      segmentXDiff * segmentXDiff + segmentYDiff * segmentYDiff;
    let p = -1;
    if (lengthSquared != 0) {
      p = dot / lengthSquared;
    }

    let xx: number = 0;
    let yy: number = 0;

    if (p < 0) {
      xx = x1;
      yy = y1;
    } else if (p > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + p * segmentXDiff;
      yy = y1 + p * segmentYDiff;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

  private minMaxRatio(): { min: number; max: number } {
    let min = 0;
    let max = 1;
    return { min, max };
  }
}
interface IRatioInfo {
  point: {
    x: number;
    y: number;
  };
  left: boolean;
  dot: number;
  ratio: number;
}
