import {
  DocumentDto,
  DocumentPageDto,
  DocumentPageLayoutType,
  DocumentSubPageDto,
  FlipbookState
} from '@/api/models';
import DocumentService from './DocumentService';
import InputModeContext from '@/components/LayoutEditor/InputModes/InputModeContext';
import TextBoxLayoutItem from '@/components/LayoutEditor/Items/TextBoxLayoutItem';
import LayoutItemFactory from '@/components/LayoutEditor/Items/LayoutItemFactory';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import JRect from '@/core/common/JRect';
import ITextBoxLayoutItemOptions from '@/components/LayoutEditor/Items/ITextBoxLayoutItemOptions';
import LayoutItemUtils from '@/components/LayoutEditor/Items/LayoutItemUtils';
import ContentPagination from '../export/ContentPagination';

class DocumentPageTitleService {
  public syncFlipbookGroupMaxTitleHeight(
    document: DocumentDto,
    selectedSubPageRef: DocumentSubPageDto,
    payload: {
      pages: DocumentPageDto[];
      isDelete: boolean;
    }
  ): void {
    const pagesToUpdate = new Set<DocumentPageDto>();
    for (const page of payload.pages) {
      if (page.layoutType !== DocumentPageLayoutType.None) {
        continue;
      }

      let commonPages = DocumentService.getCommonDiagramsGroupPages(
        document,
        page
      );

      // If page is in flipbook group - update maxHeight for each page in group
      if (commonPages.length > 0) {
        if (payload.isDelete) {
          // If it is delete page operation - deleted page should not be included into calculations
          commonPages = commonPages.filter(
            (p) => !payload.pages.some((pg) => pg.id === p.id)
          );
          if (commonPages.length === 0) {
            continue;
          }
        }

        // if we disabled the flipbook for the current page then reset [maxTitleHeight] for [commonPages]
        if (page.diagram.flipbookState !== FlipbookState.Enabled) {
          // do not reset maxTitleHeight for group of continuation pages.
          if (ContentPagination.getPageCount(page) > 1) {
            this.updateContinuationPagesMaxTitleHeight(page);
          } else {
            page.maxTitleHeight = page.showTitle ? page.titleHeight : 0;
            page.subPageRefs?.forEach((sp) => (sp.maxTitleHeight = 0));
          }

          if (page.showTitle) {
            commonPages.forEach((p) => {
              // do not reset maxTitleHeight for group of continuation pages.
              if (ContentPagination.getPageCount(p) > 1) {
                this.updateContinuationPagesMaxTitleHeight(p);
                return;
              }
              p.maxTitleHeight = 0;
              p.subPageRefs?.forEach((sp) => (sp.maxTitleHeight = 0));
              pagesToUpdate.add(p);
            });
          }
          continue;
        }

        // to get an array of [DocumentPageDto] and theirs [DocumentSubPageDto]
        // to take into account [titleHeight] of all pages
        const flatCommonPages = (
          pages: DocumentPageDto[] | DocumentSubPageDto[]
        ): Array<DocumentPageDto | DocumentSubPageDto> => {
          return pages.flatMap((page) => {
            const subPages = page.subPageRefs?.length
              ? flatCommonPages(page.subPageRefs)
              : [];

            const isSubPageOfSelectedPageExist =
              page.subPageRefs &&
              page.subPageRefs.some((sp) => sp.subPageIndex === 0) &&
              ContentPagination.getPageCount(page) === page.subPageRefs.length;
            return [
              ...(isSubPageOfSelectedPageExist ? [] : [page]),
              ...subPages
            ];
          });
        };

        let maxTitleHeight = 0;
        const allCommonPages = flatCommonPages(commonPages);
        const pagesWithTitleShown = allCommonPages.filter((p) => p.showTitle);
        if (pagesWithTitleShown.length > 0) {
          maxTitleHeight = Math.max(
            ...pagesWithTitleShown.map((p) => p.titleHeight)
          );
        }
        commonPages.forEach((p) => {
          if (p.diagram.flipbookState !== FlipbookState.Enabled) {
            return;
          }
          p.maxTitleHeight = maxTitleHeight;
          p.subPageRefs?.forEach((sp) => (sp.maxTitleHeight = maxTitleHeight));
          pagesToUpdate.add(p);
        });
      } else {
        // do not reset maxTitleHeight for group of continuation pages.
        if (ContentPagination.getPageCount(page) > 1) {
          this.updateContinuationPagesMaxTitleHeight(page);
          continue;
        }
        if (selectedSubPageRef) {
          page.subPageRefs.forEach((r) => {
            if (r.diagramId === selectedSubPageRef.diagramId) {
              r.maxTitleHeight = 0;
            }
          });
        } else {
          page.maxTitleHeight = 0;
        }
      }
    }
    requestIdleCallback(() => {
      DocumentService.runPageLayoutOnBackgroundPages(Array.from(pagesToUpdate));
    });
  }

  updateContinuationPagesMaxTitleHeight(page: DocumentPageDto): void {
    let maxTitleHeight = 0;

    // if there is [SubPageDto] of [DocumentPageDto]
    // and subPages exist for all continuation page we do not take into account [DocumentPageDto]
    const isSubPageOfSelectedPageExist =
      page.subPageRefs?.some((sp) => sp.subPageIndex === 0) &&
      ContentPagination.getPageCount(page) === page.subPageRefs?.length;

    const pagesWithTitleShown = [
      ...(isSubPageOfSelectedPageExist ? [] : [page]),
      ...(page.subPageRefs?.length ? page.subPageRefs : [])
    ].filter((p) => p.showTitle);
    if (pagesWithTitleShown.length > 0) {
      maxTitleHeight = Math.max(
        ...pagesWithTitleShown.map((p) => p.titleHeight)
      );
    }

    page.maxTitleHeight = maxTitleHeight;
    page.subPageRefs?.forEach((sp) => (sp.maxTitleHeight = maxTitleHeight));
  }

  createDefaultTextbox(context: InputModeContext): TextBoxLayoutItem {
    const defaultText = 'ADD_TITLE';
    const defaultPreset = 'heading';
    const containerRect = context.containerRect;
    const item: TextBoxLayoutItem = LayoutItemFactory.getItem(
      LayoutItemType.Text,
      new JRect(containerRect.x, containerRect.y),
      {
        defaultText
      } as ITextBoxLayoutItemOptions
    );
    LayoutItemUtils.createTextItem(context.items, item, null, {
      preset: defaultPreset,
      text: defaultText
    });

    item.layout.x = containerRect.x;
    if (item.layout.height < containerRect.height) {
      item.layout.y =
        containerRect.center.y - Math.ceil(item.layout.height / 2);
    }

    return item;
  }

  cleanSubPageRefs(page: DocumentPageDto): void {
    const pageCountAfterMerge = ContentPagination.getPageCount(page);

    const subPagesAfterMerge = Array.from({
      length: pageCountAfterMerge
    }).map((_, index) => index);

    [...page.subPageRefs].forEach((sp, index) => {
      if (!subPagesAfterMerge.includes(sp.subPageIndex)) {
        page.subPageRefs.splice(index, 1);
      }
    });
  }
}

const instance = new DocumentPageTitleService();
export default instance;
