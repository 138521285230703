import { ShapeNodeShape, Point } from 'yfiles';

import {
  NodeSize,
  NodeShape,
  DashStyleType,
  NodeVisualType,
  DataPropertyType,
  EdgeVisualType,
  JigsawPathShape
} from '@/api/models';

let config = {
  renderingDiagram: [
    {
      edgeVisibilityThreshold: 0, //Turned off for now
      arrowVisibilityThreshold: 0.2,
      edgeCroppingThreshold: 1.1,
      edgeHitRadiusModifier: 2,
      gridVisibilityThreshold: 0.01,
      nodeDecoratorZoomThreshold: 0.2,
      labelVisibilityZoomThreshold: 0.2
    },
    {
      edgeVisibilityThreshold: 0, //Turned off for now
      arrowVisibilityThreshold: 0.3,
      edgeCroppingThreshold: 1.1,
      edgeHitRadiusModifier: 2,
      gridVisibilityThreshold: 0.01,
      nodeDecoratorZoomThreshold: 0.3,
      labelVisibilityZoomThreshold: 0.3
    },
    {
      edgeVisibilityThreshold: 0, //Turned off for now
      arrowVisibilityThreshold: 0.4,
      edgeCroppingThreshold: 1.1,
      edgeHitRadiusModifier: 2,
      gridVisibilityThreshold: 0.01,
      nodeDecoratorZoomThreshold: 0.4,
      labelVisibilityZoomThreshold: 0.4
    }
  ],

  renderingSteps: {
    edgeVisibilityThreshold: 0, //Turned off for now
    arrowVisibilityThreshold: 0,
    edgeCroppingThreshold: 1.1,
    edgeHitRadiusModifier: 2,
    gridVisibilityThreshold: 0.01,
    nodeDecoratorZoomThreshold: 0.0,
    labelVisibilityZoomThreshold: 0.0
  },
  nodeSizeFactors: {
    [NodeSize.ExtraExtraSmall]: 1,
    [NodeSize.ExtraSmall]: 1.2,
    [NodeSize.Small]: 1.8,
    [NodeSize.Medium]: 2.6,
    [NodeSize.Large]: 3.4,
    [NodeSize.ExtraLarge]: 4.2
  },
  nodeBaseSizes: {
    width: 45,
    height: 45
  },
  nodeShapes: {
    [NodeShape.Circle]: ShapeNodeShape.ELLIPSE,
    [NodeShape.Oval]: ShapeNodeShape.ELLIPSE,
    [NodeShape.Square]: ShapeNodeShape.RECTANGLE,
    [NodeShape.Rectangle]: ShapeNodeShape.RECTANGLE,
    [NodeShape.Triangle]: ShapeNodeShape.TRIANGLE,
    [NodeShape.RoundedRectangle]: ShapeNodeShape.ROUND_RECTANGLE,
    [NodeShape.Diamond]: ShapeNodeShape.DIAMOND,
    [NodeShape.ShearedRectangle]: ShapeNodeShape.SHEARED_RECTANGLE,
    [NodeShape.ShearedRectangle2]: ShapeNodeShape.SHEARED_RECTANGLE2,
    [NodeShape.Triangle2]: ShapeNodeShape.TRIANGLE2,
    [NodeShape.Star5]: ShapeNodeShape.STAR5,
    [NodeShape.Star6]: ShapeNodeShape.STAR6,
    [NodeShape.Star8]: ShapeNodeShape.STAR8,
    [NodeShape.Trapez]: ShapeNodeShape.TRAPEZ,
    [NodeShape.Trapez2]: ShapeNodeShape.TRAPEZ2,
    [NodeShape.Hexagon]: ShapeNodeShape.HEXAGON,
    [NodeShape.Octagon]: ShapeNodeShape.OCTAGON,
    [NodeShape.FatArrow]: ShapeNodeShape.FAT_ARROW,
    [NodeShape.FatArrow2]: ShapeNodeShape.FAT_ARROW2,
    [NodeShape.Pill]: ShapeNodeShape.ROUND_RECTANGLE,
    [NodeShape.TrapezShort]: ShapeNodeShape.TRAPEZ2,
    [NodeShape.Diamond2]: ShapeNodeShape.DIAMOND
  },
  nodeGridSizes: {
    [NodeShape.Circle]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [10, 10],
        [NodeSize.ExtraSmall]: [16, 16],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Oval]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [16, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 20],
        [NodeSize.Large]: [52, 24],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.Square]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Rectangle]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [16, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 20],
        [NodeSize.Large]: [54, 24],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.Triangle]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [16, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 20],
        [NodeSize.Large]: [52, 24],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.RoundedRectangle]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [16, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 20],
        [NodeSize.Large]: [52, 24],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.Octagon]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Diamond]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.ShearedRectangle]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.ShearedRectangle2]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Triangle2]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [16, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 20],
        [NodeSize.Large]: [52, 24],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.Star5]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Star6]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Star8]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Trapez]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Trapez2]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Hexagon]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.FatArrow]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.FatArrow2]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    },
    [NodeShape.Pill]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [20, 8],
        [NodeSize.ExtraSmall]: [24, 14],
        [NodeSize.Small]: [28, 12],
        [NodeSize.Medium]: [40, 16],
        [NodeSize.Large]: [52, 20],
        [NodeSize.ExtraLarge]: [64, 24]
      }
    },
    [NodeShape.TrapezShort]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [20, 12],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 18],
        [NodeSize.Large]: [52, 26],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    },
    [NodeShape.Diamond2]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 8],
        [NodeSize.ExtraSmall]: [12, 10],
        [NodeSize.Small]: [24, 12],
        [NodeSize.Medium]: [36, 18],
        [NodeSize.Large]: [52, 26],
        [NodeSize.ExtraLarge]: [64, 32]
      }
    }
  },
  jigsawPathNodeGridSizes: {
    [JigsawPathShape.Pentagon]: {
      sizes: {
        [NodeSize.ExtraExtraSmall]: [16, 16],
        [NodeSize.ExtraSmall]: [20, 20],
        [NodeSize.Small]: [24, 24],
        [NodeSize.Medium]: [36, 36],
        [NodeSize.Large]: [52, 52],
        [NodeSize.ExtraLarge]: [64, 64]
      }
    }
  },
  nodeLabelOffsets: {
    shape: {
      [NodeShape.Triangle]: new Point(0, 10),
      [NodeShape.Triangle2]: new Point(0, -15)
    }
  },
  // padding applied to help determine the where a nodes label can be moved too
  nodeLabelPadding: 9,
  nodeRadiusFlexRatios: {
    [NodeShape.Pill]: 0.25
  },
  svgPalette: {
    sizeFactors: {
      [NodeSize.Small]: 8,
      [NodeSize.Medium]: 12,
      [NodeSize.Large]: 16
    },
    sizes: {
      default: NodeSize.Medium,
      annotation: NodeSize.Small,
      annotationCross: NodeSize.Large
    },
    category: {
      blackHatch: 'HATCH', // this must remain as 'HATCH' don't change to 'BLACK_HATCH'
      redHatch: 'HATCH_RED',
      redCross: 'RED_CROSS',
      blackCross: 'BLACK_CROSS'
    }
  },
  label: {
    defaultLabel: {
      backgroundColor: 'transparent',
      inset: 5
    },
    imageLabel: {
      backgroundColor: 'transparent',
      inset: 0
    },
    edgeLabel: {
      backgroundColor: 'white',
      inset: 0
    },
    annotationTextLabel: {
      backgroundColor: 'transparent',
      inset: 0,
      margin: 10
    },
    distanceToEdge: 5,
    maxDistanceToEdge: 25,
    outOfViewHoverDelay: 1000,
    outOfViewScrollOffset: 10
  },
  grid: {
    size: 6,
    minShapeSize: 18,
    minTextBoxSize: 6,
    jumpSize: 1,
    nodeSpacingFactors: 16,
    color: '#DEDEDE',
    viewSize: {
      web: 6,
      print: 16
    },
    //The zoom increments at which grid spacing is increased
    spacingZoomThresholds: {
      maximum: 0.7,
      large: 0.35,
      medium: 0.125,
      small: 0.06,
      minimum: 0.03
    }
  },
  stretchNodes: [
    NodeShape.Oval,
    NodeShape.Rectangle,
    NodeShape.RoundedRectangle
  ],
  stretchNodeFactor: 2,
  supportedNodeVisualTypes: [
    NodeVisualType.Shape,
    NodeVisualType.Composite,
    NodeVisualType.Image
  ],
  supportedEdgeVisualTypes: [
    EdgeVisualType.Curved,
    EdgeVisualType.Straight,
    EdgeVisualType.Elbow,
    EdgeVisualType.Arc
  ],
  supportedDashStyles: [
    DashStyleType.Solid,
    DashStyleType.Dash,
    DashStyleType.DashDot,
    DashStyleType.DashDotDot,
    DashStyleType.Dot
  ],
  defaultFontStyles: [
    {
      model: 'normal',
      view: 'normal',
      title: 'Normal'
    },
    {
      model: 'heading',
      view: 'h1',
      title: 'Heading'
    },
    {
      model: 'subheading',
      view: 'h2',
      title: 'Subheading'
    },
    {
      model: 'footnote',
      view: 'h3',
      title: 'Footnote'
    }
  ],
  supportedFontFamilies: [
    'Arial',
    'Arial Narrow',
    'Calibri',
    'Courier New',
    'Ebrima',
    'Minion Pro',
    'Georgia',
    'Trebuchet MS',
    'Times New Roman',
    'Verdana'
  ],
  supportedFontStyles: ['Normal', 'Italic'],
  supportedFontWeights: ['Normal', 'Bold'],
  supportedFontTextDecoration: ['None', 'Underline'],
  supportedFontSizes: [
    6, 7, 8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36,
    40, 44, 48, 54, 60, 66, 72, 80, 88, 96
  ],
  dataPropertyControlTypes: [
    { type: DataPropertyType.Boolean, icon: 'la-toggle-on' },
    { type: DataPropertyType.Date, icon: 'la-calendar-day' },
    { type: DataPropertyType.Dropdown, icon: 'la-stream' },
    { type: DataPropertyType.MultiSelect, icon: 'la-list' },
    { type: DataPropertyType.Number, icon: 'la-arrows-alt-v' },
    { type: DataPropertyType.Text, icon: 'la-comment' },
    { type: DataPropertyType.People, icon: 'la-person' }
  ],
  zoomLevels: [0.02, 0.03, 0.06, 0.13, 0.25, 0.5, 1, 2, 4],
  minimumZoom: 0.02,
  maximumZoom: 4,
  defaultZoomLevel: 1.2,
  defaultEdgeLabelHitTestRadius: 18,
  defaultHitTestRadius: 4,
  defaultFontFamily: 'Arial',
  defaultFontSize: 10,
  defaultTextColor: '#000000',
  defaultFontWeight: 'normal',
  defaultFontStyle: 'normal',
  defaultTextDecoration: 'none',
  defaultNodeLabelLineHeight: '1',
  maxDecoratorSlots: 4,
  offsetRightFromCanvasLeftBound: {
    small: 8,
    medium: 6,
    large: 4
  },
  paletteItemDropOffsetStep: 1,
  cursorsUrls: {
    DOT_NORMAL: '/resources/cursors/dot-normal.cur',
    DOT_SMALL: '/resources/cursors/dot-normal.cur',
    CIRCLE: '/resources/cursors/dot-normal.cur'
  },
  diagramLogoWidth: 200,
  sideBarWidth: 280,
  sideBarMaxWidth: 320,
  sideBarThumbWidth: 20,
  defaultTransitionDuration: 300,
  quickStartCompleteFadeOutMs: 3000,
  contextMenu: {
    contextMenuWidth: 180,
    submenuMaxWidth: 270
  },
  maxNodeEdgeRadius: 50,
  diagramScrollbarSize: 15,
  layoutAnimationDurationMs: 450,
  legend: {
    nodeName: 'LEGEND',
    nodePadding: 22,
    nodeItemWidth: 146,
    defaultLayout: {
      itemMinWidth: 165,
      incrementStep: 80
    },
    stepsDefaultLayout: {
      itemMinWidth: 100,
      incrementStep: 80
    },
    masterDefaultLayout: {
      itemMinWidth: 180,
      incrementStep: 80
    },
    legendPadding: 10,
    legendBorderWidth: 1
  },
  selection: {
    default: {
      color: '#15C1FF',
      thickness: 4,
      padding: 5
    },
    textbox: {
      color: '#15C1FF',
      thickness: 1,
      padding: 3
    }
  },
  arrowGrowthFactor: 0.4,
  clearFiltersButtonHeight: 50,
  dataPanelOffsetY: 20,
  diagramControlsHeight: 28,
  diagramControlsWidth: 160,
  groupGridSpaces: 18,
  groupNodePaddingWidth: 0,
  groupNodePaddingHeight: 0,
  groupingDefaults: {
    strokeColor: '#000000',
    strokeDash: DashStyleType.Dash,
    strokeWidth: 1,
    fillOpacity: 0.3,
    fillColor: '#ffffff',
    margin: 20,
    glueExtent: 20,
    minimumBridgeSize: 20
  },
  dividingLines: {
    minimumSize: 30
  },
  snapLines: {
    color: '#ff00a0',
    thickness: 2
  },
  defaultAnimationDuration: 450
};

config.groupNodePaddingWidth = config.groupGridSpaces * config.grid.size;
config.groupNodePaddingHeight = config.groupGridSpaces * config.grid.size;

export default config;
