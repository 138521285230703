import {
  ArrowStyleDto,
  CompositeNodeStyleDto,
  DashStyleDto,
  DataPropertyDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  EdgePortDto,
  EdgeStyleDto,
  SolidColorFillDto,
  ImageNodeStyleDto,
  INodeStyleDto,
  JigsawPathShapeNodeStyleDto,
  LayoutDto,
  NodeVisualType,
  ShapeNodeStyleDto,
  IFillDto,
  FillType,
  LinearGradientDto
} from '@/api/models';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames
} from '@/core/common/DataPropertyDisplayType';
import DecorationState from '@/core/styles/DecorationState';
import JurisdictionDecorator, {
  JurisdictionDecorationState
} from '@/core/styles/decorators/JurisdictionDecorator';
import { isNil } from 'lodash';
import isEqual from 'lodash/isEqual';

export class SerializedDiagramEntityComparer {
  public static labelsEqual(labelA: string, labelB: string): boolean {
    if (isNil(labelA) && isNil(labelB)) {
      return true;
    }
    return labelA === labelB;
  }

  public static labelDataEquals(labelDataA: any, labelDataB: any): boolean {
    if (isNil(labelDataA) && isNil(labelDataB)) {
      return true;
    }
    return isEqual(labelDataA, labelDataB);
  }

  public static nodeDataPropertyStyleStateEquals(
    dataPropertyStyleDataA: { isActive: boolean },
    dataPropertyStyleDataB: { isActive: boolean }
  ): boolean {
    return dataPropertyStyleDataA.isActive === dataPropertyStyleDataB.isActive;
  }

  public static nodeDataPropertyDisplayTypesEqual(
    dataPropertyDisplayTypeA: { [name: string]: DataPropertyDisplayType[] },
    dataPropertyDisplayTypeB: { [name: string]: DataPropertyDisplayType[] },
    dataPropertyDisplayTypeName: DataPropertyDisplayTypeNames,
    dataPropertyDisplayType: DataPropertyDisplayType
  ): boolean {
    const stateA = dataPropertyDisplayTypeA[
      dataPropertyDisplayTypeName
    ].includes(dataPropertyDisplayType);
    const stateB = dataPropertyDisplayTypeB[
      dataPropertyDisplayTypeName
    ].includes(dataPropertyDisplayType);

    return stateA === stateB;
  }

  /**
   * Compares only jurisdiction or state data properties
   * @param dataPropertiesA
   * @param dataPropertiesB
   * @param definitionId Must be appConsts.JURISDICTION_DEFINITION_ID or appConsts.STATE_DEFINITION_ID
   * @returns
   */
  public static nodeJurisdictionOrStateDataEquals(
    dataPropertyA: DataPropertyDto,
    dataPropertyB: DataPropertyDto
  ): boolean {
    if (
      (dataPropertyA && !dataPropertyB) ||
      (!dataPropertyA && dataPropertyB) ||
      (dataPropertyA &&
        dataPropertyB &&
        dataPropertyA.value !== dataPropertyB.value)
    ) {
      return false;
    }

    return true;
  }

  public static jurisdictionLocationsEqual(
    decorationStatesA: { [name: string]: DecorationState },
    decorationStatesB: { [name: string]: DecorationState }
  ): boolean {
    const jurisdictionLocationA = (
      decorationStatesA[
        JurisdictionDecorator.INSTANCE.$class
      ] as JurisdictionDecorationState
    ).jurisdictionLocation;
    const jurisdictionLocationB = (
      decorationStatesB[
        JurisdictionDecorator.INSTANCE.$class
      ] as JurisdictionDecorationState
    ).jurisdictionLocation;

    if (!jurisdictionLocationA || !jurisdictionLocationB) {
      return true;
    }

    return (
      jurisdictionLocationA.fixedPosition ===
        jurisdictionLocationB.fixedPosition &&
      jurisdictionLocationA.ratio.x === jurisdictionLocationB.ratio.x &&
      jurisdictionLocationA.ratio.y === jurisdictionLocationB.ratio.y
    );
  }

  public static fillsEqual(fillA: IFillDto, fillB: IFillDto): boolean {
    if (fillA.type !== fillB.type) return false;
    if (fillA.type == FillType.SolidColor) {
      return (
        (fillA as SolidColorFillDto).color ===
        (fillB as SolidColorFillDto).color
      );
    } else if (fillA.type == FillType.LinearGradient) {
      const gradientA = fillA as LinearGradientDto;
      const gradientB = fillB as LinearGradientDto;
      return (
        gradientA.startPoint.x === gradientB.startPoint.x &&
        gradientA.startPoint.y === gradientB.startPoint.y &&
        gradientA.endPoint.x === gradientB.endPoint.x &&
        gradientA.endPoint.y === gradientB.endPoint.y &&
        gradientA.spreadMethod === gradientB.spreadMethod &&
        gradientA.gradientStops.length === gradientB.gradientStops.length &&
        gradientA.gradientStops.every(
          (stop, index) =>
            stop.color === gradientB.gradientStops[index].color &&
            stop.offset === gradientB.gradientStops[index].offset
        )
      );
    }
  }

  public static thicknessEqual(
    thicknessA: number,
    thicknessB: number
  ): boolean {
    return thicknessA === thicknessB;
  }

  public static anglesEqual(angleA: number, angleB: number): boolean {
    return angleA === angleB;
  }

  public static dashesEqual(
    dashStyleA: DashStyleDto,
    dashStyleB: DashStyleDto
  ): boolean {
    return dashStyleA.offset === dashStyleB.offset &&
      dashStyleA.dashes &&
      dashStyleB.dashes
      ? dashStyleA.dashes.every(
          (dash, index) => dash === dashStyleB.dashes[index]
        )
      : dashStyleA?.dashes?.length == dashStyleB?.dashes?.length;
  }

  public static arrowTypesEqual(
    styleA: ArrowStyleDto,
    styleB: ArrowStyleDto
  ): boolean {
    return styleA.type === styleB.type;
  }

  public static edgeBridgeStatesEqual(
    styleA: EdgeStyleDto,
    styleB: EdgeStyleDto
  ): boolean {
    return styleA.bridge === styleB.bridge;
  }

  public static edgePortDirectionsEqual(
    portDirectionA: number,
    portDirectionB: number
  ): boolean {
    return portDirectionA === portDirectionB;
  }

  public static displayOrdersEqual(orderA: number, orderB: number): boolean {
    return orderA === orderB;
  }

  public static layoutsEqual(layoutA: LayoutDto, layoutB: LayoutDto): boolean {
    return (
      layoutA.height === layoutB.height &&
      layoutA.width === layoutB.width &&
      layoutA.x === layoutB.x &&
      layoutA.y === layoutB.y
    );
  }

  public static nodeLocationsEqual(
    layoutA: LayoutDto,
    layoutB: LayoutDto
  ): boolean {
    return layoutA.x === layoutB.x && layoutA.y === layoutB.y;
  }

  public static nodeSizesEqual(
    layoutA: LayoutDto,
    layoutB: LayoutDto
  ): boolean {
    return layoutA.height === layoutB.height && layoutA.width === layoutB.width;
  }

  public static nodeCentersEqual(
    layoutA: LayoutDto,
    layoutB: LayoutDto
  ): boolean {
    return (
      layoutA.y + layoutA.height / 2 === layoutB.y + layoutB.height / 2 &&
      layoutA.x + layoutA.width / 2 === layoutB.x + layoutB.width / 2
    );
  }

  public static nodeTypesEqual(
    nodeA: DiagramNodeDto,
    nodeB: DiagramNodeDto
  ): boolean {
    return nodeA.data['name'] === nodeB.data['name'];
  }

  public static edgeTypesEqual(
    edgeA: DiagramEdgeDto,
    edgeB: DiagramEdgeDto
  ): boolean {
    return edgeA.data['name'] === edgeB.data.name;
  }

  /**
   *
   * @param portAData Port object and node uuid to which that port belongs
   * @param portBData Port object and node uuid to which that port belongs
   * @returns
   */
  public static edgePortsEqual(
    portAData: { port: EdgePortDto; nodeUuid: string },
    portBData: { port: EdgePortDto; nodeUuid: string }
  ): boolean {
    return (
      portAData.port.x === portBData.port.x &&
      portAData.port.y === portBData.port.y &&
      (portAData.port.portDirection ?? 0) ==
        (portBData.port.portDirection ?? 0) &&
      portAData.nodeUuid === portBData.nodeUuid
    );
  }

  public static edgeBendsEqual(
    newEdge: DiagramEdgeDto,
    oldEdge: DiagramEdgeDto
  ): boolean {
    const newEdgeBends = newEdge.bends as Array<{ x: number; y: number }>;
    const oldEdgeBends = oldEdge.bends as Array<{ x: number; y: number }>;

    // compare only if both values are initialized (DiagramReader issue)
    if (!newEdgeBends || !oldEdgeBends) {
      return newEdgeBends?.length == oldEdgeBends?.length;
    }

    if (newEdgeBends.length !== oldEdgeBends.length) {
      return false;
    } else {
      for (let i = 0; i < newEdgeBends.length; ++i) {
        if (
          newEdgeBends[i].x !== oldEdgeBends[i].x ||
          newEdgeBends[i].y !== oldEdgeBends[i].y
        ) {
          return false;
        }
      }
    }

    return true;
  }

  public static edgeHeightsEqual(
    edgeA: DiagramEdgeDto,
    edgeB: DiagramEdgeDto
  ): boolean {
    return edgeA.style.height === edgeB.style.height;
  }

  public static nodeStylesEqual(
    styleA: INodeStyleDto,
    styleB: INodeStyleDto
  ): boolean {
    if (styleA.visualType !== styleB.visualType) {
      return false;
    }
    switch (styleA.visualType) {
      case NodeVisualType.Shape: {
        styleA = styleA as ShapeNodeStyleDto;
        styleB = styleB as ShapeNodeStyleDto;
        return (
          this.fillsEqual(
            (styleA as ShapeNodeStyleDto).fill,
            (styleB as ShapeNodeStyleDto).fill
          ) &&
          this.fillsEqual(
            (styleA as ShapeNodeStyleDto).stroke.fill,
            (styleB as ShapeNodeStyleDto).stroke.fill
          ) &&
          this.dashesEqual(
            (styleA as ShapeNodeStyleDto).stroke.dashStyle,
            (styleB as ShapeNodeStyleDto).stroke.dashStyle
          ) &&
          this.thicknessEqual(
            (styleA as ShapeNodeStyleDto).stroke.thickness,
            (styleB as ShapeNodeStyleDto).stroke.thickness
          )
        );
      }
      case NodeVisualType.JigsawPathShape: {
        styleA = styleA as JigsawPathShapeNodeStyleDto;
        styleB = styleB as JigsawPathShapeNodeStyleDto;
        return (
          this.fillsEqual(
            (styleA as JigsawPathShapeNodeStyleDto).fill,
            (styleB as JigsawPathShapeNodeStyleDto).fill
          ) &&
          this.fillsEqual(
            (styleA as JigsawPathShapeNodeStyleDto).stroke.fill,
            (styleB as JigsawPathShapeNodeStyleDto).stroke.fill
          ) &&
          this.dashesEqual(
            (styleA as JigsawPathShapeNodeStyleDto).stroke.dashStyle,
            (styleB as JigsawPathShapeNodeStyleDto).stroke.dashStyle
          ) &&
          this.thicknessEqual(
            (styleA as JigsawPathShapeNodeStyleDto).stroke.thickness,
            (styleB as JigsawPathShapeNodeStyleDto).stroke.thickness
          )
        );
      }
      case NodeVisualType.Image: {
        return this.anglesEqual(
          (styleA as ImageNodeStyleDto).rotation,
          (styleB as ImageNodeStyleDto).rotation
        );
      }
      case NodeVisualType.Composite: {
        const compositeStylesA = (
          styleA as CompositeNodeStyleDto
        ).styleDefinitions.map((d) => d.nodeStyle);
        const compositeStylesB = (
          styleB as CompositeNodeStyleDto
        ).styleDefinitions.map((d) => d.nodeStyle);
        if (compositeStylesA.length !== compositeStylesB.length) {
          return false;
        }

        return compositeStylesA.every(
          (stylesDefinitionA, index) =>
            this.fillsEqual(
              stylesDefinitionA.fill,
              compositeStylesB[index].fill
            ) &&
            this.fillsEqual(
              stylesDefinitionA.stroke.fill,
              compositeStylesB[index].stroke.fill
            ) &&
            this.dashesEqual(
              stylesDefinitionA.stroke.dashStyle,
              compositeStylesB[index].stroke.dashStyle
            ) &&
            this.thicknessEqual(
              stylesDefinitionA.stroke.thickness,
              compositeStylesB[index].stroke.thickness
            )
        );
      }
    }

    return true;
  }

  public static dataPropertyArraysEqual(
    oldDataProperties: DataPropertyDto[],
    newDataProperties: DataPropertyDto[]
  ): boolean {
    if (oldDataProperties.length !== newDataProperties.length) return false;

    for (const oldDataProperty of oldDataProperties) {
      const correspondingNewDataProperty = newDataProperties.find(
        (newDataProperty) =>
          newDataProperty.dataPropertyDefinitionId ===
          oldDataProperty.dataPropertyDefinitionId
      );
      if (
        !correspondingNewDataProperty ||
        !this.dataPropertiesEqual(oldDataProperty, correspondingNewDataProperty)
      ) {
        return false;
      }
    }
    return true;
  }

  public static dataPropertiesEqual(
    dataPropertyA: DataPropertyDto,
    dataPropertyB: DataPropertyDto
  ): boolean {
    return (
      dataPropertyA.dataPropertyDefinitionId ===
        dataPropertyB.dataPropertyDefinitionId &&
      dataPropertyA.value === dataPropertyB.value &&
      dataPropertyA.index === dataPropertyB.index
    );
  }
}
